import React from "react";
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import moment from "moment";
import { getPublicMooringInfo } from "../actions/moorings";

import { Container, FormControl, Form, Button, Alert, Spinner } from "react-bootstrap";
import FormInput from '../components/FormInput'

import bp_banner from "../assets/bp_banner_large.png"; // Tell webpack this JS file uses this image
import COLORS from "../constants/colors";

const LightRed = "https://resources.boatpark.app/mooring_status/light_red.png";
const LightGreen = "https://resources.boatpark.app/mooring_status/light_green.png";
const i18nPrefix = "MooringStatus";

class MooringStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mooring: false,
      loading: true,
      success: false,
      error: false,
    };

  }

  componentDidMount() {

    if (this.props.match.params) {
      let mooring_id = this.props.match.params.mooring_id;

      getPublicMooringInfo(mooring_id, moment(), moment().add(1, 'day'),
        (mooring) => { this.setState({ mooring: mooring, loading: false }) },
        (error) => {
          this.setState({ error: error, loading: false })
        })
    }
  }





  render() {
    const { t } = this.props
    const { mooring } = this.state
    const instantBookingPossible = mooring.availability_status == 'available'
    const lightImage = instantBookingPossible ? LightGreen : LightRed;
    const title = instantBookingPossible ? t(`${i18nPrefix}.available`) : t(`${i18nPrefix}.unavailable`)
    const infoTextHtml = instantBookingPossible ? t(`${i18nPrefix}.infoAvailable`) : t(`${i18nPrefix}.infoUnavailable`)

    const link = String(window.location.href)
    const linkWithoutLanguage = link.includes("?lng=") ? link.substring(0, link.length - 7) : link

    return (
      <Container style={styles.container} className="no-user-select" >
        <img
          style={styles.banner}
          src={bp_banner}
          alt="Logo"
        />

        {/* Show loading indicaor only during loading */}
        {this.state.loading &&
          <div style={styles.contentRow}>
            <Spinner style={{ width: 50, height: 50 }} animation="border" variant="secondary" role="status" />
          </div>
        }


        {!this.state.loading && <div>
          <div style={styles.titleContainer}>
            <h1 style={{ margin: 0 }}>{title}</h1>
          </div>
          <img
            style={styles.imgLight}
            src={lightImage}
            alt="Logo"
          />


          <div style={{ marginBottom: 20 }} dangerouslySetInnerHTML={{ __html: infoTextHtml }} ></div>
          {instantBookingPossible &&
            <div>
              <button
                style={styles.buttonInstantBooking}
                onClick={() => {
                  window.location.href = `/FastBooking/${this.state.mooring.mooring_id}`;
                }}>
                <div style={{ fontWeight: 'bold' }}>
                  {t(`${i18nPrefix}.instantBooking`)}
                </div>
              </button>
            </div>
          }
        </div>
        }

        <div style={styles.contentRow}>
          <a style={styles.languageLink} href={`${linkWithoutLanguage}?lng=en`}>English</a><p>&nbsp;-&nbsp;</p>
          <a style={styles.languageLink} href={`${linkWithoutLanguage}?lng=de`}>Deutsch</a><p>&nbsp;-&nbsp;</p>
          <a style={styles.languageLink} href={`${linkWithoutLanguage}?lng=fr`}>Français</a><p>&nbsp;-&nbsp;</p>
          <a style={styles.languageLink} href={`${linkWithoutLanguage}?lng=it`}>Italiano</a><p>&nbsp;-&nbsp;</p>
          <a style={styles.languageLink} href={`${linkWithoutLanguage}?lng=es`}>Espagnol</a>
        </div>
        <div>
          <div style={styles.contentRow} >
            <a href="https://apps.apple.com/app/id1466777753">
              <img style={styles.imgStores} src="https://resources.boatpark.app/mooring_status/apple.png" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.boatpark">
              <img style={styles.imgStores} src="https://resources.boatpark.app/mooring_status/google.png" />
            </a>
          </div>
        </div>

      </Container >
    );
  }
}


const styles = {
  container: {
    maxWidth: 800,
    borderRadius: 5,
  },
  banner: {
    display: 'block',
    width: '30%',
    minWidth: 200,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: 20,
  },
  titleContainer: {
    width: '100%',
    backgroundColor: COLORS.SECONDARY_BLUE,
    borderRadius: 5,
    color: COLORS.MAIN_WHITE,
    padding: 20,
    textAlign: "center",
    marginBottom: 20,
  },
  imgLight: {
    display: 'block',
    width: '80%',
    maxWidth: 300,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: 20,
  },
  buttonInstantBooking: {
    padding: 20,
    display: 'block',
    width: '30%',
    minWidth: 200,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 20,
    marginBottom: 40,
    backgroundColor: COLORS.SECONDARY_BLUE,
    borderRadius: 5,
    outline: 'none',
    border: 'none',
    color: COLORS.MAIN_WHITE,
  },
  languageLink: {
    color: 'black',
  },
  contentRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 20,
  },
  imgStores: {
    margin: 10,
  },
};

export default withTranslation()(MooringStatus);