import React from "react";
import axios from "axios";
import "../i18n"
import i18n from "../i18n";

import {
  Container,
  Form,
  Row,
  Button,
  Col,
  Alert,
  Modal,
  Collapse,
} from "react-bootstrap";
import FormInput from '../components/FormInput'
import FormCheckbox from '../components/FormCheckbox'


import { verifyEmail } from '../actions/auth'
import { updateAndResendActivationEmail } from '../actions/register'
import { getAccountDataByToken } from '../actions/account'
import { withTranslation } from 'react-i18next';
import { validateRegisterform } from '../utils/validators';
import StatusAlerts from "../components/StatusAlerts";



const i18nPrefix = 'ResendEmail';


const dropDownArrowStyle = {
  transition: 'transform 250ms ease', // smooth transition
  float: "right",
  height: 15,
  marginTop: 8,
  marginRight: 10,
}


class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      error: false,
      account_token: "",
      email: "",
      mobile: "",

    };
  }


  componentDidMount() {

    // Check if we are registered with a account_token
    if (this.props.match.params.account_token) {
      const token = this.props.match.params.account_token
      getAccountDataByToken(token,
        (account) => {
          console.log("account", account)
          this.setState({
            account_token: token,
            account_id: account.account_id,
            firstname: account.firstname,
            lastname: account.lastname,
            email: account.email,
            mobilenumber: account.mobilenumber,
          });
        },
        (error) => {
          this.setState({ loading: false, success: false, error: true });
        })
    }
  }


  handleResendClick = () => {
    const data = {
      account_token: this.state.account_token,
      email: this.state.email,
      mobile: this.state.mobile,
    }
    this.setState({ loading: true, success: false, error: false, }, () => {
      updateAndResendActivationEmail(data,
        (response) => {
          this.setState({ loading: false, success: true, error: false, successMessage: this.props.t(`${i18nPrefix}.emailHasBeenSent`, { email: this.state.email }) })
        },
        (err) => {
          this.setState({ loading: false, uccess: false, error: true, errorMessage: this.props.t('errors.somethingWentWrong') })
        }
      )
    })
  }

  render() {
    const { t } = this.props;
    const formErrors = {}

    return (
      <Container style={{ maxWidth: 800, marginTop: 50, borderRadius: 5, backgroundColor: "white" }}>
        <h1>{t(`${i18nPrefix}.title`)}</h1>
        <FormInput
          type="text"
          label={t(`${i18nPrefix}.email`)}
          value={this.state.email}
          error={formErrors.email}
          onChange={(event) =>
            this.setState({ email: event.target.value })
          }
        />
        <FormInput
          type="tel"
          label={t(`${i18nPrefix}.mobile`)}
          value={this.state.mobilenumber}
          error={formErrors.mobilenumber}
          onChange={(event) =>
            this.setState({ mobilenumber: event.target.value })
          }
          disabled
        />
        <Button disabled={this.state.loading} variant="primary" type="submit" onClick={() => this.handleResendClick()}>
          {t(`${i18nPrefix}.resend`)}
        </Button>

        <StatusAlerts
          state={this.state}
          successMessage={this.state.successMessage}
          errorMessage={this.state.errorMessage}
        />
      </Container >



    );
  }
}


export default withTranslation()(Register)
