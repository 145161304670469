import React from 'react';
import PropTypes from 'prop-types';
import COLORS from '../constants/colors';
import moment from "moment/moment";


import { useTranslation } from 'react-i18next';
import {
    Container,
    Form,
    Row,
    Button,
    Col,
    Alert,
    Modal,
} from "react-bootstrap";

export default function DateTimeText(props) {

    const { t, i18n } = useTranslation();
    const label = props.label || "";
    const last = props.last ? true : false;
    const { error } = props
    var errorMessage = ""
    if (error) {
        errorMessage = error == "*" ? "*" : t(`validators.${error}`)
    }

    // Value needs to be in YYYY-MM-DD format for date input
    const value = props.value ? moment(props.value).format("YYYY-MM-DD") : ""
    return (
        <div style={{ marginBottom: 5 }}>

            <div
                style={{
                    color: COLORS.MAIN_GRAY,
                    alignSelf: 'center',
                    fontSize: 14,
                    float: 'left'
                }}>


                {label.toUpperCase()}
                {props.unit ? ` [${props.unit}]` : ""}

            </div>
            {props.type == "file" &&
                <div style={{ overflow: "hidden", height: 15, width: "100%" }}></div>
            }
            {error && <div style={{
                float: "right", color: "red", marginRight: 10, color: COLORS.MAIN_RED,
                alignSelf: 'center',
                fontSize: 14,
                left: 3,
            }}>{errorMessage}</div>}
            <div>

            </div>
            <Form.Control
                style={{ border: "none", borderRadius: 0, paddingLeft: 0, marginLeft: -3, marginBottom: -5, backgroundColor: "white", color: COLORS.MAIN_BLUE, fontWeight: "bold", fontSize: 20 }}
                ref={props.ref}
                type="date"
                {...props}
                value={value}
            />

            <div>{props.timelabel} {props.time}</div>
            {props.type == "file" &&
                <div style={{ overflow: "hidden", height: 5, width: "100%" }}></div>
            }
            {!last && <div style={{ width: "100%", height: 1, backgroundColor: COLORS.GRAY_OUTLINE }}></div>}
        </div >
    );


}

DateTimeText.propTypes = {
    label: PropTypes.string.isRequired
};


