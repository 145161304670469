import React from "react";
import { withTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import COLORS from '../constants/colors';
import {
    Row,
    Col,
    Button,
} from "react-bootstrap";
import AddIcon from '../assets/icons/add.png'


class AddNewButton extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        const { style } = this.props
        return (
            <div className="add-new-button" style={this.props.center ? styles.centerContainer : {}}>
                <Button
                    className="button-container"
                    style={{ ...styles.addButtonContainer, ...style }}
                    onClick={this.props.onClick}
                >
                    <Row>
                        <Col sm={1}>
                            <img
                                style={styles.addButton}
                                src={AddIcon}
                                alt="icon"
                            />
                        </Col>
                        <Col sm={10}>
                            {this.props.label}
                        </Col>
                    </Row>
                </Button>
            </div >
        );
    }
}

const styles = {
    centerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    addButtonContainer: {
        color: COLORS.MAIN_BLUE,
        fontWeight: 'bold',
        flexDirection: 'column',
        borderStyle: 'dashed',
        borderWidth: 1,
        borderRadius: 5,
        borderColor: COLORS.BLUE_OPAQUE,
        backgroundColor: COLORS.GRAY_OPAQUE,
        marginTop: 0,
        marginBottom: 10,
        maxWidth: 400,
    },
    addButton: {
        resizeMode: 'contain',
        height: 20,
        marginTop: 2,
        verticalAlign: 'top',
        boxSizing: 'border-box'
    },
}


export default withTranslation()(AddNewButton)