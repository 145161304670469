import React from "react";

import {
  Row,
  Col,
} from "react-bootstrap";
import FormInput from '../components/FormInput';
import { SegmentedControl } from 'segmented-control-react';





export default class MooringDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      error: false,
      mooring: {
      },

    };
  }

  componentDidMount() {


  }




  render() {
    const { mooring } = this.props;

    return (
      <div style={{ backgroundColor: "white", padding: 10 }}>
        <h3>Details</h3>

        <FormInput
          readOnly
          type="text"
          label="Dates"
          value={`From ${this.props.from_date} To ${this.props.to_date}`}
          readOnly
        />


        <FormInput
          readOnly
          type="text"
          label="Coordinates"
          value={`${mooring.latitude} / ${mooring.longitude}`}
          readOnly
        />

        <FormInput
          readOnly
          label="Max Length"
          type="number"
          step={0.1}
          precision={2}
          value={this.props.mooring.max_length}
        />

        <FormInput
          readOnly
          label="Max Width"
          type="number"
          step={0.1}
          precision={2}
          value={this.props.mooring.max_beam}
        />

        <FormInput
          readOnly
          label="Max Beam"
          type="number"
          step={0.1}
          precision={2}
          value={this.props.mooring.max_draught}
        />

        <FormInput
          readOnly
          label="Harbor Name"
          type="tyêxt"
          value={this.props.mooring.harbour_name}
        />

        <FormInput
          readOnly
          label="Berth Number"
          type="text"
          value={this.props.mooring.berth_number}
        />

        <FormInput
          readOnly
          label="Pier Height"
          type="number"
          step={0.1} precision={2}
          value={this.props.mooring.pier_height}
        />


        <div>
          <Row>
            <Col md={6}>
              Power
            </Col>
            <Col md={6}>
              <SegmentedControl
                style={{ border: "none" }}
                selected={parseInt(this.props.mooring.power)}
                variant="base"
                segments={[
                  { name: "No" },
                  { name: "Yes" },

                ]}



              />
            </Col>
          </Row>

        </div>


        <div>
          <Row>
            <Col md={6}>
              water
            </Col>
            <Col md={6}>
              <SegmentedControl
                style={{ border: "none" }}
                selected={parseInt(this.props.mooring.water)}
                variant="base"
                segments={[
                  { name: "No" },
                  { name: "Yes" },

                ]}


              />
            </Col>
          </Row>

        </div>


        <div>
          <Row>
            <Col md={6}>
              buoy
            </Col>
            <Col md={6}>
              <SegmentedControl
                style={{ border: "none", height: 30 }}
                selected={parseInt(this.props.mooring.buoy)}
                variant="base"
                segments={[
                  { name: "No" },
                  { name: "Yes" },

                ]}



              />
            </Col>
          </Row>

        </div>




        <FormInput
          readOnly
          label="Additional Description"
          type="text"
          value={this.props.mooring.additional_description}
        />

        <FormInput
          readOnly
          label="Confidential Description"
          type="text"
          value={this.props.mooring.confidential_description}
        />
        <h3>Home of</h3>
        <FormInput
          readOnly
          label="Home Boat Name"
          type="text"
          value={this.props.mooring.home_boat_name}
        />
        <FormInput
          readOnly
          last={1}
          label="Port of registry"
          type="text"
          value={this.props.mooring.home_boat_port_of_registry}
        />

        <h3>Availabilities</h3>
        {this.props.mooring.available_periods.map(period => (
          <div key={period.from_date}>{`From: ${period.from_date} To: ${period.to_date}`}</div>
        ))}



      </div>


    );
  }
}


