import React from "react";
import { withTranslation } from 'react-i18next';

import moment from 'moment'
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import COLORS from '../constants/colors';
import {
    Container,
    Row,
    Button,
    Col,
    Form,
} from "react-bootstrap";

import DeleteIcon from '../assets/icons/delete.png'
import FormInput from '../components/FormInput';
import { SegmentedControl } from 'segmented-control-react';


class BookingTimesItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = props.booking_time;
    }

    componentWillReceiveProps(props) {
        this.setState({ ...props.booking_time })
    }

    onChangeBookingTime = () => {
        const updatedBookingTime = { update: true, ...this.state };
        this.props.change(updatedBookingTime);
    }
    render() {
        const { t, mooring } = this.props;
        // this.state = this.props.booking_time;
        const booking_time = this.state;
        return (
            <div>
                <Container style={{ padding: 5, backgroundColor: "#e3e3e7", marginBottom: 10, borderRadius: 5 }}>
                    <Row md={12}>
                        <Col md={4} xs={6}>
                            <FormInput
                                md={3}
                                label={t('MaintainTimesAndPrices.from')}
                                type="date"
                                required
                                value={this.state.from_date}
                                onChange={(e) => {
                                    this.setState({
                                        from_date: e.target.value,
                                    }, () => this.onChangeBookingTime());
                                }}
                                last
                            />
                        </Col>
                        <Col md={4} xs={6}>
                            <FormInput
                                md={3}
                                label={t('MaintainTimesAndPrices.to')}
                                type="date"
                                required
                                value={this.state.to_date}
                                onChange={(e) => {
                                    this.setState({
                                        to_date: e.target.value,
                                    }, () => this.onChangeBookingTime());
                                }}
                                last
                            />
                        </Col>
                        <Col md={1} xs={4}>

                            <div
                                style={{
                                    color: COLORS.MAIN_GRAY,
                                    alignSelf: 'center',
                                    fontSize: 14,
                                }} >DBD</div>
                            <Form.Check
                                className={'big-checkbox'}
                                style={{ marginTop: 5, marginLeft: 10, marginRight: 10, }}
                                type="checkbox"
                                checked={this.state.day_by_day}
                                onChange={(e) => {
                                    console.log("e.target.checked", e.target.checked)
                                    this.setState({
                                        day_by_day: e.target.checked,
                                    }, () => this.onChangeBookingTime());
                                }}
                            />

                        </Col>
                        <Col md={2} xs={4}>
                            <FormInput
                                label={t('MaintainTimesAndPrices.pricePerNight')}
                                type={mooring.custom_price ? "text" : "number"}
                                disabled={mooring.custom_price}
                                value={mooring.custom_price ? "custom" : this.state.daily_rate}
                                onChange={(e) => {
                                    this.setState({
                                        daily_rate: e.target.value,
                                    }, () => this.onChangeBookingTime());
                                }}
                                last
                            />
                        </Col>
                        <Col md={1} xs={4}>
                            <Button style={{ backgroundColor: 'transparent', borderColor: 'transparent' }} onClick={() => this.props.remove()}>
                                <img
                                    style={{ width: 25, marginTop: 20 }}
                                    src={DeleteIcon}
                                    alt="deleteIcon"
                                />

                                {/* {t('MaintainMoorings.delete')} */}
                            </Button>
                        </Col>
                    </Row>

                </Container>
            </div>
        );
    }
}

const styles = {
    button: {
        backgroundColor: "white",
        color: COLORS.MAIN_GRAY,
        fontWeight: "bold",
        borderColor: COLORS.MAIN_GRAY,
        marginRight: 10,
        marginTop: 20,
        maxHeight: 40,
    },


};

export default withTranslation()(BookingTimesItem)