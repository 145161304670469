import React from "react";
import axios from "axios";

import {
    Container,
    Form,
    Row,
    Button,
    Col,
    Alert,
    Modal,
} from "react-bootstrap";

import EditMooring from '../halfscreens/EditMooring'


// import { SegmentedControl } from 'segmented-control'
export default class Mooring extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            success: false,
            error: false,
            role: "marina",
            communicationLanguage: "en",
            mooring: {
                booking_times: [],
            },
        };
    }

    componentDidMount() {

        if (this.props.location.state.mooring) {
            this.setState({ mooring: this.props.location.state.mooring });

        }
        else if (this.props.match.params) {
            let id = this.props.match.params.id;
        }
    }






    render() {

        const { mooring } = this.state;
        const booking_times = this.state.mooring.booking_times;
        return (
            <Container sm={12}>
                <h1>Mooring</h1>

                <Row>
                    <Col sm={6} style={{ backgroundColor: "white" }}>

                        <EditMooring
                            mooring={this.props.location.state.mooring}
                            history={this.props.history}
                        />

                    </Col>
                    <Col sm={6}>


                        <h3>Times and Prices</h3>

                        {booking_times.map((booking_time) => <h3>{`${booking_time.from_date} - ${booking_time.to_date} => ${booking_time.daily_rate}/night`}</h3>)}


                        <Form.Control as="select"
                            value={this.state.mooring.payment_currency}
                            onChange={(text) => {
                                this.setState({
                                    mooring: { ...this.state.mooring, payment_currency: text.target.value },
                                });
                            }}
                        >
                            <option value="CHF">CHF</option>
                            <option value="EUR">EUR</option>
                            <option value="USD">USD</option>
                            <option value="GPB">GPB</option>
                        </Form.Control>

                    </Col>
                </Row>
                {this.state.loading && (
                    <Alert key={1} variant={"primary"}>
                        Loading
                    </Alert>
                )}
                {this.state.success && (
                    <Alert key={1} variant={"success"}>
                        Success: Mooring Updated
                    </Alert>
                )}
                {this.state.error && (
                    <Alert key={1} variant={"danger"}>
                        {this.state.error}
                    </Alert>
                )}
            </Container>



        );
    }
}
