import React, { useEffect, useState } from "react";
import moment from "moment";
import "../i18n"
import {
    Container,
    Form,
    Row,
    Button,
    Col,
    Alert,
    FormControl,
    Modal,
} from "react-bootstrap";
import FormInput from './FormInput'
import FormCheckbox from './FormCheckbox'

import { withTranslation } from 'react-i18next';
import { validateCreateBoatDocumentForm } from '../utils/validators';

import { createBoat, updateBoat, deleteBoat } from '../actions/boats'
// import { validateCreateBoatForm } from '../utils/validators';

import StatusAlerts from "./StatusAlerts";

import globalStyles from '../stylesheets/globalStyles';

import { getImageToken, uploadBoatDocument, deleteBoatDocument } from '../actions/documents'



const i18nPrefix = 'EditBoatDocumentModal';

const initialState = {
    show: false,
    create: false, //Are we creating or editing a boat document
    loading: false,
    success: false,
    error: false,
    imageToken: false,
    user_id: false,
    boat_document: {},
    selectedFileName: "",
}

class EditBoatModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }



    componentDidMount() {
        this.props.onRef && this.props.onRef(this)
    }

    show = (boat_document) => {
        if (boat_document) {
            this.setState({ boat_document: boat_document, show: true, create: false })
            getImageToken(boat_document.boat_document_id,
                (document_token) => { this.setState({ imageToken: document_token.token, user_id: document_token.user_id }) },
                () => { })

        }
        else {
            this.setState({ boat_document: {}, show: true, create: true })
        }
    }
    handleShow = () => {
        this.setState({ show: true })
    }

    handleClose = () => {
        this.props.onClose && this.props.onClose();
        this.setState(initialState)
    }


    uploadBoatDocumentClicked = () => {


        const noErrors = Object.values(validateCreateBoatDocumentForm(this.state)).every(x => (x == false));

        if (noErrors) {
            // For image uploads we need to pass a FormData object
            const formData = new FormData();
            formData.append("photo",
                this.state.selectedFile,
                this.state.selectedFile.name
            );

            formData.append("document_title", this.state.boat_document.document_title)
            formData.append("boat_id", this.props.boat_id);

            this.setState({ loading: true, success: false, error: false }, () => {
                uploadBoatDocument(formData,
                    () => {
                        this.setState({ loading: false, success: true, error: false }, () => {
                            this.handleClose();
                        });
                    },
                    () => {
                        this.setState({ loading: false, success: false, error: true })
                    })
            });
        }
        else {
            this.setState({ error: true, errorMessage: this.props.t('errors.someValuesAreNotCorrect') });
        }


    }

    deleteBoatDocumentClicked = () => {
        window.confirm(this.props.t(`${i18nPrefix}.areYouSureDeleteDocument`)) &&
            deleteBoatDocument(this.state.boat_document.boat_document_id,
                () => {
                    this.setState({ loading: false, success: true, error: false })
                    this.handleClose();
                },
                (err) => { })
    }


    handleFileInput = (event) => {

        const file = event.target.files[0]
        var error = false

        if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg") {
            console.log("Wrong file")
            window.confirm(this.props.t(`${i18nPrefix}.onlyImages`));
            return
        }
        if (!error) {
            this.setState({ selectedFile: file, selectedFileName: event.target.value });
        }

    }



    render() {

        const { t } = this.props
        const formErrors = validateCreateBoatDocumentForm(this.state)

        return (

            <>

                <Modal size="lg" show={this.state.show} onHide={this.handleClose} animation={true}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.create ? t(`${i18nPrefix}.createBoatDocument`) : t(`${i18nPrefix}.editBoatDocument`)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <StatusAlerts
                            state={this.state}
                            errorMessage={this.state.errorMessage}
                        />


                        <Row >
                            <Col>
                                <FormInput
                                    type="text"
                                    label={t(`${i18nPrefix}.title`)}

                                    value={this.state.boat_document.document_title}
                                    error={formErrors.document_title}
                                    onChange={(event) => {
                                        this.setState({
                                            boat_document: { ...this.state.boat_document, document_title: event.target.value },
                                        });
                                    }}
                                />
                            </Col>
                        </Row >

                        {this.state.imageToken &&

                            <img
                                style={{ width: "100%" }}
                                src={`${process.env.REACT_APP_API_HOST}boatdocuments/get_my_picture?token=${this.state.imageToken}`}
                                alt="image"
                            />
                        }

                        {this.state.create &&
                            <FormInput
                                type="file"
                                unit={"PNG/JPG"}
                                label={t(`${i18nPrefix}.image`)}
                                value={this.state.selectedFileName}
                                error={formErrors.selectedFileName}
                                onChange={
                                    this.handleFileInput}
                                last
                            />
                        }



                        <div style={{ width: '100%', height: 30 }}></div>






                    </Modal.Body>
                    <Modal.Footer>

                        {!this.state.create &&
                            <Button style={globalStyles.deleteButton} disabled={this.state.loading} onClick={() => this.deleteBoatDocumentClicked()}>
                                {t(`${i18nPrefix}.deleteBoatDocument`)}
                            </Button>
                        }
                        {this.state.create &&
                            <Button style={globalStyles.saveButton} disabled={this.state.loading} onClick={() => this.uploadBoatDocumentClicked()}>
                                {t(`${i18nPrefix}.createBoatDocument`)}
                            </Button>
                        }
                        {/* {!this.state.create &&
                            <Button style={globalStyles.saveButton} disabled={this.state.loading} onClick={() => this.editBoatDocumentClicked()}>
                                {t(`global.save`)}
                            </Button>
                        } */}
                    </Modal.Footer>
                </Modal>

            </>

        );

    }
}


export default withTranslation()(EditBoatModal)



