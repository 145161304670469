import React from "react";
import axios from "axios";
import "../i18n"
import i18n from "../i18n";

import {
  Container,
  Form,
  Row,
  Button,
  Col,
  Alert,
  Modal,
  Collapse,
} from "react-bootstrap";
import FormInput from '../components/FormInput'
import FormCheckbox from '../components/FormCheckbox'



import { verifyEmail } from '../actions/auth'
import { register, completeRegistration, doesAccountExist } from '../actions/register'
import { getAccountDataByToken } from '../actions/account'
import { getPayrexxTransactionLink } from '../actions/paymntMethods'
import { withTranslation } from 'react-i18next';
import { validateRegisterform } from '../utils/validators';
import DropDownIcon from '../assets/icons/dropdown.png'



const i18nPrefix = 'Register';


const dropDownArrowStyle = {
  transition: 'transform 250ms ease', // smooth transition
  float: "right",
  height: 15,
  marginTop: 8,
  marginRight: 10,
}


function getNegativeBalance(account) {
  const balances = [
    { currency: "CHF", amount: account.balance_CHF },
    { currency: "EUR", amount: account.balance_EUR },
    { currency: "USD", amount: account.balance_USD },
    { currency: "GBP", amount: account.balance_GBP },
  ];

  var result = false;
  // Check if there is a negative balance
  balances.map((balance) => {
    if (balance.amount < 0) {
      console.log("negativeBalance", balance);
      result = balance
    }
  });
  return result;
}

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      error: false,
      accountExists: false,
      dataFtomUri: false,
      role: "Private",
      communication_language: null,
      firstname: "",
      lastname: "",
      email: "",
      mobilenumber: "",
      password: "",
      showPassword: false,
      passwordRepeat: "",
      showPasswordRepeat: false,

      // communication_language: "en",
      // firstname: "Florian",
      // lastname: "Bolli",
      // email: "florian.bolli@gmx.ch",
      // mobilenumber: "+41789067674",
      // password: "florian1",
      // showPassword: false,
      // passwordRepeat: "florian1",
      // showPasswordRepeat: false,

      referral_account: "",
      AcceptedTCs: false,
      club: "",
      marina: "",
      contact_token: false,
      mooring_transfer_token: false,
      account_token: false,
      showBoatInfo: true,
      continue_as_guest: false,
      unregisteredAccount: null,
      negativeBalance: false,
      account: {},
      boat: {
        name: "",
        registration_number: "",
        length: null,
        beam: null,
        draft: null,
      }
    };
  }


  componentDidMount() {


    // Check if we can prefill the form from data from the URL
    if (this.props.match.params.args) {
      let args = this.props.match.params.args;
      if (args.includes("&")) {
        args = args.split("&");

        //Check if account already exists
        const email = args[2]
        const language = args[5].toLowerCase();

        const accountData = {}
        this.setState({
          dataFtomUri: true,
          firstname: args[0],
          lastname: args[1],
          email: args[2],
          mobilenumber: args[3],
          role: args[4],
          communication_language: args[5].toUpperCase(),
          referral_account: args[6],
          club: args[7],
          marina: args[8],
          contact_token: args[9],
        })
        i18n.changeLanguage(language)
      }

    }

    // Check if we are registered with a mooring_transfer_token
    if (this.props.match.params.mooring_transfer_token) {
      const token = this.props.match.params.mooring_transfer_token
      const email = this.props.match.params.email
      console.log("Registration with token: ", token)
      this.setState({ email: email, mooring_transfer_token: token })
    }

    // Check if we are registered with a account_token
    if (this.props.match.params.account_token) {
      const token = this.props.match.params.account_token
      getAccountDataByToken(token,
        (account) => {
          console.log("account", account)
          const continue_as_guest = account.email_already_exists
          this.setState({
            unregisteredAccount: account,
            account_id: account.account_id,
            firstname: account.firstname,
            lastname: account.lastname,
            email: account.email,
            mobilenumber: account.mobilenumber,
            communication_language: account.communication_language,
            account_token: token,
            email_already_exists: account.email_already_exists,
            continue_as_guest: true,
            role: 'guest',
            boat: {
              ...this.state.boat,
              ...account?.boat
            },
            account: account,
            role: account.role,
          });

          let negativeBalance = getNegativeBalance(account)
          this.setState({ negativeBalance: negativeBalance })

          if (this.props.match.params.email_activation_code) {
            const data = {
              code: this.props.match.params.email_activation_code,
              account_id: account.account,
            }
            verifyEmail(data, (response) => { console.log("Response", response) }, () => { })
          }
        },
        (error) => { })

    }
  }

  doesAccountExistSuccess = (exists) => {
    //If account already exists, dont let register again.
    this.setState({ accountExists: exists })
    if (exists) {
      const userData =
      {
        email: this.state.email,
        club: this.state.club,
        marina: this.state.marina,
        role: this.state.role,
      }
      register(userData, null, () => {

      }, () => {

      });

    }
  }

  handleRegisterClick() {
    const userData = this.state;

    const formErrors = validateRegisterform(this.state);
    const noErrors = Object.values(formErrors).every(x => (x == false));
    if (!noErrors) {
      if (formErrors.AcceptedTCs != false) {
        this.setState({ error: this.props.t('errors.pleaseAcceptTCs') });
        return;
      }
      this.setState({ error: this.props.t('errors.someValuesAreNotCorrect') });
      return;

    }
    else { // No errors
      this.setState({ loading: true, success: false, error: false }, () => {
        if (this.state.account_token) {

          const data = {
            account_id: this.state.account_id,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            role: this.state.role,
            boat: this.state.boat,
            continue_as_guest: this.state.continue_as_guest,
            password: this.state.password,
          }
          completeRegistration(data, () => { window.location = "https://boatpark.app/" }, this.registerFail)
        }
        else {
          register(userData, this.state.boat, this.registerSuccess, this.registerFail)
        }
      });
    }
  }

  handlePayClick = () => {
    const { negativeBalance, unregisteredAccount } = this.state;

    const formErrors = validateRegisterform(this.state);
    const noErrors = Object.values(formErrors).every(x => (x == false));
    console.log("formErrors: ", formErrors);
    if (!noErrors) {
      this.setState({ error: this.props.t('errors.someValuesAreNotCorrect') });
      return;

    }
    if (unregisteredAccount.role == 'guest') {

      const data = {
        account_id: this.state.account_id,
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        role: this.state.role,
        continue_as_guest: this.state.continue_as_guest,
        boat: this.state.boat,
      }
      completeRegistration(data,
        () => {
          console.log("Completed registration")
          getPayrexxTransactionLink(-negativeBalance.amount, negativeBalance.currency, unregisteredAccount.account_id,
            (link) => {
              console.log("Link", link)
              window.location.replace(link);
            },
            (error) => { })
        },
        (error) => { })
    }

  }

  registerSuccess = (data) => {
    const account_id = data.account_id
    this.setState({ loading: false, error: false, success: true }, () => {
      this.props.history.push(`/activate/${account_id}`);
    });
  }
  registerFail = (error) => {
    const errorMessage = this.props.t(`errors.${error.response.data.language_variable}`);
    this.setState({ loading: false, success: false, error: errorMessage });
  }

  render() {

    const { t } = this.props
    const roles = t(`${i18nPrefix}.roles`)
    const languages = t(`global.communicationLanguages`)
    const formErrors = validateRegisterform(this.state);
    const { boat } = this.state

    const registrationAfterFastBooking = this.state.account_token ? true : false

    const showGuestCheckbox = registrationAfterFastBooking && !this.state.email_already_exists

    return (
      <Container style={{ maxWidth: 800, marginTop: 50, borderRadius: 5, backgroundColor: "white" }}>
        <h1>{t(`${i18nPrefix}.title`)}</h1>
        {this.state.accountExists &&
          <Row>
            <Col>
              <Alert key={1} variant={"primary"}>
                {t("errors.thisEmailIsAlreadyTaken")}
              </Alert>

              <Button onClick={() => { window.location.href = this.props.t("links.website"); }}>
                {t("global.contunie")}
              </Button>

            </Col>
          </Row>
        }
        {
          !this.state.accountExists &&
          <Row>
            <Col style={{ backgroundColor: "white" }}>
              <FormInput as="select"
                label={t(`${i18nPrefix}.role`)}
                id="input_role"
                value={this.state.role}
                onChange={(event) => {
                  this.setState({ role: event.target.value });
                }}
                disabled={registrationAfterFastBooking}
              >
                <option value="private">Private</option>
                <option value="marina">Marina</option>
                <option value="guest">Guest</option>
              </FormInput>

              {showGuestCheckbox &&
                <FormCheckbox
                  id="checkbox_continue_as_guest"
                  label={t(`${i18nPrefix}.continueAsGuest`)}
                  checked={this.state.continue_as_guest}
                  onChange={(e) => {
                    this.setState({
                      continue_as_guest: e.target.checked,
                      role: e.target.checked ? "guest" : "private",
                    })
                  }} />
              }


              <FormInput
                type="text"
                label={t(`${i18nPrefix}.firstName`)}
                id="input_firstname"
                value={this.state.firstname}
                error={formErrors.firstname}
                onChange={(event) =>
                  this.setState({ firstname: event.target.value })
                }
              />
              <FormInput
                type="text"
                label={t(`${i18nPrefix}.lastName`)}
                id="input_lastname"
                value={this.state.lastname}
                error={formErrors.lastname}
                onChange={(event) =>
                  this.setState({ lastname: event.target.value })
                }
              />
              <FormInput as="select"
                label={t(`${i18nPrefix}.communicationLanguage`)}
                id="input_communication_language"
                value={this.state.communication_language?.toUpperCase()}
                error={formErrors.communication_language}
                onChange={(event) => {
                  this.setState({ communication_language: event.target.value });
                }}
              >
                < option value={null}></option>

                {
                  languages.map((language) =>
                    < option value={language}>{language}</option>
                  )
                }

              </FormInput>
              <FormInput
                type="text"
                label={t(`${i18nPrefix}.email`)}
                id="input_email"
                value={this.state.email}
                error={formErrors.email}

                onChange={(event) => this.setState({ email: event.target.value })}
              />
              <FormInput
                type="tel"
                label={t(`${i18nPrefix}.mobile`)}
                id="input_mobilenumber"
                value={this.state.mobilenumber}
                error={formErrors.mobilenumber}
                onChange={(event) =>
                  this.setState({ mobilenumber: event.target.value })
                }
                disabled={registrationAfterFastBooking}
              />
              {!this.state.continue_as_guest &&
                <FormInput
                  type="password"
                  label={t(`${i18nPrefix}.password`)}
                  id="input_password"
                  value={this.state.password}
                  error={formErrors.password}
                  autocomplete="new-password"
                  onChange={(event) =>
                    this.setState({ password: event.target.value })
                  }
                />
              }
              {!this.state.continue_as_guest &&

                <FormInput
                  type="password"
                  label={t(`${i18nPrefix}.repeatPassword`)}
                  id="input_passwordRepeat"
                  value={this.state.passwordRepeat}
                  error={formErrors.passwordRepeat}
                  autocomplete="new-password"
                  onChange={(event) =>
                    this.setState({ passwordRepeat: event.target.value })
                  }
                />
              }
              {!this.state.account_token &&
                <FormInput
                  type="text"
                  name="referral_account"
                  label={t(`${i18nPrefix}.referral`)}
                  id="input_referral_account"
                  value={this.state.referral_account}
                  error={formErrors.referral_account}
                  autocomplete="off"
                  onChange={(event) =>
                    this.setState({ referral_account: event.target.value })
                  }
                />
              }


              {/* Optional Boat info */}
              <Row onClick={() => this.setState({ showBoatInfo: !this.state.showBoatInfo })} >
                <Col md={10} xs={10}>
                  <h3>{t(`${i18nPrefix}.OptionalBoatInfo`)}</h3>
                </Col>
                <Col md={2} xs={2}>
                  <img
                    style={{
                      ...dropDownArrowStyle,
                      transform: this.state.showBoatInfo ? 'rotate(180deg)' : '',
                    }}
                    src={DropDownIcon}
                    alt="DropDownIcon"
                    className="spin"
                  />
                </Col>
              </Row>
              <Collapse in={this.state.showBoatInfo}>
                <div id="showBoatInfo">

                  <FormInput
                    id="input_name"
                    type="text"
                    label={t(`${i18nPrefix}.name`)}
                    value={boat.name}
                    error={formErrors.name}
                    onChange={(event) => this.setState({ boat: { ...boat, name: event.target.value } })}
                  />
                  <FormInput
                    id="input_registration_number"
                    type="text"
                    label={t(`${i18nPrefix}.registration_number`)}
                    value={boat.registration_number}
                    error={formErrors.registration_number}
                    onChange={(event) => this.setState({ boat: { ...boat, registration_number: event.target.value } })}
                  />
                  <FormInput
                    id="input_length"
                    type="number"
                    label={t(`${i18nPrefix}.length`)}
                    unit="m"
                    value={boat.length}
                    error={formErrors.length}
                    onChange={(event) => this.setState({ boat: { ...boat, length: event.target.value } })}
                    disabled={this.state.account?.boat?.length}
                  />
                  <FormInput
                    id="input_beam"
                    type="number"
                    label={t(`${i18nPrefix}.beam`)}
                    unit="m"
                    value={boat.beam}
                    error={formErrors.beam}
                    onChange={(event) => this.setState({ boat: { ...boat, beam: event.target.value } })}
                    disabled={this.state.account?.boat?.beam}
                  />
                  <FormInput
                    id="input_draft"
                    type="number"
                    label={t(`${i18nPrefix}.draft`)}
                    unit="m"
                    value={boat.draft}
                    error={formErrors.draft}
                    onChange={(event) => this.setState({ boat: { ...boat, draft: event.target.value } })}
                  />
                </div>
              </Collapse>



              {/* {JSON.stringify(this.state)} */}
              {!this.state.continue_as_guest &&
                <>
                  {/* <div style={{ borderColor: 'blue', borderWidth: 5, borderStyle: 'solid' }} > */}

                  <FormCheckbox
                    id="checkbox_tcs"
                    type="checkbox"
                    label={
                      <span>
                        {t(`${i18nPrefix}.iAcceptThe`)}
                        <a target="_blank" href={t('links.TCsLink')}>
                          {t(`${i18nPrefix}.TCs`)}
                        </a>
                      </span>
                    }
                    onChange={(event) => { this.setState({ AcceptedTCs: event.target.checked }); }}
                    error={formErrors.AcceptedTCs}
                  />

                  {/* <Form.Group controlId="formBasicCheckbox" style={{ marginTop: 10, }}>
                    <Form.Check style={{ float: 'left' }} type="checkbox" onChange={(event) => { this.setState({ AcceptedTCs: event.target.checked }); }} />
                    <div style={{ float: 'left', marginLeft: 10 }}>{t(`${i18nPrefix}.iAcceptThe`)} <a target="_blank" href={t('links.TCsLink')}>{t(`${i18nPrefix}.TCs`)}</a> {formErrors.AcceptedTCs && <div style={{ float: 'right', color: COLORS.MAIN_RED }}> * </div>}</div>

                  </Form.Group> */}

                  <div style={{ width: '100%', height: 30 }}></div>

                  <Button disabled={this.state.loading} id="button_register" variant="primary" type="submit" onClick={() => this.handleRegisterClick()}>
                    {t(`${i18nPrefix}.register`)}
                  </Button>
                </>
              }
              {this.state.continue_as_guest &&
                <Button id="button_pay" disabled={this.state.loading} variant="primary" type="submit" onClick={() => this.handlePayClick()}>
                  {t(`${i18nPrefix}.pay`)}
                </Button>
              }
              <div style={{ width: '100%', height: 30 }}></div>

              {this.state.loading && (
                <Alert key={1} variant={"primary"}>
                  {t("global.loading")}
                </Alert>
              )}
              {this.state.success && (
                <Alert key={2} variant={"success"}>
                  {t(`${i18nPrefix}.registerSuccess`)}
                </Alert>
              )}
              {this.state.error && (
                <Alert id="alert_error" key={3} variant={"danger"}>
                  {this.state.error}
                </Alert>
              )}
            </Col>
          </Row>
        }
      </Container >



    );
  }
}


export default withTranslation()(Register)
