import React from "react";
import { withTranslation } from 'react-i18next';

import Linkify from 'react-linkify';

import { createUnregisteredAccount } from "../actions/register";
import { getPublicMooringInfo } from "../actions/moorings";
import { verifyMobile } from "../actions/auth";
import { addBookingUnregistered, addBooking, getBookingPrice } from "../actions/bookings";
import { checkIfEmailExists, getMyAccount } from "../actions/account";
import { getMyPaymentMethods } from "../actions/paymntMethods";
import { errorsExist, formValid, validateFastBookingForm } from '../utils/validators';


import { Container, Row, Col, Form, Button, Spinner, Modal, FormSelect } from "react-bootstrap";
import FormInput from '../components/FormInput'
import FormSegmentedControl from '../components/FormSegmentedControl'
import YesNoModal from '../components/YesNoModal'
import StatusAlerts from "../components/StatusAlerts";
import i18n from "../i18n";


import moment from "moment/moment";
import FormInputWrapper from "../components/FormInputWrapper";
import DateTimeText from "../components/DateTimeText";
import { getNumberOfDaysBetweenDates } from "../utils/utilities"

import LoginModal from "../components/LoginModal";
import FastBookingNavBar from "../components/FastBookingNavBar";
import { withSearchParams } from '../components/HOCs'


const i18nPrefix = 'FastBooking';
class FastBooking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from_date: moment(),
      to_date: moment().add(1, "day"),
      // firstname: "Florian",
      // lastname: "Bolli",
      // email: "florian.bolli@gmx.ch",
      // mobile: "+41789067674",
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      number_of_adults: null,
      number_of_children: null,
      boat_length: null,
      boat_beam: null,
      mooring_unavailable: false,
      language: "de",
      password: "",
      account_id: null,
      account_token: null,
      mobile_activation_code: "",
      mooring: null,
      activationMode: false,
      loading: false,
      success: false,
      error: false,
      custom_daily_rate: 0,
      number_of_days: 1,
      booking_price: null,
      tax_price: null,
      total_price: null,
      showAskLoginModal: true,
      account: null,
      payrexx_tokenizations: [],
      payment_selection_index: 0,
      loggedIn: false,
      canPayInAdvance: false,
      booking_price: 0,
      tax_price: 0,
      total_price: 0,
    };

    this.YesNoModal = React.createRef();
    this.LoginModal = React.createRef();
    this.StatusAlertsRef = React.createRef();

    this.payment_selections = [];

  }

  componentDidMount() {
    if (this.props.match.params) {
      let mooring_id = this.props.match.params.mooring_id;
      let from_date = this.state.from_date;
      let to_date = this.state.to_date;
      let current_language = i18n.language.substring(0, 2)
      this.setState({ language: current_language });

      // Length and beam from URL search params
      this.setState({ boat_length: this.props.searchParams.get('l'), boat_beam: this.props.searchParams.get('b') });


      getPublicMooringInfo(mooring_id, from_date, to_date,
        (mooring) => {
          const mooring_unavailable = mooring.availability_status != "available" || Boolean(Number(mooring.needs_booking_confirmation))
          this.setState({ mooring: mooring, mooring_unavailable: mooring_unavailable }, () => {
            this.loadAccountData()
            this.reload_prices()
          })
        }, () => { });

    }

  }

  loadAccountData = () => {

    const token = localStorage.getItem("AuthToken");
    if (token) {
      getMyAccount(
        (response) => {
          const { boat } = response
          console.log("boat", boat)
          this.setState({ loggedIn: true, account: response, account_id: response.account_id, email: response.email, mobile: response.mobilenumber, firstname: response.firstname, lastname: response.lastname, boat_name: boat?.name })
          this.changeBoatSize(boat?.length, boat?.beam);


        },
        (error) => { })
      getMyPaymentMethods(
        (payrexx_tokenizations) => { this.setState({ payrexx_tokenizations: payrexx_tokenizations }) },
        (error) => { }
      )
    }
    else {
      // this.YesNoModal.show("do you already have a boatpark account ", "", () => { this.LoginModal.show() }, () => { console.log("Does not have account") })

    }



  }



  handleActivateClicked = (total_price) => {
    const title = this.props.t(`global.AreYouSure`)
    const text = this.props.t(`${i18nPrefix}.YouAreAboutToBook`, { currency: this.state.mooring.payment_currency, amount: total_price.toFixed(2) })
    this.YesNoModal.show(title, text, this.activateAndBook, () => { })
  };

  activateAndBook = () => {
    this.setState({ loading: true, error: false }, () => {
      let data = {
        code: this.state.mobile_activation_code,
        account_id: this.state.account_id,
      };
      verifyMobile(data,
        (response) => {
          this.setState({ loading: false });
          this.unregistered_booking()
        },
        (error) => {
          const errorMessage = this.props.t(`errors.${error.response.data.language_variable}`);
          this.setState({ loading: false, success: false, error: true, errorMessage: errorMessage });
        });
    });
  }

  handleCreateUnregisteredAccount = () => {

    const formErrors = validateFastBookingForm(this.state)
    if (errorsExist(formErrors)) {
      this.setState({ error: true, errorMessage: this.props.t('errors.someValuesAreNotCorrect') }, () => {
        this.scrollToAlert();

      });
      return;
    }
    this.setState({ loading: true, error: false }, () => {


      let data = {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        mobile: this.state.mobile,
        communication_language: this.state.language,
      }
      createUnregisteredAccount(data,
        (response) => {
          console.log(response);
          let successMessage = "We have sent you an SMS. Please verify your mobile by providing the avtivation code.";
          this.setState({
            loading: false,
            success: true,
            successMessage: successMessage,
            account_token: response.token,
            account_id: response.account_id,
            activationMode: true
          }, () => {
            if (this.StatusAlertsRef.current)
              this.StatusAlertsRef.current.scrollIntoView({ behavior: 'smooth' });
          })
        },
        (error) => {
          console.log(error.response.data);
          const errorMessage = this.props.t(`errors.${error.response.data.language_variable}`);
          this.setState({ loading: false, success: false, error: true, errorMessage: errorMessage });
        })

    });
  }

  unregistered_booking = () => {

    this.setState({ loading: true }, () => {


      let booking = {
        mooring_id: this.state.mooring.mooring_id,
        account_id: this.state.account_id,
        from_date: this.state.from_date,
        to_date: this.state.to_date,
        number_of_adults: this.state.number_of_adults,
        number_of_children: this.state.number_of_children,
        boat: {
          name: this.state.boat_name,
          registration_number: this.state.boat_registration_number,
          length: this.state.boat_length,
          beam: this.state.boat_beam,
        }
      }
      addBookingUnregistered(booking,
        (response) => {
          console.log("AddBookingUnregisredResponse", response);
          let web_app_link = process.env.REACT_APP_WEB_APP_LINK
          let completion_link = `${web_app_link}register/complete/${this.state.account_token}`
          console.log(completion_link)
          let successMessage = <div>
            {this.props.t(`${i18nPrefix}.bookingHasBeenSaved`)}
            <a id="link_complete_registration" href={completion_link}>{this.props.t(`${i18nPrefix}.thisLink`)}</a>
            {this.props.t(`${i18nPrefix}.TheReservationWillBePaid`)}
          </div>

          if (response.booking_price == 0) {
            successMessage = <div>
              {this.props.t(`${i18nPrefix}.freeBookingHasBeenSaved`)}
            </div>
          }
          this.setState({ loading: false, success: true, error: false, successMessage: successMessage }, () => {
            this.scrollToAlert();
          });
        },
        (error) => {
          const language_variable = error.response
          console.log("language_variable", language_variable)
          const errorMessage = this.props.t(`errors.${error.response.data.language_variable}`);
          this.setState({ loading: false, success: false, error: true, errorMessage: errorMessage });
        });
    })
  }

  registered_booking = () => {
    let payment_selection = this.payment_selections[this.state.payment_selection_index]
    let booking = {
      mooring_id: this.state.mooring.mooring_id,
      account_id: this.state.account_id,
      from_date: this.state.from_date,
      to_date: this.state.to_date,
      number_of_adults: this.state.number_of_adults,
      number_of_children: this.state.number_of_children,
      boat_length: this.state.boat_length,
      boat_beam: this.state.boat_beam,
      payment_method: payment_selection.payment_method,
      payment_method_id: payment_selection.payrexx_tokenization_id,
    }
    // console.log("Booking post object", booking);
    this.setState({ loading: true }, () => {

      addBooking(booking,
        (response) => {

          if (response.payment_link) { // In case of payrexx transaction
            window.location.replace(response.payment_link);
          }
          else {
            let successMessage = <div>
              {this.props.t(`${i18nPrefix}.bookingSuccessful`)}
            </div>

            this.setState({ loading: false, success: true, error: false, successMessage: successMessage }, () => {
              this.scrollToAlert();
              window.location.replace("https://boatpark.app/payment-successful");

            });
          }
        },
        (error) => {
          const language_variable = error.response.data.language_variable
          var errorMessage = ""
          switch (language_variable) {
            case 'bookingTooLong':
            case 'bookingTooLongYear':
              errorMessage = this.props.t(`errors.${error.response.data.language_variable}`, { max_nights: error.response.data.max_nights });
              break;
            case 'paymentFailed':
              errorMessage = this.props.t(`errors.${error.response.data.language_variable}`);
              break;

            default:
              errorMessage = this.props.t(`errors.somethingWentWrong`);
              break;

          }

          this.setState({ loading: false, success: false, error: true, errorMessage: errorMessage });

        });
    })
  }
  scrollToAlert = () => {
    if (this.StatusAlertsRef.current)
      this.StatusAlertsRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  changeBoatSize = (length, beam) => {
    console.log("length", length, beam)
    this.setState({ boat_length: length, boat_beam: beam })

    this.reload_prices(length, beam)

  }

  reload_prices = (boat_length = this.state.boat_length, boat_beam = this.state.boat_beam) => {
    const { mooring, from_date, to_date, number_of_adults, number_of_children } = this.state;
    getBookingPrice(mooring.mooring_id, from_date, to_date, boat_length, boat_beam, number_of_adults, number_of_children,
      (prices) => {
        this.setState({ booking_price: prices.booking_price, tax_price: prices.tax_price, total_price: prices.total_price });
      },
      (error) => { })
  }

  onFromDateChange = (event) => {
    const new_from_date = moment(event.target.value)
    var to_date = this.state.to_date;
    to_date = new_from_date.clone().add(1, 'day');

    const number_of_days = getNumberOfDaysBetweenDates(new_from_date, to_date)
    this.setState({ from_date: new_from_date, to_date: to_date, number_of_days: number_of_days }, () => { this.reload_prices() })

  }
  onToDateChange = (event) => {
    const new_to_date = moment(event.target.value)
    const number_of_days = getNumberOfDaysBetweenDates(this.state.from_date, new_to_date)
    this.setState({ to_date: new_to_date, number_of_days: number_of_days }, () => { this.reload_prices() })
  }

  update_payment_selections = () => {

    const { mooring } = this.state


    const currency = mooring?.payment_currency;

    // Check if internal is possible
    const colName = `balance_${mooring?.payment_currency}`;
    const balance = this.state.account && this.state.account[colName] || 0;
    const internal_payment_possible = balance > this.state.total_price

    this.payment_selections = [];
    // internal
    if (internal_payment_possible)
      this.payment_selections.push({ payment_method: 'internal', text: `Internal (${currency} ${balance})`, payrexx_tokenization_id: null });
    // payrexx tokenizations
    this.state.payrexx_tokenizations.forEach((payrexx_tokenization) => {
      if (payrexx_tokenization.default_currency == this.state.mooring.payment_currency) {
        this.payment_selections.push({ payment_method: 'payrexx_tokenization', text: `${payrexx_tokenization.type} (****${payrexx_tokenization.card_number.slice(-4)})`, payrexx_tokenization_id: payrexx_tokenization.id });
      }
    })
    // payrexx transactions
    this.payment_selections.push({ payment_method: 'payrexx_transaction', text: `CreditCard/Twint/Others`, payrexx_tokenization_id: null });


  }

  onLogout = () => {
    this.setState({
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      number_of_adults: null,
      number_of_children: null,
      boat_length: null,
      boat_beam: null,
      account_id: null,
      loggedIn: false,
      account: null,
    })
  }

  render() {
    const { t } = this.props
    const { mooring, activationMode } = this.state
    const taxes = mooring?.tourist_tax_adult != 0 || mooring?.tourist_tax_child != 0;

    const currency = mooring?.payment_currency;
    const price_by_size = mooring?.price_by_size

    this.update_payment_selections();

    const advance_payment_possible = this.payment_selections.length > 0 && this.state.loggedIn;

    const NoOrYes = t('global.NoOrYes')

    const formErrors = validateFastBookingForm(this.state)

    return (
      <>
        <YesNoModal
          onRef={(ref) => this.YesNoModal = ref}
        />

        <LoginModal
          onRef={(ref) => this.LoginModal = ref}
          onClose={this.loadAccountData}
        />

        <FastBookingNavBar
          loginClicked={() => {
            this.LoginModal.show()
          }}
          logoutSuccess={this.onLogout} />
        <Container style={{ maxWidth: 500 }}>
          {mooring && <h3>{mooring.harbour_name} {mooring.berth_number}</h3>}

          {this.state.mooring_unavailable &&
            <h4 style={{ color: "#FF0000" }}>{t(`${i18nPrefix}.mooringUnavailable`)}</h4>}
          {this.state.loading && <Spinner style={{ width: 20, height: 20, marginLeft: 10 }} animation="border" variant="secondary" role="status" />}

          {/* Date info */}
          <Row>
            <Col>
              <DateTimeText
                id="input_from_date"
                label={t(`${i18nPrefix}.from`)}
                value={moment(this.state.from_date).format("YYYY-MM-DD")}
                min={moment().subtract(1, 'day').format('YYYY-MM-DD')}
                max={moment().format('YYYY-MM-DD')}
                timelabel={t(`${i18nPrefix}.checkInTime`)}
                time={mooring?.check_in_time.slice(0, -3)}
                onChange={this.onFromDateChange}
              />

            </Col>
            <Col>
              <DateTimeText
                id="input_to_date"
                label={t(`${i18nPrefix}.to`)}
                value={this.state.to_date}
                min={moment().format('YYYY-MM-DD')}
                max={moment().add(1, 'day').format('YYYY-MM-DD')}
                timelabel={t(`${i18nPrefix}.checkOutTime`)}
                time={mooring?.check_out_time.slice(0, -3)}
                onChange={this.onToDateChange}
                disabled
              />

            </Col>
          </Row>


          <h3>{t(`${i18nPrefix}.YourInformation`)}</h3>

          <FormInput
            id="email"
            label={t(`${i18nPrefix}.email`)}
            type="email"
            value={this.state.email}
            error={formErrors.email}
            onChange={(e) => {
              this.setState({ email: e.target.value });
            }}
            onBlur={() => {
              checkIfEmailExists(this.state.email,
                (response) => {
                  console.log(response)
                  if (response.account_exists) {
                    this.LoginModal.show(this.state.email)
                  }
                }, () => { });
            }}
            disabled={this.state.loggedIn}
          />

          <FormInput
            id="mobile"
            label={t(`${i18nPrefix}.mobile`)}
            type="text"
            value={this.state.mobile}
            error={formErrors.mobile}
            onChange={(text) => {
              this.setState({ mobile: text.target.value });
            }}
            disabled={this.state.loggedIn}

          />

          <FormInput
            id="firstname"
            label={t(`${i18nPrefix}.firstname`)}
            type="text"
            value={this.state.firstname}
            error={formErrors.firstname}
            onChange={(text) => {
              this.setState({ firstname: text.target.value });
            }}
            disabled={this.state.loggedIn}

          />

          <FormInput
            id="lastname"
            label={t(`${i18nPrefix}.lastname`)}
            type="text"
            value={this.state.lastname}
            error={formErrors.lastname}
            onChange={(text) => {
              this.setState({ lastname: text.target.value });
            }}
            disabled={this.state.loggedIn}

          />

          {(price_by_size) &&
            <>
              <FormInput
                id="boat_name"
                type="text"
                label={t(`${i18nPrefix}.boat_name`)}
                value={this.state.boat_name}
                error={formErrors.boat_name}
                onChange={(text) => { this.setState({ boat_name: text.target.value }); }}
              />
              {/* <FormInput
                id="boat_registration_number"
                label={t(`${i18nPrefix}.boat_registration_number`)}
                value={this.state.boat_registration_number}
                error={formErrors.boat_registration_number}
                onChange={(text) => { this.setState({ boat_registration_number: text.target.value }); }}

              /> */}
              <FormInput
                id="boat_length"
                label={t(`${i18nPrefix}.boat_length`)}
                unit="m"
                type="number"
                value={this.state.boat_length}
                error={formErrors.boat_length}
                onChange={(text) => {
                  var value = text.target.value //.replace(",", ".")
                  // console.log("Value", value)
                  // this.setState({ boat_length: value })
                  this.changeBoatSize(value, this.state.boat_beam);
                }}
              />
              <FormInput
                id="boat_beam"
                label={t(`${i18nPrefix}.boat_beam`)}
                unit="m"
                type="number"
                value={this.state.boat_beam}
                error={formErrors.boat_beam}
                onChange={(text) => {
                  var value = text.target.value.replace(",", ".")
                  this.changeBoatSize(this.state.boat_length, value);
                }}
              />
            </>

          }

          {(taxes) &&
            <>
              <FormInput
                id="number_of_adults"
                label={t(`${i18nPrefix}.numberOfAdults`)}
                type="text"
                value={this.state.number_of_adults}
                error={formErrors.number_of_adults}
                onChange={(text) => {
                  this.setState({ number_of_adults: text.target.value }, () => this.reload_prices());
                }}
              />
              <FormInput
                id="number_of_children"
                label={t(`${i18nPrefix}.numberOfChildren`)}
                type="text"
                value={this.state.number_of_children}
                error={formErrors.number_of_children}
                onChange={(text) => {
                  this.setState({ number_of_children: text.target.value }, () => this.reload_prices());
                }}
              />
            </>

          }
          {/* mooring info */}
          {mooring &&
            <>
              <h3>{t(`${i18nPrefix}.MooringInformation`)}</h3>
              {taxes &&
                <>

                  <FormInput
                    id="booking_price"
                    disabled
                    label={t(`${i18nPrefix}.booking_price`)}
                    value={`${currency} ${(this.state.booking_price + Number.EPSILON).toFixed(2)}`}

                  />

                  <div>
                    {t(`${i18nPrefix}.taxes`)}
                  </div>
                  <div >
                    {t(`${i18nPrefix}.perAdultPerNight`)}
                    {` ${mooring.tourist_tax_adult} `}
                  </div>
                  <div >
                    {t(`${i18nPrefix}.perChildPerNight`)}
                    {` ${mooring.tourist_tax_child} `}
                  </div>

                  <FormInput
                    id="tax_price"
                    disabled
                    label={t(`${i18nPrefix}.tax_price`)}
                    value={`${currency} ${this.state.tax_price.toFixed(2)}`}

                  />

                </>}

              <FormInput
                id="total_price"
                disabled
                label={t(`${i18nPrefix}.total_price`)}
                value={`${currency} ${this.state.total_price.toFixed(2)}`}
              />
              <FormInput
                id="LBH"
                disabled
                label={t(`${i18nPrefix}.LBH`)}
                value={`${mooring.max_length.toFixed(2)} / ${mooring.max_beam.toFixed(2)} / ${mooring.max_draught.toFixed(2)}`}
              />




              <FormSegmentedControl
                label={t('EditMooring.power')}
                selected={mooring.power}
                variant="base"
                segments={NoOrYes}
                readOnly={true}
              />


              <FormSegmentedControl
                label={t('EditMooring.water')}
                selected={mooring.water}
                variant="base"
                segments={NoOrYes}
                readOnly={true}
              />


              {(mooring.additional_description && mooring.additional_description != "") &&
                <FormInputWrapper
                  label={t(`${i18nPrefix}.additionalDescription`)} >
                  <div style={{ whiteSpace: "pre-wrap" }}>
                    <Linkify
                      componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a target="blank" href={decoratedHref} key={key}>
                          {decoratedText}
                        </a>
                      )}
                    >
                      {mooring.additional_description}
                    </Linkify>
                  </div>
                </FormInputWrapper>
              }

              {advance_payment_possible &&

                <FormInput as="select"
                  id='input_payment_selection'
                  label={t(`${i18nPrefix}.payment_method`)}
                  value={this.state.payment_selection_index}
                  onChange={(text) => {
                    console.log("text.target", text.target)
                    this.setState({ payment_selection_index: text.target.value });
                  }}
                >
                  {this.payment_selections.map((item, index) => {
                    return <option value={index}>{item.text}</option>
                  })
                  }
                </FormInput>
              }

            </>
          }

          {/* buttons */}
          {activationMode &&
            <FormInput
              id="mobile_activation_code"
              label={t(`${i18nPrefix}.activationCode`)}
              type="number"
              value={this.state.mobile_activation_code}
              error={formErrors.mobile_activation_code}
              onChange={(text) => {
                this.setState({ mobile_activation_code: text.target.value });
              }}
              autoFocus
            />
          }

          {(!this.state.loggedIn && !activationMode) &&
            < Button
              id="button_book"
              variant="primary"
              size="lg"
              className="bookButton"
              onClick={() => this.handleCreateUnregisteredAccount()}
              disabled={this.state.loading || this.state.mooring_unavailable}
            >
              {t(`${i18nPrefix}.reserve`)}
              {this.state.loading && <Spinner style={{ width: 20, height: 20, marginLeft: 10, color: "white" }} animation="border" role="status" />}
            </Button>
          }

          {(!this.state.loggedIn && activationMode) &&
            <Button
              id="button_activate"
              variant="primary"
              size="lg"
              className="bookButton"
              onClick={() => this.handleActivateClicked(this.state.total_price)}
            >
              {t(`${i18nPrefix}.confirm`)}
              {this.state.loading && <Spinner style={{ width: 20, height: 20, marginLeft: 10, color: "white" }} animation="border" role="status" />}

            </Button>
          }

          {this.state.loggedIn &&
            <Button
              id="btnBook"
              variant="primary"
              size="lg"
              className="bookButton"
              onClick={() => { advance_payment_possible ? this.registered_booking() : this.unregistered_booking() }}
            >
              {t(`${i18nPrefix}.bookNow`)}
              {/* {" - "} */}
              {/* {advance_payment_possible ? "(instant payment)" : "pay later"} */}
              {this.state.loading && <Spinner style={{ width: 20, height: 20, marginLeft: 10, color: "white" }} animation="border" role="status" />}

            </Button>
          }

          <div style={{ marginTop: 15 }}>
          </div>
          <div
            ref={this.StatusAlertsRef}
          >
            <StatusAlerts
              state={{ ...this.state, loading: false }}
              errorMessage={this.state.errorMessage}
              successMessage={this.state.successMessage}
            />
          </div>


        </Container >
      </>

    );
  }
}


export default withTranslation()(withSearchParams(FastBooking));