import React from "react";
import { withTranslation } from 'react-i18next';

import {
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";

import moment from "moment/moment";

import { getMyMooringsWithAvailabilities } from '../actions/moorings'
import { replace } from '../actions/bookings'

import BookingDetailsModal from "../components/BookingDetailsModal";
import AddBookingModal from "../components/AddBookingModal";
import FilterModal from "../components/FilterModal";

import "../stylesheets/booking_table.css";
import FilterIcon from '../assets/icons/filter_icon.png'
import FilterFilledIcon from '../assets/icons/filter_filled_icon.png'


import COLORS from '../constants/colors';
import { available, unavailable, booked, available_day_by_day } from '../constants/constants';

import FormInput from "../components/FormInput";
import { deepSearchString } from "../utils/utilities";



const arrow_up = "&#8595;";
const arrow_down = "&#8593;";

const i18nPrefix = "BookingOverview";

const cellWidthDay = 60;
const cellHeight = 42;

const defaultFilters = {
  max_length: { min: 0, max: 99, filter: false },
  max_beam: { min: 0, max: 99, filter: false },
  max_draught: { min: 0, max: 99, filter: false },
  global: "",
}

const checkIsVisible = (element) => {
  const rect = element.getBoundingClientRect();
  if (rect.bottom <= window.innerHeight) {
    console.log("I see you!");
  }
};


class Bookings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      error: false,
      from_date: moment().set({ 'hour': 0, 'minute': 0, 'second': 0 }),
      to_date: moment().add(1, 'month').set({ 'hour': 0, 'minute': 0, 'second': 0 }),
      dates: [],
      moorings: [], // original unfiltered, unsorted moorings
      filteredMoorings: [], // filtered and sorted moorings to render
      filters: { ...defaultFilters },
      showBookingDetailsModal: false,
      bookingDetails: null,
      replacing: false,
      replacingDetails: null,
      sort_key: "berth_number",
      sort_direction: "ASC",
    };
    this.BookingDetailsModal = React.createRef();
    this.AddBookingModal = React.createRef();
    this.FilterModal = React.createRef();

    this.lastCellColor = null;
    this.replacingRange = [];
    this.selectedRange = [];
    this.mouseDown = false;

  }

  componentDidMount() {

    document.addEventListener("keydown", this.onKeyDown);

    this.loadMoorings(() => {
      // After loading moorings, scroll to "today" in date table
      var tableDiv = document.getElementById("date_table_div");
      tableDiv.scrollLeft = cellWidthDay
    });

    setInterval(this.loadMoorings, 15000);


  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown);
  }

  loadMoorings = (callback = null, from_date = this.state.from_date, to_date = this.state.to_date) => {
    this.setState({ loading: true }, () => {

      from_date = moment(from_date).subtract(1, 'day'); // -1 day for scrolling 
      to_date = moment(to_date).subtract(1, 'day');



      getMyMooringsWithAvailabilities(
        from_date, to_date,
        (moorings) => {
          moorings = moorings.filter(mooring => { // only show moorings with bookig_times
            return mooring.booking_times.length != 0;
          });

          console.log("From date: ", from_date.format('YYYY-MM-DD'))
          const dates = this.addDays(from_date, to_date);
          moorings.forEach((mooring) => {
            mooring.availabilities = this.get_availability_status(mooring, dates);
          })



          this.setState({ moorings: moorings, loading: false }, () => {
            this.sortAndFilterMoorings(moorings, this.state.filters, this.state.sort_key, this.state.sort_direction, callback);
          });
        }, () => {
          this.setState({ moorings: [], loading: false })
        })
    })

  }


  onKeyDown = (event) => {
    if (event.key === "Escape") {
      if (this.state.replacing) {
        this.setState({ replacing: false })
      }
    }
    else { console.log(event) }

    return event
  }

  changeSortDirections = (sort_key) => {
    var sort_direction = ""

    if (this.state.sort_key == sort_key) {
      sort_direction = this.itterateDirection(this.state.sort_direction)
    }
    else {
      sort_direction = "ASC"
    }

    this.sortAndFilterMoorings(this.state.moorings, this.state.filters, sort_key, sort_direction)
    this.setState({ sort_key: sort_key, sort_direction: sort_direction });
  }

  itterateDirection = (direction) => {
    if (direction == "ASC") { return "DSC" }
    if (direction == "DSC") { return "" }
    if (direction == "") { return "ASC" }
  }

  doNothing = (moorings) => {
    return moorings
  }

  getSortedMoorings = (moorings, key, direction) => {

    var sortedMoorings = [...moorings]
    sortedMoorings.sort(function (a, b) {
      switch (key) {
        default:
          var comparison = a[key] > b[key] ? -1 : 1;
          return direction === 'DSC' ? comparison : -comparison;
      }

    });
    return sortedMoorings;
  }

  // Set all searchable attributes ( from bookings and bookings.boat) as string of mooring object
  getStringifiedMoorings = (moorings) => {
    moorings = moorings.map((mooring) => {

      mooring.bookings = mooring.bookings.filter((booking) => {
        return (moment(booking.from_date) <= moment(this.state.to_date) && moment(booking.to_date) >= moment(this.state.from_date))
      })

      var booking_string = mooring.bookings.reduce((result, booking) => {
        var str = `${result}${booking.notes},${booking.firstname},${booking.lastname}`
        if (booking.boat) str += `${booking.boat.registration_number},${booking.boat.name}`
        return str
      }, "")
      mooring.booking_string = booking_string
      return mooring
    })
    console.log(moorings)
    return moorings
  }


  getFilteredMoorings = (moorings, filters) => {

    var filteredMoorings = [...moorings];
    filteredMoorings = this.getStringifiedMoorings(filteredMoorings)

    // Apply filters
    for (const attribute in filters) {
      const filter = filters[attribute];
      if (attribute == 'global') { // global filter: search input
        filteredMoorings = deepSearchString(filteredMoorings, this.state.filters.global);
      }
      else { // For range filters ( length, beam, draft )
        filteredMoorings = filteredMoorings.filter(mooring => {
          return mooring[attribute] >= filter.min && mooring[attribute] <= filter.max;
        });
      }
    }

    return filteredMoorings;

  }

  sortAndFilterMoorings = (moorings, filters, sort_key, sort_direction, callback = null) => {

    var filteredMoorings = this.getFilteredMoorings([...moorings], filters);
    const sortedFilteredMoorings = this.getSortedMoorings(filteredMoorings, sort_key, sort_direction)
    this.setState({ filteredMoorings: sortedFilteredMoorings, loading: false }, () => {
      if (callback != null) callback();
    });
  }

  get_availability_status(mooring, dates) {

    var availabilities = [];

    dates.forEach((date) => {
      const availability = {
        date: date,
        _date: date.format('YYYY-MM-DD'),
        status: unavailable,
        mooring_id: mooring.mooring_id,
        booking_id: null,
        booking: null,
      }
      mooring.booking_times.forEach((booking_time) => {
        if (moment(booking_time.from_date) <= date && moment(booking_time.to_date) > date) {
          availability.status = available;
          if (booking_time.day_by_day) {
            availability.status = available_day_by_day;
            //Day by day availability: first day is still available
            if (date.isSame(moment(), 'day')) {
              if (moment(booking_time.created_on).add(30, 'minutes') < moment()) {
                availability.status = available;
              }
            }
          }
        }
      })
      mooring.bookings.forEach((booking) => {
        if (moment(booking.from_date) <= date && moment(booking.to_date) > date) {
          availability.status = booked;
          availability.booking_id = booking.booking_id;
          availability.booking = booking;
        }
      })

      availabilities.push(availability);
    })
    return availabilities;
  }

  addDays = (from_date, to_date) => {
    let dates = []
    for (var i = 0; i < to_date.diff(from_date, 'days') + 2; i++) {
      const new_date = moment(from_date).add(i, "days");
      dates.push(new_date);
    }
    this.setState({ dates: dates });
    return dates;
  }

  onCellMouseDown(e, availability) {
    // console.log("a", availability);
    const mooring = this.state.filteredMoorings.find(mooring => mooring.mooring_id == availability.mooring_id)
    // console.log("mooring", mooring)

    if (!this.state.replacing) {
      if (availability.status === available) {
        if (availability.date >= moment().subtract(2, "day")) {
          if (mooring.rights.own_booking) {

            this.mouseDown = true
            e.target.classList.add('selected-range');
            this.selectedRange[0] = availability;
          }
        }
      }
    }
  }

  onCellMouseUp(e, availability) {
    if (this.mouseDown && availability.status === available) {

      this.mouseDown = false
      this.selectedRange[1] = availability;

      // Switch dates if selected backwards
      var from_date = this.selectedRange[0].date
      var to_date = this.selectedRange[1].date
      if (to_date < from_date) {
        var tmp = from_date;
        from_date = to_date;
        to_date = tmp;
      }
      this.AddBookingModal.show(availability.mooring_id, from_date, moment(to_date).add(1, "day"));

    }
  }

  onCellCick(e, availability) {
    // console.log("e, availability: ", availability);
    const { t } = this.props;
    if (this.state.replacing) {

      if (availability.status == available) {
        const booking_id = this.state.replacingDetails.booking.booking_id;
        let { boat } = this.state.replacingDetails
        const old_mooring = this.state.moorings.find(mooring => {
          return mooring.mooring_id === this.state.replacingDetails.booking.mooring_id;
        });
        const new_mooring = this.state.moorings.find(mooring => {
          return mooring.mooring_id === availability.mooring_id;
        });

        var legal = true;

        // Check if extension exists and is still available
        // console.log("boking", this.state.replacingDetails.booking)
        const { booking } = this.state.replacingDetails
        if (booking.extension) {
          // Check if whole time span from from_date up to extended to_date is available or available_day_by_day
          for (var d = moment(booking.from_date); d <= moment(booking.extension.to_date); d.add(1, "day")) {
            availability = this.get_availability_status(new_mooring, [d])[0]
            if (availability.status != available && availability.status != available_day_by_day) {
              legal = false
            }
          }

        }

        //Check if replacing is possible if boat is defined
        if (boat) {

          var warnings = "";
          // NO weight constraints
          if (!boat.weight) boat.weight = 0
          if (!new_mooring.max_weight) new_mooring.max_weight = Infinity

          if (new_mooring.max_length < boat.length) warnings = warnings += `\n` + t(`${i18nPrefix}.lengthLower`)
          if (new_mooring.max_beam < boat.beam) warnings = warnings += `\n` + t(`${i18nPrefix}.beamLower`)
          if (new_mooring.max_draught < boat.draft) warnings = warnings += `\n` + t(`${i18nPrefix}.draftLower`)
          if (new_mooring.max_weight < boat.weight) warnings += `\n` + t(`${i18nPrefix}.weightLower`)

        }

        if (warnings != "") warnings = `\n\n${t(`${i18nPrefix}.doesBoatStillFit`)} \n${warnings} \n`

        //Send replacing request
        if (legal) {
          window.confirm(t(`${i18nPrefix}.areYouSureYouWantToRebook`) + warnings) &&
            replace(booking_id, availability.mooring_id,
              (result) => {
                this.setState({ replacing: false });
                this.loadMoorings();
              },
              (err) => {
                alert(t(`${i18nPrefix}.replaceNotPossible`))
                this.setState({ replacing: false });
              }
            )
          this.setState({ replacing: false });
        }
        else {
          alert(t(`${i18nPrefix}.replaceNotPossible`))
          this.setState({ replacing: false });
        }
      }

    }
    else {

      const classNames = e.target.className.split(' ')
      var whole_booking = document.getElementsByClassName(classNames[1]);
      const col_from = whole_booking[0].id.split('_')[0];
      const col_to = whole_booking[whole_booking.length - 1].id.split('_')[0];
      this.replacingRange = [col_from, col_to];

      if (availability.status == booked) {
        this.BookingDetailsModal.show(availability.booking_id);
      }

    }

  }

  getBgColor(availability) {
    switch (availability.status) {
      case available:
        return COLORS.GREEN_MOORING_AVAILABLE_CALENDAR;
      case available_day_by_day:
        return COLORS.BLUE_MOORING_PARTIALLY_AVAILABLE;
      case booked:
        if (availability.booking.internal_paid) {
          return COLORS.RED_MOORING_UNAVAILABLE;
        }
        return COLORS.RED_MOORING_INTERNAL_UNPAID;
      case unavailable:
        return 'white';
      default:
        return 'gray';
    }
  }

  replaceClicked = (bookingDetails) => {
    this.setState({ replacing: true, replacingDetails: bookingDetails });
  };

  onCellMouseHover = (e, availability) => {

    if (this.state.replacing) { //During replacing we want to mark the new booking on the available cells

      this.lastCellColor = e.target.style.backgroundColor
      var id = e.target.id.split("_")
      var row_index = id[1]


      var legal = true;

      // Check if legal
      for (let i = this.replacingRange[0]; i <= this.replacingRange[1]; i++) {
        const cell_id = `${i}_${row_index}`;
        var hovered_cell = document.getElementById(cell_id)
        if (hovered_cell) {
          if (hovered_cell.className.split(" ")[0] != "available") {
            legal = false
          }
        }
      }

      // Change color of hovered row (for the colouns of booking)
      for (let i = this.replacingRange[0]; i <= this.replacingRange[1]; i++) {
        const cell_id = `${i}_${row_index}`;
        var hovered_cell = document.getElementById(cell_id)
        if (legal) {
          if (hovered_cell != null) hovered_cell.classList.add('hovered');
        }
        else {
          if (hovered_cell != null) hovered_cell.classList.add('hovered-illegal');
        }
      }

    }
    else { // No replacing: we want to mark the selected range
      if (this.mouseDown) {
        if (availability.mooring_id == this.selectedRange[0].mooring_id && availability.status == available) {
          e.target.classList.add('selected-range');
        }
        else {
          this.clearSelectedRange()
        }
      }
    }

  }

  clearSelectedRange = () => {
    var selectedCells = document.getElementsByClassName('selected-range');
    for (let i = 0; i < selectedCells.length; i += 0) {
      selectedCells[i].classList.remove('selected-range');
    }
    this.selectedRange = []
    this.mouseDown = false
  }

  onMouseLeaveCell = (e, availability) => {

    this.lastCellColor = e.target.style.backgroundColor
    var id = e.target.id.split("_")
    var row_index = id[1]


    for (let i = this.replacingRange[0]; i <= this.replacingRange[1]; i++) {
      const cell_id = `${i}_${row_index}`;
      var hovered_cell = document.getElementById(cell_id)
      if (hovered_cell != null) hovered_cell.classList.remove('hovered');
      if (hovered_cell != null) hovered_cell.classList.remove('hovered-illegal');
    }
    e.target.style.backgroundColor = this.lastCellColor;
  }

  onDateClicked = (e, date) => {
    window.open(`/home/reservations/${moment(date).format("YYYY-MM-DD")} `, '_blank', 'noopener,noreferrer');
  }

  filterPressed = (attribute) => {
    const filter = this.state.filters[attribute];
    const defaultFilter = defaultFilters[attribute]
    this.FilterModal.show(attribute, filter, defaultFilter)
  }

  applyFilter = (attribute, filter) => {
    console.log("new filter", attribute, filter)
    var filters = this.state.filters;
    filters[attribute] = filter;
    console.log("new filters", filters)
    this.setState({ filters: filters });
    this.sortAndFilterMoorings(this.state.moorings, filters, this.state.sort_key, this.state.sort_direction);

  }

  onTableScroll = (e) => { // Auto set from/to_date when scroll to the border
    const tableDiv = e.target
    const bottom = tableDiv.scrollWidth - tableDiv.scrollLeft === tableDiv.clientWidth;
    const top = (tableDiv.scrollLeft === 0)
    const DaysPerStep = 7

    if (bottom) {
      this.setState({ to_date: moment(this.state.to_date).add(DaysPerStep, 'days') }, () => { this.loadMoorings() })
    }
    if (top) {

      var w = window.innerWidth;

      // TODO:  better way for autoscrolling on mobile
      if (w > 768) { // Don't auto reload on mobile
        this.setState({ from_date: moment(this.state.from_date).subtract(DaysPerStep, 'days') }, () => {
          this.loadMoorings(() => {
            tableDiv.scrollLeft = DaysPerStep * cellWidthDay
          })
        }, moment(this.state.from_date).subtract(DaysPerStep, 'days'), this.state.to_date);
      }
    }
  };

  onMooringCellClicked = (mooring) => {
    window.open(`/home/mooringdetails/${mooring.mooring_id}`, '_blank', 'noopener,noreferrer');
  }


  render() {
    const { t } = this.props
    const arrow = this.state.sort_direction == '' ? "" : this.state.sort_direction === 'ASC' ? arrow_up : arrow_down
    return (
      <Container className="booking-table" sm={12} >

        <BookingDetailsModal
          onRef={(ref) => { this.BookingDetailsModal = ref }}
          onClose={() => {
            setTimeout(() => {
              this.loadMoorings()
            }, 200);
          }}
          bookingDetails={this.state.bookingDetails}
          replaceClicked={this.replaceClicked}
        />

        <AddBookingModal
          onRef={(ref) => { this.AddBookingModal = ref }}
          onClose={() => {
            this.loadMoorings();
            this.clearSelectedRange();
          }}
          onBookingSuccessful={() => { this.loadMoorings(); }}
        />

        <FilterModal
          onRef={(ref) => { this.FilterModal = ref }}
          applyFilter={(attribute, filter) => this.applyFilter(attribute, filter)}
        />



        <h2>{t(`${i18nPrefix}.title`)}  {this.state.loading && <Spinner style={{ width: 25, height: 25, marginLeft: 10, borderWidth: "0.1em" }} animation="border" variant="secondary" role="status" />} </h2>
        {this.state.replacing && <h2>Replace...</h2>}

        <Row>
          <Col>
            <FormInput
              label={t(`global.from`)}
              type="date"
              required
              value={this.state.from_date.format("YYYY-MM-DD")}
              onChange={(e) => {
                const from_date = moment(e.target.value)

                var to_date = this.state.to_date
                if (from_date > to_date) {
                  to_date = moment(from_date).add(1, 'month')
                }
                this.setState({
                  from_date: from_date,
                  to_date: to_date,
                }, () => {
                  if (from_date < moment('2000-01-01')) return
                  this.loadMoorings(() => { }, from_date, to_date)
                })
              }}
            />
          </Col>
          <Col>
            <FormInput
              label={t(`global.to`)}
              type="date"
              required
              value={this.state.to_date.format("YYYY-MM-DD")}
              min={moment(this.state.from_date).add(1, "day").format("YYYY-MM-DD")}
              onChange={(e) => {
                this.setState({
                  to_date: moment(e.target.value)
                }, () => { this.loadMoorings() })
              }}
            />
          </Col>
          <Col md={6}>
            <FormInput
              id="input_search"
              label={t(`global.search`)}
              type="text"
              placeholder="..."
              required
              value={this.state.filters.global}
              onChange={(e) => {
                this.applyFilter('global', e.target.value)
                this.setState({ filters: { ...this.state.filters, global: e.target.value } })
              }}
            />
          </Col>

        </Row>

        {(this.state.moorings) &&

          < div class="view">
            <div class="wrapper t-container" id="date_table_div" onScroll={this.onTableScroll}>
              <table class="table">
                <thead>
                  <tr>
                    <th
                      id="col_berth_number"
                      class="mooring-col first-col sticky-col"
                      onClick={() => this.changeSortDirections("berth_number")}
                    >
                      <span dangerouslySetInnerHTML={{ __html: `${t(`${i18nPrefix}.Nu`)} ${this.state.sort_key == 'berth_number' ? arrow : ""} ` }} />
                    </th>
                    <th
                      class="mooring-col second-col"
                      id="col_length"
                      style={{ width: 80 }}
                      onClick={() => this.changeSortDirections("max_length")}>
                      <span dangerouslySetInnerHTML={{ __html: `${t(`${i18nPrefix}.l`)} ${this.state.sort_key == 'max_length' ? arrow : ""} ` }} />
                      <span style={{ float: 'right' }} >
                        <img
                          id="filter_length"
                          onClick={(e) => { e.stopPropagation(); this.filterPressed("max_length"); }}
                          style={{ height: 18, marginTop: -2 }}
                          src={this.state.filters['max_length'].filter ? FilterFilledIcon : FilterIcon}
                          alt="FilterIcon"
                        />
                      </span>
                    </th>
                    <th
                      id="col_beam"
                      class="mooring-col third-col"
                      style={{ width: 80 }}
                      onClick={() => this.changeSortDirections("max_beam")} >
                      <span dangerouslySetInnerHTML={{ __html: `${t(`${i18nPrefix}.b`)} ${this.state.sort_key == 'max_beam' ? arrow : ""} ` }} />
                      <span style={{ float: 'right' }} >
                        <img
                          id="filter_beam"
                          onClick={(e) => { e.stopPropagation(); this.filterPressed("max_beam"); }}
                          style={{ height: 18, marginTop: -2 }}
                          src={this.state.filters['max_beam'].filter ? FilterFilledIcon : FilterIcon}
                          alt="FilterIcon"
                        />
                      </span>
                    </th>
                    <th
                      id="col_draft"
                      class="mooring-col fourth-col"
                      style={{ width: 80 }}
                      onClick={() => this.changeSortDirections("max_draught")} >
                      <span dangerouslySetInnerHTML={{ __html: `${t(`${i18nPrefix}.d`)} ${this.state.sort_key == 'max_draught' ? arrow : ""} ` }} />
                      <span style={{ float: 'right' }} >
                        <img
                          id="filter_draft"
                          onClick={(e) => { e.stopPropagation(); this.filterPressed("max_draught"); }}
                          style={{ height: 18, marginTop: -2 }}
                          src={this.state.filters['max_draught'].filter ? FilterFilledIcon : FilterIcon}
                          alt="FilterIcon"
                        />
                      </span>
                    </th>

                    {this.state.dates.map((date, index) => {
                      // mark today
                      var className = date.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") ? "today" : ""

                      // Mark weekends
                      if ((date.isoWeekday()) / 6 >= 1) {
                        className += " weekend"
                      }
                      className += " date_cell"

                      return <th
                        id={`date_cell_${index}`}
                        style={{ width: 60 }}

                        onClick={(e) => { this.onDateClicked(e, date) }}
                        className={className}
                      >
                        {moment(date).format('DD.MM.')}
                      </th>
                    }
                    )}
                  </tr>

                </thead>
                <tbody>

                  {this.state.filteredMoorings.map((mooring, row_index) =>
                    <tr
                      style={{ height: cellHeight, cursor: 'pointer' }}
                    >
                      <td
                        onClick={() => this.onMooringCellClicked(mooring)}
                        className="first-col mooring-col sticky-col"
                        title={mooring.berth_number}>
                        <b>{mooring.berth_number}</b>
                      </td>
                      <td className="mooring-col second-col sticky-col">
                        {mooring.max_length.toFixed(2)}

                      </td>
                      <td className="mooring-col third-col sticky-col">
                        {mooring.max_beam.toFixed(2)}
                      </td>
                      <td className="mooring-col fourth-col sticky-col">
                        {mooring.max_draught.toFixed(2)}
                      </td>

                      {
                        mooring.availabilities.map((availability, col_index) => {

                          const bgColor = this.getBgColor(availability)
                          const style = {
                            backgroundColor: bgColor,
                            height: cellHeight,
                          }

                          // Formating for bookings
                          var first = false
                          var last = false
                          var hoverable = true
                          var mooring_id_booking_id = `${mooring.mooring_id} `
                          if (availability.booking_id != null) { //only for bookings

                            mooring_id_booking_id = `${mooring.mooring_id}_${availability.booking_id} `

                            if (availability.booking.from_date == availability.date.format("YYYY-MM-DD")) {
                              first = true
                            }
                            if (availability.booking.to_date == moment(availability.date).add(+1, "day").format("YYYY-MM-DD")) {
                              last = true
                            }

                          }

                          const first_class = first ? "first" : "";
                          const last_class = last ? "last" : "";
                          const hoverable_class = hoverable ? "hoverable" : "";

                          var tooltiptext = null;
                          if (availability.status == booked) {

                            if (availability.booking.notes != "") {
                              tooltiptext = availability.booking.notes
                            }
                            else if (availability.booking.boat) {
                              const boat = availability.booking.boat
                              const boat_name = boat.name ? boat.name : ""
                              const boat_registration_number = boat.registration_number ? boat.registration_number : ""
                              tooltiptext = `${availability.booking.lastname} / ${boat_name} ${boat_registration_number} (${boat.length} / ${boat.beam} / ${boat.draft})`;
                            }
                            else {
                              tooltiptext = `${availability.booking.firstname} ${availability.booking.lastname}`;
                            }

                          }

                          return <td
                            onClick={(e) => { this.onCellCick(e, availability) }}
                            onMouseDown={(e) => { this.onCellMouseDown(e, availability) }}
                            onMouseUp={(e) => { this.onCellMouseUp(e, availability) }}
                            onMouseOver={(e) => { this.onCellMouseHover(e, availability) }}
                            onMouseLeave={(e) => { this.onMouseLeaveCell(e, availability) }}
                            style={style}
                            className={`${availability.status} ${mooring_id_booking_id} ${first_class} ${last_class} ${hoverable_class} date_cell`}
                            id={`${col_index}_${row_index}`}
                            title={tooltiptext != null && tooltiptext}
                          >
                          </td>

                        })
                      }
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        }


      </Container >



    );
  }
}

export default withTranslation()(Bookings);