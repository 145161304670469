import React from "react";
import { withTranslation } from 'react-i18next';

import moment from "moment";

import { Container, FormControl, Form, Button, Alert, Row, Col, Collapse } from "react-bootstrap";
import DataGrid, { SelectColumn, TextEditor, SelectCellFormatter, SortableHeaderCell, Row as DataGridRow } from 'react-data-grid';

import { getMyMooringTypes, getMyMooringZones, getMyMooring } from "../actions/moorings";
import { getMyContactTypes } from '../actions/contacts'
import { createWaitingContact, getWaitingContacts, updateWaitingContact, deleteWaitingContact } from '../actions/waiting_contacts';
import { getMyAccountData } from '../actions/account';

import AddNewButton from '../components/AddNewButton';
import FormInput from "../components/FormInput";
import globalStyles from '../stylesheets/globalStyles'
import { CSVLink, CSVDownload } from "react-csv";
import EditContactModal from "../components/EditContactModal";
import DeleteIcon from '../assets/icons/delete.png'
import EditWaitingContactModal from "../components/EditWaitingContactModal";
import COLORS from '../constants/colors';
import { deepSearchString } from "../utils/utilities";



const i18nPrefix = 'WaitingContactTable'

function rowKeyGetter(row) {
  return row.id;
}

const empty_filters = {
  global: "",
  contact_name: "",
  contact_address: "",
  archived: "0",
}

class WaitingContactsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      error: false,
      waiting_contacts: [],
      account: {},
      sorted_waiting_contacts: [],
      filtered_sorted_waiting_contacts: [],
      filters: empty_filters,
      sortColumns: [],
      selectedRows: new Set([]),
      selectedWaitingContacts: [],
      editable: false,
      selecting: false, // Show table only to select contact
      mooring: false,
      mooring_types: [],
      mooring_zones: [],
      contact_types: [],
      showFilter: false,
    };

    this.csvLink = React.createRef();
    this.EditWaitingContactModal = React.createRef()


  }





  componentDidMount() {

    if (this.props.location.state) {
      this.setState({ selecting: this.props.location.state.selecting, mooring_id: this.props.location.state.mooring_id });

      // In case there is a mooring
      if (this.props.location.state.mooring_id) {
        getMyMooring(this.props.location.state.mooring_id,
          (mooring) => { this.setState({ mooring: mooring }); },
          () => { })
      }

      // Load params router
      if (this.props.location.state && this.props.location.state.contact) {
        let contact_id = this.props.location.state.contact.id;


        this.EditWaitingContactModal.show({ contact_id: contact_id })

      }
    }

    getMyMooringTypes(mooring_types => this.setState({ mooring_types: mooring_types }), () => { })
    getMyMooringZones(mooring_zones => this.setState({ mooring_zones: mooring_zones }), () => { })
    getMyContactTypes(contact_types => this.setState({ contact_types: contact_types }), () => { })
    getMyAccountData(account => this.setState({ account: account }), () => { })


    getWaitingContacts(
      (waiting_contacts) => {
        const extendedReservations = this.extendReservations(waiting_contacts);
        this.setState({ waiting_contacts: extendedReservations, sorted_waiting_contacts: extendedReservations, filtered_sorted_waiting_contacts: extendedReservations }, () => this.setFilters(empty_filters))
      },
      () => { }
    )
  }

  loadWaitingContacts = () => {
    getWaitingContacts(
      (waiting_contacts) => {

        const extendedReservations = this.extendReservations(waiting_contacts);
        const sorted_waiting_contacts = this.getSortedRows(waiting_contacts, this.state.sortColumns);
        const filtered_sorted_waiting_contacts = this.getFilteredRows(sorted_waiting_contacts, this.state.filters);
        this.setState({ waiting_contacts: extendedReservations, sorted_waiting_contacts: sorted_waiting_contacts, filtered_sorted_waiting_contacts: filtered_sorted_waiting_contacts })
      },
      () => { }
    )
  }

  extendReservations(waiting_contacts) {
    const NoOrYes = this.props.t('global.NoOrYes')
    waiting_contacts.forEach(waiting_contact => {
      waiting_contact.contact_name = `${waiting_contact.contact.lastname} ${waiting_contact.contact.firstname}`
      waiting_contact.contact_address = `${waiting_contact.contact.street} ${waiting_contact.contact.zip} ${waiting_contact.contact.city}`
      waiting_contact.email = `${waiting_contact.contact.email}`
      waiting_contact.contact_type = `${waiting_contact.contact.type}`


      //Formating
      if (waiting_contact.mooring_type == null) waiting_contact.mooring_type = ""
      if (waiting_contact.mooring_zone == null) waiting_contact.mooring_zone = ""

      waiting_contact.relocation_string = NoOrYes[waiting_contact.relocation]
      waiting_contact.annual_mooring_interested_string = NoOrYes[waiting_contact.annual_mooring_interested]
      waiting_contact.archived_string = NoOrYes[waiting_contact.archived]


    });
    return waiting_contacts;
  }

  onRowsChange = (newRows, changedRow) => {
    if (this.state.editable) {
      const assigned_contact = newRows[changedRow.indexes[0]]
      this.setState({ sorted_waiting_contacts: newRows })
      updateWaitingContact(assigned_contact, (response) => { }, () => { console.log("error") })
    }
  }

  onRowClick = (arg1, arg2) => {
    // Show contact
    if (arg2.key == "contact_name" && !this.state.editable) {
      this.EditContactModal.show(arg1.contact)
    }

  }

  onRowDoubleClick = (waiting_contact, arg2) => {
    if (this.state.selecting) {
      this.props.history.push(this.props.location.state.lastScreen, { contact: waiting_contact.contact, waiting_contact: waiting_contact })
    }
    else {
      this.EditWaitingContactModal.show(waiting_contact);
    }
  }


  onSortColumnsChange = (columnSortings) => {
    this.setSorting(columnSortings);
  }


  onSelectedRowsChange = (arg1) => {
    var selectedWaitingContacts = []

    var selected_waiting_contact_ids = Array.from(arg1);
    for (var i = 0; i < selected_waiting_contact_ids.length; i++) {
      const waiting_contact = this.state.waiting_contacts.find(x => x.id === selected_waiting_contact_ids[i]);
      var selectedWaitingContact = {
        id: waiting_contact.id,
        salutation: waiting_contact.contact.salutation,
        firstname: waiting_contact.contact.firstname,
        lastname: waiting_contact.contact.lastname,
        email: waiting_contact.contact.email,
        mobile: waiting_contact.contact.mobile,
        phone_p: waiting_contact.contact.phone_p,
        phone_b: waiting_contact.contact.phone_b,
        street: waiting_contact.contact.street,
        zip: waiting_contact.contact.zip,
        city: waiting_contact.contact.city,
        country: waiting_contact.contact.country,
        contact_type: waiting_contact.contact_type,
        mooring_type: waiting_contact.mooring_type,
        mooring_zone: waiting_contact.mooring_zone,
        boat_type: waiting_contact.boat_type,
        entry_date: waiting_contact.entry_date,
        min_length: waiting_contact.min_length,
        min_beam: waiting_contact.min_beam,
        min_draught: waiting_contact.min_draught,
        annual_mooring_interested: waiting_contact.annual_mooring_interested,
        comment: waiting_contact.comment,
        last_examination: waiting_contact.last_examination,
        archived_string: waiting_contact.archived,
        archiving_reason: waiting_contact.archiving_reason,
        archiving_date: waiting_contact.archiving_date,
        registration_fee: waiting_contact.registration_fee,
        periodic_fee: waiting_contact.periodic_fee,
        currency: waiting_contact.currency,
        relocation: waiting_contact.relocation,
        contact_id: waiting_contact.contact_id,
        waiting_contact_id: waiting_contact.id,
      }
      selectedWaitingContacts.push(selectedWaitingContact);
    }

    this.setState({ selectedRows: arg1, selectedWaitingContacts: selectedWaitingContacts })
  }

  setSorting = (columnSortings = this.state.columnSortings) => {
    this.setState({ sortColumns: columnSortings })

    const sorted_waiting_contacts = this.getSortedRows(this.state.waiting_contacts, columnSortings);
    const filtered_sorted_waiting_contacts = this.getFilteredRows(sorted_waiting_contacts, this.state.filters)
    this.setState({ columnSortings: columnSortings, filtered_sorted_waiting_contacts: filtered_sorted_waiting_contacts })

  }


  setFilters = (filters, callback) => {
    const filtered_waiting_contacts = this.getFilteredRows(this.state.sorted_waiting_contacts, filters)
    const filtered_sorted_waiting_contacts = this.getSortedRows(filtered_waiting_contacts);
    this.setState({ filters: filters, filtered_sorted_waiting_contacts: filtered_sorted_waiting_contacts }, () => {
      this.searchInput.focus()
    })
  }

  getSortedRows = (waiting_contacts, columnSortings = this.state.columnSortings) => {

    var sorted_waiting_contacts = [...waiting_contacts]
    if (columnSortings && columnSortings[0]) {
      const columnKey = columnSortings[0].columnKey
      const direction = columnSortings[0].direction
      sorted_waiting_contacts.sort(function (a, b) {
        switch (columnKey) {
          case "entry_date":
            var comparison = moment(a[columnKey]) > moment(b[columnKey]) ? -1 : 1;
            return direction === 'ASC' ? comparison : -comparison;

          default:
            var comparison = a[columnKey] > b[columnKey] ? -1 : 1;

            if (a[columnKey] === null) {
              comparison = 1;
            }
            else if (b[columnKey] === null) {
              comparison = -1;
            }

            return direction === 'ASC' ? comparison : -comparison;

        }
      });
    }
    return sorted_waiting_contacts;
  }

  getFilteredRows = (waiting_contacts, filters = this.state.filters) => {

    var filtered_waiting_contacts = waiting_contacts
    if (filters.contact_name) {
      filtered_waiting_contacts = filtered_waiting_contacts.filter(assigned_contact => assigned_contact.contact_name.toLowerCase().includes(filters.contact_name.toLowerCase()))
    }
    if (filters.contact_address) {
      filtered_waiting_contacts = filtered_waiting_contacts.filter(assigned_contact => assigned_contact.contact_address.toLowerCase().includes(filters.contact_address.toLowerCase()))
    }
    if (filters.contact_type) {
      filtered_waiting_contacts = filtered_waiting_contacts.filter(assigned_contact => assigned_contact.contact_type.toLowerCase().includes(filters.contact_type.toLowerCase()))
    }
    if (filters.comment) {
      filtered_waiting_contacts = filtered_waiting_contacts.filter(assigned_contact => assigned_contact.comment.toLowerCase().includes(filters.comment.toLowerCase()))
    }
    if (filters.entry_date) {
      filtered_waiting_contacts = filtered_waiting_contacts.filter(assigned_contact => assigned_contact.entry_date.toLowerCase().includes(filters.entry_date.toLowerCase()))
    }
    if (filters.mooring_zone) {
      filtered_waiting_contacts = filtered_waiting_contacts.filter(assigned_contact => assigned_contact.mooring_zone ? assigned_contact.mooring_zone.toLowerCase().includes(filters.mooring_zone.toLowerCase()) : true)
    }
    if (filters.mooring_type) {
      filtered_waiting_contacts = filtered_waiting_contacts.filter(assigned_contact => assigned_contact.mooring_type ? assigned_contact.mooring_type.toLowerCase().includes(filters.mooring_type.toLowerCase()) : true)
    }
    if (filters.archived) {
      filtered_waiting_contacts = filtered_waiting_contacts.filter(assigned_contact => assigned_contact.archived == filters.archived)
    }
    if (filters.relocation) {
      filtered_waiting_contacts = filtered_waiting_contacts.filter(assigned_contact => assigned_contact.relocation == filters.relocation)
    }
    if (filters.global) {
      filtered_waiting_contacts = deepSearchString(filtered_waiting_contacts, filters.global)
    }

    return filtered_waiting_contacts;
  }


  onChangeSearchText = (filters) => {
    this.setState({ filters: filters })

    const filtered = this.state.waiting_contacts.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.toLowerCase().includes(filters.global.toLowerCase())));
    this.setState({ sorted_waiting_contacts: filtered })
  }


  exportToCSVClicked = () => {

    this.csvLink.link.click();
  }

  onEditContactModalClose = () => {
  }

  RowRenderer = ({ renderBaseRow, ...props }) => {
    // const color = this.state.tableState[props.idx].CHECKED === 'Y' ? "blue" : "";

    var provisional_assigned_highlight = false
    var illegal_assigned_highlight = false
    if (props.row.assigned_contacts.length != 0) {
      const assigned_contact = props.row.assigned_contacts[0]
      if (assigned_contact.provisional) {
        provisional_assigned_highlight = true
      }
      else {
        illegal_assigned_highlight = true
      }
    }


    //   // }
    // }
    const className = provisional_assigned_highlight ? "row-highlight-provisional" : illegal_assigned_highlight ? "row-highlight-illegal" : ""

    return <div className={className}><DataGridRow {...props} /></div>
  };

  render() {
    const { t } = this.props
    const filters = this.state.filters
    const editable = this.state.editable
    const mooring = this.state.mooring
    const columns = [
      SelectColumn,
      {
        key: 'contact_name', name: t(`${i18nPrefix}.contact_name`), width: 150, frozen: true,/* cellClass: 'green-cell',*/
        formatter(props) {
          const value = props.row.contact_name;
          return (
            <>
              <a onClick={(e) => { e.preventDefault() }} style={{ textDecoration: "none", color: "black", cursor: "pointer" }}> {props.row.contact_name} </a>
            </>
          );
        },
      },
      { key: 'contact_address', name: t(`${i18nPrefix}.contact_address`), width: 150, frozen: true, },
      { key: 'contact_type', name: t(`${i18nPrefix}.contact_type`), width: 150, frozen: true, },
      { key: 'entry_date', name: t(`${i18nPrefix}.entry_date`), width: 130, editor: TextEditor, editable: editable, frozen: true, },
      { key: 'email', name: t(`${i18nPrefix}.email`), width: 130, editor: TextEditor, editable: editable, },
      { key: 'mooring_zone', name: t(`${i18nPrefix}.mooring_zone`), width: 100, editor: TextEditor, editable: editable, },
      { key: 'mooring_type', name: t(`${i18nPrefix}.mooring_type`), width: 100, editor: TextEditor, editable: editable, },
      { key: 'boat_type', name: t(`${i18nPrefix}.boat_type`), width: 100, editor: TextEditor, editable: editable, },
      { key: 'min_length', name: t(`${i18nPrefix}.min_length`), width: 100, editor: TextEditor, editable: editable, },
      { key: 'min_beam', name: t(`${i18nPrefix}.min_beam`), width: 100, editor: TextEditor, editable: editable, },
      { key: 'min_draught', name: t(`${i18nPrefix}.min_draught`), width: 100, editor: TextEditor, editable: editable, },
      { key: 'relocation_string', name: t(`${i18nPrefix}.relocation`), width: 100, editor: TextEditor, editable: editable, },
      { key: 'annual_mooring_interested_string', name: t(`${i18nPrefix}.annual_mooring_interested`), width: 150, editor: TextEditor, },
      { key: 'comment', name: t(`${i18nPrefix}.comment`), width: 150, editor: TextEditor, },
      { key: 'last_examination', name: t(`${i18nPrefix}.last_examination`), width: 150, editor: TextEditor, },
      { key: 'archived_string', name: t(`${i18nPrefix}.archived`), width: 150, editor: TextEditor, },
      { key: 'archiving_reason', name: t(`${i18nPrefix}.archiving_reason`), width: 150, editor: TextEditor, },
      { key: 'archiving_date', name: t(`${i18nPrefix}.archiving_date`), width: 150, editor: TextEditor, },
      { key: 'registration_fee', name: t(`${i18nPrefix}.registration_fee`), width: 150, editor: TextEditor, },
      { key: 'periodic_fee', name: t(`${i18nPrefix}.periodic_fee`), width: 150, editor: TextEditor, },
      { key: 'currency', name: t(`${i18nPrefix}.currency`), width: 150, editor: TextEditor, },
    ];


    if (this.state.selecting) columns.shift();

    var filtered_sorted_pending_rservations = this.state.filtered_sorted_waiting_contacts

    return (
      <>



        <Container style={{ maxHeight: "none", backgroundColor: "white" }} >

          <EditContactModal
            onRef={ref => (this.EditContactModal = ref)}
            onClose={this.onEditContactModalClose}
          />

          <EditWaitingContactModal
            onRef={ref => this.EditWaitingContactModal = ref}
            onSave={this.loadWaitingContacts}
          />

          <Row>
            <Col sm={4}>
              <h1>{"Warteliste"}</h1>
              <h2>
                {this.state.selecting && "Auswählen "}
                {this.state.mooring && "für Platz"}
              </h2>

            </Col>

            {!this.state.selecting &&
              <>
                <Col sm={8}>

                  <Button
                    style={{ ...globalStyles.saveButton, marginLeft: 10 }}
                    disabled={this.state.loading}
                    onClick={() => this.exportToCSVClicked()} >
                    {t(`global.export`)}
                  </Button>

                  <Button
                    style={this.state.editable ? globalStyles.redButton : globalStyles.saveButton}
                    disabled={this.state.loading}
                    onClick={() => this.setState({ editable: !this.state.editable })} >
                    {this.state.editable ? t(`${i18nPrefix}.editOn`) : t(`${i18nPrefix}.editOff`)}
                  </Button>
                </Col>
              </>
            }

          </Row>

          {mooring &&
            <Row>
              <Col>
                <FormInput
                  value={mooring.berth_number}
                  label={t(`${i18nPrefix}.berth_number`)}
                  disabled
                />
              </Col>
              <Col>
                <FormInput
                  value={mooring.mooring_zone}
                  label={t(`${i18nPrefix}.mooring_zone`)}
                  disabled
                />
              </Col>
              <Col>
                <FormInput
                  value={mooring.mooring_type}
                  label={t(`${i18nPrefix}.mooring_type`)}
                  disabled
                />
              </Col>
              <Col>
                <FormInput
                  value={`${mooring.max_length}/${mooring.max_beam}/${mooring.max_draught}`}
                  label={t(`${i18nPrefix}.dimensions`)}
                  disabled
                />
              </Col>
            </Row>
          }


          <CSVLink
            separator={this.state.account.csv_separator}
            style={{ visibility: "hidden" }}
            ref={(ref) => this.csvLink = ref}
            filename="BP-waiting-contacts.csv"
            data={this.state.selectedWaitingContacts}>Download me
          </CSVLink>




          <Row>
            <Col md={8}>
              <input
                style={{ marginTop: 0, outline: 'none', marginBottom: 10, width: '100%', fintSize: 20, border: "none", borderRadius: 0, paddingLeft: 0, backgroundColor: "white", color: COLORS.MAIN_BLUE, boxShadow: 'none' }}
                autoFocus={true}
                ref={(input) => { this.searchInput = input; }}
                placeholder={t(`global.search`)}
                value={filters.global}
                onChange={(e) =>
                  this.setFilters({
                    ...filters,
                    global: e.target.value
                  })
                }
              />
              <div style={{ width: "100%", height: 1, backgroundColor: COLORS.GRAY_OUTLINE }}></div>
            </Col>
            <Col md={2}  >
              <button
                onClick={() => this.setFilters(empty_filters)}
                style={{ backgroundColor: 'transparent', borderColor: 'transparent', float: 'right' }}
              >
                <img
                  style={{ width: 25, marginTop: 5 }}
                  src={DeleteIcon}
                  alt="deleteIcon"
                />
              </button>
              <Button
                style={{ ...globalStyles.normalButton, float: 'right' }}
                onClick={() => this.setState({ showFilter: !this.state.showFilter })}
              >
                {t(`${i18nPrefix}.showFilter`)}
              </Button>

            </Col>
            <Col md={2} >
              <AddNewButton
                center
                label={t(`${i18nPrefix}.addEntry`)}
                onClick={() => this.EditWaitingContactModal.show()}
              />
            </Col>
          </Row>

          <Collapse in={this.state.showFilter}>
            <div >
              <Row style={{ marginTop: 20 }}>
                <Col>
                  <FormInput
                    value={filters.contact_name}
                    label={t(`${i18nPrefix}.contact_name`)}
                    onChange={(e) =>
                      this.setFilters({
                        ...filters,
                        contact_name: e.target.value
                      })
                    }
                  />
                </Col>
                <Col>
                  <FormInput
                    value={filters.contact_address}
                    label={t(`${i18nPrefix}.contact_address`)}
                    onChange={(e) =>
                      this.setFilters({
                        ...filters,
                        contact_address: e.target.value
                      })
                    }
                  />
                </Col>

                <Col>
                  <FormInput as="select"
                    value={filters.archived}
                    label={t(`${i18nPrefix}.status`)}
                    onChange={(e) =>
                      this.setFilters({
                        ...filters,
                        archived: e.target.value
                      })
                    }
                  >
                    <option value={0}>{t(`${i18nPrefix}.active`)}</option>
                    <option value={1}>{t(`${i18nPrefix}.archived`)}</option>
                    <option value={''}>{t(`${"global"}.all`)}</option>
                  </FormInput>
                </Col>

                <Col>
                  <FormInput as="select"
                    value={filters.relocation}
                    label={t(`${i18nPrefix}.relocation`)}
                    onChange={(e) =>
                      this.setFilters({
                        ...filters,
                        relocation: e.target.value
                      })
                    }
                  >
                    <option value={''}>{t(`${"global"}.all`)}</option>
                    <option value={1}>{t(`${"global"}.yes`)}</option>
                    <option value={0}>{t(`${"global"}.no`)}</option>

                  </FormInput>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormInput as="select"
                    value={filters.contact_type}
                    label={t(`${i18nPrefix}.contact_type`)}
                    onChange={(e) =>
                      this.setFilters({
                        ...filters,
                        contact_type: e.target.value
                      })
                    }
                  >
                    <option value={''}>{t(`${"global"}.all`)}</option>

                    {this.state.contact_types.map(contact_type => <option key={contact_type.id}>{contact_type.type}</option>)}
                  </FormInput>
                </Col>
                <Col>
                  <FormInput as="select"
                    value={filters.mooring_zone}
                    label={t(`${i18nPrefix}.mooring_zone`)}
                    onChange={(e) =>
                      this.setFilters({
                        ...filters,
                        mooring_zone: e.target.value
                      })
                    }
                  >
                    <option value={''}>{t(`${"global"}.all`)}</option>

                    {this.state.mooring_zones.map(mooring_zone => <option key={mooring_zone.id}>{mooring_zone.zone}</option>)}
                  </FormInput>
                </Col>
                <Col>
                  <FormInput as="select"
                    value={filters.mooring_type}
                    label={t(`${i18nPrefix}.mooring_type`)}
                    onChange={(e) =>
                      this.setFilters({
                        ...filters,
                        mooring_type: e.target.value
                      })
                    }
                  >
                    <option value={''}>{t(`${"global"}.all`)}</option>

                    {this.state.mooring_types.map(mooring_type => <option key={mooring_type.id}>{mooring_type.mooring_type}</option>)}
                  </FormInput>
                </Col>
                <Col>
                  <FormInput
                    value={filters.comment}
                    label={t(`${i18nPrefix}.comment`)}
                    onChange={(e) =>
                      this.setFilters({
                        ...filters,
                        comment: e.target.value
                      })
                    }
                  />
                </Col>


              </Row>
            </div>
          </Collapse>

          <div style={{ marginTop: 15 }}>
            <DataGrid
              style={{ maxHeight: "none", height: 700 }}
              columns={columns}
              rows={filtered_sorted_pending_rservations}
              className="rdg-light"
              onRowClick={this.onRowClick}
              onRowsChange={this.onRowsChange}
              rowKeyGetter={rowKeyGetter}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
                editable: false,
              }}
              rowRenderer={this.RowRenderer}
              onSortColumnsChange={this.onSortColumnsChange}
              sortColumns={this.state.sortColumns}
              selectedRows={this.state.selectedRows}
              onSelectedRowsChange={this.onSelectedRowsChange}
              onRowDoubleClick={this.onRowDoubleClick}
            >
              <SortableHeaderCell />
            </DataGrid>
          </div>
        </Container >
      </>
    );
  }
}


export default withTranslation()(WaitingContactsTable);
