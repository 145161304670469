import React from "react";
import { withTranslation } from 'react-i18next';

import {
  Row,
  Col,
} from "react-bootstrap";
import moment from 'moment';

import FormInput from "../components/FormInput";


import BookingItem from '../components/BookingItem';
import { getMyReservations } from "../actions/bookings";


class MaintainReservations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      error: false,
      from_date: moment().startOf('day'),
      to_date: moment().startOf('day').add(1, "month"),
      date_strings: [],
      reservations: [],
      reservations_by_date_strings: [],

    };
  }

  componentDidMount() {
    this.props.onRef && this.props.onRef(this)
    this.onDateChanged(this.state.from_date, this.state.to_date);
  }


  loadReservations = () => {
    this.onDateChanged(this.state.from_date, this.state.to_date);

  }

  onDateChanged = (from_date = this.state.from_date, to_date = this.state.to_date) => {

    getMyReservations(
      from_date.format("YYYY-MM-DD"), to_date.format("YYYY-MM-DD"),
      (reservations) => {
        var date = moment(from_date)
        var date_strings = []
        var reservations_by_date_strings = []
        while (date <= to_date) {
          const date_string = date.format("YYYY-MM-DD")
          reservations_by_date_strings[date_string] = []
          date_strings.push(date_string)

          reservations.forEach(reservation => {
            if (moment(reservation.from_date) <= date && moment(reservation.to_date) >= date) {
              reservations_by_date_strings[date_string].push(reservation)
            }
          });
          date = date.add(1, "day")
        }
        this.setState({ date_strings: date_strings, reservations_by_date_strings: reservations_by_date_strings, reservations: reservations })


      },
      () => { }
    )


  }

  scrollToDate = (date) => {
    this.setState({ from_date: date, to_date: moment(date).add(1, 'month') }, () => {
      this.onDateChanged(date, moment(date).add(1, 'month'))
    });
  }

  render() {
    const { t } = this.props;
    const authenticated = localStorage.getItem("AuthToken");
    const { reservations } = this.state;


    return (

      <div >
        <div style={{ marginBottom: 10, padding: 5, backgroundColor: "white", borderRadius: 5 }}>

          <Row>
            <Col>
              <FormInput
                label={t(`global.from`)}
                type="date"
                required
                value={moment(this.state.from_date).format("YYYY-MM-DD")}
                onChange={(e) => {
                  const date = moment(e.target.value)
                  this.setState({ from_date: date }, () => {
                    this.onDateChanged(date, this.state.to_date)
                  })
                }}
              />
            </Col>
            <Col>
              <FormInput
                label={t(`global.to`)}
                type="date"
                required
                value={moment(this.state.to_date).format("YYYY-MM-DD")}
                onChange={(e) => {
                  const date = moment(e.target.value)
                  this.setState({ to_date: date }, () => {
                    this.onDateChanged(this.state.from_date, date)
                  })
                }}
              />
            </Col>
          </Row>



        </div>
        <div style={{ backgroundColor: '', height: '800px', overflowY: 'auto', boxShadow: "none" }} >


          {this.state.date_strings.map((date_string) =>
            <div>
              <h3>{moment(date_string).format("DD.MM.yyyy")}</h3>
              {
                this.state.reservations_by_date_strings[date_string].map((reservation) =>
                  <BookingItem
                    key={reservation.booking_id}
                    booking={reservation}
                    reload={this.loadReservations}
                  />
                )
              }
              <div style={{ overflow: "hidden", width: "100%", height: 1, backgroundColor: "gray" }}></div>
            </div>

          )
          }
        </div>
      </div>


    );
  }
}

export default withTranslation()(MaintainReservations)