import React from "react";
import { withTranslation } from 'react-i18next';
import COLORS from '../constants/colors';

import { Container, FormControl, Form, Button, Alert, Row, Col } from "react-bootstrap";

import DataGrid, { SelectColumn, TextEditor, SelectCellFormatter, SortableHeaderCell } from 'react-data-grid';
import { getMyContacts, updateContact, getMyContactTypes } from "../actions/contacts";
import { getMyAccountData } from '../actions/account';

import globalStyles from '../stylesheets/globalStyles'
import AddNewButton from '../components/AddNewButton';

import EditContactModal from '../components/EditContactModal';

import { CSVLink, CSVDownload } from "react-csv";
import FormInput from "../components/FormInput";
import { deepSearchString } from "../utils/utilities";


const filterColumnClassName = 'filter-cell';
const i18nPrefix = "ContactTable";



const dropdown_style = {
  appearance: "none",
  boxSizing: "border-box",
  width: "100%",
  height: "100%",
  padding: "0px 6px 0 6px",
  border: "2px solid #ccc",
  verticalAlign: "top",
  fontFamily: "inherit",

}


function rowKeyGetter(row) {
  return row.id;
}

class ContactTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      error: false,
      contacts: [],
      account: {},
      sortedContacts: [],
      contact_types: [],
      filters: {},
      columnSortings: [],
      selectedRows: new Set([]),
      selectedContacts: [],
      editable: false,
      selecting: false, // Show table only to select contact

    };

    this.csvLink = React.createRef();

  }


  componentDidMount() {
    getMyContactTypes((contact_types) => { this.setState({ contact_types }) }, () => { })
    getMyAccountData(account => this.setState({ account: account }), () => { })

    this.reloadContacts();
    // Load params
    if (this.props.location.state) {
      this.setState({ selecting: this.props.location.state.selecting }, () => {
        this.setFilters(this.state.filters)
      });
    }
  }

  reloadContacts = () => {
    getMyContacts(
      (contacts) => {
        console.log("reloadContacts", this.state.filters, this.state.columnSortings)
        const filtered = this.getFilteredRows(contacts, this.state.filters);
        const sorted_filtered = this.getSortedRows(filtered, this.state.columnSortings);
        this.setState({ contacts: contacts, sortedContacts: sorted_filtered });
      },
      (err) => {
        this.setState({ loading: false, success: false, error: "something went wrong" });

      }
    );

  }


  onRowsChange = (newRows, changedRow) => {

    const contact = newRows[changedRow.indexes[0]]
    this.setState({ sortedContacts: newRows })

    updateContact(contact, (response) => { }, () => { console.log("error") })
  }

  onRowClick = (contact, arg2) => {
    if ((arg2.key == "lastname") && !this.state.editable) {
      this.EditContactModal.show(contact)
    }

  }

  onRowDoubleClick = (contact, arg2) => {
    if (this.state.selecting) {
      this.props.history.push(this.props.location.state.lastScreen, { contact: contact, prevState: this.props.location.state.prevState })
    }
  }


  onSortColumnsChange = (columnSortings) => {
    console.log("OnSortColumnsChange", columnSortings, this.state.filters)

    this.setState({ columnSortings: columnSortings })
    const filtered = this.getFilteredRows(this.state.contacts, this.state.filters)
    const sorted_filtered = this.getSortedRows(filtered, columnSortings)

    this.setState({ sortedContacts: sorted_filtered })


    //this.setState({ sortColumns: [], sortedContacts: this.state.contacts })

  }

  getSortedRows = (contacts, columnSortings = this.state.columnSortings) => {

    var sortedContacts = [...contacts]
    if (columnSortings && columnSortings[0]) {
      const columnKey = columnSortings[0].columnKey
      const direction = columnSortings[0].direction
      sortedContacts.sort(function (a, b) {
        switch (columnKey) {
          default:
            var comparison = a[columnKey] > b[columnKey] ? 1 : -1;
            return direction === 'ASC' ? comparison : -comparison;
        }
      });
    }
    return sortedContacts;
  }

  getFilteredRows = (contacts, filters = this.state.filters) => {
    var filtered_contacts = [...contacts]
    if (filters.global) {
      filtered_contacts = deepSearchString(filtered_contacts, filters.global)
    }
    return filtered_contacts;
  }


  onSelectedRowsChange = (arg1, arg2) => {

    var selectedContacts = []
    var selected_contact_ids = Array.from(arg1);
    for (var i = 0; i < selected_contact_ids.length; i++) {
      const contact = this.state.contacts.find(x => x.id === selected_contact_ids[i]);
      const selectedContact = {
        salutation: contact.salutation,
        firstname: contact.firstname,
        lastname: contact.lastname,
        email: contact.email,
        mobile: contact.mobile,
        phone_p: contact.phone_p,
        phone_b: contact.phone_b,
        communication_language: contact.communication_language,
        street: contact.street,
        zip: contact.zip,
        city: contact.city,
        country: contact.country,
        contact_type: contact.type,
        access_badge: contact.access_badge,
        birthday: contact.birthday,
        vat: contact.vat,
        remarks: contact.remarks,
        token: contact.token,
        customer_number: contact.customer_number,
        id: contact.id,
      }
      selectedContacts.push(selectedContact);
    }

    this.setState({ selectedRows: arg1, selectedContacts: selectedContacts })
  }




  setFilters = (filters) => {
    const filtered = this.getFilteredRows(this.state.contacts, filters)
    const filtered_sorted = this.getSortedRows(filtered, this.state.columnSortings)
    this.setState({ sortedContacts: filtered_sorted, filters: filters }, () => {
      this.searchInput.focus();

    })
  }


  inputStopPropagation = (event) => {
    if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
      event.stopPropagation();
    }
  }

  exportToCSVClicked = () => {
    this.csvLink.link.click();
  }

  showContactModal = (contact) => {
    this.EditContactModal.show(contact)
  }

  render() {
    const { t } = this.props

    const filters = this.state.filters
    const editable = this.state.editable;
    const role = localStorage.getItem("role");
    const management = role == "management";

    const columns = [
      SelectColumn,
      // { key: 'id', name: 'ID', frozen: true, },
      { key: 'firstname', name: t(`${i18nPrefix}.firstname`), width: 120, editor: TextEditor, frozen: true, editable: editable, },
      {
        key: 'lastname', name: t(`${i18nPrefix}.lastname`), width: 120, editor: TextEditor, frozen: true, editable: editable,
        formatter(props) {
          const value = props.row.lastname;
          return (
            <>
              <a onClick={(e) => { e.preventDefault() }} href={"google.com"}> {props.row.lastname} </a>
            </>
          );
        },
      },
      { key: 'email', name: t(`${i18nPrefix}.email`), width: 200, editor: TextEditor, editable: editable, },
      { key: 'mobile', name: t(`${i18nPrefix}.mobile`), width: 180, editor: TextEditor, editable: editable, },
      { key: 'communication_language', name: t(`${i18nPrefix}.communication_language`), width: 50, editor: TextEditor, editable: editable, },
      { key: 'street', name: t(`${i18nPrefix}.street`), width: 120, editor: TextEditor, editable: editable, },
      { key: 'zip', name: t(`${i18nPrefix}.zip`), width: 50, editor: TextEditor, editable: editable, },
      { key: 'city', name: t(`${i18nPrefix}.city`), width: 120, editor: TextEditor, editable: editable, },
      {
        key: 'type', name: t(`${i18nPrefix}.type`), width: 150,
        editor: (p) => (
          <select
            autoFocus
            style={dropdown_style}
            value={p.row.contact_type}
            onChange={(e) => p.onRowChange({ ...p.row, contact_type: e.target.value }, true)}
          >
            {this.state.contact_types.map((contact_type) => (
              <option key={contact_type.type}>{contact_type.type}</option>
            ))}
          </select>
        ),
      },
      { key: 'birthday', name: t(`${i18nPrefix}.birthday`), width: 150, editor: TextEditor, },
      { key: 'access_badge', name: t(`${i18nPrefix}.access_badge`), width: 150, editor: TextEditor, },
      { key: 'remarks', name: t(`${i18nPrefix}.remarks`), width: 150, editor: TextEditor, },
      { key: 'token', name: t(`${i18nPrefix}.token`), width: 150, editor: TextEditor, },
      { key: 'customer_number', name: t(`${i18nPrefix}.customer_number`), width: 150, editor: TextEditor, },
      { key: 'id', name: t(`${i18nPrefix}.id`), width: 150, editor: TextEditor, },
    ];

    if (this.state.selecting) columns.shift(); //Remove selectColumn on case of selecting



    return (
      <Container style={{ maxHeight: "none" }} >

        <EditContactModal
          onRef={(ref) => this.EditContactModal = ref}
          onClose={() => { }}
          onSave={() => { this.reloadContacts() }}
        />

        <Row>
          <Col sm={9}>
            <h1>{"Kontakte"}</h1>
            <h2>{this.state.selecting && "Auswählen"}</h2>
          </Col>




          {!this.state.selecting &&
            <>
              <Col sm={3}>

                <Button
                  style={{ ...globalStyles.saveButton, marginLeft: 10 }}
                  disabled={this.state.loading}
                  onClick={() => this.exportToCSVClicked()} >
                  {t(`global.export`)}
                </Button>

                <Button
                  style={this.state.editable ? globalStyles.redButton : globalStyles.saveButton}
                  disabled={this.state.loading}
                  onClick={() => this.setState({ editable: !this.state.editable })} >
                  {this.state.editable ? t(`${i18nPrefix}.editOn`) : t(`${i18nPrefix}.editOff`)}
                </Button>

              </Col>
            </>
          }

        </Row>


        <CSVLink
          separator={this.state.account.csv_separator}
          style={{ visibility: "hidden" }}
          ref={(ref) => this.csvLink = ref}
          filename="BP-contacts.csv"
          data={this.state.selectedContacts}>Download me
        </CSVLink>


        <Row>
          <Col md={9}>
            <input
              style={{ marginTop: 0, outline: 'none', marginBottom: 10, width: '100%', fintSize: 20, border: "none", borderRadius: 0, paddingLeft: 0, backgroundColor: "white", color: COLORS.MAIN_BLUE, boxShadow: 'none' }}
              autoFocus={true}
              ref={(input) => { this.searchInput = input; }}
              placeholder={t(`global.search`)}
              value={filters.global}
              onChange={(e) =>
                this.setFilters({
                  ...filters,
                  global: e.target.value
                })
              }
            />
            <div style={{ width: "100%", height: 1, backgroundColor: COLORS.GRAY_OUTLINE }}></div>
          </Col>

          <Col md={3}>
            <AddNewButton
              style={{ float: 'right' }}
              onClick={() => this.EditContactModal.show()}
              label={t(`${i18nPrefix}.addNewContact`)}
            />

          </Col>
        </Row>
        <div style={{ marginTop: 10 }}></div>




        <DataGrid
          style={{ maxHeight: "none", height: 700 }}
          columns={columns}
          rows={this.state.sortedContacts}
          className="rdg-light"
          onRowClick={this.onRowClick}
          onRowsChange={this.onRowsChange}
          rowKeyGetter={rowKeyGetter}
          defaultColumnOptions={{
            sortable: true,
            resizable: true
          }}
          onSortColumnsChange={this.onSortColumnsChange}
          sortColumns={this.state.columnSortings}
          selectedRows={this.state.selectedRows}
          onSelectedRowsChange={this.onSelectedRowsChange}
          onRowDoubleClick={this.onRowDoubleClick}

        >
          <SortableHeaderCell />
        </DataGrid>

        <EditContactModal
          onRef={(ref) => this.EditContactModal = ref}
          onSave={this.reloadContacts}
        />
      </Container >
    );
  }
}


export default withTranslation()(ContactTable);