import React, { useEffect, useState, } from "react";
import moment from "moment";
import "../i18n"

import {
  Button,
  Modal,
  Spinner,
  Row,
  Col,
} from "react-bootstrap";

import DatePicker from "react-date-picker";
import { withTranslation } from 'react-i18next';

import { addBooking } from '../actions/bookings'
import { getMyMooring } from '../actions/moorings'

import FormInput from './FormInput'
import StatusAlerts from "./StatusAlerts";
import CancelButton from './CancelButton';

import globalStyles from '../stylesheets/globalStyles';
import FormCheckbox from '../components/FormCheckbox'
import { getTextAreaHeight } from '../utils/utilities'

const i18nPrefix = 'AddBookingModal';

class AddBookingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      create: false, //Are we creating or editing a boat
      loading: false,
      success: false,
      error: false,
      from_date: moment(),
      to_date: moment(),
      notes: "",
      internal_paid: false,
    };
  }



  componentDidMount() {
    this.props.onRef && this.props.onRef(this)
  }

  show = (mooring_id, from_date, to_date) => {
    console.log("from, to", from_date, to_date)

    if (to_date == null) {
      to_date = moment(from_date).add(1, "day");
    }

    if (to_date < from_date) { // Switch if selected backwards
      var tmp = from_date;
      from_date = to_date;
      to_date = tmp;
    }

    if (from_date) {
      this.setState({
        show: true,
        mooring_id: mooring_id,
        from_date: from_date,
        to_date: to_date,
        notes: "",
      });
    }
    getMyMooring(mooring_id, (mooring) => { this.setState({ mooring: mooring }) }, () => { });
  }

  handleShow = () => {
    this.setState({ show: true })
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
    this.setState({ show: false })
  }


  handleBookClick = () => {

    this.setState({ success: false, loading: true, error: false }, () => {

      const booking = {
        mooring: false,
        mooring_id: this.state.mooring_id,
        from_date: this.state.from_date,
        to_date: this.state.to_date,
        notes: this.state.notes,
        internal_paid: this.state.internal_paid,
        numberOfAdults: "0",
        numberOfChildren: "0",
        payment_method: "internal",
      }
      addBooking(booking, () => {
        this.setState({ loading: false, success: true }, () => {
          this.props.onBookingSuccessful && this.props.onBookingSuccessful();
          this.handleClose();
        })
      }, () => { })

    })

  }


  render() {

    const { t } = this.props

    const element = document.getElementById('FormInputNotes')
    if (element) {
      var s_height = element.scrollHeight;
      element.setAttribute('style', 'height:' + s_height + 'px');

    }

    return (

      <Modal id="modal_add_booking" show={this.state.show} onHide={this.handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>{t(`${i18nPrefix}.title`)}</Modal.Title>
        </Modal.Header>


        <Modal.Body>

          {this.state.mooring &&
            <FormInput
              id='input_reservation_name'
              label={t(`${i18nPrefix}.berth_number`)}
              placeholder={t(`${i18nPrefix}.notesPlaceholder`)}
              value={`${this.state.mooring.harbour_name} ${this.state.mooring.berth_number}`}
            // onChange={(event) => { this.setState({ notes: event.target.value }) }}
            />
          }

          <Row>
            <Col>
              <FormInput
                id={"input_from_date"}
                label={t(`global.from`)}
                type="date"
                required
                value={moment(this.state.from_date).format("YYYY-MM-DD")}
                onChange={(e) => {
                  const date = moment(e.target.value)
                  this.setState({ from_date: date })
                }}
              />
            </Col>
            <Col>
              <FormInput
                id={"input_to_date"}
                label={t(`global.to`)}
                type="date"
                required
                value={moment(this.state.to_date).format("YYYY-MM-DD")}
                onChange={(e) => {
                  const date = moment(e.target.value)
                  this.setState({ to_date: date })
                }}
              />
            </Col>
          </Row>

          {/* internal_paid */}
          <FormCheckbox
            id={"checkbox_internal_paid"}
            label={t(`${i18nPrefix}.internal_paid`)}
            checked={this.state.internal_paid}
            onChange={(e) => {
              this.setState({
                internal_paid: e.target.checked,
              })
            }} />

          <FormInput
            id={"input_notes"}
            as="textarea"
            label={t(`${i18nPrefix}.notes`)}
            placeholder={t(`${i18nPrefix}.notesPlaceholder`)}
            value={this.state.notes}
            rows={getTextAreaHeight(this.state.notes)}
            onChange={(event) => { this.setState({ notes: event.target.value }) }}
            last
          />



          <Button
            id={"button_book"}
            style={{ ...globalStyles.normalButton, width: '100%' }}
            onClick={() => this.handleBookClick()}>
            {t(`${i18nPrefix}.bookMooring`)}
            {this.state.loading && <Spinner style={{ width: 20, height: 20, marginLeft: 10 }} animation="border" variant="secondary" role="status" />}

          </Button>

        </Modal.Body>
      </Modal >

    );

  }
}


export default withTranslation()(AddBookingModal)



