import React, { useEffect, useState, } from "react";
import moment from "moment";
import "../i18n"
import {
    Row,
    Button,
    Col,
    Modal,
} from "react-bootstrap";
import FormInput from './FormInput'

import { withTranslation } from 'react-i18next';
import { validateRegisterform } from '../utils/validators';

import { createContact, updateContact, deleteContact, getMyContactTypes, getContact } from '../actions/contacts'
import { getBoatsByContactId } from '../actions/boats'
import { getMooringsByContactId } from '../actions/moorings'
import { getWaitingContactsByContactId } from '../actions/waiting_contacts'
import { validateCreateContactForm } from '../utils/validators';

import StatusAlerts from "./StatusAlerts";

import globalStyles from '../stylesheets/globalStyles';
import AddNewButton from './AddNewButton';

import EditBoatModal from "../components/EditBoatModal";
import CountrySelector from "../components/CountrySelector";
import EditWaitingContactModal from "../components/EditWaitingContactModal";

import COLORS from '../constants/colors';

const i18nPrefix = 'EditContactModal';

const emptyContact = {
    rights: {
        read: false,
        edit: false,
        delete: false,

    }
};

class EditContactModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            create: false, //Are we creating or editing a contact
            loading: false,
            success: false,
            error: false,
            contact: emptyContact,
            contact_types: [],
            boats: [],
            moorings: [],
            waiting_contacts: [],
        };

        this.EditWaitingContactModal = React.createRef()

    }



    componentDidMount() {
        this.props.onRef && this.props.onRef(this)


        getMyContactTypes(
            (contact_types) => { this.setState({ contact_types: contact_types }) },
            (error) => { })

        // Load from props
        if (this.props.contact) {
            this.setState({ contact: this.props.contact, create: false })
        }
        else {
            this.setState({
                create: true,
                boats: [],
                moorings: [],
                waiting_contacts: [],
            })
        }
    }

    loadBoats = (contact = this.state.contact) => {
        getContact(contact.id, (contact) => this.setState({ contact: contact }, () => { }))
        getBoatsByContactId(contact.id, (boats) => { this.setState({ boats: boats }); }, () => { });
        getMooringsByContactId(contact.id, (moorings) => {
            moorings.sort((a, b) => moment(a.assigned_contact.to_date) < moment(b.assigned_contact.to_date) ? 1 : -1)
            this.setState({ moorings: moorings });
        }, () => { })
        getWaitingContactsByContactId(contact.id, (waiting_contacts) => { this.setState({ waiting_contacts: waiting_contacts }); }, () => { })
    }

    show = (contact) => {
        if (contact) {
            this.setState({ contact: contact, show: true, create: false })
            this.loadBoats(contact)
        }
        else {
            this.setState({ contact: {}, show: true, create: true })

        }
    }
    handleShow = () => {
        this.setState({ show: true })
    }

    handleClose = () => {
        this.props.onClose && this.props.onClose();
        this.setState({ show: false, contact: emptyContact, edit: false, success: false, error: false })
    }

    handleSave = () => {
        this.props.onSave && this.props.onSave();
        this.handleClose();
    }

    hide = () => {
        this.setState({ show: false })
    }
    unhide = () => {
        this.setState({ show: true })
    }


    createContactClicked = () => {

        const formErrors = validateCreateContactForm(this.state.contact);
        const noErrors = Object.values(formErrors).every(x => (x == false));
        if (!noErrors) {
            this.setState({ error: true, errorMessage: this.props.t('errors.someValuesAreNotCorrect') });
            return;
        }
        else { //no form errors
            this.setState({ loading: true, success: false, error: false }, () => {
                createContact(this.state.contact,
                    () => {
                        this.setState({ loading: false, success: true, error: false });
                        this.handleSave();
                    },
                    (error) => {
                        const errorMessage = this.props.t(`errors.${error.response.data.language_variable}`);
                        this.setState({ loading: false, success: false, error: true, errorMessage: errorMessage, })
                    })
            });
        }
    }


    editContactClicked = () => {

        const formErrors = validateCreateContactForm(this.state.contact);
        const noErrors = Object.values(formErrors).every(x => (x == false));
        if (!noErrors) {
            this.setState({ error: true, errorMessage: this.props.t('errors.someValuesAreNotCorrect') });
            return;
        }


        else { //no form errors
            this.setState({ loading: true, success: false, error: false }, () => {
                updateContact(this.state.contact,
                    () => {
                        this.setState({ loading: false, success: true, error: false });
                        this.handleSave();
                    },
                    (error) => {
                        const errorMessage = this.props.t(`errors.${error.response.data.language_variable}`);
                        this.setState({ loading: false, success: false, error: true, errorMessage: errorMessage, })
                    })
            });
        }
    }


    deleteContactClicked = () => {
        window.confirm(this.props.t(`${i18nPrefix}.areYouSureDeleteContact`)) &&
            deleteContact(this.state.contact.id,
                () => {
                    this.setState({ loading: false, success: true, error: false })
                    this.handleSave();
                },
                (err) => { })
    }



    render() {

        const { t } = this.props
        const languages = t(`global.communicationLanguages`)

        const formErrors = validateCreateContactForm(this.state.contact);

        const role = localStorage.getItem("role");
        const management = role == "management";

        return (

            <>
                <EditBoatModal
                    onRef={(ref) => { this.EditBoatModal = ref }}
                    onClose={() => { this.loadBoats(); this.unhide() }}
                    contact_id={this.state.contact.id}
                />
                <EditWaitingContactModal
                    onRef={ref => this.EditWaitingContactModal = ref}
                // onClose={this.loadBoats()}
                />


                <Modal size="lg" show={this.state.show} onHide={this.handleClose} animation={true}>

                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.create ? t(`${i18nPrefix}.createContact`) : t(`${i18nPrefix}.editContact`)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >

                        <StatusAlerts
                            state={this.state}
                            errorMessage={this.state.errorMessage}
                        />


                        <Row >
                            <Col md={6} >
                                <FormInput
                                    type="text"
                                    label={t(`${i18nPrefix}.salutation`)}
                                    value={this.state.contact.salutation}
                                    error={formErrors.salutation}
                                    onChange={(event) => {
                                        this.setState({
                                            contact: { ...this.state.contact, salutation: event.target.value },
                                        });
                                    }}
                                />
                            </Col>
                            <Col md={6} >
                            </Col>
                        </Row >


                        <Row >

                            <Col md={6} >
                                <FormInput
                                    type="text"
                                    label={t(`${i18nPrefix}.firstName`)}
                                    value={this.state.contact.firstname}
                                    error={formErrors.firstname}
                                    onChange={(event) => {
                                        this.setState({
                                            contact: { ...this.state.contact, firstname: event.target.value },
                                        });
                                    }}
                                />
                            </Col>
                            <Col md={6} >
                                <FormInput
                                    type="text"
                                    label={t(`${i18nPrefix}.lastName`)}
                                    value={this.state.contact.lastname}
                                    error={formErrors.lastname}
                                    onChange={(event) => {
                                        this.setState({
                                            contact: { ...this.state.contact, lastname: event.target.value },
                                        });
                                    }}
                                />
                            </Col>
                        </Row >
                        <Row >
                            <Col md={6} >
                                <FormInput as="select"
                                    label={t(`${i18nPrefix}.communicationLanguage`)}
                                    value={this.state.contact.communication_language}
                                    error={formErrors.communication_language}
                                    onChange={(event) => {
                                        this.setState({
                                            contact: { ...this.state.contact, communication_language: event.target.value },
                                        });
                                    }}
                                >
                                    < option value={null}></option>

                                    {
                                        languages.map((language, index) =>
                                            < option key={index} value={language}>{language}</option>
                                        )
                                    }

                                </FormInput>
                            </Col>
                            <Col md={6} >

                                <FormInput as="select"
                                    label={t(`${i18nPrefix}.type`)}
                                    value={this.state.contact.type}
                                    error={formErrors.type}

                                    onChange={(event) => {
                                        this.setState({
                                            contact: { ...this.state.contact, type: event.target.value },
                                        });
                                    }}
                                >
                                    < option value={null}></option>

                                    {
                                        this.state.contact_types.map((contact_type) =>
                                            < option key={contact_type.id} value={contact_type.type}>{contact_type.type}</option>
                                        )
                                    }

                                </FormInput>
                            </Col>
                        </Row>


                        <Row >
                            <Col md={6} >
                                <FormInput
                                    type="text"
                                    label={t(`${i18nPrefix}.email`)}
                                    error={formErrors.email}
                                    value={this.state.contact.email}
                                    onChange={(event) => {
                                        this.setState({
                                            contact: { ...this.state.contact, email: event.target.value },
                                        });
                                    }}
                                />
                            </Col>
                            <Col md={6} >
                                <FormInput
                                    type="tel"
                                    label={t(`${i18nPrefix}.mobile`)}
                                    value={this.state.contact.mobile}
                                    error={formErrors.mobile}
                                    onChange={(event) => {
                                        this.setState({
                                            contact: { ...this.state.contact, mobile: event.target.value },
                                        });
                                    }}
                                />
                            </Col>
                        </Row >


                        <Row >
                            <Col md={6} >
                                <FormInput
                                    type="text"
                                    label={t(`${i18nPrefix}.street`)}
                                    value={this.state.contact.street}
                                    error={formErrors.street}
                                    onChange={(event) => {
                                        this.setState({
                                            contact: {
                                                ...this.state.contact,
                                                street: event.target.value
                                            },
                                        });
                                    }}
                                />
                            </Col>
                            <Col md={6} >
                            </Col>
                        </Row >


                        <Row >
                            <Col md={6} >
                                <FormInput
                                    type="text"
                                    label={t(`${i18nPrefix}.zip`)}
                                    value={this.state.contact.zip}
                                    error={formErrors.zip}
                                    onChange={(event) => {
                                        this.setState({
                                            contact: {
                                                ...this.state.contact,
                                                zip: event.target.value
                                            },
                                        });
                                    }}
                                />

                            </Col>
                            <Col md={6} >

                                <FormInput
                                    type="text"
                                    label={t(`${i18nPrefix}.city`)}
                                    value={this.state.contact.city}
                                    error={formErrors.city}
                                    onChange={(event) => {
                                        this.setState({
                                            contact: {
                                                ...this.state.contact,
                                                city: event.target.value
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row >


                        <Row>
                            <Col md={6} >
                                <CountrySelector
                                    label={"Country"}
                                    value={this.state.contact.country}
                                    onChange={(value) => {
                                        console.log(value);
                                        this.setState({
                                            contact: { ...this.state.contact, country: value },
                                        });
                                    }}
                                />
                            </Col>
                            <Col md={6} ></Col>
                        </Row>






                        <Row >
                            <Col md={6} >

                                <FormInput
                                    type="text"
                                    label={t(`${i18nPrefix}.phone_p`)}
                                    value={this.state.contact.phone_p}
                                    error={formErrors.phone_p}
                                    onChange={(event) => {
                                        this.setState({
                                            contact: {
                                                ...this.state.contact,
                                                phone_p: event.target.value
                                            },
                                        });
                                    }}
                                />
                            </Col>
                            <Col md={6} >
                                <FormInput
                                    type="text"
                                    label={t(`${i18nPrefix}.phone_b`)}
                                    value={this.state.contact.phone_b}
                                    error={formErrors.phone_b}
                                    onChange={(event) => {
                                        this.setState({
                                            contact: {
                                                ...this.state.contact,
                                                phone_b: event.target.value
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row >






                        <Row >
                            <Col md={6} >

                                <FormInput
                                    type="date"
                                    label={t(`${i18nPrefix}.birthday`)}
                                    value={this.state.contact.birthday}
                                    error={formErrors.birthday}
                                    onChange={(event) => {
                                        this.setState({
                                            contact: {
                                                ...this.state.contact,
                                                birthday: event.target.value
                                            },
                                        });
                                    }}
                                />
                            </Col>
                            <Col md={6} >

                            </Col>
                        </Row >






                        <Row >
                            <Col md={6} >
                                <FormInput
                                    type="text"
                                    label={t(`${i18nPrefix}.alternative_email`)}
                                    value={this.state.contact.alternative_email}
                                    error={formErrors.alternative_email}
                                    onChange={(event) => {
                                        this.setState({
                                            contact: {
                                                ...this.state.contact,
                                                alternative_email: event.target.value
                                            },
                                        });
                                    }}
                                />
                            </Col>
                            <Col md={6} >
                                <FormInput
                                    type="text"
                                    label={t(`${i18nPrefix}.alternative_mobile`)}
                                    value={this.state.contact.alternative_mobile}
                                    error={formErrors.alternative_mobile}
                                    onChange={(event) => {
                                        this.setState({
                                            contact: {
                                                ...this.state.contact,
                                                alternative_mobile: event.target.value
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row >


                        <Row >
                            <Col md={6} >
                                <FormInput
                                    type="text"
                                    label={t(`${i18nPrefix}.access_badge`)}
                                    value={this.state.contact.access_badge}
                                    error={formErrors.access_badge}
                                    onChange={(event) => {
                                        this.setState({
                                            contact: {
                                                ...this.state.contact,
                                                access_badge: event.target.value
                                            },
                                        });
                                    }}
                                />
                            </Col>
                            <Col md={6} >
                                <FormInput
                                    type="text"
                                    label={t(`${i18nPrefix}.vat`)}
                                    value={this.state.contact.vat}
                                    error={formErrors.vat}
                                    onChange={(event) => {
                                        this.setState({
                                            contact: {
                                                ...this.state.contact,
                                                vat: event.target.value
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row >

                        <Row >
                            <Col md={6} >

                                <FormInput as="textarea"
                                    type="text"
                                    label={t(`${i18nPrefix}.remarks`)}
                                    value={this.state.contact.remarks}
                                    error={formErrors.remarks}
                                    onChange={(event) => {
                                        this.setState({
                                            contact: {
                                                ...this.state.contact,
                                                remarks: event.target.value
                                            },
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row >
                            <Col md={6} >
                                <FormInput
                                    type="text"
                                    label={t(`${i18nPrefix}.boatparkAccount`)}
                                    value={this.state.contact.account_email}
                                    // error={formErrors.remarks}
                                    onChange={(event) => {
                                        this.setState({
                                            contact: {
                                                ...this.state.contact,
                                                account_email: event.target.value
                                            },
                                        });
                                    }}
                                />

                            </Col>
                            <Col md={6} >
                                <FormInput
                                    type="text"
                                    label={t(`${i18nPrefix}.customer_number`)}
                                    value={this.state.contact.customer_number}
                                    // error={formErrors.remarks}
                                    onChange={(event) => {
                                        this.setState({
                                            contact: {
                                                ...this.state.contact,
                                                customer_number: event.target.value
                                            },
                                        });
                                    }}
                                />

                            </Col>
                        </Row>
                        <Row >

                            <Col md={6} >
                                <FormInput
                                    type="text"
                                    label={t(`${i18nPrefix}.id`)}
                                    value={this.state.contact.id}
                                    disabled
                                />
                            </Col>
                            <Col md={6} >
                                <FormInput
                                    type="text"
                                    label={t(`${i18nPrefix}.token`)}
                                    value={this.state.contact.token}
                                    disabled
                                />
                            </Col>

                        </Row>

                        <h3>{t(`${i18nPrefix}.boats`)}</h3>

                        {!this.state.create && this.state.boats.map((boat) => {
                            return <div key={boat.id}>
                                <div
                                    style={{ marginBottom: 15, borderRadius: 5, padding: 10, fontWeight: "bold", fontSize: 20, borderColor: "#bbb", borderStyle: "solid", borderWidth: 2 }}>

                                    <Row>
                                        <Col>
                                            {`${boat.registration_number}${boat.registration_number && boat.name ? " / " : ""}${boat.name}`}
                                        </Col>
                                        <Col>
                                            {boat.make}
                                        </Col>
                                        <Col>
                                            {`${boat.length?.toFixed(2)} / ${boat.beam?.toFixed(2)}`}

                                        </Col>

                                        <Col>
                                            <Button
                                                style={{ ...globalStyles.normalButton, float: "right" }}
                                                onClick={() => { this.EditBoatModal.show(boat); }}
                                            >{
                                                    management ? t(`${i18nPrefix}.edit`) : t(`${i18nPrefix}.show`)}
                                            </Button>


                                        </Col>
                                    </Row>

                                </div>


                            </div>
                        })}

                        {!this.state.create && management &&
                            <AddNewButton
                                center
                                onClick={() => { this.EditBoatModal.show(); }}
                                label={t(`${i18nPrefix}.addNewBoat`)}
                            />

                        }


                        <h3>{t(`${i18nPrefix}.moorings`)}</h3>

                        {!this.state.create && this.state.moorings.map((mooring) => {
                            const active = moment(mooring.assigned_contact.from_date) < moment() && moment(mooring.assigned_contact.to_date) > moment()
                            const bgColor = active ? COLORS.WHITE_ACTIVE : COLORS.GRAY_INACTIVE;
                            return <div key={mooring.mooring_id} >
                                <div
                                    style={{ marginBottom: 15, borderRadius: 5, padding: 10, fontWeight: "bold", fontSize: 20, borderColor: "#bbb", borderStyle: "solid", borderWidth: 2, backgroundColor: `${bgColor}` }}>
                                    <div >

                                        <Row>
                                            <Col>
                                                <a style={{ textDecoration: "none", color: "black" }} href={`/home/mooringdetails/${mooring.mooring_id}`} target="_blank" >   {mooring.berth_number} </a>
                                            </Col>
                                            <Col>
                                                {mooring.mooring_zone}

                                            </Col>
                                            <Col>
                                                {mooring.mooring_type}
                                            </Col>
                                            <Col>
                                                {`${parseFloat(mooring.max_length).toFixed(2)} / ${parseFloat(mooring.max_beam).toFixed(2)} / ${parseFloat(mooring.max_draught).toFixed(2)}`}
                                            </Col>
                                        </Row>
                                    </div>


                                </div>


                            </div>
                        })}

                        <h3>{t(`${i18nPrefix}.watiting_contacts`)}</h3>
                        {!this.state.create && this.state.waiting_contacts.map((waiting_contact) => {
                            return <div key={waiting_contact.getMooringsByContactId}>
                                <div
                                    style={{ marginBottom: 15, borderRadius: 5, padding: 10, fontWeight: "bold", fontSize: 20, borderColor: "#bbb", borderStyle: "solid", borderWidth: 2 }}>

                                    <Row>
                                        <Col>
                                            {waiting_contact.entry_date}
                                        </Col>
                                        <Col>
                                            {waiting_contact.mooring_zone}

                                        </Col>
                                        <Col>
                                            {waiting_contact.mooring_type}
                                        </Col>


                                        <Col>
                                            <Button
                                                style={{ ...globalStyles.normalButton, float: "right" }}
                                                onClick={() => this.EditWaitingContactModal.show(waiting_contact)}
                                            >{
                                                    management ? t(`${i18nPrefix}.edit`) : t(`${i18nPrefix}.show`)}
                                            </Button>


                                        </Col>


                                    </Row>

                                </div>


                            </div>
                        })}

                        <div style={{ width: '100%', height: 30 }}></div>







                    </Modal.Body>
                    <Modal.Footer>

                        {!this.state.create && this.state.contact.rights.delete &&
                            <Button style={globalStyles.deleteButton} disabled={this.state.loading} onClick={() => this.deleteContactClicked()}>
                                {t(`${i18nPrefix}.deleteContact`)}
                            </Button>
                        }
                        {this.state.create &&
                            <Button style={globalStyles.saveButton} disabled={this.state.loading} onClick={() => this.createContactClicked()}>
                                {t(`${i18nPrefix}.createContact`)}
                            </Button>
                        }
                        {!this.state.create && this.state.contact.rights.edit &&
                            <Button style={globalStyles.saveButton} disabled={this.state.loading} onClick={() => this.editContactClicked()}>
                                {t(`global.save`)}
                            </Button>
                        }
                    </Modal.Footer>


                </Modal >

            </>

        );

    }
}


export default withTranslation()(EditContactModal)



