import React, { useEffect, useState, } from "react";
import moment from "moment";
import "../i18n"

import {
    Container,
    Form,
    Row,
    Button,
    Col,
    Alert,
    FormControl,
    Modal,
} from "react-bootstrap";
import FormInput from './FormInput'

import { withTranslation } from 'react-i18next';
import { validateBoatForm } from '../utils/validators';

import { createBoat, updateBoat, deleteBoat } from '../actions/boats'

import StatusAlerts from "../components/StatusAlerts";

import globalStyles from '../stylesheets/globalStyles';
import AddNewButton from './AddNewButton';

import PdfModal from '../components/PdfModal';
import { getBoatDocuments, getBoatDocumentToken } from '../actions/documents'

import EditBoatDocumentModal from "./EditBoatDocumentModal";


const i18nPrefix = 'EditBoatModal';

const emptyBoat = {};

class EditBoatModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            create: false, //Are we creating or editing a boat
            loading: false,
            success: false,
            error: false,
            boat: emptyBoat,
            pdfToken: "",
            showPdfModal: false,
            boat_documents: [],
            contact_id: null,
        };
    }



    componentDidMount() {
        this.props.onRef && this.props.onRef(this)
    }

    show = (boat) => {
        if (boat) {
            this.setState({ boat: boat, show: true, create: false })
            this.loadBoatDocuments(boat)
        }
        else {
            this.setState({ boat: {}, contact_id: null, show: true, create: true })
        }
    }
    handleShow = () => {
        this.setState({ show: true })
    }

    handleClose = () => {
        this.props.onClose && this.props.onClose();
        this.setState({ show: false, boat: emptyBoat, edit: false, error: false, success: false })
    }

    loadBoatDocuments = (boat = this.state.boat) => {
        getBoatDocuments(boat.id,
            (boat_documents) => this.setState({ boat_documents: boat_documents }),
            () => { })
    }

    showBoatDocumentsPressed = () => {
        getBoatDocumentToken(this.state.boat.id,
            (token) => { this.setState({ pdfToken: token, showPdfModal: true }) },
            () => { })
    }

    createBoatClicked = () => {

        const noErrors = Object.values(validateBoatForm(this.state.boat)).every(x => (x == false));
        if (noErrors) {
            var { boat } = this.state
            boat.contact_id = this.props.contact_id;
            createBoat(this.state.boat,
                () => {
                    this.setState({ loading: false, success: true, error: false });
                    this.handleClose();
                },
                () => {
                    this.setState({ loading: false, success: false, error: true })
                })
        }
        else { //form errors
            this.setState({ error: true, errorMessage: this.props.t('errors.someValuesAreNotCorrect') });
        }

    }


    editBoatClicked = () => {
        const noErrors = Object.values(validateBoatForm(this.state.boat)).every(x => (x == false));
        if (noErrors) {
            updateBoat(this.state.boat,
                () => {
                    this.setState({ loading: false, success: true, error: false });
                    this.handleClose();
                },
                () => {
                    this.setState({ loading: false, success: false, error: true })
                })
        }
        else { //form errors
            this.setState({ error: true, errorMessage: this.props.t('errors.someValuesAreNotCorrect') });
        }

    }


    deleteBoatClicked = () => {
        window.confirm(this.props.t(`${i18nPrefix}.areYouSureDeleteBoat`)) &&
            deleteBoat(this.state.boat.id,
                () => {
                    this.setState({ loading: false, success: true, error: false })
                    this.handleClose();
                },
                () => { })
    }



    render() {

        const { t } = this.props
        const types = t(`${i18nPrefix}.boatTypes`)
        const drives = t(`${i18nPrefix}.boatDrives`)
        const formErrors = validateBoatForm(this.state.boat)

        const role = localStorage.getItem("role");
        const management = role == "management";

        return (

            <>
                <EditBoatDocumentModal
                    onRef={(ref) => { this.EditBoatDocumentModal = ref }}
                    onClose={() => { this.loadBoatDocuments() }}
                    boat_id={this.state.boat.id}
                />
                <Modal size="lg" show={this.state.show} onHide={this.handleClose} animation={true}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.create ? t(`${i18nPrefix}.createBoat`) : t(`${i18nPrefix}.editBoat`)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Row>
                            <Col md={12}>
                                <StatusAlerts
                                    state={this.state}
                                    errorMessage={this.state.errorMessage}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <FormInput
                                    type="text"
                                    label={t(`${i18nPrefix}.name`)}
                                    value={this.state.boat.name}
                                    error={formErrors.name}
                                    onChange={(event) => {
                                        this.setState({
                                            boat: { ...this.state.boat, name: event.target.value },
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row >
                            <Col>
                                <FormInput
                                    type="text"
                                    label={t(`${i18nPrefix}.make`)}
                                    value={this.state.boat.make}
                                    error={formErrors.make}
                                    onChange={(event) => {
                                        this.setState({
                                            boat: { ...this.state.boat, make: event.target.value },
                                        });
                                    }}
                                />
                            </Col>
                            <Col>
                                <FormInput
                                    type="text"
                                    label={t(`${i18nPrefix}.registration_number`)}
                                    value={this.state.boat.registration_number}
                                    error={formErrors.registration_number}
                                    onChange={(event) => {
                                        this.setState({
                                            boat: { ...this.state.boat, registration_number: event.target.value },
                                        });
                                    }}
                                />
                            </Col>
                        </Row >


                        <Row >

                            <Col>
                                <FormInput
                                    type="number"
                                    label={t(`${i18nPrefix}.length`)}
                                    unit="m"
                                    value={this.state.boat.length}
                                    error={formErrors.length}
                                    onChange={(event) => {
                                        this.setState({
                                            boat: { ...this.state.boat, length: event.target.value },
                                        });
                                    }}
                                />
                            </Col>
                            <Col>
                                <FormInput
                                    type="number"
                                    label={t(`${i18nPrefix}.beam`)}
                                    unit="m"
                                    value={this.state.boat.beam}
                                    error={formErrors.beam}
                                    onChange={(event) => {
                                        this.setState({
                                            boat: { ...this.state.boat, beam: event.target.value },
                                        });
                                    }}
                                />
                            </Col>
                        </Row >
                        <Row>
                            <Col>
                                <FormInput
                                    type="number"
                                    label={t(`${i18nPrefix}.draft`)}
                                    unit="m"
                                    value={this.state.boat.draft}
                                    error={formErrors.draft}
                                    onChange={(event) => {
                                        this.setState({
                                            boat: { ...this.state.boat, draft: event.target.value },
                                        });
                                    }}
                                />
                            </Col>
                            <Col>
                                <FormInput
                                    type="number"
                                    unit="kg"
                                    label={t(`${i18nPrefix}.weight`)}
                                    value={this.state.boat.weight}
                                    error={formErrors.weight}
                                    onChange={(event) => {
                                        this.setState({
                                            boat: { ...this.state.boat, weight: event.target.value },
                                        });
                                    }}
                                />
                            </Col>
                        </Row>



                        <Row >

                            <Col>
                                <FormInput as="select"
                                    label={t(`${i18nPrefix}.type`)}
                                    value={this.state.boat.type}
                                    error={formErrors.type}
                                    onChange={(event) => {
                                        this.setState({
                                            boat: { ...this.state.boat, type: event.target.value },
                                        });
                                    }}
                                >
                                    {
                                        types.map((type, index) =>
                                            < option key={index} value={type}>{type}</option>
                                        )
                                    }
                                </FormInput>
                            </Col>
                            <Col>
                                <FormInput as="select"
                                    label={t(`${i18nPrefix}.drive`)}
                                    value={this.state.boat.drive}
                                    error={formErrors.drive}
                                    onChange={(event) => {
                                        this.setState({
                                            boat: { ...this.state.boat, drive: event.target.value },
                                        });
                                    }}
                                >
                                    <option value={""}>{t(`global.pleaseSelect`)}</option>
                                    {
                                        drives.map((drive, index) =>
                                            < option key={index} value={drive}>{drive}</option>
                                        )
                                    }
                                </FormInput>
                            </Col>



                        </Row >

                        {this.state.boat.id &&
                            <>
                                <h3>{t(`${i18nPrefix}.documents`)}</h3>

                                {this.state.boat_documents.map((boat_document) =>
                                    <div key={boat_document.id} style={{ marginBottom: 15, borderRadius: 5, padding: 10, borderColor: "#bbb", borderStyle: "solid", borderWidth: 2, fontSize: 20, fontWeight: "bold" }}>
                                        <Row>
                                            <Col>
                                                {boat_document.document_title}
                                            </Col>
                                            <Col>
                                                <Button
                                                    style={{ ...globalStyles.normalButton, float: "right" }}
                                                    onClick={() => { this.EditBoatDocumentModal.show(boat_document) }}>
                                                    {t(`${i18nPrefix}.show`)}
                                                </Button>
                                            </Col>
                                        </Row>

                                    </div>
                                )}


                                {management &&
                                    <AddNewButton
                                        center
                                        label={t(`${i18nPrefix}.addNewDocument`)}
                                        onClick={() => { this.EditBoatDocumentModal.show() }}
                                    />
                                }



                                < PdfModal
                                    handleClose={() => { this.setState({ showPdfModal: false }) }}
                                    show={this.state.showPdfModal}
                                    pdfUrl={`${process.env.REACT_APP_API_HOST}boatdocuments/get_my_pdf?token=${this.state.pdfToken}`}
                                />
                            </>
                        }

                        <div style={{ width: '100%', height: 30 }}></div>






                    </Modal.Body>
                    <Modal.Footer>

                        {!this.state.create && management &&
                            < Button style={globalStyles.deleteButton} disabled={this.state.loading} onClick={() => this.deleteBoatClicked()}>
                                {t(`${i18nPrefix}.deleteBoat`)}
                            </Button>
                        }
                        {this.state.create && management &&
                            <Button style={globalStyles.saveButton} disabled={this.state.loading} onClick={() => this.createBoatClicked()}>
                                {t(`${i18nPrefix}.createBoat`)}
                            </Button>
                        }
                        {!this.state.create && management &&
                            <Button style={globalStyles.saveButton} disabled={this.state.loading} onClick={() => this.editBoatClicked()}>
                                {t(`global.save`)}
                            </Button>
                        }
                    </Modal.Footer>
                </Modal>

            </>

        );

    }
}


export default withTranslation()(EditBoatModal)



