import React, { useEffect, useState, } from "react";
import moment from "moment";
import "../i18n"

import {
  Button,
  Modal,
  Spinner,
  Row,
  Col,
  FormText,
} from "react-bootstrap";
import FormInput from './FormInput'

import { withTranslation } from 'react-i18next';
import { getTextAreaHeight } from '../utils/utilities'

import { createBoat, updateBoat, deleteBoat } from '../actions/boats'

import StatusAlerts from "../components/StatusAlerts";
import CancelButton from '../components/CancelButton';

import globalStyles from '../stylesheets/globalStyles';
import AddNewButton from './AddNewButton';

import PdfModal from '../components/PdfModal';
import { getBoatDocuments, getBoatDocumentToken } from '../actions/documents'

import { DATE_FORMAT } from '../constants/format';
import { getBookingDetails, updateBooking } from '../actions/bookings'
import FormCheckbox from '../components/FormCheckbox'
import FormInputWrapper from '../components/FormInputWrapper'
import DateTimeText from "./DateTimeText";


const i18nPrefix = 'BookingDetailsModal';


class BookingDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      create: false, //Are we creating or editing a boat
      loading: false,
      success: false,
      error: false,
      bookingDetails: null,
      booking: null,
      boat: null,
    };
  }



  componentDidMount() {
    this.props.onRef && this.props.onRef(this)
  }

  show = (booking_id) => {

    if (booking_id) {
      getBookingDetails(booking_id,
        (booking_details) => {
          this.setState({
            bookingDetails: booking_details,
            booking: booking_details.booking,
            boat: booking_details.boat,
            show: true
          })
        },
        () => { })
    }
  }
  handleShow = () => {
    this.setState({ show: true })
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
    this.setState({ show: false })
  }


  buttonReplaceClicked = () => {
    if (this.props.replaceClicked) {
      this.handleClose();
      this.props.replaceClicked(this.state.bookingDetails)
    }
  }

  buttonSaveClicked = () => {
    const { booking } = this.state;

    const post_object = {
      booking_id: booking.booking_id,
      notes: booking.notes,
      internal_paid: booking.internal_paid,
    }
    updateBooking(post_object, (booking) => { }, (err) => { })
    this.handleClose();

  }


  showBoatDocumentsPressed = () => {
    getBoatDocumentToken(this.state.bookingDetails.boat_id,
      (token) => { this.setState({ pdfToken: token, showPdfModal: true }) },
      () => { })
  }



  render() {

    const { t } = this.props

    const bookingDetails = this.state.bookingDetails;
    const selectedBooking = bookingDetails ? bookingDetails.booking : null;
    const rights = selectedBooking ? selectedBooking.rights : null;

    console.log("this.state.booking", bookingDetails)

    const boat = (bookingDetails && bookingDetails.boat) ? bookingDetails.boat : null;

    const extension_date = (bookingDetails && bookingDetails.booking && bookingDetails.booking.extension) ? bookingDetails.booking.extension.to_date : null
    const dateRangeText = bookingDetails && (extension_date ? `${moment(bookingDetails.from_date).format(DATE_FORMAT)} - ${moment(bookingDetails.to_date).format(DATE_FORMAT)} (${moment(extension_date).format(DATE_FORMAT)})` : `${moment(bookingDetails.from_date).format(DATE_FORMAT)} - ${moment(bookingDetails.to_date).format(DATE_FORMAT)}`)


    return (

      <Modal id="modal_booking_details" show={this.state.show} onHide={this.handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>{`Booking Details: ${bookingDetails && bookingDetails.booking.booking_id}`}</Modal.Title>
        </Modal.Header>

        {bookingDetails && bookingDetails.boat_id &&
          <PdfModal
            onClick={() => { }}
            handleClose={() => { this.setState({ showPdfModal: false }) }}
            show={this.state.showPdfModal}
            pdfUrl={`${process.env.REACT_APP_API_HOST}boatdocuments/get_my_pdf?token=${this.state.pdfToken}`}
          />
        }

        {bookingDetails != null && <Modal.Body>


          <Row>
            <Col>
              <DateTimeText
                id={"input_from_date"}
                disabled
                label={t(`FastBooking.from`)}
                value={bookingDetails.from_date}
                timelabel={t(`FastBooking.checkInTime`)}
                time={this.state.bookingDetails.mooring?.check_in_time.slice(0, -3)}

              />

            </Col>
            <Col>
              <DateTimeText
                id={"input_to_date"}
                disabled
                label={t(`FastBooking.to`)}
                value={bookingDetails.to_date}
                timelabel={t(`FastBooking.checkOutTime`)}
                time={this.state.bookingDetails.mooring?.check_out_time.slice(0, -3)}

              />

            </Col>
          </Row>


          {/* <FormInput
            readOnly
            type="text"
            label={t('MooringDetails.from') + " - " + t('MooringDetails.to')}
            value={dateRangeText}
          /> */}
          <FormInput
            id="input_name"
            readOnly
            type="text"
            label={t('MaintainTimesAndPrices.name')}
            value={`${bookingDetails.firstname} ${bookingDetails.lastname}`}
          />

          <FormInputWrapper
            label={t('MaintainTimesAndPrices.email')}
          >
            <a id={"link_email"} href={`mailto:${bookingDetails.email}`}>{bookingDetails.email}</a>
          </FormInputWrapper>

          <FormInputWrapper
            label={t('MaintainTimesAndPrices.mobile')}
          >
            <a id={"link_mobile"}
              href={`tel:${bookingDetails.mobilenumber}`}>{bookingDetails.mobilenumber}</a>
          </FormInputWrapper>


          {(boat && boat.registration_number != "", boat.registration_number != null) &&
            <FormInput
              id={"input_boat_registration_number"}
              label={t(`MaintainTimesAndPrices.boatRegistrationNumber`)}
              value={`${bookingDetails.boat.registration_number} `}
              editable={false}
              pointerEvents="none"
              last={bookingDetails.boat.registration_number == ""}
            />
          }
          {(boat && boat.length) &&
            <FormInput
              id={"input_boat_dimensions"}
              label={t(`MaintainTimesAndPrices.boatDimensions`)}
              value={`${bookingDetails.boat.length} / ${bookingDetails.boat.beam} / ${bookingDetails.boat.draft} m`}
              editable={false}
              pointerEvents="none"
              last
            />}
          {(boat && boat.name != "" && boat.name != null) &&
            <FormInput
              id={"input_boat_name"}
              label={t(`MaintainTimesAndPrices.boatName`)}
              value={`${bookingDetails.boat.name} `}
              editable={false}
              pointerEvents="none"
              last={bookingDetails.boat.name == ""}
            />
          }
          {(boat && boat.make != "" && boat.make != null) &&
            <FormInput
              id={"input_make"}
              label={t(`EditBoatModal.make`)}
              value={`${bookingDetails.boat.make} `}
              editable={false}
              pointerEvents="none"
              last={bookingDetails.boat.make == ""}
            />
          }
          {(boat && boat.type && boat.type != "" && boat.type != null) &&
            <FormInput

              label={t(`EditBoatModal.type`)}
              value={`${bookingDetails.boat.type} `}
              editable={false}
              pointerEvents="none"
              last={bookingDetails.boat.type == ""}
            />
          }
          {(boat && boat.drive && boat.drive != "" && boat.drive != null) &&
            <FormInput
              id={"input_drive"}
              label={t(`EditBoatModal.drive`)}
              value={`${bookingDetails.boat.drive} `}
              editable={false}
              pointerEvents="none"
              last={bookingDetails.boat.drive == ""}
            />
          } {(boat && boat.weight) &&
            <FormInput
              id={"input_weight"}
              label={t(`EditBoatModal.weight`)}
              value={`${bookingDetails.boat.weight} `}
              editable={false}
              pointerEvents="none"
              last={bookingDetails.boat.weight == ""}
            />
          }

          {bookingDetails.boat_id && bookingDetails.documents &&
            <Button
              onClick={this.showBoatDocumentsPressed}>{t('MaintainTimesAndPrices.showBoatDocuments')}</Button>
          }


          <FormCheckbox
            id='checkbox_internal_paid'
            label={t(`${i18nPrefix}.internal_paid`)}
            checked={Boolean(this.state.booking.internal_paid)}
            onChange={(e) => {
              this.setState({
                booking: { ...this.state.booking, internal_paid: e.target.checked }
              })
            }}
            disabled={!rights.update || !this.state.booking.own}
          />

          <FormInput
            id={"input_notes"}
            as="textarea"
            type="text"
            label={t('MooringDetails.notes')}
            value={`${this.state.booking.notes}`}
            rows={getTextAreaHeight(this.state.booking.notes)}
            onChange={(e) => {
              this.setState({ booking: { ...this.state.booking, notes: e.target.value } })
            }}
          />


          {rights.update && <Button id="button_save" style={{ ...globalStyles.normalButton, width: "100%", marginTop: 10, marginBottom: 10 }} onClick={this.buttonSaveClicked}>
            {t(`global.save`)}
            {this.state.loading && <Spinner style={{ width: 20, height: 20 }} animation="border" variant="secondary" role="status" />}
          </Button>}

          {rights.replace && <Button id="button_replace" style={{ ...globalStyles.normalButton, width: "100%", marginBottom: 10 }} onClick={this.buttonReplaceClicked}>
            {t(`${i18nPrefix}.replace`)}
            {this.state.loading && <Spinner style={{ width: 20, height: 20 }} animation="border" variant="secondary" role="status" />}
          </Button>}

          {rights.cancel && <CancelButton
            id="button_cancel"
            key={selectedBooking.booking_id}
            booking={selectedBooking}
            onCancelSuccess={() => {
              this.setState({ selectedBooking: false });
              this.handleClose();
            }}
          />}

        </Modal.Body>}
      </Modal>

    );

  }
}


export default withTranslation()(BookingDetailsModal)



