import React from 'react';
import PropTypes from 'prop-types';

import {
    Button,

    Modal
} from "react-bootstrap";

export default function PdfModal(props) {
    return (

        <>
            <Modal
                show={props.show}
                onHide={props.handleClose}
                dialogClassName="modal-80w"
                aria-labelledby="example-custom-modal-styling-title"

            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Boatdocuments
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                >
                    <object width="100%" height="700" data={`${props.pdfUrl}`} type="application/pdf">   </object>


                </Modal.Body>

            </Modal >
        </>
    );

}

PdfModal.propTypes = {
    label: PropTypes.string.isRequired
};