import axios from "axios";
import i18n from "../i18n";
export function loginRequest(loginObject, callback, errorcallback) {
  axios
    .post(`auth/login`, loginObject)
    .then((res) => {
      const response = res.data;
      const token = response.token;
      const communication_language = response.communication_language;


      localStorage.setItem("email", response.email);
      localStorage.setItem("originalEmail", response.email);
      localStorage.setItem("AuthToken", token);
      localStorage.setItem("role", response.role);
      localStorage.setItem("originalRole", response.role);
      localStorage.setItem("AccountData", JSON.stringify(response.account))
      localStorage.setItem("originalAccountData", JSON.stringify(response.account))
      i18n.changeLanguage(communication_language.toLowerCase());

      axios.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
      };
      i18n.changeLanguage(communication_language);

      callback(response);
    })
    .catch((error) => {
      errorcallback(error);
    });
}

export function logoutRequest(callback, errorcallback) {
  axios
    .post(`auth/logout`)
    .then((res) => {
      const response = res.data;
      axios.defaults.headers.common = {
        Authorization: ``,
      };
      localStorage.removeItem("AuthToken");
      localStorage.removeItem("AccountData");
      localStorage.removeItem("AuthorizedAccounts");
      i18n.changeLanguage()  // calling without argument uses auto language detector

      callback(response);
    })
    .catch((error) => {

      axios.defaults.headers.common = {
        Authorization: ``,
      };
      localStorage.removeItem("AuthToken");
      localStorage.removeItem("AuthorizedAccounts");
      i18n.changeLanguage()  // calling without argument uses auto language detector

      errorcallback(error);
    });


  //Remove also original auth token
  const originalAuthToken = localStorage.getItem("originalAuthToken")
  if (originalAuthToken) {
    axios.defaults.headers.common = { Authorization: `Bearer ${originalAuthToken}`, };
    axios.post(`auth/logout`)
    localStorage.removeItem("originalAuthToken");

  }

}

export function verifyMobile(activationObject, callback, errorcallback) {
  axios
    .post("users/activate", activationObject)
    .then((response) => {
      if (response.status == 200) {
        callback();
      }
    })
    .catch((err) => {
      errorcallback(err);
    });
}

export function verifyEmail(data, callback, errorcallback) {
  axios
    .post("users/verify_email", data)
    .then((response) => {
      if (response.status == 200) {
        callback();
      }
    })
    .catch((err) => {
      errorcallback(err);
    });
}


export async function loginToMasterAccount(account_id, callback, errorcallback) {
  axios
    .post(`auth/login_to_master_account`, { account_id: account_id })
    .then(async (response) => {
      response = response.data;
      const token = response.token;

      const originalAuthToken = localStorage.getItem("AuthToken");
      localStorage.setItem("originalAuthToken", originalAuthToken);
      localStorage.setItem("AuthToken", response.token);
      localStorage.setItem("email", response.email);
      localStorage.setItem("role", response.role);

      localStorage.setItem("AccountData", JSON.stringify(response.account))

      axios.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
      };
      callback(response);
      window.location.reload();
    })
    .catch((error) => {
      errorcallback(error);
    });
}

export async function goBackToMainAccount() {
  const originalAuthToken = localStorage.getItem("originalAuthToken");
  if (originalAuthToken) {

    await axios.post(`auth/logout`)

    localStorage.setItem("AuthToken", originalAuthToken);
    localStorage.setItem("email", localStorage.getItem("originalEmail"));
    localStorage.setItem("role", localStorage.getItem("originalRole"));
    localStorage.setItem("AccountData", localStorage.getItem("originalAccountData"));


    axios.defaults.headers.common = {
      Authorization: `Bearer ${originalAuthToken}`,
    };

    window.location.reload();

  }
}

export async function getMyAuthorizedAccounts(callback, errorcallback) {

  axios.get(`auth/get_my_account_authoritations`)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      errorcallback(error);
    });

}