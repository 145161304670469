import React from "react";
import { withTranslation } from 'react-i18next';
import COLORS from '../constants/colors';
import FormInput from '../components/FormInput';
import globalStyles from "../stylesheets/globalStyles";

import i18n from "../i18n";


import {
    Button,
    Modal,
} from "react-bootstrap";
import AddIcon from '../assets/icons/add.png'

import { transferMooring, sendTransferInvitation } from '../actions/moorings'


class TransferMooringSection extends React.Component {

    constructor(props) {
        super(props);
        const communication_language = i18n.language
        this.state = {
            transfer_email: null,
            invitation_email_language: communication_language,
        };

    }

    componentDidMount() {
        this.autoFillEmail();
    }


    componentDidUpdate(prevProps) {
        if (this.props.mooring.mooring_id !== prevProps.mooring.mooring_id) {
            this.autoFillEmail();
        }
    }

    autoFillEmail = () => {
        const { mooring } = this.props;
        if (!mooring.boatpark_account) {
            if (mooring.renters && mooring.renters.length != 0) {
                // console.log("mooring: ", mooring.renters[0].contact.email)
                this.setState({ transfer_email: mooring.renters[0].contact.email })
            }
        }
        else {
            this.setState({ transfer_email: "" });
        }
    }

    handleTransferClick = () => {
        if (this.state.transfer_email == null || this.state.transfer_email == "") return; //Not empty

        transferMooring(this.props.mooring, this.state.transfer_email,
            (response) => {
                if (this.props.reloadMooring) this.props.reloadMooring();
            },
            (error) => {
                const errorResponse = error.response.data
                if (errorResponse.error_code && errorResponse.error_code == 887) { // Boatpark account does not exist: send transfer invitation email?
                    this.showTransferInvitationModal()
                }
                else {
                    this.setState({ error: true })
                }
            })
    }

    showTransferInvitationModal = () => {
        this.setState({ showTransferInvitationModal: true })
    }
    closeTransferInvitationModal = () => {
        this.setState({ showTransferInvitationModal: false })
    }
    renderTransferInvitationModal = () => {
        const { t } = this.props;
        const languages = t(`global.communicationLanguages`);
        return (
            <>
                <Modal show={this.state.showTransferInvitationModal} onHide={this.closeTransferInvitationModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t(`TransferMooring.modalTitle`)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{t(`TransferMooring.modalBody`)}
                        <FormInput as="select"
                            label={t(`TransferMooring.emailLanguage`)}
                            value={this.state.invitation_email_language.toUpperCase()}
                            onChange={(event) => {
                                this.setState({ invitation_email_language: event.target.value });
                            }}
                        >
                            {
                                languages.map((language, index) =>
                                    < option key={index} value={language}>{language}</option>
                                )
                            }

                        </FormInput>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeTransferInvitationModal}>
                            {t('global.cancel')}
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                sendTransferInvitation(this.props.mooring, this.state.transfer_email, this.state.invitation_email_language, () => { }, () => { })
                                this.closeTransferInvitationModal();
                            }
                            }>
                            {t(`TransferMooring.sendEmail`)}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }



    render() {
        const { t } = this.props;

        return (
            <>
                {this.renderTransferInvitationModal()}

                <h3> {t('EditMooring.transferMooring')}</h3>
                <FormInput
                    label={t('EditMooring.email')}
                    placeholder={t('EditMooring.transferEmailPlaceholder')}
                    type="text"
                    value={this.state.transfer_email}
                    onChange={(e) => {
                        this.setState({
                            transfer_email: e.target.value,
                        });
                    }}
                />
                <Button onClick={this.handleTransferClick} style={{ ...globalStyles.normalButton, color: "red", width: "100%", marginBottom: 20, }}>{t('EditMooring.transfer')}</Button>
            </>
        );
    }
}



export default withTranslation()(TransferMooringSection)