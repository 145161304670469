import React from "react";
import { withTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import COLORS from '../constants/colors';
import globalStyles from '../constants/globalStyles';

import {
  Container,
  FormControl,
  Row,
  Button,
  Col,
  Alert,
} from "react-bootstrap";

import p_active from '../assets/maintain_bookings_screen/p_active.png';
import p_inactive from '../assets/maintain_bookings_screen/p_inactive.png';

import { addBooking } from '../actions/bookings'


class MooringItem extends React.Component {


  visitMooring = () => {
    this.props.history.push({
      pathname: `/mooring/${this.props.mooring.mooring_id}`,
      state: { mooring: this.props.mooring }
    })
  }

  handleTimesAndPricesClick = () => {
    window.open(`/maintainTimesAndPrices/${this.props.mooring.mooring_id}`, "_blank")
  }


  render() {
    const { t } = this.props;
    const { mooring } = this.props;
    return (
      <div className="mooring-item" id={`mooring_item_${mooring.mooring_id}`}>
        <div style={{ float: "left", height: "100%" }}>
          <img
            onClick={() => {
              if (this.props.pointOutMooring) {
                this.props.pointOutMooring(this.props.mooring)
              }
            }}
            src={mooring.supervising ? p_inactive : p_active} alt="Logo"
            style={{
              marginTop: 17,
              width: 75,
              height: 75,
            }}
            className="mooring-logo"
          />
        </div>
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: 5,
            overflow: 'visible',
            boxSizing: "border-box",
            width: "auto",
            padding: 10,
            marginLeft: 40,
            marginRight: 10,
            paddingLeft: 30,
            marginBottom: 20,
            boxSizing: 'border-box'
          }}
          className="button-container"
        >
          <div>

            <div md={11} style={{ paddingLeft: 20 }}>
              <div>
                <h3 style={{ color: COLORS.MAIN_BLUE }}>{mooring.harbour_name} {mooring.berth_number}</h3>
              </div>
              <div>
                <h3 style={{ color: COLORS.MAIN_GRAY, fontSize: 18 }}>{mooring.renter_name}</h3>
              </div>
              <div>
                <Button style={globalStyles.button} onClick={this.props.infoClicked}>{t('MaintainMoorings.info')}</Button>

                <Button style={globalStyles.button} onClick={this.props.editClicked}>{mooring.rights.edit ? t('MaintainMoorings.edit') : t('MaintainMoorings.show')}</Button>

                {(mooring.rights.edit_times && (mooring.app_visible == 1 || mooring.supervising_marina == 5229 || mooring.supervising_marina == 4150)) &&

                  <Button style={globalStyles.button} onClick={this.handleTimesAndPricesClick}>{t('MaintainMoorings.times')}</Button>
                }
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}



export default withTranslation()(MooringItem);