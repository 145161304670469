import React from "react";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import { logoutRequest, loginToMasterAccount, goBackToMainAccount } from "../actions/auth";

import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
} from "react-bootstrap";
import bp_banner from "../assets/bp_banner_large.png"; // Tell webpack this JS file uses this image
const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

class MainNavbar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      account_data: {},
      authorized_accounts: [],
      authorized_accounts_search_text: "",
      registerScreen: false,
    }
  }
  // Todo: Find faster way to get role 
  componentDidMount() {
  }

  handleLogout = () => {
    logoutRequest(this.logoutSuccess, this.logoutFail);
  };
  logoutSuccess = () => {
    this.props.history.push("/login");
  };
  logoutFail = (error) => {
    this.props.history.push("/login");
  };

  loginToMasterAccountPressed = (account_id) => {
    loginToMasterAccount(account_id, (response) => { }, (error) => { })
  }

  changeBackPressed = () => {
    goBackToMainAccount()

  }

  render() {
    const { t } = this.props
    const authenticated = localStorage.getItem("AuthToken");
    const email = localStorage.getItem("email");
    const originalEmail = localStorage.getItem("originalEmail");
    const account_data = JSON.parse(localStorage.getItem("AccountData")) || {};

    const authorized_accounts_json = localStorage.getItem("AuthorizedAccounts")
    var authorized_accounts = authorized_accounts_json ? JSON.parse(authorized_accounts_json) : [];
    const authorized_accounts_searchable = authorized_accounts.length > 3
    if (this.state.authorized_accounts_search_text != "") {
      authorized_accounts = authorized_accounts.filter((authorized_account) => { return authorized_account.email.includes(this.state.authorized_accounts_search_text) })
      console.log("authorized_accounts", authorized_accounts)
    }


    // const authorized_accounts = []
    // const management = this.state.account_data && this.state.account_data.role == "management";
    const role = localStorage.getItem("role");
    const management = role == "management"; //TODO: check if account is management account
    const marina_account = role == "marina";

    const register_screen = window.location.href.includes("register")



    return (
      <Navbar
        id="navbar"
        bg="light"
        expand="lg"
        sticky="top"
        style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 10, paddingTop: 10 }}>
        <Nav.Link href={authenticated ? "/home" : ""}>
          <Navbar.Brand id="nav_brand" ><img src={bp_banner} alt="Logo" />
            {process.env.REACT_APP_DEPLOYMENT_ENV == "dev" && <div style={{ color: 'red', position: 'absolute', marginLeft: 60, marginTop: 40 }}>Dev</div>}
          </Navbar.Brand>
        </Nav.Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {authenticated && <Nav.Link id="nav_link_bookings" href="/Bookings">{t('Navbar.reservations')}</Nav.Link>}
            {authenticated && <Nav.Link id="nav_link_moorings" href="/MooringTable">{t('Navbar.moorings')}</Nav.Link>}
            {authenticated && account_data.rights && account_data.rights.show_contact_tab && <Nav.Link id="nav_link_contacts" href="/ContactTable">{t('Navbar.contacts')}</Nav.Link>}
            {authenticated && management && <Nav.Link id="nav_link_waiting_contacts" href="/WaitingContactsTable">{t('Navbar.waitingList')}</Nav.Link>}
            {(authenticated) && <Nav.Link id="nav_link_account" href="/account">{t('Navbar.account')}</Nav.Link>}
            {!authenticated && <Nav.Link id="nav_link_register" href="/register">{t('Navbar.register')}</Nav.Link>}
            {(!authenticated && !register_screen) && <Nav.Link id="nav_link_login" href="/login">{t('Navbar.login')}</Nav.Link>}
          </Nav>
          {/* <Form inline>

            <Button variant="outline-success">Search</Button>
          </Form> */}
        </Navbar.Collapse>
        {/* {role} */}


        <Navbar.Collapse className="justify-content-end">


          {authenticated &&
            <NavDropdown align="end" id="nav-dropdown-signed-in-as" title={`${email}`}>

              <NavDropdown.Item onClick={this.changeBackPressed}>
                {originalEmail}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              {authorized_accounts_searchable &&
                <div>
                  <Form.Control
                    style={{ margin: 'auto', width: '95%' }}
                    value={this.state.authorized_accounts_search_text}
                    onChange={(e) => this.setState({ authorized_accounts_search_text: e.target.value })}
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                  />
                  <NavDropdown.Divider />
                </div>
              }
              <div style={{ maxHeight: 500, overflowY: "scroll" }}>

                {authorized_accounts.map((authorized_account) =>
                  <div key={authorized_account.account_id} >
                    <NavDropdown.Item onClick={() => this.loginToMasterAccountPressed(authorized_account.account_id)} >
                      {authorized_account.email}
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                  </div>
                )}
              </div>

              <NavDropdown.Item
                id="nav_logout" onClick={this.handleLogout}>
                {t('Navbar.logout')}
              </NavDropdown.Item>

            </NavDropdown>
          }
        </Navbar.Collapse>
      </Navbar >
    );
  }
}

export default withTranslation()(withRouter(MainNavbar));
