import COLORS from "../constants/colors";

const globalStyles = {
  saveButton: {
    float: "right",
    backgroundColor: COLORS.MAIN_GREEN,
    fontWeight: "bold",
    borderColor: COLORS.GREEN_MOORING_AVAILABLE,
  },
  redButton: {
    marginLeft: 10,
    float: "right",
    backgroundColor: "red",
    fontWeight: "bold",
    borderColor: COLORS.GREEN_MOORING_AVAILABLE,
  },
  normalButton: {
    backgroundColor: "white",
    color: COLORS.MAIN_GRAY,
    fontWeight: "bold",
    borderColor: COLORS.MAIN_GRAY,
    height: 40,
    width: "100%",
    marginTop: 10,
    marginBottom: 10
  },
  deleteButton: {
    backgroundColor: "white",
    color: "red",
    fontWeight: "bold",
    borderColor: COLORS.MAIN_GRAY,
  },
  colForm: {
    backgroundColor: "white",
    padding: 20,
    height: "100%",
  },
  link: {
    color: COLORS.MAIN_BLUE,
    textDecorationLine: 'underline',
  }

};

export default globalStyles;
