const de = {
  MooringDetails: {
    title: "Platz buchen",
    details: "Details",
    occupiedBy: "Heimat-Liegeplatz von",
    taxes: "Taxen",
    perAdultPerNight: "Pro Person in Hochtarif und Nacht",
    perChildPerNight: "Pro Person in Niedertarif und Nacht",
    coordinates: "Koordinaten",
    maxLength: "Max. Länge",
    maxBeam: "Max. Breite",
    maxDraught: "Max. Tiefgang",
    lengthBeamDraft: "Länge/Breite/Tiefgang",
    berthNo: "Platz Nummer/Identifikation",
    marinaName: "Hafen Name",
    additionalDescription: "Weitere Beschreibung",
    pierHeight: "Steg Höhe (über Wasser)",
    power: "Strom vorhanden?",
    water: "Wasser vorhanden?",
    buoy: "Bojen-Platz?",
    boatName: "Schiffsname",
    port: "Heimathafen",
    from: "Von",
    to: "Bis",
    numberOfAdults: "Anzahl Personen Hochtarif",
    numberOfChildren: "Anzahl Personen Niedertarif",
    totalTouristTaxes: "Total Taxen",
    excluding: "Exklusive ",
    including: "Inklusive ",
    harbourFee: "der Hafengebühr",
    cancel: "Stornieren",
    notes: "Bemerkungen",
    timesAndPrices: "Zeiten und Preise",
    bookMooring: "Liegeplatz Buchen",
    edit: "Editieren",
    claim: "Beanspruchen",
    mooringZone: "Platz Zone",
    mooringType: "Platz Typ",
    permanentRenter: "Dauermieter",
    shipSizeLBD: "Schiffsdimensionen (L x B x T)",
    boatRegistrationNumber: "Schiff",
    info: "Info",
    exemptedFor: "Freigestellt für",
    boatparkAccount: "Boatpark Account",
    email: "Email",
    name: "name",
    mobile: "mobile",
    marina_notes: "Hafennotizen",
  },
  Login: {
    email: "Email",
    password: "Passwort",
    login: "Anmelden",
    register: "Registriere neuen Boatpark Account",
    forgotPassword: "Passwort vergessen?",
    emailHasBeenSentTitle: "e-Mail wurde versandt",
    emailHasBeenSent: "e-Mail wurde versandt. Bitte Spam-Ordner prüfen, falls es nicht ankommt.",
    confirmEmailTitle: "e-Mail bestätigen",
    confirmEmail: "Bitte bestätige die e-Mail Adresse mittels Link im Mail an Dich. Soll die e-Mail neu versandt werden?",
  },
  ForgotPassword: {
    title: "Passwort vergessen",
    email: "Email",
    errorsMessage: "Es ist ein Fehler aufgetreten: Bitte versuche es nocheinmal.",
    emailHasBeenSent: "e-Mail wurde versandt. Bitte Spam-Ordner prüfen, falls es nicht ankommt.",
    reset: "Zurück setzen",
    register: "Registrieren neuer Boatpark Benutzer",
    weWillSendEmail: "Wir senden dir eine Email mit einem Link zum Zurücksetzen deines Passworts.",
    info: "Geben Sie Ihre E-Mail-Adresse ein, um einen Link zu erhalten, über den Sie Ihr Passwort ändern können.",
    send: "Senden",
  },
  Register: {
    title: "Registrieren",
    register: "Speichern",
    roles: ["Private", "Marina"],
    communicationLanguages: ["EN", "DE", "FR", "IT", "ES"],
    communicationLanguage: "Kommunikations-Sprache",
    role: "Rolle",
    iAcceptThe: "Ich akzeptiere die ",
    lastName: "Nachname oder Firma",
    firstName: "Vorname",
    email: "Email",
    mobile: "Mobil Telefon (ex. +41 12 345 67 89)",
    password: "Passwort",
    repeatPassword: "Passwort wiederholen",
    referral: "Empfohlen durch / Ambassador e-Mail",
    TCs: "AGBs",
    registerSuccess: "Registrierung erfolgreich. Eine Bestätigunes E-Mail wurde dir zugestellt.",
    OptionalBoatInfo: "Boot Info",
    name: "Schiffsname",
    registration_number: "Kennzeichen",
    length: "Länge",
    beam: "Breite",
    draft: "Tiefgang",
    continueAsGuest: "Als Gast fortfahren",
    pay: "Jetzt bezahlen",
  },
  FastBooking: {
    from: 'Von',
    to: 'Bis',
    booking_price: "Buchungspreis",
    tax_price: "Total Taxen",
    total_price: "Total-Preis",
    taxes: "Taxen",
    perAdultPerNight: "Pro Person in Hochtarif und Nacht",
    perChildPerNight: "Pro Person in Niedertarif und Nacht",
    numberOfAdults: "Anzahl Personen Hochtarif",
    numberOfChildren: "Anzahl Personen Niedertarif",
    mooringUnavailable: "Dieser Liegeplatz ist nicht mehr für die Sofort-Buchung verfügbar. Bitte benutzen Sie die Boatpark-App oder wählen Sie einen anderen Liegeplatz.",
    LBH: "Länge / Breite / Tiefgang",
    bookingHasBeenSaved: "Ihre Buchung wurde gespeichert. Prüfen Sie Ihre E-Mail/SMS, um Ihre Anmeldung abzuschließen, nachdem Sie Ihr Boot sicher festgemacht haben. Sie können dies auch jetzt tun über ",
    thisLink: "diesen Link.",
    TheReservationWillBePaid: "Die Reservierung wird nach Abschluss Ihrer Registrierung bezahlt.",
    YouAreAboutToBook: "Sie sind dabei, diesen Platz für eine Nacht für {{currency}} {{amount}} zu buchen: möchten Sie fortfahren?",
    YourInformation: "Ihre Angaben",
    MooringInformation: "Informationen über den Liegeplatz",
    firstname: "Vorname",
    lastname: "Nachname oder Firma",
    email: "Email",
    mobile: "Mobil Telefon (ex. +41 12 345 67 89)",
    reserve: "Reservieren",
    activationCode: "Aktivierungs-Code",
    confirm: "Bestätigen",
    totalPrice: "Total-Preis",
    additionalDescription: "Weitere Beschreibung",
    boat_length: "Schiffslänge",
    boat_beam: "Schiffsbreite",
    checkInTime: "Check In: ",
    checkOutTime: "Check Out: ",
    boat_name: "Schiffsname",
    boat_registration_number: "Kennzeichen",
    freeBookingHasBeenSaved: "Ihre Buchung wurde gespeichert.",
    bookNow: "Jetzt buchen",
    bookingSuccessful: "Der Liegeplatz wurde erfolgreich reserviert.",
    payment_method: "Zahlungsmethode",
  },
  ResetPasasword: {
    title: "Passwort zurücksetzen",
    password: "Neues Passwort",
    passwordRepeat: "Passwort (wiederholen)",
    reset: "Zurücksetzen",
    paswordHasBeenReset: "Das Passwort wurde zurückgesetzt",
  },
  EditContactModal: {
    editContact: "Kontakt bearbeiten",
    newContact: "Neuer Kontakt",
    communicationLanguages: ["EN", "DE", "FR", "IT", "ES"],
    communicationLanguage: "Kommunikations-Sprache",
    salutation: "Anrede",
    lastName: "Nachname oder Firma",
    firstName: "Vorname",
    email: "Email",
    mobile: "Mobil Telefon (ex. +41 12 345 67 89)",
    creationSuccess: "Kontakt erstellt",
    street: "Strasse",
    zip: "PLZ",
    city: "Ort",
    birthday: "Geburtstag",
    phone_p: "Telefon Privat",
    phone_b: "Telefon Geschäftlich",
    alternative_email: "alternative Email",
    alternative_mobile: "alternatives Mobil Telefon",
    type: "Typ",
    access_badge: "Zugangs Badge",
    remarks: "Bemerkungen",
    vat: "MWST Nr.",
    edit: "Bearbeiten",
    show: "Zeigen",
    boats: "Schiffe",
    createContact: "Kontakt erstellen",
    save: "speichern",
    deleteContact: "Kontakt löschen",
    addNewBoat: "Schiff hinzufügen",
    areYouSureDeleteContact: "Bist du sicher, dass du diesen Kontakt löschen möchtest?",
    boatparkAccount: "Boatpark Account",
    token: "Token",
    customer_number: "Debitoren Nummer",
    id: "ID",
    moorings: "Liegeplätze",
    watiting_contacts: "Warteliste Einträge",
  },
  ContactTable: {
    addNewContact: "Kontakt erstellen",
    firstname: "Vorname",
    lastname: "Nachname",
    email: "Email",
    mobile: "Mobile",
    communication_language: "Sprache",
    street: "Strasse",
    zip: "PLZ",
    city: "Ort",
    birthday: "Geburtstag",
    type: "Typ",
    access_badge: "Zugangsbadge",
    remarks: "Bemerkungen",
    token: "Token",
    customer_number: "Debitoren Nummer",
    id: "ID",
    editOn: "Bearbeiten: ein",
    editOff: "Bearbeiten: aus",
  },
  EditBoatModal: {
    editBoat: "Schiffsdaten bearbeiten",
    name: "Name",
    registration_number: "Kennzeichen",
    length: "Länge",
    beam: "Breite",
    draft: "Tiefgang",
    type: "Typ",
    drive: "Antrieb",
    weight: "Gewicht",
    createBoat: "Schiff erstellen",
    save: "speichern",
    deleteBoat: "Schiff löschen",
    boatTypes: ["", "Motor", "Segel"],
    boatDrives: ["Kein", "Benzin", "Diesel", "Elektrisch", "andere",],
    documents: "Dokumente",
    addNewDocument: "Dokument hinzufügen",
    show: "Zeigen",
    areYouSureDeleteBoat: "Bist du sicher, dass du dieses Schiff löschen möchtest?",
    showPDF: "Zeige Schiffs-Dokumente",
    make: "Marke",
  },
  EditBoatDocumentModal: {
    editBoatDocument: "Dokument bearbeiten",
    createBoatDocument: "Dokument erstellen",
    title: "Titel",
    image: "Bild",
    save: "Speichern",
    deleteBoatDocument: "Dokument löschen",
    areYouSureDeleteDocument: "Bist du sicher, dass du dieses Dokument löschen willst?",
  },
  MaintainAssignedContacts: {
    title: "Mieter Verwalten",
    editRenter: "Mieter bearbeiten",
    addRenter: "Mieter hinzufügen",
    correspondence: "Korrespondenz",
    exemption: "Freistellung",
    provisional: "Provisorisch",
    contact: "Kontakt",
    boat: "Schiff",
    from: "Von",
    to: "Bis",
    notes: "Notizen",
  },
  EditWaitingContactModal: {
    editReservation: "Eintrag bearbeiten",
    addReservation: "Eintrag hinzufügen",
    contact: "Kontakt",
    entryDate: "Eintritts Datum",
    mooring_zone: "Platz Zone",
    length: "Länge",
    beam: "Breite",
    draft: "Tiefgang",
    mooring_type: "Platztyp",
    annual_mooring_interested: "Interessiert an Jahresplatz",
    relocation: "Umplatzierung",
    comment: "Kommentar",
    last_examination: "Letzte Überprüfung",
    archived: "Archiviert",
    archiving_reason: "Grund für archivierung",
    archiving_date: "Datum Archivierung",
    currency: "Währung",
    registration_fee: "Einschreibegebühr",
    periodic_fee: "Periodische Gebühr",
    payment_period: "Zahlungsperiode [Jahre]",
    areYouSureDeleteEntry: "Bist du sicher, dass du diesen Eintrag löschen willst? Er kann auch archiviert werden.",
    boat_type: "Schiffstyp",
    WaitingContactShouldNotBeUnarchived: "Einträge auf der Warteliste sollten nicht un-archiviert werden.",
  },
  WaitingContactTable: {
    contact_name: "Name",
    contact_address: "Adresse",
    contact_type: "Kontakt Typ",
    entry_date: "Eintrittsdatum",
    mooring_zone: "Platzzone",
    mooring_type: "Platztyp",
    boat_type: "Boots-Typ",
    min_length: "Min. Länge",
    min_beam: "Min. Breite",
    min_draught: "Min. Tiefgang",
    relocation: "Umplatzierung",
    annual_mooring_interested: "Jahresplatz",
    comment: "Kommentar",
    archiving_reason: "Grund für Archivierung",
    archiving_date: "Datum Archivierung",
    registration_fee: "Einschreibegebühr",
    periodic_fee: "Periodische Gebühr",
    currency: "Währung",
    payment_period: "Zahlungsperiode [Jahre]",
    last_examination: "Letzte Überprüfung",
    all: "Alle",
    archived: "Archiviert",
    active: "Aktiv",
    status: "Status",
    editOn: "Bearbeiten: ein",
    editOff: "Bearbeiten: aus",
    showFilter: "Filter",
    resetFilter: "Zurücksetzen",
    berth_number: "Platznummer",
    dimensions: "Dimensionen (L/B/T)",
    addEntry: "Neuer Eintrag",
    email: "Email",
  },
  Account: {
    title: "Benutzer-Konto",
    role: "Rolle",
    roles: ["Private", "Marina"],
    communicationLanguages: ["EN", "DE", "FR", "IT", "ES"],
    communicationLanguage: "Kommunikations-Sprache",
    firstName: "Vorname",
    lastName: "Nachname oder Firma",
    email: "Email",
    mobile: "Mobil Telefon (ex. +41 12 345 67 89)",
    myBoat: "Mein Schiff",
    boatRegistrationNumber: "Schiffs-Kennzeichen",
    boatName: "Schiffsname und Heimathafen",
    beam: "Breite [m]",
    length: "Länge [m]",
    draft: "Tiefgang [m]",
    boatDocumentScans: "Schiffsdokumente (Scans)",
    additionalInformation: "Weitere Beschreibung",
    reimbursementBankAccount: "Bank Konto für Rückerstattungen",
    paymentmethod: "Zahlungsmittel",
    vat: "MwSt Nummer (falls relevant)",
    changePassword: "Passwort ändern",
    password: "Neues Passwort",
    passwordRepeat: "Passwort (wiederholen)",
    success: "Speichern erfolgreich",
    types: "Typen",
    mooringTypes: "Platz Typen",
    addMooringType: "Platz Typ hinzufügen",
    mooringZones: "Platz Zonen",
    addMooringZone: "Platz Zone Hinzufügen",
    contactTypes: "Kontakt Typen",
    addContactType: "Kontakt Typ hinzufügen",
    default_currency: "Standard Währung",
    default_periodic_fee: "Standard Periodische Gebühr",
    default_registration_fee: "Standard Einschreibegebühr",
    default_payment_period: "Standard Zahlungsperiode",
    manual_booking_confirmation: "Manuelle Buchungsbestätigung",
    automatic_response: "Automatische Antwort",
    automatic_response_after: "Automatische Antwort nach",
    accept: "Akzeptieren",
    decline: "Ablehnen",
    salutation: "Anrede",
    street: "Strasse",
    zip: "PLZ",
    city: "Stadt",
    country: "Land",
    bankAccountData: "Bank Konto für Rückerstattungen",
    currency: "Währung",
    iban: "IBAN",
    owner_name: "Name des Inhabers",
    confirmation_emails_own: "E-Mail Bestätigung für Eigenbuchungen",
    settings: "Einstellungen",
    csv_separator: "CSV Trennzeichen",
    email_cc: "Email Kopie an",
    email_admin_cc: "Abrechnungs-Email Kopie an",
    max_nights_per_year: "Max. Anzahl Nächte pro Jahr und Gast",
  },
  ActivateAccount: {
    title: "Benutzer aktivieren",
    activate: "Aktivieren",
    activationCode: "Aktivierungs-Code",
    activationText: "Vor dem Aktivieren und Zurückkehren auf den Anmelde-Bildschirm, bestätige bitte deine Email Adresse mittels Link in der Email an dich.",
    resendSMS: "SMS mit Aktivierungs-Code neu senden",
    activationFailed: "Aktivierung fehlgeschlagen",
    tryAgain: "Bitte versuche es erneut",
    smsHasBeenSentTitle: "SMS wurde versandt",
    smsHasBeenSent: "SMS wurde versandt. Bitte Support kontaktieren, falls es nicht ankommt.",
    mobileInfo: "Wir haben Ihnen eine SMS mit einem Aktivierungscode geschickt. Bitte geben Sie den code hier ein:",
    emailInfo: "Ausserdem haben sie eine E-Mail von uns bekommen. Bitte klicken sie den Link in der E-Mail, um die Registrierung abzuschliessen.",
  },
  MaintainMoorings: {
    title: "Liegeplätze ändern",
    dailyPrice: "daily price",
    delete: "Liegeplatz Löschen",
    edit: "Ändern",
    show: "Zeigen",
    times: "Zeiten",
    info: "Info",
    book: "Buchen",
    addNewMooring: "Neuer Liegeplatz hinzufügen",
    doYouReallyWantToDeleteMooring: "Willst du diesen Liegeplatz wirklich löschen?",
  },
  MooringTable: {
    berth_number: "Platznummer",
    harbour_name: "Hafen",
    permanent_renter_names: "Dauermieter",
    exemption_renter_names: "Freigestellt für",
    renter_from_date: "Vermietet von",
    renter_to_date: "Vermietet bis",
    max_length: "Max. Länge",
    max_beam: "Max. Breite",
    max_draught: "Max. Tiefgang",
    max_weight: "Max. Gewicht",
    pier_height: "Steg Höhe",
    power: "Strom ",
    water: "Wasser",
    buoy: "Boje",
    mooring_type: "Platztyp",
    mooring_zone: "Platzzone",
    marina_notes: "Hafen Notizen",
    app_visible: "Sichtbar in App",
    additional_description: "Zusätzliche Beschreibung",
    confidential_description: "Vertrauliche Beschreibung",
    editOn: "Edit: on",
    editOff: "Edit: off",
    operating_cost: "Betriebskosten",
    yearly_rate: "Jährliche Kosten",
    payment_currency: "Währung",
    daily_rate_default: "Preis/Nacht",
    mass_edit: "Code ändern",
    confidential_code_old: "Aktueller Geheimcode",
    confidential_code_new: "Neuer Geheimcode",
  },
  AddMooringMap: {
    title: "Liegeplatz hinzufügen",
    whereIs: "Suche nach Ort",
    zoomIn: "Suche deinen Hafen und vergrössere die Ansicht. " + "Tippe und halte den Finger auf deinen Liegeplatz.",
    apply: "Liegeplatz erstellen",
    desiredLocation: "Suche nach Ort",
  },
  EditMooring: {
    details: "Details",
    occupiedBy: "Heimat-Liegeplatz von",
    coordinates: "Koordinaten",
    appVisible: "Sichtbar in der App",
    maxLength: "Max. Länge",
    maxBeam: "Max. Breite",
    maxDraught: "Max. Tiefgang",
    maxWeight: "Max. Gewicht",
    real_length: "Effektive Länge",
    real_beam: "Effektive Breite",
    berthNo: "Platz Nummer/Identifikation",
    harbourName: "Hafen Name",
    additionalDescription: "Weitere Beschreibung",
    confidentialDescription: "Vertrauliche Informationen",
    pierHeight: "Steg Höhe (über Wasser)",
    power: "Strom vorhanden?",
    water: "Wasser vorhanden?",
    buoy: "Bojen-Platz?",
    boatName: "Schiffsname",
    port: "Heimathafen",
    create: "Speichern",
    additionalDescriptionPlaceholder: "Geben Sie hier den Link auf die Hafen-Homepage an und weitere Informationen zum Liegeplatz, welcher für alle User zugänglich sind.",
    confidentialDescriptionPlaceholder: "Geben Sie hier vertrauliche Zusatz-Informationen an, welche nur im Fall einer Reservation an den Mieter geschickt werden (z.B. Kombination für Zugangs-Code zu Hafenanlage).",
    marinaNotes: "Hafen Bemerkungen",
    mooringType: "Platz Typ",
    mooringZone: "Platz Zone",
    availabilities: "Verfügbarkeiten",
    settings: "Einstellungen",
    maxNights: "Max. Anzahl Nächte",
    maxNightsPerYear: "Max. Nächte pro Jahr",
    finance: "Finanzen",
    yearlyRates: "Jahresmiete",
    transferMooring: "Liegeplatz Transferieren",
    transfer: "Transferieren",
    email: "Email",
    operatingCosts: "Betriebskosten",
    manualConfirmation: "Manuelle Bestätigung",
    areYouSureDeleteMooring: "Bist du sicher, dass du diesen Liegeplatz löschen möchtest?",
    AreaBlockedTitle: "Gesperrter Bereich",
    AreaBlocked: "Dieser Bereich ist gesperrt. Wenn Sie hier einen Liegeplatz haben, kontaktieren Sie bitte {{contact_email}}, um ihn Ihnen zuzuweisen.",
    country: "Land",
  },
  TransferMooring: {
    modalTitle: "Boatpark Konto existiert nicht",
    modalBody: "Es gibt kein Boatpark-Konto mit dieser E-Mail. Möchten Sie eine Einladungs-E-Mail zur Registrierung bei Boatpark senden? Der Liegeplatz wird nach der Registrierung automatisch transferiert.",
    emailLanguage: "E-Mail Sprache",
    sendEmail: "Einladung versenden",
  },
  MaintainTimesAndPrices: {
    title: "Zeiten und Preise",
    feeIncluded: "Hafengebühr inklusive?",
    currency: "Währung",
    from: "Von",
    to: "Bis",
    pricePerNight: "Preis/Nacht",
    addNext: "Neue Periode hinzufügen",
    addNewPeriod: "Neue Periode hinzufügen",
    dayByDayInfoTitle: "Tag-für-Tag Verfügbarkeit",
    dayByDayInfo: "Wenn dieses Feld markiert wird, kann der Liegeplatz nur am gleichen Tag und nur für eine Nacht gebucht werden (keine Vorausbuchung). Das ermöglicht dem Liegeplatz-Inhaber, dass er im Fall einer früheren Rückkehr die Verfügbarkeit seines Platzes täglich löschen kann um den Platz für sich zu beanspruchen.",
    continueTitle: "Bist Du sicher?",
    continueText: "Bildschirm verlassen, ohne zu speichern?",
    availabilityPeriods: "Verfügbarkeits Perioden",
    calendar: "Kalender",
    askMarinaPermission: "Hafengebühr darf nur inkludiert werden, wenn Du zur Untervermietung des Platzes berechtigt bist: darfst Du das?",
    berthWillBeAvailable: "Der Liegeplatz wird in 30 Minuten für andere buchbar",
    prices: "Preise",
    defaultPrice: "Standard Preis",
    tourist_tax_adult: "Taxe Hochtarif pro Nacht",
    tourist_tax_child: "Taxe Niedertarif pro Nacht",
    name: "Name",
    email: "Email",
    mobile: "Mobil Telefon",
    boatRegistrationNumber: "Schiffs-Kennzeichen",
    boatDimensions: "Schiffs-Dimensionen",
    updateSuccessful: "Update erfolgreich",
    showBoatDocuments: "Boot Dokumente",
    updateSuccessful: "Speichern erfolgreich",
  },
  Navbar: {
    home: "Home",
    account: "Benutzer-Konto",
    maintainMyBookings: "Meine Buchungen",
    findAndBookMoorings: "Finden & Buchen",
    shareMyMoorings: "Liegeplatz anbieten",
    login: "Anmelden",
    logout: "Abmelden",
    whatWeDo: "Was wir tun",
    register: "Registrieren",
    reservations: "Reservierungen",
    contacts: "Kontakte",
    moorings: "Liegeplätze",
    waitingList: "Warteliste",
    signedInAs: "Angemeldet als: ",
  },
  FindMoorings: {
    showRentedMoorings: "Zeige in App unsichtbare Plätze",
    showMarinaMap: "Zeige Hafenplan",
    showBerthNumbers: "Zeige Platznummern",
  },
  BookingOverview: {
    title: "Reservationsübersicht",
    replacing: "umplatzieren...",
    Nu: "ID",
    l: "L",
    b: "B",
    d: "T",
    areYouSureYouWantToRebook: "Sind Sie sicher, dass Sie diese Buchung umplatzieren möchten?",
    lengthLower: "Schiffs-Länge ist zu gross für den Liegeplatz",
    beamLower: "Schiffs-Breite ist zu gross für den Liegeplatz",
    draftLower: "Schiffs-Tiefgang ist zu gross für den Liegeplatz",
    weightLower: "Schiff ist zu schwer für den Liegeplatz",
    doesBoatStillFit: "Schiffs-Dimensionen Check",
    replaceNotPossible: "Umplatzieung nicht möglich. Die Buchung hat eine fortlaufende Verlängerung.",
  },
  AddBookingModal: {
    title: "Buchung hinzufügen",
    notes: "Bemerkungen",
    notesPlaceholder: "...",
    bookMooring: "Buchen",
    berth_number: "Platznummer",
    internal_paid: "Bezahlt",
  },
  BookingDetailsModal: {
    title: "Buchungsdetails",
    replace: "Reservation umplatzieren",
    internal_paid: "Bezahlt",
  },
  FilterModal: {
    title: "Filter",
    min: "min",
    max: "max",
    clear: "Zurücksetzen",
    apply: "Anwenden",
  },
  MooringStatus: {
    available: "Liegeplatz verfügbar",
    unavailable: "Liegeplatz reserviert",
    infoAvailable: "Dieser Liegeplatz ist frei und kann für eine Nacht benutzt werden.<br>Um Doppelreservationen zu vermeiden, reservieren Sie den Platz bitte <span style=\"color: red\">vor dem Festmachen</span> mit 'Sofortbuchung'.",
    infoUnavailable: "Dieser Liegeplatz ist für heute Nacht reserviert und darf nicht belegt werden.<br> Um einen freien Platz zu finden, wenden Sie sich bitte an den Hafenmeister oder laden Sie die Boatpark- App herunter, um passende Liegeplätze in Ihrer Nähe zu finden:  <a href=\"https://boatpark.app\">https://boatpark.app</a>",
    instantBooking: "Sofortbuchung",
    noInstantBooking: "Dieser Liegeplatz ist nicht verfürgar für die Sofortbuchung. Bitte buchen Sie ihn über die App oder wenden Sie sich direkt an den Hafen.",
  },
  validators: {
    required: "obligatorisch",
    invalid: "ungültig",
    priceIsRequired: "Preis obligatorisch",
    periodsShouldNotOverlap: "Perioden dürfen nicht überlappen",
    tooShort: "zu kurz",
    invalidFormat: "ungültiges Format",
    repeatedPasswordWrong: "Passwörter nicht identisch",
  },
  links: {
    website: "https://boatpark.app/de/",
    TCsLink: "https://boatpark.app/de/zuhause/wie-es-funktioniert/allgemeine-geschaeftsbedingungen-agbs/",
  },
  global: {
    save: "Speichern",
    create: "Erstellen",
    yes: "Ja",
    no: "Nein",
    ok: "Ok",
    AreYouSure: "Bist du sicher?",
    NoOrYes: ["Nein", "Ja"],
    currencys: ["CHF", "EUR", "USD", "GBP"],
    communicationLanguages: ["EN", "DE", "FR", "IT", "ES"],
    noInternetTitle: "Kein Internet",
    noInternetMessage: "Bitte verbinde dich mit dem Internet und versuche es erneut.",
    loading: "laden",
    contunie: "Weiter",
    search: "Suchen",
    edit: "Bearbeiten",
    delete: "Löschen",
    monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    pleaseSelect: "Bitte wählen",
    all: "Alle",
    year: "Jahre",
    export: "Exportieren",
    cancel: "Abbrechen",
    apply: "Apply",
    from: "Von",
    to: "Bis",
  },
  errors: {
    somethingWentWrong: "Etwas ist schief gelaufen, bitte versuchen Sie es noch einmal.",
    someValuesAreNotCorrect: "Einige Werte sind nicht korrekt",
    thisEmailIsAlreadyTaken: "Diese E-Mail wird bereits verwendet",
    clubDataUpdated: "Diese E-Mail wird bereits verwendet",
    pleaseAddAValidCreditCard: "Zahlung nicht möglich: bitte gültige Kreditkarte erfassen",
    invalidCreditCard: "Diese Kreditkarte ist ungültig. Bitte verwende eine andere Karte.",
    pleaseAddPaymentMethod: "Zahlung nicht möglich: bitte gültige Kreditkarte erfassen",
    doubleBookingsNotPossible: "Sie haben in diesem Zeitraum bereits eine Reservation: Doppelbuchungen sind nicht möglich",
    mooringWasBookedMeantime: "Liegeplatz wurde in der Zwischenzeit gebucht: bitte prüfen Sie noch einmal",
    canNotDeleteBookingtime: "Löschung des Verfügbarkeitszeitraums nicht möglich: Ihr Liegeplatz ist in diesem Zeitraum bereits gebucht",
    refertalDoesNotExist: "Eingegebene Referral e-Mail ist kein autorisierter Ambassador: bitte entfernen",
    invalidMobileNumber: "Diese Mobilnummer ist uns nicht bekannt. Bitte verwenden Sie das internationale Format, z.B. +41 79 1234567",
    emailAndPasswordDoNotMatch: "E-Mail und Passwort stimmen nicht überein",
    accountDoesNotExist: "E-Mail gesendet falls Konto existiert",
    pleaseLoginAgain: "Bitte loggen Sie sich erneut ein.",
    couldNotDeleteMooring: "Liegeplatz konnte nicht gelöscht werden: Für diesen Liegeplatz liegen Buchungen vor.",
    bookingWithoutOwnMooringNotYetPosible: "Reservation ohne Freigabe eines eigenen Platzes erst ab {{time}} möglich",
    loginFailed: "Anmelden nicht möglich, bitte überprüfe dein Email und Passwort",
    pleaseActivateMobile: "Bitte aktiviere deinen Benutzer mit dem Code in der geschickten SMS",
    pleaseActivateEmail: "Bitte bestätige deine Email-Adresse mit dem Link in der geschickten Mail",
    pleaseAcceptTCs: "Bitte akzeptiere die AGBs",
    boatparkAccountEmailDoesNotExist: "Diese E-Mail Adresse gehört zu keinem Boatpark Account",
    pleaseUseMobileApp: "Dein account ist nicht zugelassen für die WebApp. Bitte verwende die Boatpark Mobile App auf deinem Smart Phone."
  },
};

export default de;
