const it = {
  MooringDetails: {
    title: "Book Mooring",
    details: "Details",
    occupiedBy: "Mooring usually occupied by",
    taxes: "Taxes",
    perAdultPerNight: "Per persona in tariffa alta e notte",
    perChildPerNight: "Per persona in tariffa bassa e notte",
    coordinates: "Coordinates*",
    maxLength: "Max. length",
    maxBeam: "Max. beam",
    maxDraught: "Max. draft",
    lengthBeamDraft: "Length/Beam/Draft",
    berthNo: "Berth number/identification",
    marinaName: "Marina name",
    additionalDescription: "Additional Description",
    pierHeight: "Pier height (above water)",
    power: "Power available?",
    water: "Water available?",
    buoy: "Buoy place?",
    boatName: "Boat name",
    port: "Port of registry",
    from: "From",
    to: "To",
    numberOfAdults: "Numero di persone a tariffa alta",
    numberOfChildren: "Numero di persone a tariffa bassa",
    totalTouristTaxes: "Total Tourist taxes",
    excluding: "Excluding ",
    including: "Including ",
    harbourFee: "the marina fee",
    cancel: "Cancel",
    notes: "Osservazioni",
    timesAndPrices: "Times and Prices",
    bookMooring: "Book Mooring",
    edit: "Edit",
    claim: "Reclamare",
    mooringZone: "Mooring Zone",
    mooringType: "Mooring Type",
    permanentRenter: "Permanent Renter",
    shipSizeLBD: "Boat dimensions (L x W x D)",
    boatRegistrationNumber: "Boat",
    info: "Info",
    exemptedFor: "Exempted for",
    boatparkAccount: "Boatpark account",
    email: "email",
    name: "name",
    mobile: "mobile",
    marina_notes: "Marina Notes",
  },
  Login: {
    email: "Email",
    password: "Password",
    login: "Login",
    register: "Register for Boatpark account",
    forgotPassword: "Forgot password?",
    emailHasBeenSentTitle: "Email has been sent",
    emailHasBeenSent: "An email has been sent. If you do not receive it, please check your spam folder.",
    confirmEmailTitle: "Confirm your email",
    confirmEmail: "Please confirm mail address via link in e-Mail sent to you. Do you need a new e-Mail?",
  },
  ForgotPassword: {
    title: "Forgot Password",
    email: "Email",
    errorsMessage: "Something went wrong. Please try again.",
    emailHasBeenSent: "An email has been sent. If you do not receive it, please check your spam folder.",
    reset: "Reset",
    register: "Register for Boatpark account",
    weWillSendEmail: "We will send you an email with a link, where you can reset your password.",
    info: "Inserisca il suo indirizzo e-mail per ricevere un link dove potrà modificare la sua password.",
    send: "Invia",
  },
  Register: {
    title: "Registrare",
    register: "Registrare",
    roles: ["Private", "Marina"],
    communicationLanguages: ["EN", "DE", "FR", "IT", "ES"],
    communicationLanguage: "Lingua di comunicazione",
    role: "Funzione",
    iAcceptThe: "Accetto le ",
    lastName: "Cognome o società",
    firstName: "Nome",
    email: "Email",
    mobile: "Telefono mobile (ex. +41 12 345 67 89)",
    password: "Password",
    repeatPassword: "Ripetere password",
    referral: "Referenza / ambasciatore Email",
    TCs: "T&Cs",
    registerSuccess: "La registrazione ha avuto successo.",
    OptionalBoatInfo: "Info barca",
    name: "Nome della barca",
    registration_number: "Immatricolazione della barca",
    length: "Lunghezza [m]",
    beam: "Larghezza [m]",
    draft: "Pescaggio [m]",
    name: "Boat Name",
    continueAsGuest: "Continua come visitatore",
    pay: "Paga ora",
  },
  FastBooking: {
    from: "Arrivo",
    to: "Partenza",
    booking_price: "Prezzo di prenotazione",
    tax_price: "Tasse totali",
    total_price: "Prezzo totale",
    taxes: "Tassa",
    perAdultPerNight: "Per persona in tariffa alta e notte",
    perChildPerNight: "Per persona in tariffa alta e notte",
    numberOfAdults: "Numero di persone a tariffa alta",
    numberOfChildren: "Numero di persone a tariffa bassa",
    mooringUnavailable: "Questo ormeggio non è più disponibile per la prenotazione immediata. Si prega di utilizzare la Boatpark-App o di prendere un altro ormeggio.",
    LBH: "Lunghezza / Larghezza / Pescaggio",
    bookingHasBeenSaved: "La prenotazione è stata salvata. Consultate la vostra e-mail/SMS per completare la registrazione dopo aver ormeggiato in sicurezza la vostra barca. Potete anche farlo ora utilizzando ",
    thisLink: "questo link.",
    TheReservationWillBePaid: "La prenotazione sarà pagata una volta completata la registrazione.",
    YouAreAboutToBook: "State per prenotare questo posto per una notte per {{currency}} {{amount}}: vuoi continuare?",
    YourInformation: "Le vostre informazioni",
    MooringInformation: "Informazioni sull'ormeggio",
    firstname: "Firstname",
    lastname: "Lastname",
    email: "Email",
    mobile: "Mobile phone (ex. +41 12 345 67 89)",
    reserve: "Prenota",
    activationCode: "Codice attivazione",
    confirm: "Confermare",
    totalPrice: "Prezzo di prenotazione",
    additionalDescription: "Additional description",
    boat_length: "Lunghezza della barcha",
    boat_beam: "Lunghezza della barcha",
    checkInTime: "Check-In: ",
    checkOutTime: "Check-Out: ",
    boat_name: "Nome della barca",
    boat_registration_number: "Immatricolazione della barca",
    freeBookingHasBeenSaved: "La sua prenotazione è stata salvata.",
    bookNow: "Prenoti ora",
    bookingSuccessful: "L'ormeggio è stato prenotato con successo.",
    payment_method: "Metodo di pagamento",
  },
  ResetPasasword: {
    title: "Resettare la password",
    password: "New password",
    passwordRepeat: "Password (Repeat)",
    reset: "Resettare",
    paswordHasBeenReset: "La password è stata resettata",
  },
  EditContactModal: {
    editContact: "Edit Contact",
    newContact: "New Contact",
    communicationLanguages: ["EN", "DE", "FR", "IT", "ES"],
    communicationLanguage: "Communication Language",
    salutation: "Salutation",
    lastName: "Lastname or Company name",
    firstName: "First name",
    email: "Email",
    mobile: "Mobile phone (ex. +41 12 345 67 89)",
    creationSuccess: "Creation successful",
    street: "street",
    zip: "zip",
    city: "city",
    birthday: "birthday",
    phone_p: "phone private",
    phone_b: "phone work",
    alternative_email: "alternative email",
    alternative_mobile: "alternative mobile",
    type: "Type",
    access_badge: "Access badge",
    remarks: "remarks",
    vat: "vat",
    edit: "Edit",
    show: "Show",
    boats: "Boats",
    createContact: "Create Contact",
    save: "save",
    deleteContact: "Delete Contact",
    addNewBoat: "Add new boat",
    areYouSureDeleteContact: "Are you sure you wish to delete this contact?",
    boatparkAccount: "Boatpark Account",
    token: "Token",
    customer_number: "Customer Number",
    id: "ID",
    moorings: "Moorings",
    watiting_contacts: "Waiting for mooring",
  },
  ContactTable: {
    addNewContact: "Add new contact",
    firstname: "Firstname",
    lastname: "Lastname",
    email: "Email",
    mobile: "Mobile",
    street: "Street",
    communication_language: "Language",
    zip: "Zip",
    city: "City",
    birthday: "Birthday",
    type: "Type",
    access_badge: "Access badge",
    remarks: "Remarks",
    token: "Token",
    customer_number: "Customer Number",
    id: "ID",
    editOn: "Edit: on",
    editOff: "Edit: off",
  },
  EditBoatModal: {
    editBoat: "Edit Boat",
    name: "Boat Name",
    registration_number: "Registration Number",
    length: "length",
    beam: "beam",
    draft: "draft",
    type: "type",
    drive: "drive",
    weight: "weight",
    createBoat: "Create Boat",
    save: "save",
    deleteBoat: "Delete Boat",
    boatTypes: ["", "Motor", "Sail"],
    boatDrives: ["none", "gasoline", "diesel", "electric", "other",],
    documents: "Documents",
    addNewDocument: "Add new document",
    show: "Show",
    areYouSureDeleteBoat: "Are you sure you wish to delete this boat?",
    showPDF: "Show Boat Documents",
    make: "Make",
  },
  EditBoatDocumentModal: {
    editBoatDocument: "Edit document",
    createBoatDocument: "Create document",
    title: "Title",
    image: "Image",
    save: "Save",
    deleteBoatDocument: "Delete document",
    areYouSureDeleteDocument: "Are you sure you wish to delete this document?",
  },
  MaintainAssignedContacts: {
    title: "Maintain Renters",
    editRenter: "Edit Renter",
    addRenter: "Add new Renter",
    correspondence: "Correspondence",
    exemption: "Exemption",
    provisional: "Provisional",
    contact: "Contact",
    boat: "Boat",
    from: "From",
    to: "To",
    notes: "Osservazioni",
  },
  EditWaitingContactModal: {
    editReservation: "Edit entry",
    addReservation: "Add entry",
    contact: "Contact",
    entryDate: "Entry date",
    mooring_zone: "Mooring Zone",
    length: "Length",
    beam: "Beam",
    draft: "Draft",
    mooring_type: "Mooring Tye",
    annual_mooring_interested: "Interested in annual reservation",
    relocation: "Relocation",
    comment: "Comment",
    last_examination: "Last examination",
    archived: "Archived",
    archiving_reason: "Reason for archiving",
    archiving_date: "Archiving date",
    currency: "Currency",
    registration_fee: "Registration Fee",
    periodic_fee: "Periodic Fee",
    payment_period: "Payment Period [Years]",
    areYouSureDeleteEntry: "Are you sure you want to delete this entry? You can also archive it.",
    boat_type: "Boat type",
    WaitingContactShouldNotBeUnarchived: "Las entradas de la lista de espera no deben des-archivarse.",
  },
  WaitingContactTable: {
    contact_name: "Name",
    contact_address: "Address",
    contact_type: "Contact Type",
    entry_date: "Entry date",
    mooring_zone: "Mooring zone",
    mooring_type: "Mooring type",
    boat_type: "Tipo di barca",
    min_length: "Min. length",
    min_beam: "Min. beam",
    min_draught: "Min. draft",
    relocation: "Relocation",
    annual_mooring_interested: "annual mooring",
    comment: "Comment",
    archiving_reason: "Archiving reason",
    archiving_date: "Archiving date",
    registration_fee: "Registration Fee",
    periodic_fee: "Periodic Fee",
    currency: "Currency",
    payment_period: "Payment Period [Years]",
    last_examination: "Last examination",
    all: "All",
    active: "Active",
    archived: "Archived",
    status: "Status",
    editOn: "Edit: on",
    editOff: "Edit: off",
    showFilter: "Filter",
    resetFilter: "Reset",
    berth_number: "Berth number",
    dimensions: "Dimensions (L/B/D)",
    addEntry: "Add entry",
    email: "Email",
  },
  Account: {
    title: "Account",
    role: "Role",
    roles: ["Private", "Marina"],
    communicationLanguages: ["EN", "DE", "FR", "IT", "ES"],
    communicationLanguage: "Communication language",
    firstName: "First name",
    lastName: "Lastname or Company name",
    email: "Email",
    mobile: "Mobile phone (ex. +41 12 345 67 89)",
    myBoat: "My Boat",
    boatRegistrationNumber: "Boat registration number",
    boatName: "Boat name and port of registry",
    beam: "Beam [m]",
    length: "length [m]",
    draft: "draft [m]",
    boatDocumentScans: "Boat documents (Scans)",
    additionalInformation: "Additional Information",
    reimbursementBankAccount: "Reimbursement Bank Account",
    paymentmethod: "Payment Method",
    vat: "VAT number (if relevant)",
    changePassword: "Change Password",
    password: "New password",
    passwordRepeat: "Password (Repeat)",
    balance: "Balance",
    success: "Updated Account Data",
    types: "Types",
    mooringTypes: "Mooring Types",
    addMooringType: "Add Mooring Type",
    mooringZones: "Mooring Zones",
    addMooringZone: "Add Mooring Zone",
    contactTypes: "Contact Types",
    addContactType: "Add Contact Types",
    default_currency: "Default Currency",
    default_periodic_fee: "Default periodic fee",
    default_registration_fee: "Default registration fee",
    default_payment_period: "Default payment period",
    manual_booking_confirmation: "Manual booking confirmation",
    automatic_response: "automatic response",
    automatic_response_after: "automatic response after",
    accept: "Accept",
    decline: "Decline",
    salutation: "Saluto",
    street: "Via",
    zip: "CAP",
    city: "Città",
    country: "Paese",
    bankAccountData: "Conto bancario per rimborso",
    currency: "Valuta",
    iban: "IBAN",
    owner_name: "Nome del proprietario",
    confirmation_emails_own: "Conferme via e-mail per le proprie prenotazioni",
    settings: "Impostazioni",
    csv_separator: "Separatore CSV",
    email_cc: "Copia e-mail a",
    email_admin_cc: "Copia e-mail di regolamento a",
    max_nights_per_year: "Max. numero di notti all'anno e ospite",
  },
  ActivateAccount: {
    title: "Attivare il conto",
    activate: "Attivare",
    activationCode: "Codice attivazione",
    activationText: "Prima di attivare e tornare alla schermata di login, confermate il vostro e-mail cliccando sul link inviato al vostro conto e-mail",
    resendSMS: "Reinviare SMS con codice attivazione",
    activationFailed: "Errore di attivazione",
    tryAgain: "Si prega di riprovare",
    smsHasBeenSentTitle: "È stato inviato un SMS",
    smsHasBeenSent: "È stato inviato un SMS. Se non lo ricevete, contattate il supporto",
    mobileInfo: "Ti abbiamo inviato un SMS con il codice di attivazione. Si prega di inserire il codice qui: ",
    emailInfo: "Hai ricevuto un'e-mail da noi. Per favore, clicca il link nell'e-mail per completare la registrazione.",
  },
  MaintainMoorings: {
    title: "Maintain Moorings",
    dailyPrice: "daily price",
    delete: "Delete Mooring",
    edit: "Edit",
    show: "Show",
    times: "Times",
    info: "Info",
    book: "Book",
    addNewMooring: "Add new mooring",
    doYouReallyWantToDeleteMooring: "Do you really want to delete this mooring?",
  },
  MooringTable: {
    berth_number: "Berth number",
    harbour_name: "Marina",
    permanent_renter_names: "Permanent Renter",
    exemption_renter_names: "Exempted for",
    renter_from_date: "Rent from",
    renter_to_date: "Rent to",
    max_length: "Max. length",
    max_beam: "Max. beam",
    max_draught: "Max. draft",
    max_weight: "Max. weight",
    pier_height: "Pier height",
    power: "Power ",
    water: "Water",
    buoy: "Buoy",
    mooring_type: "Mooring type",
    mooring_zone: "Mooring zone",
    marina_notes: "Marina notes",
    app_visible: "App visible",
    additional_description: "Additional Description",
    confidential_description: "Confidential Description",
    editOn: "Edit: on",
    editOff: "Edit: off",
    operating_cost: "Operating Cost",
    yearly_rate: "Yearly Rate",
    payment_currency: "Currency",
    daily_rate_default: "Price/Night",
    mass_edit: "Cambia codice",
    confidential_code_old: "Codice confidenziale attuale",
    confidential_code_new: "Nuovo codice confidenziale",
  },
  AddMooringMap: {
    title: "Add Mooring",
    whereIs: "Where is your mooring?",
    zoomIn: "Zoom-in to your marina and indicate your mooring berth by " + "touching and holding a place on the map.",
    apply: "Create mooring",
    desiredLocation: "Desired mooring location?",
  },
  EditMooring: {
    details: "Details",
    occupiedBy: "Mooring usually occupied by",
    coordinates: "Coordinates",
    appVisible: "Visible in app",
    maxLength: "Max. length",
    maxBeam: "Max. beam",
    maxDraught: "Max. draft",
    maxWeight: "Max. weight",
    real_length: "LUNGHEZZA EFFETTIVA",
    real_beam: "LARGHEZZA EFFETTIVA",
    berthNo: "Berth number/identification",
    harbourName: "Marina name",
    additionalDescription: "Additional description",
    confidentialDescription: "Confidential description",
    pierHeight: "Pier height (above water)",
    power: "Power available?",
    water: "Water available?",
    buoy: "Buoy place?",
    boatName: "Boat name",
    port: "Port of registry",
    create: "save",
    additionalDescriptionPlaceholder: "Enter here the link to the port homepage and further information about the berth, which is available to all users.",
    confidentialDescriptionPlaceholder: "Enter additional confidential information, which will only be sent to the user in case of a reservation (e.g. secret code for access to the port facility).",
    marinaNotes: "Marina Notes",
    mooringType: "Mooring Type",
    mooringZone: "Mooring Zone",
    availabilities: "availabilities",
    settings: "Settings",
    maxNights: "MAX. NUMERO DI NOTTI",
    maxNightsPerYear: "MAX. NOTTI ALL'ANNO",
    finance: "Finance",
    yearlyRates: "Yearly Rates",
    transferMooring: "Transfer Mooring",
    transfer: "Transfer",
    email: "Email",
    operatingCosts: "Operating Costs",
    manualConfirmation: "Manual Confirmation",
    areYouSureDeleteMooring: "Are you sure you wish to delete this mooring?",
    AreaBlockedTitle: "Area bloccata",
    AreaBlocked: "Quest'area è bloccata. Se avete un ormeggio qui, contattate {{contatto_email}} per farvelo assegnare.",
    country: "Paese",
  },
  TransferMooring: {
    modalTitle: "Il conto Boatpark non esiste",
    modalBody: "Non esiste un account Boatpark con questa e-mail. Vuoi inviare un'email di invito per registrarti a Boatpark? Il posto barca verrà trasferito automaticamente dopo la registrazione.",
    emailLanguage: "Lingua delle e-mail",
    sendEmail: "Inviare l'invito",
  },
  MaintainTimesAndPrices: {
    title: "Maintain Times and Prices",
    feeIncluded: "Marina fee included?",
    currency: "Currency",
    from: "From",
    to: "To",
    pricePerNight: "Price/night",
    addNext: "Add next",
    addNewPeriod: "Add new period",
    dayByDayInfoTitle: "Day-by-day availability",
    dayByDayInfo: "If this field is marked, the mooring can only be booked on same day and only for one night (no advance booking). This allows the mooring owner to cancel the bookable period on a daily basis and make the mooring available for himself in case of earlier return.",
    continueTitle: "Are you sure?",
    continueText: "Do you really want to leave this screen without saving?",
    availabilityPeriods: "Availability Periods",
    calendar: "Calendar",
    askMarinaPermission: "Marina fees may only be included if you are entitled to sublet the mooring: are you allowed to do so?",
    berthWillBeAvailable: "Berth will be released in 30 minutes",
    prices: "Prices",
    defaultPrice: "Default Price",
    tourist_tax_adult: "Tariffa alta per notte",
    tourist_tax_child: "Tariffa bassa per notte",
    name: "Name",
    email: "Email",
    mobile: "Mobile",
    boatRegistrationNumber: "Boat registration number",
    boatDimensions: "Boat dimensions",
    updateSuccessful: "Update successful",
    showBoatDocuments: "Show Boat Documents",
    boatDocuments: "Boat Documents"
  },
  Navbar: {
    home: "Home",
    account: "Conto",
    maintainMyBookings: "Le mie prenotazioni",
    findAndBookMoorings: "Trovare & prenotare",
    shareMyMoorings: "Condividere ormeggio",
    login: "Login",
    logout: "Logout",
    whatWeDo: "Cosa facciamo",
    register: "Registrazione",
    reservations: "Prenotazioni",
    contacts: "Contatti",
    moorings: "Moorings",
    waitingList: "Lista d'attesa",
    signedInAs: "Signed in as: ",
  },
  FindMoorings: {
    showRentedMoorings: "Mostra ormeggi invisibili nell'app",
    showMarinaMap: "Show marina Map",
    showBerthNumbers: "Show berth numbers",
  },
  BookingOverview: {
    title: "Panoramica della prenotazione",
    replacing: "sostituendo...",
    Nu: "ID",
    l: "La",
    b: "Lu",
    d: "Pe",
    areYouSureYouWantToRebook: "Vuoi davvero riposizionare questa prenotazione?",
    lengthLower: "Barca lunghezza è troppo grande per l'ormeggio",
    beamLower: "Barca larghezza è troppo grande per l'ormeggio",
    draftLower: "Barca pescaggio è troppo grande per l'ormeggio",
    weightLower: "Barca è troppo pesante per l'ormeggio",
    doesBoatStillFit: "Check dimensioni della barca",
    replaceNotPossible: "Cambio di posto non è possibile. La prenotazione ha un'estensione continua.",
  },
  AddBookingModal: {
    title: "Aggiungi prenotazione",
    notes: "Osservazioni",
    notesPlaceholder: "...",
    bookMooring: "Prenota Ormeggio",
    berth_number: "Berth Number",
    internal_paid: "Pagato",
  },
  BookingDetailsModal: {
    title: "Dettagli della prenotazione",
    replace: "Rimpiazzare la prenotazione",
    internal_paid: "Pagato",
  },
  FilterModal: {
    title: "Filtro",
    min: "Min",
    max: "Max",
    clear: "Ripristinare",
    apply: "Applicare",
  },
  MooringStatus: {
    available: "Posto barca disponibile",
    unavailable: "Posto barca occupato",
    infoAvailable: "Questo ormeggio è disponibile e può essere utilizzato per una notte.<br>Per evitare doppie prenotazioni, si prega di prenotare l'ormeggio prima di ormeggiare la sua barca con 'Prenotazione immediata'.",
    infoUnavailable: "L’ormeggio è riservato per questa notte e non può essere occupato.<br>Per trovare un posto barca disponibile si prega di contattare la capitaneria di porto o utilizzare l’applicazione Boatpark: <a href=\"https://boatpark.app\">https://boatpark.app</a>",
    instantBooking: "Prenotazione immediata",
    // noInstantBooking: "hhhhhhhh",
  },
  validators: {
    required: "required",
    invalid: "invalid",
    priceIsRequired: "price is required",
    periodsShouldNotOverlap: "periods should not overlap",
    tooShort: "too short",
    invalidFormat: "invalid format",
    repeatedPasswordWrong: "passwords do not match",
  },
  links: {
    website: "https://boatpark.app/it/",
    TCsLink: "https://boatpark.app/it/casa/come-funziona/termini-e-condizioni-tc/",
  },
  global: {
    save: "Save",
    create: "Create",
    yes: "Yes",
    no: "No",
    ok: "Ok",
    AreYouSure: "Are you sure?",
    NoOrYes: ["No", "Yes"],
    currencys: ["CHF", "EUR", "USD", "GBP"],
    communicationLanguages: ["EN", "DE", "FR", "IT", "ES"],
    noInternetTitle: "No Internet",
    noInternetMessage: "Please connect to the internet and try again",
    loading: "loading",
    contunie: "Continue",
    search: "Search",
    edit: "edit",
    delete: "delete",
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    pleaseSelect: "Please select",
    all: "All",
    year: "Year",
    export: "export",
    cancel: "Annullare",
    apply: "Applicare",
    from: "Da",
    to: "a",
  },
  errors: {
    somethingWentWrong: "Qualcosa è andato storto, per favore riprova.",
    someValuesAreNotCorrect: "Alcuni valori non sono corretti",
    thisEmailIsAlreadyTaken: "Questa e-mail è già utilizzata",
    clubDataUpdated: "Questa e-mail è già utilizzata",
    pleaseAddAValidCreditCard: "Pagamento non possibile: inserire una carta di credito valida",
    invalidCreditCard: "Questa carta di credito non è valida. Si prega di utilizzare un'altra carta.",
    pleaseAddPaymentMethod: "Pagamento non possibile: inserire una carta di credito valida",
    doubleBookingsNotPossible: "Avete già una prenotazione in questo lasso di tempo: Le prenotazioni doppie non sono possibili.",
    mooringWasBookedMeantime: "L'ormeggio è stato prenotato nel frattempo: si prega di controllare di nuovo",
    canNotDeleteBookingtime: "Non è possibile cancellare il periodo di disponibilità: il vostro ormeggio è già prenotato durante questo periodo",
    refertalDoesNotExist: "L'e-mail di riferimento inserita non è un ambasciatore autorizzato: si prega di rimuovere",
    invalidMobileNumber: "Non riconosciamo questo numero di cellulare. Si prega di utilizzare il formato internazionale, ad es. +41 79 1234567",
    emailAndPasswordDoNotMatch: "E-mail e password non corrispondono",
    accountDoesNotExist: "E-mail inviata se l'account esiste",
    pleaseLoginAgain: "Effettuare nuovamente il login.",
    couldNotDeleteMooring: "Non è stato possibile cancellare l'ormeggio: esistono prenotazioni per questo ormeggio.",
    bookingWithoutOwnMooringNotYetPosible: "Prenotazione senza condividere proprio posto possibile solo a partire dalle ore {{time}}",
    loginFailed: "Il login non è successo, si prega di rivedere la tua email e la password",
    pleaseActivateMobile: "Attiva il tuo account con il codice dell'SMS",
    pleaseActivateEmail: "Per favore, verifica il tuo indirizzo email usando il link che ti abbiamo inviato",
    pleaseAcceptTCs: "Si prega di accettare i termini e le condizioni",
    boatparkAccountEmailDoesNotExist: "Questo indirizzo email non appartiene a nessun conto Boatpark",
    pleaseUseMobileApp: "Il tuo account non è approvato per la web app. Si prega di utilizzare l'applicazione Boatpark Mobile App sul tuo telefono cellulare.",
  },
};

export default it;
