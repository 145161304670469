import React from "react";
import axios from "axios";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useLocation } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./stylesheets/bootstrap_finetuning.css";
import "./stylesheets/mobile_elements.css";

import MainNavbar from "./components/MainNavbar";

import Register from "./screens/Register";
import MobileVerification from "./screens/MobileVerification";
import Account from "./screens/Account";
import Login from "./screens/Login";
import Home from "./screens/Home";
import Mooring from "./screens/Mooring";
import MooringTable from "./screens/MooringTable";
import MaintainTimesAndPrices from "./screens/MaintainTimesAndPrices";
import MaintainAssignedContacts from "./screens/MaintainAssignedContacts";
import ContactTable from "./screens/ContactTable";
import Bookings from "./screens/Bookings";
import WaitingContactsTable from "./screens/WaitingContactsTable";
import FastBooking from "./screens/FastBooking";
import MooringStatus from "./screens/MooringStatus";
import ResendEmail from "./screens/ResendEmail";
import ForgotPassword from "./screens/ForgotPassword";
import ResetPassword from "./screens/ResetPassword";

import "./i18n";
import { useTranslation } from "react-i18next";

// Routes where no login is required
const routesWithoutRequiredLogin = ['login', 'register', 'activate', 'FastBooking', 'MooringStatus', 'ResendEmail', 'ResetPassword']

//Routes where navbar is not shown
const routesWithoutNavBar = ['/register/complete', 'FastBooking', "MooringStatus", 'ResendEmail', 'ResetPassword'];

function App() {
  axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

  //If user is logged in, retreive token
  const token = localStorage.getItem("AuthToken");
  if (token) {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
  }


  const currentRoute = String(window.location.pathname).substring(1)
  axios.interceptors.response.use(
    function (response) { return response; },
    function (error) {
      // console.log("AXIOS ERROR response", error.response.status)
      // handle error
      if (error) {
        //401 means invalid login session
        if (error.response.status == 401) {
          console.log("login session dead");
          axios.defaults.headers.common = {
            Authorization: ``,
          };
          localStorage.removeItem("AuthToken");
          // Go to login screen if we are logged out and not an a page without requred login
          if (!routesWithoutRequiredLogin.includes(currentRoute)) {
            window.location.href = '/login';
          }
        }
      }
      return Promise.reject(error);
    });

  const { t } = useTranslation();


  //Routes where navbar is not shown
  let hideNavBar = false;
  routesWithoutNavBar.forEach(routeWithoutNavBar => {
    if (String(window.location).includes(routeWithoutNavBar)) hideNavBar = true;
  });

  return (
    <Router>
      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
      {!hideNavBar && <MainNavbar />}
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/activate/:id" component={MobileVerification} />
        <Route path="/register/token/:mooring_transfer_token/:email?" component={Register} />
        <Route path="/register/complete/:account_token/" component={Register} />
        <Route path="/register/:args?" component={Register} />
        <Route path="/register" component={Register} test="ehhlo" t={t} />
        <Route path="/account" component={Account} />
        {/* <Route path="/home/:id?" component={Home} /> */}
        <Route path="/home/:action?/:id?" component={Home} />
        <Route path="/MooringTable" component={MooringTable} />
        <Route path="/mooring/:id" component={Mooring} />
        <Route path="/maintainTimesAndPrices/:id" component={MaintainTimesAndPrices} />
        {/* <Route path="/Contact/:id?" component={EditContact} /> */}
        <Route path="/ContactTable" component={ContactTable} />
        <Route path="/Bookings" component={Bookings} />
        <Route path="/Renters/:id" component={MaintainAssignedContacts} />
        <Route path="/WaitingContactsTable/" component={WaitingContactsTable} />
        <Route path="/FastBooking/:mooring_id" component={FastBooking} />
        <Route path="/MooringStatus/:mooring_id" component={MooringStatus} />
        <Route path="/ResendEmail/:account_token" component={ResendEmail} />
        <Route path="/ForgotPassword/:email?" component={ForgotPassword} />
        <Route path="/ResetPassword/:token" component={ResetPassword} />
        <Route path="/">
          <Redirect to="/home" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
