import axios from "axios";

export const getBoatsByContactId = (contact_id, callback, errorcallback) => {
  axios
    .get(`/boats/get_by_contact_id?contact_id=${contact_id}`)
    .then((response) => {
      if (response.status == 200) {
        callback(response.data);
      }
    })
    .catch((err) => {
      errorcallback(err);
    });
};


export function createBoat(boat, callback, errorCallback) {
  axios
    .post("/boats/add_boat", boat)
    .then((res) => {
      const response = res.data;
      callback(response);
    })
    .catch((error) => {
      errorCallback(error);
    });
}


export function updateBoat(boat, callback, errorCallback) {
  axios
    .post("/boats/update_boat", boat)
    .then((res) => {
      const response = res.data;
      callback(response);
    })
    .catch((error) => {
      errorCallback(error);
    });
}

export function deleteBoat(id, callback, errorCallback) {
  axios
    .post("/boats/delete_boat", { boat_id: id })
    .then((res) => {
      const response = res.data;
      callback(response);
    })
    .catch((error) => {
      errorCallback(error);
    });
}
