/* eslint-disable no-undef */
/*global google*/

// import { withScriptjs, withGoogleMap, GoogleMap, Marker, GroundOverlay } from "react-google-maps";
import React, { useState, useEffect } from "react";
import { getBoundsForMarina } from "../actions/moorings";

import { GoogleMap, Marker, GroundOverlay, useJsApiLoader } from '@react-google-maps/api';

const greenPin = {
    url: 'https://mt.google.com/vt/icon?psize=30&font=fonts/arialuni_t.ttf&color=ff304C13&name=icons/spotlight/spotlight-waypoint-a.png&ax=43&ay=48&text=%E2%80%A2', // url
};
const redPin = {
    url: 'https://mt.googleapis.com/vt/icon/name=icons/spotlight/spotlight-poi.png&scale=1', // url
};
const bluePin = {
    url: 'https://mt.google.com/vt/icon?psize=20&font=fonts/Roboto-Regular.ttf&color=ff330000&name=icons/spotlight/spotlight-waypoint-blue.png&ax=44&ay=48&scale=1&text=%E2%80%A2', // url
};
const whitePin = {
    url: "https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_white.png"
}
const greyPin = {
    url: "https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_grey.png"
}
const greenPinC = {
    url: 'https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_greenC.png'
};
const redPinC = {
    url: 'https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_redC.png'
};
const bluePinC = {
    url: 'https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_blueC.png'
};


const specialPin = {
    // url: 'https://raw.githubusercontent.com/Concept211/Google-Maps-Markers/master/images/marker_blackA.png'
    url: 'https://mt.google.com/vt/icon/name=icons/spotlight/spotlight-ad.png'
    // url: 'https://mt.google.com/vt/icon?psize=20&font=fonts/Roboto-Regular.ttf&color=ff330000&name=icons/spotlight/spotlight-waypoint-blue.png&ax=44&ay=48&scale=1&text=D', // url

}





const getPinIcon = (mooring) => {
    var icon = redPin;

    if (mooring.app_visible == 1) { // mooring oublically visible
        if (mooring.availability_status == "available") icon = greenPin;
        if (mooring.availability_status == "partially_available") icon = bluePin;
        if (mooring.availability_status == "available_day_by_day") icon = bluePin;
        if (mooring.availability_status == "unavailable") icon = redPin;
        if (mooring.availability_status == "not_released") icon = greyPin;

        if (mooring.claimable) {
            if (mooring.availability_status == "available") icon = greenPinC;
            if (mooring.availability_status == "partially_available") icon = bluePinC;
            if (mooring.availability_status == "available_day_by_day") icon = bluePinC;
            if (mooring.availability_status == "unavailable") icon = redPinC;
        }


    }
    else { // Mooring not app visible
        icon = mooring.rent ? whitePin : specialPin
    }

    return icon
}


export function MooringsMap(props) {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    })

    const [map, setMap] = React.useState(null)
    const [windowSize, setWindowSize] = React.useState([
        window.innerWidth,
        window.innerHeight,
    ]);



    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);


    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!

        getBoundsForMarina(
            (marina_bounds) => {
                var { min_lat, min_lng, max_lat, max_lng } = marina_bounds

                var bounds = new window.google.maps.LatLngBounds();
                var min = new window.google.maps.LatLng(min_lat, min_lng);
                var max = new window.google.maps.LatLng(max_lat, max_lng);
                bounds.extend(min);
                bounds.extend(max);
                map.fitBounds(bounds);

            },
            (error) => { }
        )


        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const onDragEnd = () => {

        if (!map || !isLoaded) return
        let ne = map.getBounds().getNorthEast();
        let sw = map.getBounds().getSouthWest();
        const bounds = {
            min_lng: sw.lng(),
            min_lat: sw.lat(),
            max_lng: ne.lng(),
            max_lat: ne.lat(),
        }
        props.setBounds(bounds)
    }


    const googleMapOptions = {
        streetViewControl: false,
        draggable: true, // make map draggable
        zoomControlOptions: { position: 9 },
        keyboardShortcuts: false, // disable keyboard shortcuts
        scaleControl: true, // allow scale controle
        scrollwheel: true, // allow scroll wheel
        mapTypeId: 'satellite',
    }


    const containerStyle = {
        width: '100%',
        height: windowSize[1] - 289
    };

    const { allMoorings, filter, add_mooring_position, map_overlays } = props


    return isLoaded ? (
        < div id="google_map_container" style={{ flex: 1, }} >
            <GoogleMap
                mapContainerStyle={containerStyle}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={googleMapOptions}
                tilt={0}
                onClick={props.onClick}
                onDragEnd={onDragEnd}
                onZoomChanged={onDragEnd}
            >
                { /* Child components, such as markers, info windows, etc. */}
                <></>

                {Array.isArray(allMoorings) && allMoorings.map((mooring) =>
                    <Marker
                        label={filter.show_berth_numbers ? (mooring.mooring_id % 5 == 0) ? map.zoom >= 20 ? { className: "labelClass", color: '#00aaff', fontWeight: 'bold', fontSize: '14px', text: mooring.berth_number } : "" : "" : ""}
                        title={mooring.berth_number}
                        key={mooring.mooring_id}
                        position={{ lat: parseFloat(mooring.latitude), lng: parseFloat(mooring.longitude) }}
                        icon={getPinIcon(mooring)}
                        onClick={() => props.onPinClick(mooring)}
                    />)
                }

                {filter.show_map_overlays &&
                    map_overlays.map((map_overlay, index) =>
                        < GroundOverlay
                            key={index}
                            bounds={{
                                north: map_overlay.lat2,
                                south: map_overlay.lat1,
                                east: map_overlay.lng2,
                                west: map_overlay.lng1,
                            }}
                            url={map_overlay.url}
                        />
                    )
                }



                {add_mooring_position &&
                    <Marker
                        key={0}
                        position={{ lat: parseFloat(add_mooring_position.latitude), lng: parseFloat(add_mooring_position.longitude) }}
                        icon={
                            redPin
                        }
                    />
                }


            </GoogleMap>
        </div >
    ) : <></>

}


