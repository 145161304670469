import React from "react";
import { withTranslation } from 'react-i18next';

import { sendForgotPasswordLink } from "../actions/users";

import { Container, FormControl, Form, Button, Alert, Spinner } from "react-bootstrap";
import FormInput from '../components/FormInput'

import AlertModal from "../components/AlertModal";
import StatusAlerts from "../components/StatusAlerts";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loading: false,
      success: false,
      error: false,
    };
    this.AlertModal = React.createRef();

  }

  componentDidMount() {
    let email = this.props.match?.params?.email;
    if (email) {
      this.setState({ email: email });
    }
  }

  handleSend = () => {
    this.setState({ loading: true, success: false, error: false }, () => {
      sendForgotPasswordLink(this.state.email,
        (response) => { this.setState({ loading: false, success: true, error: false, successMessage: this.props.t('ForgotPassword.emailHasBeenSent') }) },
        (error) => { this.setState({ loading: false, success: false, error: true }) })
    })
  }


  render() {
    const { t } = this.props
    return (
      <Container style={{ maxWidth: 500, marginTop: 100, borderRadius: 5 }}>
        <div style={{ marginBottom: 10 }}>
          <h1>{t(`ForgotPassword.title`)}</h1>
          {t(`ForgotPassword.info`)}
        </div>
        <AlertModal onRef={(ref) => this.AlertModal = ref} />
        <FormInput
          id="input_email"
          label={t('ForgotPassword.email')}
          value={this.state.email}
          onChange={(e) => {
            this.setState({ email: e.target.value })
          }} />
        <Button
          id="btnLogin"
          variant="primary"
          onClick={() => this.handleSend()}
          disabled={this.state.loading}
        >
          {t('ForgotPassword.send')}

          {this.state.loading && <Spinner style={{ width: 20, height: 20, marginLeft: 10 }} animation="border" role="status" />}
        </Button>
        <StatusAlerts state={this.state} />


      </Container >
    );
  }
}


export default withTranslation()(ForgotPassword);