const fr = {
  MooringDetails: {
    title: "Réservation amarrage",
    details: "Détails",
    occupiedBy: "Amarrage habituellement occupé par",
    taxes: "Taxe",
    perAdultPerNight: "Par personne en haut tarif et par nuit",
    perChildPerNight: "Par personne en bas tarif et par nuit",
    coordinates: "Coordonnées",
    maxLength: "Max. longueur",
    maxBeam: "Max. largeur",
    maxDraught: "Max. tirant d’eau",
    lengthBeamDraft: "longueur/largeur/tirant d’eau",
    berthNo: "No. couchette/identification",
    marinaName: "Nom de la marina",
    additionalDescription: "Description complémentaire",
    pierHeight: "Hauteur quai (au-dessus l’eau)",
    power: "Electricité disponible?",
    water: "Eau disponible?",
    buoy: "Poste de bouée?",
    boatName: "Nom du bateau",
    port: "Port d'immatriculation",
    from: "Arrivée",
    to: "Départ",
    numberOfAdults: "Nombre de personnes haut tarif",
    numberOfChildren: "Nombre de personnes bas tarif",
    totalTouristTaxes: "Total des taxes",
    excluding: "Excluant ",
    including: "Incluant ",
    harbourFee: "frais de marina",
    cancel: "Annuler",
    notes: "Remarques",
    timesAndPrices: "Temps et prix",
    bookMooring: "Book Mooring",
    edit: "Editer",
    claim: "Réclamer",
    mooringZone: "Zone d'amarrage",
    mooringType: "Type d'amarrage",
    permanentRenter: "Permanent Renter",
    shipSizeLBD: "Dimensions bateau (Lon x Lar x T)",
    boatRegistrationNumber: "Bateau",
    info: "Info",
    exemptedFor: "Exempté pour",
    boatparkAccount: "Boatpark compte",
    email: "email",
    name: "Nom",
    mobile: "Téléphone mobile",
    marina_notes: "Notes marina",
  },
  Login: {
    email: "Email",
    password: "Mot de passe",
    title: "Login",
    register: "Créer un nouveau compte Boatpark",
    forgotPassword: "Oublié mot de passe",
    emailHasBeenSentTitle: "Un e-mail a été envoyé",
    emailHasBeenSent: "Un e-mail a été envoyé. Si vous ne le recevez pas, veuillez vérifier votre spam.",
    confirmEmailTitle: "Confirmez votre e-mail",
    confirmEmail: "Confirmez l'adresse par le lien dans l'e-mail qui vous a été envoyé. Besoin d'un nouveau e-mail?",
  },
  ForgotPassword: {
    title: "Oublié mot de passe?",
    email: "Email",
    errorsMessage: "Un problème est survenu. Veuillez réessayer.",
    emailHasBeenSent: "Un e-mail a été envoyé. Si vous ne le recevez pas, veuillez vérifier votre spam.",
    reset: "Reset",
    register: "Créer un nouveau compte Boatpark",
    weWillSendEmail: "Nous vous envoyons un e-mail avec un lien, où vous pouvez réinitialiser votre mot de passe.",
    info: "Entrez votre courriel pour recevoir un lien vous permettant de modifier votre mot de passe.",
    send: "Envoyer",
  },
  Register: {
    title: "Enregistrer",
    register: "Modifier",
    roles: ["Private", "Marina"],
    communicationLanguages: ["EN", "DE", "FR", "IT", "ES"],
    communicationLanguage: "Langue de communication",
    role: "ROLE",
    iAcceptThe: "J'accepte les ",
    lastName: "Nom de famille ou société",
    firstName: "Prénom",
    email: "Email",
    mobile: "Téléphone mobile (ex. +41 12 345 67 89)",
    password: "Mot de passe",
    repeatPassword: "Répéter mot de passe",
    referral: "Référence / ambassadeur Email",
    TCs: "CGVs",
    registerSuccess: "Enregistrement réussi",
    OptionalBoatInfo: "Informations bateau",
    name: "Nom du bateau",
    registration_number: "No. immatriculation",
    length: "Longueur",
    beam: "Largeur",
    draft: "Tirant d'eau",
    continueAsGuest: "Continuer comme visiteur",
    pay: "Payer maintenant",
  },
  FastBooking: {
    from: "Arrivée",
    to: "Départ",
    booking_price: "Prix de la réservation",
    tax_price: "Total des taxes",
    total_price: "Prix totale",
    taxes: "Taxe",
    perAdultPerNight: "Par personne en haut tarif et par nuit",
    perChildPerNight: "Par personne en bas tarif et par nuit",
    numberOfAdults: "Nombre de personnes haut tarif",
    numberOfChildren: "Nombre de personnes bas tarif",
    mooringUnavailable: "Cet amarrage n'est plus disponible pour une réservation immédiate. Veuillez utiliser l'application Boatpark ou prendre un autre amarrage.",
    LBH: "Longueur / Largeur / Tirant d'eau",
    bookingHasBeenSaved: "Votre réservation a été enregistrée. Consultez votre Email/SMS pour compléter votre enregistrement après avoir amarré votre bateau en toute sécurité. Vous pouvez également le faire maintenant en utilisant ",
    thisLink: "ce lien.",
    TheReservationWillBePaid: "La réservation sera payée à la fin de votre enregistrement.",
    YouAreAboutToBook: "Vous êtes en train de réserver cette place pour une nuit au prix de {{currency}} {{amount}}: voulez-vous continuer ?",
    YourInformation: "Vos informations",
    MooringInformation: "Informations sur la place",
    firstname: "Prénom",
    lastname: "Nom de famille",
    email: "Email",
    mobile: "Téléphone mobile (ex. +41 12 345 67 89)",
    reserve: "Réserver",
    activationCode: "Code d'activation",
    confirm: "Confirmer",
    totalPrice: "Prix totale",
    additionalDescription: "Description complémentaire",
    boat_length: "Longueur du bateau",
    boat_beam: "Largeur du bateau",
    checkInTime: "Check-In: ",
    checkOutTime: "Check-Out: ",
    boat_name: "Nom du bateau",
    boat_registration_number: "No. immatriculation",
    freeBookingHasBeenSaved: "Votre réservation a été enregistrée.",
    bookNow: "Réservez maintenant",
    bookingSuccessful: "L'amarrage a été réservé avec succès.",
    payment_method: "Mode de paiement",
  },
  ResetPasasword: {
    title: "Réinitialiser le mot de passe",
    password: "Nouveau mot de passe",
    passwordRepeat: "Mot de passe (Répétition)",
    reset: "Réinitialiser",
    paswordHasBeenReset: "Le mot de passe a été réinitialisé",
  },
  EditContactModal: {
    editContact: "Editer contact",
    newContact: "contact",
    communicationLanguages: ["EN", "DE", "FR", "IT", "ES"],
    communicationLanguage: "Langue de communication",
    salutation: "Appel",
    lastName: "Nom de famille ou société",
    firstName: "Prénom",
    email: "Email",
    mobile: "Téléphone mobile (ex. +41 12 345 67 89)",
    creationSuccess: "Creation successful",
    street: "Rue",
    zip: "ZIP",
    city: "Ville",
    birthday: "Date de naissance",
    phone_p: "Téléphone privé",
    phone_b: "Téléphone bureau",
    alternative_email: "E-mail alternatif",
    alternative_mobile: "Téléphone mobile alternatif",
    type: "Type",
    access_badge: "Badge d'accès",
    remarks: "Commentaires",
    vat: "Numéro TVA ",
    edit: "Edit",
    show: "Montrer",
    boats: "Bateaux",
    createContact: "Ajouter contact",
    save: "Sauvegarder",
    deleteContact: "Eliminer contact",
    addNewBoat: "Ajouter bateau",
    areYouSureDeleteContact: "Vous voulez vraiment éliminer ce contact?",
    boatparkAccount: "Boatpark compte",
    token: "Token",
    customer_number: "Numéro débiteur",
    id: "ID",
    moorings: "Liste d'attente",

  },
  ContactTable: {
    addNewContact: "Ajouter contact",
    firstname: "Prénom",
    lastname: "Nom de famille",
    email: "Email",
    mobile: "Mobile",
    street: "Rue",
    communication_language: "Langue",
    zip: "Zip",
    city: "Ville",
    birthday: "Date de naissance",
    type: "Type",
    access_badge: "Badge d'accès",
    remarks: "Commentaires",
    token: "Token",
    customer_number: "Numéro débiteur",
    id: "ID",
    editOn: "Edit: on",
    editOff: "Edit: off",
  },
  EditBoatModal: {
    editBoat: "Editer bateau",
    name: "Nom du bateau",
    registration_number: "No. immatriculation",
    length: "Longueur",
    beam: "Largeur",
    draft: "Tirant d'eau",
    type: "type",
    drive: "Type de puissance",
    weight: "Poids",
    createBoat: "Ajouter bateau",
    save: "Sauvegarder",
    deleteBoat: "Eliminer",
    boatTypes: ["", "Moteur", "Voile"],
    boatDrives: ["Aucun", "Essence", "Diesel", "Électrique", "Autre"],
    documents: "Documents",
    addNewDocument: "Ajouter document",
    show: "Montrer",
    areYouSureDeleteBoat: "Vous voulez vraiment éliminer ce bateau?",
    showPDF: "Afficher documents bateau",
    make: "Type",
  },
  EditBoatDocumentModal: {
    editBoatDocument: "Editer document",
    createBoatDocument: "Ajouter document",
    title: "Titre",
    image: "Image",
    save: "Sauvegarder",
    deleteBoatDocument: "Eliminer document",
    areYouSureDeleteDocument: "Vous voulez vraiment éliminer ce document?",
  },
  MaintainAssignedContacts: {
    title: "Maintenir locataire",
    editRenter: "Editer locataire",
    addRenter: "Ajouter locataire",
    correspondence: "Correspondance",
    exemption: "Exemption",
    provisional: "Provisoire",
    contact: "Contact",
    boat: "Bateau",
    from: "De",
    to: "Jusqu'à",
    notes: "Remarques",
  },
  EditWaitingContactModal: {
    editReservation: "Edit",
    addReservation: "Ajouter",
    contact: "Contact",
    entryDate: "Date d'entrée",
    mooring_zone: "Zone d'amarrage",
    length: "Longueur",
    beam: "Largeur",
    draft: "Tirant d'eau",
    mooring_type: "Type d'amarrage",
    annual_mooring_interested: "Intéressé placement à l'année",
    relocation: "Changement de place",
    comment: "Commentaire",
    last_examination: "Dernière vérification",
    archived: "Archivé",
    archiving_reason: "Raison de l'archivage",
    archiving_date: "Date d'archivage",
    currency: "Monnaie",
    registration_fee: "Frais d'inscription",
    periodic_fee: "Frais périodiques",
    payment_period: "Période de paiement [années]",
    areYouSureDeleteEntry: "Voulez-vous vraiment supprimer cette entrée ? Vous pouvez également l'archiver.",
    boat_type: "Type de navire",
    WaitingContactShouldNotBeUnarchived: "Les inscriptions sur la liste d'attente ne doivent pas être désarchivées.",
  },
  WaitingContactTable: {
    contact_name: "Nom",
    contact_address: "Adresse",
    contact_type: "Type contact",
    entry_date: "Date de l'inscription",
    mooring_zone: "Zone d'amarrage",
    mooring_type: "Type d'amarrage",
    boat_type: "Type de bateau",
    min_length: "Min. longueur",
    min_beam: "Min. largeur",
    min_draught: "Min. tirant d’eau",
    relocation: "Changement de place",
    annual_mooring_interested: "Intéressé placement à l'année",
    comment: "Commentaire",
    archiving_reason: "Raison de l'archivage",
    archiving_date: "Date d'archivage",
    registration_fee: "Frais d'inscription",
    periodic_fee: "Frais périodiques",
    currency: "Monnaie",
    payment_period: "Période de paiement [années]",
    last_examination: "Dernière vérification",
    all: "Tous ",
    active: "Active",
    archived: "Archivé",
    status: "Status",
    editOn: "Edit: on",
    editOff: "Edit: off",
    showFilter: "Filtre",
    resetFilter: "Réinitialiser",
    berth_number: "No. couchette",
    dimensions: "Dimensions bateau (Lon x Lar x T)",
    addEntry: "Ajouter entrée",
    email: "Email",
    daily_rate_default: "Prix par nuit",
  },
  Account: {
    title: "Compte",
    role: "Role",
    roles: ["Private", "Marina"],
    communicationLanguages: ["EN", "DE", "FR", "IT", "ES"],
    communicationLanguage: "Langue de communication",
    firstName: "Prénom",
    lastName: "Nom de famille ou société",
    email: "Email",
    mobile: "Téléphone mobile (ex. +41 12 345 67 89)",
    myBoat: "Mon bateau",
    boatRegistrationNumber: "Immatriculation du bateau",
    boatName: "Nom du bateau et port d'immatriculation",
    beam: "Largeur [m]",
    length: "Longueur [m]",
    draft: "Tirant d'eau [m]",
    boatDocumentScans: "Documents bateau (Scans)",
    additionalInformation: "Informations complémentaires",
    reimbursementBankAccount: "Remboursement compte bancaire",
    paymentmethod: "Mode de paiement",
    vat: "Numéro TVA (si applicable)",
    changePassword: "Changer mot de passe",
    password: "Nouveau mot de passe",
    passwordRepeat: "Mot de passe (Répétition)",
    balance: "Solde",
    success: "Compte actualisé",
    types: "Types",
    mooringTypes: "Types d'amarrage",
    addMooringType: "Ajouter  type amarrage",
    mooringZones: "Zones amarrage",
    addMooringZone: "Ajouter zone amarrage",
    contactTypes: "Types contact",
    addContactType: "Ajouter type contact",
    default_currency: "Monnaie par défaut",
    default_periodic_fee: "Frais périodiques par défaut",
    default_registration_fee: "Frais d'inscription par défaut",
    default_payment_period: "Période de paiement par défaut",
    manual_booking_confirmation: "Confirmation manuelle de la réservation",
    automatic_response: "Réponse automatique",
    automatic_response_after: "Réponse automatique après",
    accept: "Accept",
    decline: "Déclin",
    salutation: "Appel",
    street: "Rue",
    zip: "ZIP",
    city: "Ville",
    country: "Pays",
    bankAccountData: "Remboursement compte bancaire",
    currency: "Monnaie",
    iban: "IBAN",
    owner_name: "Nom du propriétaire",
    confirmation_emails_own: "Confirmations par e-mail pour vos propres réservations",
    settings: "Réglages",
    csv_separator: "Séparateur CSV",
    email_cc: "Email copie à",
    email_admin_cc: "E-mail de règlement copie à",
    max_nights_per_year: "Max. Anzahl Nächte pro Jahr und Gast",
  },
  ActivateAccount: {
    title: "Activer compte",
    activate: "Modifier",
    activationCode: "Code d'activation",
    activationText: "Avant d'activer et de revenir à l'écran login, veuillez confirmer votre adresse e-mail en cliquant sur le lien envoyé à votre compte e-mail",
    resendSMS: "Renvoyer SMS avec code d'activation",
    activationFailed: "Erreur d'activation",
    tryAgain: "Veuillez réessayer",
    smsHasBeenSentTitle: "SMS a été envoyé",
    smsHasBeenSent: "SMS a été envoyé. Si vous ne le recevez pas, veuillez contacter le support",
    mobileInfo: "Nous vous avons envoyé un SMS avec un code d'activation. Veuillez entrer le code ici: ",
    emailInfo: "Vous avez également reçu un e-mail de notre part. Veuillez cliquer sur le lien dans l'e-mail pour terminer l'inscription. ",
  },
  MaintainMoorings: {
    title: "Maintenir amarrages",
    dailyPrice: "Prix par jour",
    delete: "Eliminer",
    edit: "Editer",
    show: "Montrer",
    times: "Temps",
    info: "Info",
    book: "Réserver",
    addNewMooring: "Ajouter nouvel amarrage",
    doYouReallyWantToDeleteMooring: "Voulez-vous vraiment éliminer cet amarrage?",
  },
  MooringTable: {
    berth_number: "No. Couchette",
    harbour_name: "Marina",
    permanent_renter_names: "Locataire",
    exemption_renter_names: "Exempted for",
    renter_from_date: "Loué de",
    renter_to_date: "Loué jusqu'à",
    max_length: "Max. longueur",
    max_beam: "Max. largeur",
    max_draught: "Max. tirant d’eau",
    max_weight: "Max. Poids",
    pier_height: "Hauteur quai",
    power: "Electricité ",
    water: "Eau",
    buoy: "bouée",
    mooring_type: "Type d'amarrage",
    mooring_zone: "Zone d'amarrage",
    marina_notes: "Notes marina",
    app_visible: "Visible dans l'app",
    additional_description: "Description complémentaire",
    confidential_description: "Description confidentielle",
    editOn: "Edit: on",
    editOff: "Edit: off",
    operating_cost: "Le coût d'exploitation",
    yearly_rate: "Coût annuel",
    payment_currency: "Monnaie",
    mass_edit: "Changer le code",
    confidential_code_old: "Code confidentiel actuel",
    confidential_code_new: "Nouveau code confidentiel",
  },
  AddMooringMap: {
    title: "Ajouter amarrage",
    whereIs: "Où est votre amarrage?",
    zoomIn: "Zoomez sur votre marina et indiquez votre poste d'amarrage en touchant et en tenant un endroit sur la carte.",
    apply: "Créer amarrage",
    desiredLocation: "Lieu souhaité d'amarrage?",
  },
  EditMooring: {
    details: "Détails",
    occupiedBy: "Amarrage habituellement occupé par",
    coordinates: "Coordonnées",
    appVisible: "Visible dans l'app",
    maxLength: "Max. longueur",
    maxBeam: "Max. largeur",
    maxDraught: "Max. tirant d’eau",
    maxWeight: "Max. Poids",
    real_length: "LONGUEUR EFFECTIVE",
    real_beam: "LARGEUR EFFECTIVE",
    berthNo: "No. couchette/identification",
    harbourName: "Nom de la marina",
    additionalDescription: "Description complémentaire",
    confidentialDescription: "Description confidentielle",
    pierHeight: "Hauteur quai (au-dessus l’eau)",
    power: "Electricité disponible?",
    water: "Eau disponible?",
    buoy: "Poste de bouée?",
    boatName: "Nom du bateau",
    port: "Port d'immatriculation",
    create: "Créer",
    additionalDescriptionPlaceholder: "Entrez ici le lien vers la homepage du port et des informations complémentaires sur le poste d'amarrage, qui sont accessibles à tous les utilisateurs.",
    confidentialDescriptionPlaceholder: "Entrez ici des informations confidentielles supplémentaires qui ne seront envoyées à l'utilisateur qu'en cas de réservation (par exemple, un code secret pour l'accès à la facilité).",
    marinaNotes: "Notes marina",
    mooringType: "Type d'amarrage",
    mooringZone: "Zones amarrage",
    availabilities: "disponibilités ",
    settings: "Paramètres",
    maxNights: "Max. nombre de nuits",
    maxNightsPerYear: "Max. nuits par an",
    finance: "Finance",
    yearlyRates: "Yearly Rates",
    transferMooring: "Transfert d'amarrage",
    transfer: "Transfert",
    email: "Email",
    operatingCosts: "Frais opérationnels",
    manualConfirmation: "Confirmation manuelle",
    areYouSureDeleteMooring: "Vous voulez vraiment éliminer cet amarrage?",
    AreaBlockedTitle: "Zone bloquée",
    AreaBlocked: "Cette zone est bloquée. Si vous avez un amarrage ici, veuillez contacter {{contact_email}} pour qu'il vous soit attribué.",
    country: "Pays",
  },
  TransferMooring: {
    modalTitle: "Le compte Boatpark n'existe pas",
    modalBody: "Il n'y a pas de compte Boatpark avec cet e-mail. Souhaitez-vous envoyer un e-mail d'invitation à l'enregistrement sur Boatpark ? L'amarrage est automatiquement transféré après l'enregistrement.",
    emailLanguage: "Langue de l'e-mail",
    sendEmail: "Envoyer l'invitation",
  },
  MaintainTimesAndPrices: {
    title: "Maintenir temps et prix",
    feeIncluded: "Incluant frais de marina?",
    currency: "Monnaie",
    from: "De",
    to: "à",
    pricePerNight: "Prix/nuit",
    addNext: "Ajouter suivant",
    addNewPeriod: "Ajouter nouvelle période",
    dayByDayInfoTitle: "Disponibilité jour par jour",
    dayByDayInfo: "Si marqué, l'amarrage ne peut être réservé que le jour même et pour une seule nuit (pas de réservation à l'avance). Cela permet au propriétaire de l'amarrage d'annuler la période de disponibilité quotidienne et de mettre l'amarrage à sa disposition en cas de retour anticipé.",
    continueTitle: "Vous êtes sûr ?",
    continueText: "Quitter cet écran sans sauvegarder?",
    availabilityPeriods: "Périodes de disponibilité ",
    calendar: "Calendrier",
    askMarinaPermission: "Les frais de port ne peuvent être inclus que si vous êtes autorisé à sous-louer la place: êtes-vous autorisé?",
    berthWillBeAvailable: "Amarrage sera disponible dans 30 minutes",
    prices: "Prix",
    defaultPrice: "Prix/Nuit",
    tourist_tax_adult: "Taxe haut tarif par nuit",
    tourist_tax_child: "Taxe bas tarif par nuit",
    name: "Nom",
    email: "Email",
    mobile: "Téléphone mobile",
    boatRegistrationNumber: "Immatriculation du bateau",
    boatDimensions: "dimensions bateau",
    updateSuccessful: "Succès",
    showBoatDocuments: "Document",
    boatDocuments: "Documents"
  },
  Navbar: {
    home: "Home",
    account: "Compte",
    maintainMyBookings: "Mes réservations",
    findAndBookMoorings: "Trouver & réserver",
    shareMyMoorings: "Partager amarrage",
    login: "Login",
    logout: "Logout",
    whatWeDo: "Ce que nous faisons",
    register: "Enregistrer",
    reservations: "Réservations",
    contacts: "Contacts",
    moorings: "Amarrages",
    waitingList: "Liste d'attente ",
    signedInAs: "Signed in as: ",
  },
  FindMoorings: {
    showRentedMoorings: "Montrer les places invisibles dans l'app",
    showMarinaMap: "Montrer la carte de la marina ",
    showBerthNumbers: "Montrer les numéros de couchette",
  },
  BookingOverview: {
    title: "Aperçu des réservations",
    replacing: "Repositionnant…",
    Nu: "ID",
    l: "Lo",
    b: "La",
    d: "Ti",
    areYouSureYouWantToRebook: "Vraiment repositionner cette réservation ?",
    lengthLower: "Bateau longueur est trop grand pour l'amarrage",
    beamLower: "Bateau largeur est trop grand pour l'amarrage",
    draftLower: "Bateau tirant d'eau est trop grand pour l'amarrage",
    weightLower: "Bateau est trop lourd pour l'amarrage",
    doesBoatStillFit: "Check dimensions du bateau",
    replaceNotPossible: "Repositionner pas possible. La réservation a une prolongation continuelle.",
  },
  AddBookingModal: {
    title: "Ajouter réservation",
    notes: "Remarques",
    notesPlaceholder: "...",
    bookMooring: "Réserver amarrage",
    berth_number: "No. couchette",
    internal_paid: "Payé",
  },
  BookingDetailsModal: {
    title: "Détails de la réservation",
    replace: "Repositionner la réservation",
    internal_paid: "Payé",
  },
  FilterModal: {
    title: "Filtrer",
    min: "Min",
    max: "Max",
    clear: "Réinitialiser",
    apply: "Appliquer",
  },
  MooringStatus: {
    available: "Poste d’amarrage disponible",
    unavailable: "Poste d’amarrage réservé",
    infoAvailable: "Cette place est disponible et peut être utilisée pour une nuit.<br>Pour éviter les doubles réservations, veuillez réserver la place <span style=\"color: red\">avant d'amarrer votre bateau</span> avec 'Réservation immédiate'.",
    infoUnavailable: "Cette place est réservée pour la nuit et ne peut pas être occupée.<br>Pour trouver un poste d’amarrage disponible, veuillez contacter le capitaine du port ou télécharger l’application Boatpark pour trouver des postes d’amarrage appropriés dans votre région:  <a href=\"https://boatpark.app\">https://boatpark.app</a>",
    instantBooking: "Réservation immédiate",
    // noInstantBooking: "hhhhhhhh",
  },
  validators: {
    required: "obligatoire",
    invalid: "invalide",
    priceIsRequired: "prix est obligatoire",
    periodsShouldNotOverlap: "périodes ne doivent pas se croiser",
    tooShort: "trop courte",
    invalidFormat: "format invalide",
    repeatedPasswordWrong: "mots de passe pas identiques",
  },
  links: {
    website: "https://boatpark.app/fr/",
    TCsLink: "https://boatpark.app/fr/domicile/comment-cela-fonctionne/conditions-generales-de-vente-cgv/",
  },
  global: {
    save: "Sauvegarder",
    create: "Créer",
    yes: "Oui",
    no: "Non",
    ok: "Ok",
    AreYouSure: "Vous êtes sûr?",
    NoOrYes: ["Non", "Oui"],
    currencys: ["CHF", "EUR", "USD", "GBP"],
    communicationLanguages: ["EN", "DE", "FR", "IT", "ES"],
    noInternetTitle: "Pas d'Internet",
    noInternetMessage: "Veuillez vous connecter à l'internet et réessayer",
    loading: "loading",
    contunie: "Continue",
    search: "Recherche",
    edit: "Edit",
    delete: "Eliminer",
    monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    pleaseSelect: "Choisissez",
    all: "Tous ",
    year: "An",
    export: "Exporter",
    cancel: "Annuler",
    apply: "Appliquer",
    from: "De",
    to: "à",
  },
  errors: {
    somethingWentWrong: "Quelque chose s'est mal passé, veuillez réessayer.",
    someValuesAreNotCorrect: "Certaines valeurs ne sont pas correctes",
    thisEmailIsAlreadyTaken: "Cet e-mail est déjà utilisé",
    clubDataUpdated: "Cet e-mail est déjà utilisé",
    pleaseAddAValidCreditCard: "Paiement impossible : veuillez saisir une carte de crédit valide",
    invalidCreditCard: "Cette carte de crédit n'est pas valable. Veuillez utiliser une autre carte.",
    pleaseAddPaymentMethod: "Paiement impossible : veuillez saisir une carte de crédit valide",
    doubleBookingsNotPossible: "Vous avez déjà une réservation dans ce délai : Les doubles réservations ne sont pas possibles.",
    mooringWasBookedMeantime: "L'amarrage a été réservé dans le même temps : veuillez vérifier à nouveau",
    canNotDeleteBookingtime: "La suppression de la période de disponibilité n'est pas possible : votre amarrage est déjà réservé pendant cette période",
    refertalDoesNotExist: "L'e-mail de recommandation saisi n'est pas un ambassadeur autorisé : veuillez supprimer",
    invalidMobileNumber: "Nous ne reconnaissons pas ce numéro de téléphone portable. Veuillez utiliser le format international, par exemple +41 79 1234567",
    emailAndPasswordDoNotMatch: "E-mail et mot de passe ne correspondent pas",
    accountDoesNotExist: "E-mail envoyé si le compte existe",
    pleaseLoginAgain: "Veuillez vous connecter à nouveau.",
    couldNotDeleteMooring: "Impossible de supprimer l'amarrage : des réservations existent pour cet amarrage.",
    bookingWithoutOwnMooringNotYetPosible: "Réservation sans partager place propre possible uniquement à partir de {{time}}",
    loginFailed: "Could not log you in, please review your email and password",
    pleaseActivateMobile: "Please activate your account with the code from your SMS",
    pleaseActivateEmail: "Please verify your email adress using the link we have sent",
    pleaseAcceptTCs: "Please accept the terms and conditions",
    boatparkAccountEmailDoesNotExist: "Cette adresse e-mail ne correspond à aucun compte Boatpark",
    pleaseUseMobileApp: "Ton compte n'est pas autorisé pour la WebApp. Veuillez utiliser l'application Boatpark Mobile sur votre téléphone portable.",
  },
};

export default fr;
