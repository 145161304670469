import React from "react";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import { logoutRequest, loginToMasterAccount, goBackToMainAccount } from "../actions/auth";

import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
} from "react-bootstrap";
import bp_banner from "../assets/bp_banner_large.png"; // Tell webpack this JS file uses this image


class MainNavbar extends React.Component {

  constructor(props) {
    super(props);


  }
  // Todo: Find faster way to get role 
  componentDidMount() {
  }

  handleLogout = () => {
    logoutRequest(() => { this.props.logoutSuccess && this.props.logoutSuccess() }, () => { });
  };


  render() {
    const { t } = this.props
    const authenticated = localStorage.getItem("AuthToken");
    const email = localStorage.getItem("email");
    const originalEmail = localStorage.getItem("originalEmail");


    return (
      <Navbar
        id="navbar"
        bg="light"
        // expand="lg"
        sticky="top"
        style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 10, paddingTop: 10 }}>
        <Nav.Link target="_blank" href={"https://boatpark.app"}>
          <Navbar.Brand id="nav_brand" ><img src={bp_banner} alt="Logo" />
            {process.env.REACT_APP_DEPLOYMENT_ENV == "dev" && <div style={{ color: 'red', position: 'absolute', marginLeft: 60, marginTop: 40 }}>Dev</div>}
          </Navbar.Brand>
        </Nav.Link>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end">
          {!authenticated && <Nav.Link id="nav_login" onClick={this.props.loginClicked}>{t('Navbar.login')}</Nav.Link>}

          {authenticated &&
            <NavDropdown id="nav-dropdown-signed-in-as" title={`${email}`}>

              <NavDropdown.Item
                id="nav_logout" onClick={this.handleLogout}>
                {t('Navbar.logout')}
              </NavDropdown.Item>

            </NavDropdown>
          }
        </Navbar.Collapse>
      </Navbar >

    );
  }
}

export default withTranslation()(withRouter(MainNavbar));
