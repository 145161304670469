const es = {
  MooringDetails: {
    title: "Book Mooring",
    details: "Details",
    occupiedBy: "Mooring usually occupied by",
    taxes: "Taxes",
    perAdultPerNight: "Por persona en tarifa alta y noche",
    perChildPerNight: "Por persona en tarifa baja y noche",
    coordinates: "Coordinates*",
    maxLength: "Max. length",
    maxBeam: "Max. beam",
    maxDraught: "Max. draft",
    lengthBeamDraft: "Length/Beam/Draft",
    berthNo: "Berth number/identification",
    marinaName: "Marina name",
    additionalDescription: "Additional Description",
    pierHeight: "Pier height (above water)",
    power: "Power available?",
    water: "Water available?",
    buoy: "Buoy place?",
    boatName: "Boat name",
    port: "Port of registry",
    from: "From",
    to: "To",
    numberOfAdults: "Número de personas tarifa alta",
    numberOfChildren: "Número de personas tarifa baja",
    totalTouristTaxes: "Total Tourist taxes",
    excluding: "Excluding ",
    including: "Including ",
    harbourFee: "the marina fee",
    cancel: "Cancel",
    notes: "Observaciones",
    timesAndPrices: "Times and Prices",
    bookMooring: "Book Mooring",
    edit: "Edit",
    claim: "Reclamar",
    mooringZone: "Mooring Zone",
    mooringType: "Mooring Type",
    permanentRenter: "Permanent Renter",
    shipSizeLBD: "Boat dimensions (L x W x D)",
    boatRegistrationNumber: "Boat",
    info: "Info",
    exemptedFor: "Exempted for",
    boatparkAccount: "Boatpark account",
    email: "email",
    name: "name",
    mobile: "mobile",
    marina_notes: "Marina Notes",
  },
  Login: {
    email: "Email",
    password: "Password",
    login: "Login",
    register: "Register for Boatpark account",
    forgotPassword: "Forgot password?",
    emailHasBeenSentTitle: "Email has been sent",
    emailHasBeenSent: "An email has been sent. If you do not receive it, please check your spam folder.",
    confirmEmailTitle: "Confirm your email",
    confirmEmail: "Please confirm mail address via link in e-Mail sent to you. Do you need a new e-Mail?",
  },
  ForgotPassword: {
    title: "Forgot Password",
    email: "Email",
    errorsMessage: "Something went wrong. Please try again.",
    emailHasBeenSent: "An email has been sent. If you do not receive it, please check your spam folder.",
    reset: "Reset",
    register: "Register for Boatpark account",
    weWillSendEmail: "We will send you an email with a link, where you can reset your password.",
    info: "Introduzca su correo electrónico para recibir un enlace donde podrá cambiar su contraseña.",
    send: "Enviar",
  },
  Register: {
    title: "Registrar",
    register: "Registrar",
    roles: ["Private", "Marina"],
    communicationLanguages: ["EN", "DE", "FR", "IT", "ES"],
    communicationLanguage: "Lenguaje de comunicación",
    role: "Función",
    iAcceptThe: "Acepto los ",
    lastName: "Nombre de familia o compañía",
    firstName: "Nombre",
    email: "Email",
    mobile: "Teléfono móvil (ex. +41 12 345 67 89)",
    password: "Password",
    repeatPassword: "Repetición password",
    referral: "Referente / embajador Email",
    TCs: "T&Cs",
    registerSuccess: "Su registro fue exitoso",
    OptionalBoatInfo: "Información barco",
    name: 'Nombre del barco',
    registration_number: 'Matriculación del barco',
    length: 'Longitud [m]',
    beam: 'Manga [m]',
    draft: 'Calado [m]',
    continueAsGuest: "Continuar como visitante",
    pay: "Pagar ahora",
  },
  FastBooking: {
    from: 'Llegada',
    to: 'Partida',
    booking_price: "Precio de reserva",
    tax_price: "Total de tasas",
    total_price: "Precio total",
    taxes: "Tasas",
    perAdultPerNight: "Por persona en tarifa alta y noche",
    perChildPerNight: "Por persona en tarifa baja y noche",
    numberOfAdults: "Número de personas tarifa alta",
    numberOfChildren: "Número de personas tarifa baja",
    mooringUnavailable: "Este amarre ya no está disponible para reservas inmediatas. Por favor, utilice la Boatpark-App o tomar otro amarre.",
    LBH: "Longitud / Manga / Calado",
    bookingHasBeenSaved: "Su reserva ha sido guardada. Consulte su Email/SMS para completar su registro después de amarrar su barco con seguridad. También puede hacerlo ahora utilizando ",
    thisLink: "este enlace.",
    TheReservationWillBePaid: "La reserva se pagará al completar su registro.",
    YouAreAboutToBook: "Está a punto de reservar este lugar para una noche por {{currency}} {{amount}}: ¿desea continuar?",
    YourInformation: "Sus datos",
    MooringInformation: "Información sobre el amarre",
    firstname: 'Nombre',
    lastname: 'Nombre de familia o compañía',
    email: 'Email',
    mobile: 'Teléfono móvil (ex. +41 12 345 67 89)',
    reserve: "Reservar",
    activationCode: 'Código de activación',
    confirm: "Confirmar",
    totalPrice: "Precio total",
    additionalDescription: "Additional Description",
    boat_length: "Longitud del barco",
    boat_beam: "Manga del barco",
    checkInTime: "Check-In: ",
    checkOutTime: "Check-Out: ",
    boat_name: 'Nombre del barco',
    boat_registration_number: 'Matriculación del barco',
    freeBookingHasBeenSaved: "Su reserva ha sido guardada.",
    bookNow: "Reservar ahora",
    bookingSuccessful: "El amarre ha sido reservado con éxito.",
    payment_method: "Método de pago",
  },
  ResetPasasword: {
    title: "Restablecer contraseña",
    password: "New password",
    passwordRepeat: "Password (Repeat)",
    reset: "Restablecer",
    paswordHasBeenReset: "Se ha restablecido la contraseña",
  },
  EditContactModal: {
    editContact: "Edit Contact",
    newContact: "New Contact",
    communicationLanguages: ["EN", "DE", "FR", "IT", "ES"],
    communicationLanguage: "Communication Language",
    salutation: "Salutation",
    lastName: "Lastname or Company name",
    firstName: "First name",
    email: "Email",
    mobile: "Mobile phone (ex. +41 12 345 67 89)",
    creationSuccess: "Creation successful",
    street: "street",
    zip: "zip",
    city: "city",
    birthday: "birthday",
    phone_p: "phone private",
    phone_b: "phone work",
    alternative_email: "alternative email",
    alternative_mobile: "alternative mobile",
    type: "Type",
    access_badge: "Access badge",
    remarks: "remarks",
    vat: "vat",
    edit: "Edit",
    show: "Show",
    boats: "Boats",
    createContact: "Create Contact",
    save: "save",
    deleteContact: "Delete Contact",
    addNewBoat: "Add new boat",
    areYouSureDeleteContact: "Are you sure you wish to delete this contact?",
    boatparkAccount: "Boatpark Account",
    token: "Token",
    customer_number: "Customer Number",
    id: "ID",
    moorings: "Moorings",
    watiting_contacts: "Waiting for mooring",
  },
  ContactTable: {
    addNewContact: "Add new contact",
    firstname: "Firstname",
    lastname: "Lastname",
    email: "Email",
    mobile: "Mobile",
    street: "Street",
    communication_language: "Language",
    zip: "Zip",
    city: "City",
    birthday: "Birthday",
    type: "Type",
    access_badge: "Access badge",
    remarks: "Remarks",
    token: "Token",
    customer_number: "Customer Number",
    id: "ID",
    editOn: "Edit: on",
    editOff: "Edit: off",
  },
  EditBoatModal: {
    editBoat: "Edit Boat",
    name: "Boat Name",
    registration_number: "Registration Number",
    length: "length",
    beam: "beam",
    draft: "draft",
    type: "type",
    drive: "drive",
    weight: "weight",
    createBoat: "Create Boat",
    save: "save",
    deleteBoat: "Delete Boat",
    boatTypes: ["", "Motor", "Sail"],
    boatDrives: ["none", "gasoline", "diesel", "electric", "other",],
    documents: "Documents",
    addNewDocument: "Add new document",
    show: "Show",
    areYouSureDeleteBoat: "Are you sure you wish to delete this boat?",
    showPDF: "Show Boat Documents",
    make: "Make",
  },
  EditBoatDocumentModal: {
    editBoatDocument: "Edit document",
    createBoatDocument: "Create document",
    title: "Title",
    image: "Image",
    save: "Save",
    deleteBoatDocument: "Delete document",
    areYouSureDeleteDocument: "Are you sure you wish to delete this document?",
  },
  MaintainAssignedContacts: {
    title: "Maintain Renters",
    editRenter: "Edit Renter",
    addRenter: "Add new Renter",
    correspondence: "Correspondence",
    exemption: "Exemption",
    provisional: "Provisional",
    contact: "Contact",
    boat: "Boat",
    from: "From",
    to: "To",
    notes: "Observaciones",
  },
  EditWaitingContactModal: {
    editReservation: "Edit entry",
    addReservation: "Add entry",
    contact: "Contact",
    entryDate: "Entry date",
    mooring_zone: "Mooring Zone",
    length: "Length",
    beam: "Beam",
    draft: "Draft",
    mooring_type: "Mooring Tye",
    annual_mooring_interested: "Interested in annual reservation",
    relocation: "Relocation",
    comment: "Comment",
    last_examination: "Last examination",
    archived: "Archived",
    archiving_reason: "Reason for archiving",
    archiving_date: "Archiving date",
    currency: "Currency",
    registration_fee: "Registration Fee",
    periodic_fee: "Periodic Fee",
    payment_period: "Payment Period [Years]",
    areYouSureDeleteEntry: "Are you sure you want to delete this entry? You can also archive it.",
    boat_type: "Boat type",
    WaitingContactShouldNotBeUnarchived: "Waiting contacts that have already beed assigned to a mooring should not be unarchived. Please create a new waiting contact entry instead.",
  },
  WaitingContactTable: {
    contact_name: "Name",
    contact_address: "Address",
    contact_type: "Contact Type",
    entry_date: "Entry date",
    mooring_zone: "Mooring zone",
    mooring_type: "Mooring type",
    boat_type: "Tipo de barco",
    min_length: "Min. length",
    min_beam: "Min. beam",
    min_draught: "Min. draft",
    relocation: "Relocation",
    annual_mooring_interested: "annual mooring",
    comment: "Comment",
    archiving_reason: "Archiving reason",
    archiving_date: "Archiving date",
    registration_fee: "Registration Fee",
    periodic_fee: "Periodic Fee",
    currency: "Currency",
    payment_period: "Payment Period [Years]",
    last_examination: "Last examination",
    all: "All",
    active: "Active",
    archived: "Archived",
    status: "Status",
    editOn: "Edit: on",
    editOff: "Edit: off",
    showFilter: "Filter",
    resetFilter: "Reset",
    berth_number: "Berth number",
    dimensions: "Dimensions (L/B/D)",
    addEntry: "Add entry",
    email: "Email",
  },
  Account: {
    title: "Account",
    role: "Role",
    roles: ["Private", "Marina"],
    communicationLanguages: ["EN", "DE", "FR", "IT", "ES"],
    communicationLanguage: "Communication language",
    firstName: "First name",
    lastName: "Lastname or Company name",
    email: "Email",
    mobile: "Mobile phone (ex. +41 12 345 67 89)",
    myBoat: "My Boat",
    boatRegistrationNumber: "Boat registration number",
    boatName: "Boat name and port of registry",
    beam: "Beam [m]",
    length: "length [m]",
    draft: "draft [m]",
    boatDocumentScans: "Boat documents (Scans)",
    additionalInformation: "Additional Information",
    reimbursementBankAccount: "Reimbursement Bank Account",
    paymentmethod: "Payment Method",
    vat: "VAT number (if relevant)",
    changePassword: "Change Password",
    password: "New password",
    passwordRepeat: "Password (Repeat)",
    balance: "Balance",
    success: "Updated Account Data",
    types: "Types",
    mooringTypes: "Mooring Types",
    addMooringType: "Add Mooring Type",
    mooringZones: "Mooring Zones",
    addMooringZone: "Add Mooring Zone",
    contactTypes: "Contact Types",
    addContactType: "Add Contact Types",
    default_currency: "Default Currency",
    default_periodic_fee: "Default periodic fee",
    default_registration_fee: "Default registration fee",
    default_payment_period: "Default payment period",
    manual_booking_confirmation: "Manual booking confirmation",
    automatic_response: "automatic response",
    automatic_response_after: "automatic response after",
    accept: "Accept",
    decline: "Decline",
    salutation: "Saludo",
    street: "Calle",
    zip: "CP",
    city: "Ciudad",
    country: "País",
    bankAccountData: "Cuenta bancaria para el reembolso",
    currency: "Moneda",
    iban: "IBAN",
    owner_name: "Nombre del propietario",
    confirmation_emails_own: "Confirmaciones por correo electrónico para las reservas propias",
    settings: "Impostazioni",
    csv_separator: "Separador CSV",
    email_cc: "Copia del correo electrónico a",
    email_admin_cc: "Copia del correo electrónico de la cuenta a",
    max_nights_per_year: "Máx. Número de noches al año y visitante",
  },
  ActivateAccount: {
    title: 'Activar la cuenta',
    activate: 'Activar',
    activationCode: 'Código de activación',
    activationText: 'Antes de activar y volver a la página de login, confirme su e-mail haciendo clic en el enlace enviado a su cuenta de e-mail',
    resendSMSWithActivationCode: 'Reenviar SMS con el código de activación',
    activationFailed: 'Error de activación',
    tryAgain: 'Por favor, inténtelo de nuevo',
    smsHasBeenSentTitle: 'Se ha enviado un SMS',
    smsHasBeenSent: 'Se ha enviado un SMS. Si no lo recibe, contacte el soporte',
    mobileInfo: "Te hemos enviado un SMS con el código de activación. Por favor, introduzca el código aquí: ",
    emailInfo: "Ha recibido un e-mail nuestro. Haga clic en el enlace del e-mail para completar la registración.",
  },
  MaintainMoorings: {
    title: "Maintain Moorings",
    dailyPrice: "daily price",
    delete: "Delete Mooring",
    edit: "Edit",
    show: "Show",
    times: "Times",
    info: "Info",
    book: "Book",
    addNewMooring: "Add new mooring",
    doYouReallyWantToDeleteMooring: "Do you really want to delete this mooring?",
  },
  MooringTable: {
    berth_number: "Berth number",
    harbour_name: "Marina",
    permanent_renter_names: "Permanent Renter",
    exemption_renter_names: "Exempted for",
    renter_from_date: "Rent from",
    renter_to_date: "Rent to",
    max_length: "Max. length",
    max_beam: "Max. beam",
    max_draught: "Max. draft",
    max_weight: "Max. weight",
    pier_height: "Pier height",
    power: "Power ",
    water: "Water",
    buoy: "Buoy",
    mooring_type: "Mooring type",
    mooring_zone: "Mooring zone",
    marina_notes: "Marina notes",
    app_visible: "App visible",
    additional_description: "Additional Description",
    confidential_description: "Confidential Description",
    editOn: "Edit: on",
    editOff: "Edit: off",
    operating_cost: "Operating Cost",
    yearly_rate: "Yearly Rate",
    payment_currency: "Currency",
    daily_rate_default: "Price/Night",
    mass_edit: "Cambiar código",
    confidential_code_old: "Código confidencial actual",
    confidential_code_new: "Nuevo código confidencial",
  },
  AddMooringMap: {
    title: "Add Mooring",
    whereIs: "Where is your mooring?",
    zoomIn: "Zoom-in to your marina and indicate your mooring berth by " + "touching and holding a place on the map.",
    apply: "Create mooring",
    desiredLocation: "Desired mooring location?",
  },
  EditMooring: {
    details: "Details",
    occupiedBy: "Mooring usually occupied by",
    coordinates: "Coordinates",
    appVisible: "Visible in app",
    maxLength: "Max. length",
    maxBeam: "Max. beam",
    maxDraught: "Max. draft",
    maxWeight: "Max. weight",
    real_length: "LONGITUD EFECTIVA",
    real_beam: "MANGA EFECTIVA",
    berthNo: "Berth number/identification",
    harbourName: "Marina name",
    additionalDescription: "Additional description",
    confidentialDescription: "Confidential description",
    pierHeight: "Pier height (above water)",
    power: "Power available?",
    water: "Water available?",
    buoy: "Buoy place?",
    boatName: "Boat name",
    port: "Port of registry",
    create: "save",
    additionalDescriptionPlaceholder: "Enter here the link to the port homepage and further information about the berth, which is available to all users.",
    confidentialDescriptionPlaceholder: "Enter additional confidential information, which will only be sent to the user in case of a reservation (e.g. secret code for access to the port facility).",
    marinaNotes: "Marina Notes",
    mooringType: "Mooring Type",
    mooringZone: "Mooring Zone",
    availabilities: "availabilities",
    settings: "Settings",
    maxNights: "MAX. NÚMERO DE NOCHES",
    maxNightsPerYear: "MAX. NOCHES AL AÑO",
    finance: "Finance",
    yearlyRates: "Yearly Rates",
    transferMooring: "Transfer Mooring",
    transfer: "Transfer",
    email: "Email",
    operatingCosts: "Operating Costs",
    manualConfirmation: "Manual Confirmation",
    areYouSureDeleteMooring: "Are you sure you wish to delete this mooring?",
    AreaBlockedTitle: "Área bloqueada",
    AreaBlocked: "Esta zona está bloqueada. Si tiene un amarre aquí, póngase en contacto con {{contact_email}} para asignárselo.",
    country: "País",
  },
  TransferMooring: {
    modalTitle: "La cuenta de Boatpark no existe",
    modalBody: "No hay ninguna cuenta de Boatpark con este correo electrónico. ¿Desea enviar un correo electrónico de invitación para registrarse en Boatpark? El amarre se transferirá automáticamente después de la inscripción.",
    emailLanguage: "Idioma del e-mail",
    sendEmail: "Enviar invitación",
  },
  MaintainTimesAndPrices: {
    title: "Maintain Times and Prices",
    feeIncluded: "Marina fee included?",
    currency: "Currency",
    from: "From",
    to: "To",
    pricePerNight: "Price/night",
    addNext: "Add next",
    addNewPeriod: "Add new period",
    dayByDayInfoTitle: "Day-by-day availability",
    dayByDayInfo: "If this field is marked, the mooring can only be booked on same day and only for one night (no advance booking). This allows the mooring owner to cancel the bookable period on a daily basis and make the mooring available for himself in case of earlier return.",
    continueTitle: "Are you sure?",
    continueText: "Do you really want to leave this screen without saving?",
    availabilityPeriods: "Availability Periods",
    calendar: "Calendar",
    askMarinaPermission: "Marina fees may only be included if you are entitled to sublet the mooring: are you allowed to do so?",
    berthWillBeAvailable: "Berth will be released in 30 minutes",
    prices: "Prices",
    defaultPrice: "Default Price",
    tourist_tax_adult: "Tarifa alta por noche",
    tourist_tax_child: "Tarifa baja por noche",
    name: "Name",
    email: "Email",
    mobile: "Mobile",
    boatRegistrationNumber: "Boat registration number",
    boatDimensions: "Boat dimensions",
    updateSuccessful: "Update successful",
    showBoatDocuments: "Show Boat Documents",
    boatDocuments: "Boat Documents"
  },
  Navbar: {
    home: "Home",
    account: 'Cuenta',
    maintainMyBookings: 'Mis reservaciónes',
    findAndBookMoorings: 'Encuentrar & reservar',
    shareMyMoorings: 'Compartir amarre',
    login: 'Login',
    logout: 'Logout',
    whatWeDo: 'Lo que hacemos',
    register: "Registro",
    reservations: "Reservas",
    contacts: "Contactos",
    moorings: "Moorings",
    waitingList: "Waiting List",
    signedInAs: "Signed in as: ",
  },
  FindMoorings: {
    showRentedMoorings: "Mostrar amarres invisibles en la aplicación",
    showMarinaMap: "Show marina Map",
    showBerthNumbers: "Show berth numbers",
  },
  BookingOverview: {
    title: "Resumen de reservas",
    replacing: "replacing...",
    Nu: "No.",
    l: "L",
    b: "M",
    d: "C",
    areYouSureYouWantToRebook: "¿Realmente quiere reposicionar esta reserva?",
    lengthLower: "Barco longitud es tan grande por el amarre",
    beamLower: "Barco manga es tan grande por el amarre",
    draftLower: "Barco calado es tan grande por el amarre",
    weightLower: "Barco es tan pesado por el amarre",
    doesBoatStillFit: "Check dimensiones del barco",
    replaceNotPossible: "Cambio de amarre imposible. La reserva tiene una extensión continua.",
  },
  AddBookingModal: {
    title: "Añadir Reserva",
    notes: "Observaciones",
    notesPlaceholder: "...",
    bookMooring: "Reservar amarre",
    berth_number: "Número de amarre",
    internal_paid: "Pagado",
  },
  BookingDetailsModal: {
    title: "Detalles de la reserva",
    replace: "Reemplazar reserva",
    internal_paid: "Pagado",
  },
  FilterModal: {
    title: "Filtrar",
    min: "Min",
    max: "Max",
    clear: "Restablecer",
    apply: "Aplicar",
  },
  MooringStatus: {
    available: "Parqueo disponible",
    unavailable: "Parqueo reservado",
    infoAvailable: "Este amarre está disponible y se puede utilizar durante una noche. <br>Para evitar reservas dobles, reserve el amarre <span style=\"color: red\">antes de atracar su barco</span> con 'Reserva immediata'.",
    infoUnavailable: "Esta amarra está reservada para esta noche y no puede ser ocupada.<br>Para encontrar un parqueo disponible, póngase en contacto con el capitán del puerto o descargue la aplicación Boatpark para encontrar amarraderos adecuados en su región: <a href=\"https://boatpark.app\">https://boatpark.app</a>",
    instantBooking: "Reserva immediata",
    // noInstantBooking: "hhhhhhhh",
  },
  validators: {
    required: "required",
    invalid: "invalid",
    priceIsRequired: "price is required",
    periodsShouldNotOverlap: "periods should not overlap",
    tooShort: "too short",
    invalidFormat: "invalid format",
    repeatedPasswordWrong: "passwords do not match",
  },
  links: {
    website: "https://boatpark.app/es/",
    TCsLink: "https://boatpark.app/es/casa/como-funciona/terminos-y-condiciones-tc/",
  },
  global: {
    save: "Save",
    create: "Create",
    yes: "Yes",
    no: "No",
    ok: "Ok",
    AreYouSure: "Are you sure?",
    NoOrYes: ["No", "Yes"],
    currencys: ["CHF", "EUR", "USD", "GBP"],
    communicationLanguages: ["EN", "DE", "FR", "IT", "ES"],
    noInternetTitle: "No Internet",
    noInternetMessage: "Please connect to the internet and try again",
    loading: "loading",
    contunie: "Continue",
    search: "Search",
    edit: "edit",
    delete: "delete",
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    pleaseSelect: "Please select",
    all: "All",
    year: "Year",
    export: "export",
    cancel: "Anular",
    apply: "Aplicar",
    from: "De",
    to: "a",
  },
  errors: {
    somethingWentWrong: "Algo salió mal, por favor, inténtelo de nuevo.",
    someValuesAreNotCorrect: "Algunos valores no son correctos",
    thisEmailIsAlreadyTaken: "Este e-mail ya se utiliza",
    clubDataUpdated: "Este e-mail ya se utiliza",
    pleaseAddAValidCreditCard: "No es posible el pago: por favor, introduzca una tarjeta de crédito válida",
    invalidCreditCard: "Esta tarjeta de crédito no es válida. Por favor, use otra tarjeta.",
    pleaseAddPaymentMethod: "No es posible el pago: por favor, introduzca una tarjeta de crédito válida",
    doubleBookingsNotPossible: "Ya tienes una reserva en este plazo: No se pueden hacer reservas dobles.",
    mooringWasBookedMeantime: "El amarre fue reservado mientras tanto: por favor, compruebe de nuevo",
    canNotDeleteBookingtime: "No es posible suprimir el período de disponibilidad: su amarre ya está reservado durante este período",
    refertalDoesNotExist: "El e-mail de referencia introducido no es un embajador autorizado: por favor, elimine",
    invalidMobileNumber: "No reconocemos este número de móvil. Por favor, utilice el formato internacional, por ejemplo +41 79 1234567",
    emailAndPasswordDoNotMatch: "E-mail y contraseña no coinciden",
    accountDoesNotExist: "Se envía un e-mail si la cuenta existe",
    pleaseLoginAgain: "Por favor, inicie sesión de nuevo.",
    couldNotDeleteMooring: "No se puede eliminar el amarre: existen reservas para este amarre.",
    bookingWithoutOwnMooringNotYetPosible: "Reservación sin compartir propio amarre sólo posible a partir de las {{time}}",
    loginFailed: "El login no es correcto, por favor revise su e-mail y contraseña",
    pleaseActivateMobile: "Active su cuenta con el código del SMS",
    pleaseActivateEmail: "Por favor, verifique su dirección de e-mail utilizando el enlace que hemos enviado",
    pleaseAcceptTCs: "Por favor, acepte los términos y condiciones",
    boatparkAccountEmailDoesNotExist: "Esta dirección de correo electrónico no pertenece a ninguna cuenta de Boatpark",
    pleaseUseMobileApp: "Su cuenta no está aprobada para la aplicación web. Por favor, utilice la aplicación móvil de Boatpark en su teléfono móvil.",
  },
};

export default es;
