import axios from "axios";



export const getBoatDocuments = (boat_id, callback, errorcallback) => {


  axios.post(`boatdocuments/get_my_documents?boat_id=${boat_id}`)
    .then(response => {
      if (response.status == 200) {

        const boat_documents = response.data.boat_documents;
        callback(boat_documents);

      }
    })
    .catch(err => {
      errorcallback(err);
    });
};

export const getImageToken = (document_id, callback, errorcallback) => {


  axios.post(`boatdocuments/get_document_token?`, { document_id: document_id })
    .then(response => {
      if (response.status == 200) {

        const document_token = response.data;
        callback(document_token);

      }
    })
    .catch(err => {
      errorcallback(err);
    });
};


export const getBoatDocumentToken = (boat_id, callback, errorcallback) => {



  /*Since a private image is called only through a URL, the following happens:
     * We make an axios call (identified) and request a token for seing the document
     * The token is valid only once and we will get the document if we pt the token in the urls
    */
  axios.post('boatdocuments/get_pdf_token', { boat_id: boat_id })
    .then(response => {
      if (response.status == 200) {

        const token = response.data.token;

        //Place token in URL for getting the image
        callback(token);

      }
    })
    .catch(err => {
      errorcallback(err);
    });
};

export const uploadBoatDocument = (formData, callback, errorcallback) => {

  axios.post("boatdocuments/upload_document", formData)
    .then((response) => {
      callback(response)
    })
    .catch((error) => {
      errorcallback(error)
    })
}



export const deleteBoatDocument = (document_id, callback, errorcallback) => {
  axios.post(`boatdocuments/delete_document?id=${document_id}`)
    .then(response => {
      if (response.status == 200) {
        callback(response);
      }
    })
    .catch(err => {
      errorcallback(err);
    });
};


