import React from "react";
import { withTranslation } from 'react-i18next';

import {
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";

import FormInput from '../components/FormInput';
import FormSegmentedControl from '../components/FormSegmentedControl'

import moment from "moment";
import DatePicker from 'react-date-picker'; // do not remoove...

import { addBooking, getBookingDetails } from '../actions/bookings'
import { getMyMooring, claimMooring } from '../actions/moorings'

import { getTextAreaHeight } from '../utils/utilities'


import COLORS from '../constants/colors'



import BookingCalendar from '../components/BookingCalendar'
import { DateRangePicker, DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import MooringItem from "../components/MooringItem";
import StatusAlerts from "../components/StatusAlerts";

import TableIcon from '../assets/icons/table.png'
import EditContactModal from "../components/EditContactModal";
import TransferMooringSection from "../components/TransferMooringSection";


const getDaysBetweenPeriod = (startDate, stopDate) => {

  var dateArray = [];
  var currentDate = moment(startDate);
  var stopDate = moment(stopDate);
  while (currentDate < stopDate) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
}

class MooringDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      error: false,
      mooring: {
        renters: [{ contact: {} }],
        app_visible: false,
        own: false,
      },
      bookingDetails: false,
      selectedBooking: false,
      range: false,
      notes: "",
    };
    this.EditContactModal = React.createRef();
  }

  componentDidMount() {
    this.props.onRef && this.props.onRef(this)
  }

  loadMooring(mooring_id) {
    this.setState({
      loading: false,
      success: false,
      error: false,
    }, () => {
      getMyMooring(mooring_id, this.getMyMooringSuccess, () => { }, this.props.from_date, this.props.to_date)
    })
  }


  reloadMooring = () => {
    getMyMooring(this.state.mooring.mooring_id, this.getMyMooringSuccessAndReload, () => { }, this.props.from_date, this.props.to_date)
  }

  handleBookClick = () => {

    this.setState({ success: false, loading: true, error: false }, () => {

      const booking = {
        mooring_id: this.state.mooring.mooring_id,
        from_date: this.props.from_date,
        to_date: this.props.to_date,
        notes: this.state.notes,
        numberOfAdults: "0",
        numberOfChildren: "0",
        payment_method: "internal"
      }
      addBooking(booking, this.bookMooringSuccess, this.bookMooringFail)

    })

  }

  handleClaimClick = () => {

    this.setState({ success: false, loading: true, error: false }, () => {
      claimMooring(this.state.mooring.mooring_id, (data) => {
        this.setState({ loading: false, error: false, success: "Claim Successful" })
        this.reloadMooring()

      },
        () => {
          this.setState({ loading: false, error: this.props.t('errors.somethingWentWrong') })
        }
      )
    })
  }




  bookMooringSuccess = () => {
    // this.setState({ loading: false, success: true, error: false });
    this.reloadMooring()
  }

  bookMooringFail = (error) => {
    const errorMessage = this.props.t(`errors.${error.data.language_variable}`);
    this.setState({ loading: false, success: false, error: errorMessage });
  }

  getMyMooringSuccessAndReload = (mooring) => {
    this.setState({ mooring: { ...mooring }, error: false, loading: false }, () => {
      this.props.reloadMap();
    });
  }

  getMyMooringSuccess = (mooring) => {
    // console.log("moorin", mooring)
    this.setState({ mooring: mooring, error: false, loading: false });
  }

  getMyMooringFail = () => {

  }



  onClickFromDate = (e) => {
    this.setState({ clicktype: 1 })
  }

  onClickToDate = (e) => {
    this.setState({ clicktype: 2 })
  }

  onClickAssignedContacts = () => {
    this.props.history.push({
      pathname: `/renters/${this.state.mooring.mooring_id}`,
    }, { mooring: this.state.mooring })
  }

  onCalChange = date => {

    const start = moment(date[0])
    const end = moment(date[1])

    this.setState({ date: [start.toDate(), end.toDate()], range: false })
  }




  render() {
    const { t } = this.props;
    const { mooring } = this.state;
    const app_visible = mooring.app_visible == 1
    const own = mooring.own ? true : false;
    var from_date = this.props.from_date;
    var to_date = this.state.to_date;
    from_date = moment(from_date).format("YYYY-MM-DD");
    to_date = moment(to_date).format("YYYY-MM-DD");

    const role = localStorage.getItem("role");
    const management = role == "management";

    const bookable = mooring.own && (this.state.mooring.availability_status == "available" || this.state.mooring.availability_status == "available_day_by_day")

    const claimable = mooring.rights ? mooring.rights.claim : false

    const renters = this.state.mooring.renters || []
    const permanent_contacts = renters.filter(renter => renter.exemption == 0)
    const exemption_contacts = renters.filter(renter => renter.exemption == 1)
    const active_contacts = renters


    var boat_info = {}
    if (active_contacts[0]) boat_info = active_contacts[0].boat;


    return (
      <div id="mooring_details_container">
        <EditContactModal
          onRef={ref => (this.EditContactModal = ref)}
        />
        <Row>
          <Col md={12}>
            <StatusAlerts
              state={this.state}
            />
          </Col>
        </Row>



        {own &&
          <MooringItem
            mooring={mooring}
            history={this.props.history}
            editClicked={() => this.props.editClicked(mooring)}
          />
        }
        {!own &&
          <div style={styles.section}>
            <h2>
              {mooring.harbour_name} {mooring.berth_number}
            </h2>
          </div>
        }

        {app_visible && mooring.rights && mooring.rights.read_details &&
          <div style={styles.section}>
            <BookingCalendar
              mooring={mooring}
              onFromDateChange={date => {
                from_date = date.format("YYYY-MM-DD")
                this.setState({ from_date: from_date }, () => {
                  this.reloadMooring()
                })
                this.props.onFromDateChange(from_date)
              }}
              onToDateChange={date => {
                to_date = date.format("YYYY-MM-DD")
                this.setState({ to_date: to_date }, () => {
                  this.reloadMooring()
                })
                this.props.onToDateChange(to_date)
              }}
              reloadMooring={this.reloadMooring}
            />
          </div>
        }

        {
          true &&
          claimable &&
          <div style={styles.section}>
            <div>
              <Button
                style={{ width: "100%", backgroundColor: COLORS.MAIN_GREEN, borderColor: COLORS.MAIN_GREEN }}
                onClick={() => this.handleClaimClick()}
              >
                {t('MooringDetails.claim')}
              </Button>

            </div>
          </div>
        }

        {
          bookable &&
          <div style={styles.section}>

            <Row>
              <Col>
                <FormInput
                  type="date"
                  required
                  label={t('global.from')}
                  value={moment(this.props.from_date).format('YYYY-MM-DD')}
                  onChange={(e) => {
                    const date = e.target.value
                    this.props.onFromDateChange(date);
                  }}
                />
              </Col>
              <Col>
                <FormInput
                  type="date"
                  required
                  label={t('global.to')}
                  value={moment(this.props.to_date).format('YYYY-MM-DD')}
                  onChange={(e) => {
                    const date = e.target.value
                    this.props.onToDateChange(date);
                  }}
                />
              </Col>
            </Row>



            <div>

              <FormInput
                as="textarea"
                type="text"
                label={t('MooringDetails.notes')}
                placeholder="..."
                value={this.state.notes}
                rows={getTextAreaHeight(this.state.notes)}
                onChange={(e) => { this.setState({ notes: e.target.value }) }}
              />

              <Button
                style={{ width: "100%", backgroundColor: COLORS.MAIN_GREEN, borderColor: COLORS.MAIN_GREEN }}
                onClick={() => this.handleBookClick()}>
                {t('MooringDetails.bookMooring')}
              </Button>



            </div>
          </div>
        }


        {mooring.rights && mooring.rights.read_details &&
          <div id="mooring_details_info" style={styles.section}>

            <h3>{t('MooringDetails.info')}</h3>

            {mooring.mooring_zone &&
              <FormInput
                readOnly
                type="text"
                label={t('MooringDetails.mooringZone')}
                value={`${mooring.mooring_zone}`}
              />
            }

            <FormInput
              readOnly
              type="text"
              label={t('MooringDetails.lengthBeamDraft')}
              value={`${parseFloat(mooring.max_length).toFixed(2)} / ${parseFloat(mooring.max_beam).toFixed(2)} / ${parseFloat(mooring.max_draught).toFixed(2)}`}
              unit="m"
            />

            {mooring.mooring_type &&
              <FormInput
                readOnly
                type="text"
                label={t('MooringDetails.mooringType')}
                value={`${mooring.mooring_type}`}
              />
            }


            {/* Show all renters without exemtion */}
            {((permanent_contacts && permanent_contacts.length > 0) || management) &&
              <div style={{ marginBottom: 5 }}>
                <Row>
                  <Col>
                    <div style={{
                      color: COLORS.MAIN_GRAY,
                      alignSelf: 'center',
                      fontSize: 14,
                      float: 'left'
                    }}>
                      {t('MooringDetails.permanentRenter').toUpperCase()}
                    </div>
                  </Col>
                </Row>
                <Row>
                  {permanent_contacts &&
                    permanent_contacts
                      .map((renter) => {
                        var renter_name = `${renter.contact.firstname} ${renter.contact.lastname}`
                        if (renter.provisional == 1) renter_name = `(${renter_name})`
                        return < Col key={renter.id}>
                          <span
                            style={{ border: "none", borderRadius: 0, paddingLeft: 0, backgroundColor: "white", color: COLORS.MAIN_BLUE }}
                            onClick={() => this.EditContactModal.show(renter.contact)}
                            type={"text"}
                          >
                            {renter_name}
                          </span>
                        </Col>
                      })
                  }
                  <Col>
                    <img
                      style={{ width: 30, marginTop: 0, float: "right" }}
                      onClick={this.onClickAssignedContacts}
                      src={TableIcon}
                      alt="tableicon"
                    />
                  </Col>
                </Row>
              </div >
            }


            {/* Show all renters with exemtion */}
            {exemption_contacts.length != 0 &&
              <div style={{ marginBottom: 5 }}>
                <Row>
                  <Col>
                    <div style={{
                      color: COLORS.MAIN_GRAY,
                      alignSelf: 'center',
                      fontSize: 14,
                      float: 'left'
                    }}>
                      {t('MooringDetails.exemptedFor').toUpperCase()}
                    </div>
                  </Col>
                </Row>
                <Row>
                  {exemption_contacts &&
                    exemption_contacts
                      .map((renter) => {
                        var renter_name = `${renter.contact.firstname} ${renter.contact.lastname}`
                        if (renter.provisional == 1) renter_name = `(${renter_name})`
                        return < Col key={renter.id}>
                          <span
                            style={{ border: "none", borderRadius: 0, paddingLeft: 0, backgroundColor: "white", color: COLORS.MAIN_BLUE }}
                            onClick={() => this.EditContactModal.show(renter.contact)}
                            type={"text"}
                          >
                            {renter_name}
                          </span>
                        </Col>
                      }
                      )

                  }

                </Row>
              </div >

            }
            {boat_info.length &&
              <>
                <FormInput
                  readOnly
                  type="text"
                  label={t('MooringDetails.shipSizeLBD')}
                  value={`${parseFloat(boat_info.length).toFixed(2)} / ${parseFloat(boat_info.beam).toFixed(2)} / ${parseFloat(boat_info.draft).toFixed(2)}`}
                />
                <FormInput
                  readOnly
                  type="text"
                  label={t('MooringDetails.boatRegistrationNumber')}
                  value={`${boat_info.registration_number}${boat_info.registration_number && boat_info.name ? " / " : ""}${boat_info.name}`}
                />
              </>
            }
            {mooring.marina_notes &&
              <FormInput
                readOnly
                type="text"
                label={t('MooringDetails.marina_notes')}
                value={`${mooring.marina_notes}`}
              />
            }
          </div>



        }



        {mooring.boatpark_account &&
          <div style={styles.section}>

            <h3>{t('MooringDetails.boatparkAccount')}</h3>

            <FormInput
              readOnly
              type="text"
              label={t('MooringDetails.email')}
              value={`${mooring.boatpark_account.email}`}
            />

            <FormInput
              readOnly
              type="text"
              label={t('MooringDetails.name')}
              value={`${mooring.boatpark_account.lastname} ${mooring.boatpark_account.firstname}`}
            />

            <FormInput
              readOnly
              type="text"
              label={t('MooringDetails.mobile')}
              value={`${mooring.boatpark_account.mobilenumber}`}
            />



          </div>
        }
        {
          this.state.mooring.rights && this.state.mooring.rights.transfer == 1 &&
          <div style={styles.section}>
            <TransferMooringSection
              reloadMooring={this.reloadMooring}
              mooring={this.state.mooring}
            />
          </div>
        }

      </div >


    );
  }
}



const styles = {
  section: {
    backgroundColor: "white",
    marginBottom: 20,
    padding: 20,
    borderRadius: 5,
  },


}

export default withTranslation()(MooringDetails)