import React from "react";
import { withTranslation } from 'react-i18next';

import { verifyMobile } from "../actions/auth";

import { Container, FormControl, Form, Button, Alert } from "react-bootstrap";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      password: "",
      loading: false,
      success: false,
      error: false,
    };

  }

  componentDidMount() {
    if (this.props.match.params.id) {
      let account_id = this.props.match.params.id;
      this.setState({ account_id: account_id })
    }
    else {
      this.setState({
        error: "This page is no longer valid"
      })
    }
  }

  handleActivateClicked = (e) => {
    this.setState({ loading: true, success: false, error: false }, () => {
      const activationObject = {
        code: this.state.token,
        account_id: this.state.account_id,
      };
      verifyMobile(activationObject, this.verifySuccess, this.verifyError);
    });
  };

  verifySuccess = () => {
    this.setState({ loading: false, success: true, error: false });
    window.location.replace(this.props.t("links.website"));
  };

  verifyError = (error) => {
    const errorMessage = this.props.t(`ActivateAccount.activationFailed`);
    this.setState({ loading: false, success: false, error: errorMessage });
  }

  render() {
    const { t } = this.props
    return (
      <Container style={{ maxWidth: 500, marginTop: 100, borderRadius: 5 }}>
        <Form>

          {t("ActivateAccount.mobileInfo")}
          <div style={{ overflow: "hidden", height: 20 }}></div>
          <Form.Group>
            <Form.Label>{t('ActivateAccount.activationCode')}</Form.Label>
            <FormControl
              id="input_activation_code"
              type="number"
              value={this.state.token}
              onChange={(text) => {
                this.setState({ token: text.target.value });
              }}
              onKeyPress={event => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  this.handleActivateClicked();
                }
              }}
            />
            <Form.Text className="text-muted">
              {/* We'll never share your email with anyone else. */}
            </Form.Text>
          </Form.Group>


          <Button id="button_activate" variant="primary" onClick={() => this.handleActivateClicked()} >
            {t('ActivateAccount.activate')}
          </Button>
          <div style={{ overflow: "hidden", height: 20 }}></div>
          {t("ActivateAccount.emailInfo")}


          <div style={{ height: 15 }} />
          {this.state.loading && (
            <Alert key={1} variant={"primary"}>
              {t('global.loading')}
            </Alert>
          )}

          {this.state.error && (
            <Alert id="alert_activation_failed" key={1} variant={"danger"}>
              {this.state.error}
            </Alert>
          )}
        </Form>


      </Container >
    );
  }
}


export default withTranslation()(Login);