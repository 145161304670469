import axios from "axios";
import i18n from "../i18n";

export function getMyAccountData(callback, errorcallback) {
  axios
    .get("/account/get_account_data")
    .then((response) => {
      if (response.status == 200) {
        var accountData = response.data.accountData;
        i18n.changeLanguage(accountData.communication_language.toLowerCase());
        callback(accountData);
      }
    })
    .catch((err) => {
      errorcallback(err);
    });
}

export function getMyAccount(callback, errorcallback) {
  axios
    .get("/account/get_my_account")
    .then((response) => {
      if (response.status == 200) {
        callback(response.data);
      }
    })
    .catch((err) => {
      errorcallback(err);
    });
}


export function getAccountDataByToken(token, callback, errorcallback) {
  axios
    .get(`/account/get_by_token?token=${token}`)
    .then((response) => {
      if (response.status == 200) {
        var accountData = response.data;
        callback(accountData);
      }
    })
    .catch((err) => {
      errorcallback(err);
    });
}

export function getMyBankAccountData(callback, errorcallback) {
  axios
    .get("/account/get_account_data")
    .then((response) => {
      if (response.status == 200) {
        var bankaccountData = response.data.bankaccountData;
        callback(bankaccountData);
      }
    })
    .catch((err) => {
      errorcallback(err);
    });
}

export function updateBankAccountData(bankAccountData, callback, errorcallback) {
  console.log("updateBankAccountData", bankAccountData)
  axios
    .post("account/update_bankaccount_data", bankAccountData)
    .then((response) => {
      if (response.status == 200) {
        callback(response.data);
      }
    })
    .catch((err) => {
      errorcallback(err);
    });
}


export const updateAccountData = (accountData, callback, errorcallback) => {
  i18n.changeLanguage(accountData.communication_language.toLowerCase());
  axios
    .post("account/update_account_data", accountData)
    .then((response) => {
      if (response.status == 200) {
        callback(response.data);
      }
    })
    .catch((err) => {
      errorcallback(err);
    });
};


export const checkIfEmailExists = (email, callback, errorcallback) => {
  axios
    .post(`account/check_if_email_exists?email=${email}`,)
    .then((response) => {
      if (response.status == 200) {
        callback(response.data);
      }
    })
    .catch((err) => {
      errorcallback(err);
    });
};
