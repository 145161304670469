import React from 'react';
import PropTypes from 'prop-types';
import COLORS from '../constants/colors';
import { useTranslation } from 'react-i18next';
import SegmentedControl from './SegmentedControl';
// import SegmentedControl from '@react-native-segmented-control/segmented-control';
import {
    Container,
    Form,
    Row,
    Button,
    Col,
    Alert,
    Modal,
} from "react-bootstrap";

export default function FormSegmentedControl(props) {

    const { t, i18n } = useTranslation();
    const label = props.label || "";
    const last = props.last ? true : false;
    const { error } = props
    var errorMessage = ""
    if (error) {
        errorMessage = error == "*" ? "*" : t(`validators.${error}`)
    }
    return (
        <div style={{ marginBottom: 5 }}>


            {error && <div style={{
                float: "right", color: "red", marginRight: 10, color: COLORS.MAIN_RED,
                alignSelf: 'center',
                fontSize: 14,
                left: 3,
            }}>{errorMessage}</div>}
            <Row>
                <Col lg={6} md={12} style={{
                    color: COLORS.MAIN_GRAY,
                    alignSelf: 'center',
                    fontSize: 14,
                    float: 'left'
                }}>
                    {label.toUpperCase()}
                </Col>
                <Col lg={6} md={12} >
                    <SegmentedControl
                        style={{ border: "none" }}
                        variant="base"
                        segments={props.segments}
                        onChangeSegment={props.onChange}
                        selected={props.segments.indexOf(props.value)}
                        {...props}
                    />
                </Col>
            </Row>
            {!last && <div style={{ width: "100%", height: 1, backgroundColor: COLORS.GRAY_OUTLINE }}></div>}
        </div >
    );


}

FormSegmentedControl.propTypes = {
    label: PropTypes.string.isRequired,
    segments: PropTypes.any.isRequired,
};



// const styles = StyleSheet.create({
//     container: {
//         flex: 1
//     },
//     asterisk: {
//         color: COLORS.MAIN_RED,
//         fontSize: 17
//     },
//     inputContainer: {
//         flex: 1,
//         flexDirection: 'column',
//         alignItems: 'center',
//         height: '8%',
//     },
//     valueContainer: {
//         flex: 1,
//         flexDirection: 'row',
//         alignItems: 'center'
//     },
//     errorContainer: {
//         flexDirection: 'row'
//     },
//     errorMessage: {
//         color: COLORS.MAIN_RED,
//         alignSelf: 'center',
//         fontSize: 17,
//         left: 3,
//     },
//     input: {
//         flex: 1,
//         paddingVertical: 10,
//         paddingLeft: 0,
//         color: COLORS.MAIN_BLUE,
//         textAlign: 'right',
//         marginRight: "3%",
//         fontSize: 17,
//     },
//     separator: {
//         height: 1,
//         borderBottomColor: COLORS.LINE_GRAY,
//         borderBottomWidth: 0.5,
//     },
//     formItemContainer: {
//         flex: 1,
//         justifyContent: 'space-between',
//         flexDirection: 'row',
//         width: '90%'
//     },
//     unit: {
//         color: COLORS.MAIN_BLUE,
//         marginRight: "3%",
//         fontSize: 17
//     },
//     formLabel: {
//         color: COLORS.MAIN_GRAY,
//         alignSelf: 'center',
//         fontSize: 17
//     }
// });

