import axios from "axios";
import moment from "moment";


export function getBoundsForMarina(callback, errorcallback) {

  const url = `moorings/get_bounds_for_marina`
  console.log(url);
  axios
    .get(url)
    .then((response) => {
      if (response.status == 200) {
        var bounds = response.data;
        console.log("bounds", bounds);
        callback(bounds);
      }
    })
    .catch((err) => {
      errorcallback(err.response);
    });
}

export function getMyMoorings(callback, errorcallback, date = null) {
  const url = date ? `moorings/get_my_moorings_with_renter_names?date=${date}` : "moorings/get_my_moorings_with_renter_names";
  axios
    .get(url)
    .then((response) => {
      if (response.status == 200) {
        var moorings = response.data.moorings;
        callback(moorings);
      }
    })
    .catch((err) => {
      errorcallback(err.response);
    });
}

export function getMyMooringsWithAvailabilities(from_date, to_date, callback, errorcallback) {

  from_date = moment(from_date).format("YYYY-MM-DD");
  to_date = moment(to_date).format("YYYY-MM-DD");
  axios
    .get(`moorings/get_moorings_with_availabilities?from_date=${from_date}&to_date=${to_date}`)
    .then((response) => {
      if (response.status == 200) {
        var moorings = response.data;

        callback(moorings);
      }
    })
    .catch((err) => {
      errorcallback(err.response);
    });
}

export function getMyMooringsDetailed(callback, errorcallback, date = null) {
  date = moment(date).format('YYYY-MM-DD');
  const url = date ? `moorings/get_my_moorings_width_details?date=${date}` : "moorings/get_my_moorings";
  axios
    .get(url)
    .then((response) => {
      if (response.status == 200) {
        var moorings = response.data.moorings;
        callback(moorings);
      }
    })
    .catch((err) => {
      errorcallback(err.response);
    });
}

export function getMooringsByContactId(contact_id, callback, errorcallback) {
  axios
    .get(`moorings/get_by_assigned_contact_id?contact_id=${contact_id}`)
    .then((response) => {
      if (response.status == 200) {
        var moorings = response.data;
        callback(moorings);
      }
    })
    .catch((err) => {
      errorcallback(err.response);
    });
}

export function getMyMooring(mooring_id, callback, errorcallback, from_date = null, to_date = null) {
  var requestUrl = `moorings/get_my_mooring?mooring_id=${mooring_id}`;
  if (from_date != null) {
    from_date = moment(from_date).format("YYYY-MM-DD");
    to_date = moment(to_date).format("YYYY-MM-DD");
    requestUrl = `moorings/get_my_mooring?mooring_id=${mooring_id}&from_date=${from_date}&to_date=${to_date}`;
  }
  axios
    .get(requestUrl)
    .then((response) => {
      if (response.status == 200) {
        var mooring = response.data.mooring;
        callback(mooring);
      }
    })
    .catch((err) => {
      errorcallback(err.response);
    });
}

export function getAllMoorings(from_date, to_date, bounds, callback, errorcallback) {
  from_date = moment(from_date).format("YYYY-MM-DD");
  to_date = moment(to_date).format("YYYY-MM-DD");

  const url = `moorings/search_moorings?from_date=${from_date}&to_date=${to_date}`

  axios
    .get(url, {
      params: {
        bounds: bounds,
      }
    })
    .then((response) => {
      if (response.status == 200) {
        var moorings = response.data;
        callback(moorings);
      }
    })
    .catch((err) => {
      errorcallback(err.response);
    });
}

export const updateMooring = async (mooring, callback, errorcallback) => {
  axios
    .post("moorings/update_mooring", mooring)
    .then((response) => {
      if (response.status == 200) {
        callback(response.data);
      }
    })
    .catch((err) => {
      errorcallback(err.response);
    });
};

export const updateFinances = async (mooring, callback, errorcallback) => {
  axios
    .post("moorings/update_currency", mooring)
    .then((response) => {
      if (response.status == 200) {
        callback(response.data);
      }
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const createMooring = async (mooring, callback, errorcallback) => {
  axios
    .post("moorings/create_mooring", mooring)
    .then((response) => {
      if (response.status == 200) {
        callback(response.data);
      }
    })
    .catch((err) => {
      errorcallback(err);
    });
};

export const deleteMooring = async (mooring_id, callback, errorcallback) => {
  axios
    .post("moorings/delete_mooring", { mooring_id: mooring_id })
    .then((response) => {
      console.log("Res", response)
      if (response.status == 200) {
        callback(response.data);
      }
    })
    .catch((err) => {
      errorcallback(err.response);
    });
};

export function claimMooring(mooring_id, callback, errorcallback) {
  axios
    .post(`moorings/claim_mooring?mooring_id=${mooring_id}`)
    .then((response) => {
      if (response.status == 200) {
        var data = response.data;
        callback(data);
      }
    })
    .catch((err) => {
      errorcallback(err.response);
    });
}

export function getMyMooringTypes(callback, errorcallback) {
  axios
    .get("moorings/get_my_mooring_types")
    .then((response) => {
      if (response.status == 200) {
        var mooring_types = response.data.mooring_types;
        callback(mooring_types);
      }
    })
    .catch((err) => {
      errorcallback(err.response);
    });
}


export function updateMooringTypes(mooring_types, callback, errorcallback) {
  axios
    .post("moorings/update_mooring_types", mooring_types)
    .then((response) => {
      if (response.status == 200) {
        callback();
      }
    })
    .catch((err) => {
      errorcallback(err.response);
    });
}


export function getMyMooringZones(callback, errorcallback) {
  axios
    .get("moorings/get_my_mooring_zones")
    .then((response) => {
      if (response.status == 200) {
        var mooring_zones = response.data.mooring_zones;
        callback(mooring_zones);
      }
    })
    .catch((err) => {
      errorcallback(err.response);
    });
}


export function updateMooringZones(mooring_zones, callback, errorcallback) {
  axios
    .post("moorings/update_mooring_zones", mooring_zones)
    .then((response) => {
      if (response.status == 200) {
        callback();
      }
    })
    .catch((err) => {
      errorcallback(err.response);
    });
}


export function transferMooring(mooring, email, callback, errorcallback) {
  axios
    .post("moorings/transfer_mooring", { mooring: mooring, email: email })
    .then((response) => {
      if (response.status == 200) {
        callback();
      }
    })
    .catch((err) => {
      errorcallback(err);
    });
}

export function sendTransferInvitation(mooring, email, language, callback, errorcallback) {
  axios
    .post("moorings/send_transfer_invitation", { mooring: mooring, email: email, language: language })
    .then((response) => {
      if (response.status == 200) {
        callback();
      }
    })
    .catch((err) => {
      errorcallback(err);
    });
}

export function getCountryCode(lat, lon, callback, errorcallback) {
  axios
    .get(`https://api.opencagedata.com/geocode/v1/json?key=9674fd4dd1794b589139c45192e1e0f6&q=${lat}%2C${lon}`)
    .then((response) => {
      if (response.status == 200) {
        console.log("response", response.data.results[0].components["ISO_3166-1_alpha-2"])
        const countryCode = response.data.results[0].components["ISO_3166-1_alpha-2"]
        callback(countryCode);
      }
    })
    .catch((err) => {
      errorcallback(err);
    });
}

export function replaceConfidentialCode(old_code, new_code, callback, errorcallback) {
  axios
    .post("moorings/replace_confidential_code", { old: old_code, new: new_code })
    .then((response) => {
      if (response.status == 200) {
        callback();
      }
    })
    .catch((err) => {
      errorcallback(err);
    });
}
export function getPublicMooringInfo(mooring_id, from_date, to_date, callback, errorcallback) {

  from_date = moment(from_date).format("YYYY-MM-DD");
  to_date = moment(to_date).format("YYYY-MM-DD");

  axios
    .get(`moorings/get_public_info?mooring_id=${mooring_id}&from_date=${from_date}&to_date=${to_date}`)
    .then((response) => {
      if (response.status == 200) {
        console.log("response", response.data)
        callback(response.data);
      }
    })
    .catch((err) => {
      errorcallback(err);
    });
}



export function getCustomDailyRate(mooring_id, booking_time_id, boat_length = null, boat_beam = null, callback, errorcallback) {
  console.log(`booking_times/get_costom_daily_rate?mooring_id=${mooring_id}&booking_time_id=${booking_time_id}&boat_length=${boat_length}&boat_beam=${boat_beam}`)
  if (boat_length == null || boat_length == "") boat_length = 0
  if (boat_beam == null || boat_beam == "") boat_beam = 0
  axios
    .get(`booking_times/get_costom_daily_rate?mooring_id=${mooring_id}&booking_time_id=${booking_time_id}&boat_length=${boat_length}&boat_beam=${boat_beam}`)
    .then((response) => {
      if (response.status == 200) {
        console.log("response", response.data)
        callback(response.data);
      }
    })
    .catch((err) => {
      errorcallback(err);
    });
}

