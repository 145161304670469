import React, { useState, useEffect } from "react";
import { withTranslation } from 'react-i18next';

import moment from "moment";
// import MyMapComponent from '../components/MooringsMap'
import { MooringsMap } from '../components/MooringsMap'

import { Row, Button, Col, } from "react-bootstrap";

import { getAllMoorings } from "../actions/moorings";
import { getMyMapOverlays } from "../actions/map_overlays";
import { getMyAccountData } from '../actions/account'
import { getBoundsForMarina } from "../actions/moorings";


import FormInput from "../components/FormInput";
import Form from "react-bootstrap/esm/Form";
import QRIcon from '../assets/icons/bp_qr_symbol.png'

import COLORS from '../constants/colors';
const i18nPrefix = 'FindMoorings';


const Cursor = () => {
  var [position, setPosition] = useState({ x: 0, y: 0 });

  position.x -= 10
  position.y -= 38

  useEffect(() => {
    addEventListeners();
    return () => removeEventListeners();
  }, []);

  const addEventListeners = () => {
    document.addEventListener("mousemove", onMouseMove);
  };

  const removeEventListeners = () => {
    document.removeEventListener("mousemove", onMouseMove);
  };

  const onMouseMove = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };
  return (
    <div>
      <img className="cursor"
        style={{ left: `${position.x}px`, top: `${position.y}px` }} src={"https://mt.googleapis.com/vt/icon/name=icons/spotlight/spotlight-poi.png&scale=1"} />
    </div >
  )

}

class FindMoorings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      error: false,
      allMoorings: false,
      filteredMoorings: false,
      add_mooring: false,
      change_mooring_position: false,
      center: [5, 6],
      bounds: null,
      filter: {
        show_permanent_moorings: false,
        show_foreign_moorings: true,
        show_map_overlays: false,
        show_berth_numbers: true,
      },
      map_overlays: []
    };
  }

  componentDidMount() {
    this.props.onRef(this)
    document.addEventListener("keydown", this.keyDown, false);

    getBoundsForMarina(
      (marina_bounds) => {
        console.log("bounds", marina_bounds);
        this.refreshMoorings(marina_bounds)
      },
      (error) => { }
    )
    getMyMapOverlays((map_overlays) => { this.setState({ map_overlays: map_overlays }) }, () => { })
    getMyAccountData((account) => { }, () => { })


    this.setState({
      filter: {
        show_permanent_moorings: JSON.parse(localStorage.getItem("show_permanent_moorings")),
        show_foreign_moorings: true,
        show_map_overlays: JSON.parse(localStorage.getItem("show_map_overlays")),
        show_berth_numbers: JSON.parse(localStorage.getItem("show_berth_numbers")),
      },
    })
  }

  refreshMoorings = (bounds = this.state.bounds) => {
    getAllMoorings(this.props.from_date, this.props.to_date, bounds, this.getAllMooringsSuccess, this.getAllMooringsError);
  }




  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
    this.props.onRef(undefined)
  }

  keyDown = (event) => {
    //If escape is pressed to the same as right click
    if (event.keyCode === 27) {
      this.onMapRightClick()
    }
  }

  getAllMooringsSuccess = (moorings) => {
    console.log("getAppmoorings success", moorings)
    this.filterMoorings(moorings)
  }

  getAllMooringsError = (response) => {
    this.setState({ loading: false, success: false, error: "something went wrong" });
  }

  reloadMap = () => {
    console.log("this.state.bounds", this.state.bounds)
    this.refreshMoorings()
    this.props.onReloadPress()
  }

  onMapClick = (e) => {

    if (this.state.add_mooring) {
      this.setState({ add_mooring: false })
      const position = {
        latitude: e.latLng.lat(),
        longitude: e.latLng.lng(),
      }
      this.props.addMooring(position)
    }
    else if (this.state.change_mooring_position) {
      this.setState({ change_mooring_position: false })
      const position = {
        latitude: e.latLng.lat(),
        longitude: e.latLng.lng(),
      }
      this.props.changeMooringPosition(position)
    }
    else {
      this.props.onMapClick()
    }
  }

  onMapRightClick = () => {
    // Dismiss add_mooring or change_mooring action
    this.setState({
      add_mooring: false,
      change_mooring_position: false
    })
    this.reloadMap()

  }

  addMooringPressed = () => {
    this.setState({ add_mooring: true })
  }

  filterMoorings = (moorings) => {

    var filteredMoorings = moorings || [];

    if (!this.state.filter.show_permanent_moorings) {
      filteredMoorings = filteredMoorings.filter((mooring) => mooring.app_visible == 1);
    }

    if (!this.state.filter.show_foreign_moorings) {
      filteredMoorings = filteredMoorings.filter((mooring) => mooring.own == 1);
    }

    this.setState({ filteredMoorings: filteredMoorings });
  }

  annimateToCoordinates = (lat, lon) => {
    this.setState({ center: [lat, lon] })
  }

  changeMooringPositionClicked = (mooring_id) => {

    var filteredMoorings = this.state.filteredMoorings.filter(function (mooring) {
      return mooring.mooring_id != mooring_id
    })

    this.setState({ change_mooring_position: true, filteredMoorings: filteredMoorings })
  }
  changeMooringPosition = (updated_mooring) => {

    const filteredMoorings = this.state.filteredMoorings.concat(updated_mooring)
    this.setState({
      filteredMoorings: filteredMoorings
    });

  }

  render() {
    const { t } = this.props;
    const allMoorings = this.state.allMoorings;
    const filteredMoorings = this.state.filteredMoorings
    var fdfdf = new Date(this.props.from_date)
    const filter = this.state.filter;
    var to_date_min = new Date()
    to_date_min.setDate(fdfdf.getDate() + 1);

    const role = localStorage.getItem("role");
    const management = role == "management";
    const private_role = role == "private";
    const right_add_mooring = this.props.account_data.rights?.create_mooring
    const show_add_mooring_button = !private_role && right_add_mooring
    console.log(this.props.account_data.rights?.create_mooring)

    const supervised_by_management = this.props.account_data.supervising_management ? true : false
    const option_show_permanent_moorings = true; //management || supervised_by_management;
    const option_show_marina_plan = this.state.map_overlays.length != 0

    const account_id = this.props.account_data.account_id
    const bpms_link = encodeURIComponent(`${process.env.REACT_APP_MS_LINK}${account_id}`);
    const QRLink = `https://api.qrserver.com/v1/create-qr-code/?size=512x512&data=${bpms_link}`;


    return (
      <div

        onContextMenu={(e) => this.onMapRightClick(e)}
        style={{ width: "100%", height: "100%" }}>
        {this.state.add_mooring &&
          <Cursor />
        }
        {
          this.state.change_mooring_position &&
          <Cursor />
        }
        <div style={{ marginBottom: 10, padding: 5, backgroundColor: "white", borderRadius: 5 }}>
          <Row>

            <Col md={3}>
              <FormInput
                id="input_from_date_home"
                label={t(`global.from`)}
                type="date"
                required
                value={moment(this.props.from_date).format("YYYY-MM-DD")}
                onChange={(e) => {
                  const date = moment(e.target.value).format("YYYY-MM-DD")
                  this.props.onFromDateChange(date)
                  this.setState({ from_date: date }, () => {
                    this.reloadMap()
                  })
                }}
              />
            </Col>

            <Col md={3}>
              <FormInput
                id="input_to_date_home"
                label={t(`global.to`)}
                type="date"
                required
                value={moment(this.props.to_date).format("YYYY-MM-DD")}
                onChange={(e) => {
                  const date = moment(e.target.value).format("YYYY-MM-DD")
                  this.props.onToDateChange(date)
                  this.setState({ to_date: date }, () => {
                    this.reloadMap()
                  })
                }}
              />
            </Col>


            <Col xs={10} md={5} style={{ marginTop: 15, }}>
              {(show_add_mooring_button) &&
                <Button id="button_add_mooring" style={{ ...styles.button }} onClick={this.addMooringPressed}>{t('MaintainMoorings.addNewMooring')}</Button>
              }
            </Col>

            <Col xs={1} md={1} style={{ marginTop: 15, }} >
              <a href={QRLink} target="_blank">
                <img
                  style={{ width: 28, marginTop: 6, }}
                  onClick={this.onClickWaitingContactsTable}
                  src={QRIcon}
                  alt="tableicon"
                />
              </a>

            </Col>
            {/* <Col>
              <Button style={styles.button} onClick={() => this.reloadMap()}>Reload</Button>
              </Col> */}

          </Row>
        </div>


        <div style={{ marginBottom: 10, padding: 5, backgroundColor: "white", borderRadius: 5 }}>

          <Row>
            {option_show_permanent_moorings &&
              <Col>
                <Form.Check id="show_permanent_moorings" type="checkbox" label={t(`${i18nPrefix}.showRentedMoorings`)}
                  checked={this.state.filter.show_permanent_moorings}
                  onChange={(event) => {
                    this.setState({ filter: { ...filter, show_permanent_moorings: event.target.checked } })
                    localStorage.setItem("show_permanent_moorings", JSON.stringify(event.target.checked))
                    this.reloadMap()
                  }}
                />
              </Col>
            }
            {option_show_marina_plan &&
              <Col>
                <Form.Check id="show_map_overlays" type="checkbox" label={t(`${i18nPrefix}.showMarinaMap`)}
                  checked={this.state.filter.show_map_overlays}
                  onChange={(event) => {
                    this.setState({ filter: { ...filter, show_map_overlays: event.target.checked } })
                    localStorage.setItem("show_map_overlays", JSON.stringify(event.target.checked))
                    this.reloadMap()
                  }}
                />


              </Col>
            }
            <Col>

              <Form.Check id="show_berth_numbers" type="checkbox" label={t(`${i18nPrefix}.showBerthNumbers`)}
                checked={this.state.filter.show_berth_numbers}
                onChange={(event) => {
                  this.setState({ filter: { ...filter, show_berth_numbers: event.target.checked } })
                  localStorage.setItem("show_berth_numbers", JSON.stringify(event.target.checked))
                  this.reloadMap()
                }}
              />

            </Col>

            {/* <Form.Check id="show_foreign_moorings" type="checkbox" label="Show foreign moorings"
              checked={this.state.filter.show_foreign_moorings}
              onChange={(event) => {
                this.setState({ filter: { ...filter, show_foreign_moorings: event.target.checked } })
                this.reloadMap()
              }}
            /> */}

          </Row>

        </div >

        < MooringsMap
          allMoorings={this.state.filteredMoorings || []}
          onPinClick={this.props.onPinClick}
          onClick={(e) => this.onMapClick(e)}
          add_mooring_position={this.props.add_mooring_position}
          filter={this.state.filter}
          map_overlays={this.state.map_overlays}
          setBounds={(bounds) => {
            this.setState({ bounds: bounds });
            this.refreshMoorings(bounds);
          }
          }
        />
      </div >
    )
  }
}

const styles = {
  button: {
    backgroundColor: "white",
    color: COLORS.MAIN_GRAY,
    fontWeight: "bold",
    borderColor: COLORS.MAIN_GRAY,
    marginRight: 10,
    marginTop: 5,
    marginBottom: 5,
  },


}

export default withTranslation()(FindMoorings);