const en = {
  MooringDetails: {
    title: "Book Mooring",
    details: "Details",
    occupiedBy: "Mooring usually occupied by",
    taxes: "Taxes",
    perAdultPerNight: "Per person in high tariff and night",
    perChildPerNight: "Per person in low tariff and night",
    coordinates: "Coordinates*",
    maxLength: "Max. length",
    maxBeam: "Max. beam",
    maxDraught: "Max. draft",
    lengthBeamDraft: "Length/Beam/Draft",
    berthNo: "Berth number/identification",
    marinaName: "Marina name",
    additionalDescription: "Additional Description",
    pierHeight: "Pier height (above water)",
    power: "Power available?",
    water: "Water available?",
    buoy: "Buoy place?",
    boatName: "Boat name",
    port: "Port of registry",
    from: "From",
    to: "To",
    numberOfAdults: "Per person in high tariff and night",
    numberOfChildren: "Per person in low tariff and night",
    totalTouristTaxes: "Total Tourist taxes",
    excluding: "Excluding ",
    including: "Including ",
    harbourFee: "the marina fee",
    cancel: "Cancel",
    notes: "notes",
    timesAndPrices: "Times and Prices",
    bookMooring: "Book Mooring",
    edit: "Edit",
    claim: "Claim",
    mooringZone: "Mooring Zone",
    mooringType: "Mooring Type",
    permanentRenter: "Permanent Renter",
    shipSizeLBD: "Boat dimensions (L x W x D)",
    boatRegistrationNumber: "Boat",
    info: "Info",
    exemptedFor: "Exempted for",
    boatparkAccount: "Boatpark account",
    email: "email",
    name: "name",
    mobile: "mobile",
    marina_notes: "Marina Notes",
  },
  Login: {
    email: "Email",
    password: "Password",
    login: "Login",
    register: "Register for Boatpark account",
    forgotPassword: "Forgot password?",
    emailHasBeenSentTitle: "Email has been sent",
    emailHasBeenSent: "An email has been sent. If you do not receive it, please check your spam folder.",
    confirmEmailTitle: "Confirm your email",
    confirmEmail: "Please confirm mail address via link in e-Mail sent to you. Do you need a new e-Mail?",
  },
  ForgotPassword: {
    title: "Forgot Password",
    email: "Email",
    errorsMessage: "Something went wrong. Please try again.",
    emailHasBeenSent: "An email has been sent. If you do not receive it, please check your spam folder.",
    reset: "Reset",
    register: "Register for Boatpark account",
    weWillSendEmail: "We will send you an email with a link, where you can reset your password.",
    info: "Enter your email to receive a link where you can change your password.",
    send: "Send",
  },
  Register: {
    title: "Register",
    register: "Save",
    roles: ["Private", "Marina"],
    communicationLanguages: ["EN", "DE", "FR", "IT", "ES"],
    communicationLanguage: "Communication Language",
    role: "role",
    iAcceptThe: "I accept the ",
    lastName: "Lastname or Company name",
    firstName: "First name",
    email: "Email",
    mobile: "Mobile phone (ex. +41 12 345 67 89)",
    password: "Password",
    repeatPassword: "Repeat Password",
    referral: "Referral / Ambassador e-Mail",
    TCs: "T&Cs",
    registerSuccess: "Registration successful",
    OptionalBoatInfo: "Boat Info",
    name: "Boat Name",
    registration_number: "Registration Number",
    length: "length",
    beam: "beam",
    draft: "draft",
    continueAsGuest: "Continue as guest",
    pay: "Pay Now",
  },
  FastBooking: {
    from: "from",
    to: "to",
    booking_price: "Booking price",
    tax_price: "Total Taxes",
    total_price: "Total Price",
    taxes: "Taxes",
    perAdultPerNight: "Tax high tariff per night",
    perChildPerNight: "Tax low tariff per night",
    numberOfAdults: "Number of persons high tariff",
    numberOfChildren: "Number of persons low tariff",
    mooringUnavailable: "This mooring is no longer available for Instant booking. Please use the Boatpark-App or take another mooring.",
    LBH: "Length / Beam / Draft",
    bookingHasBeenSaved: "Your booking has been saved. Check your Email/SMS to complete your registration after safely mooring your boat. You can also do it now using ",
    thisLink: "this link.",
    TheReservationWillBePaid: "The reservation will be paid upon completion of your registration.",
    YouAreAboutToBook: "You are about to book this place for one night for {{currency}} {{amount}}, do you want to continue?",
    YourInformation: "Your information",
    MooringInformation: "Information about the Mooring",
    firstname: "firstname",
    lastname: "lastname",
    email: "Email",
    mobile: "Mobile phone (ex. +41 12 345 67 89)",
    reserve: "Reserve",
    activationCode: "Mobile Activation Code",
    confirm: "Confirm",
    totalPrice: "Total Price",
    additionalDescription: "Additional description",
    boat_length: "Boat length",
    boat_beam: "Boat beam",
    checkInTime: "Check in: ",
    checkOutTime: "Check out: ",
    boat_name: "Boat name",
    boat_registration_number: "Boat registration number",
    freeBookingHasBeenSaved: "Your booking has been saved.",
    bookNow: "Book now",
    bookingSuccessful: "The mooring has been successfully reserved.",
    payment_method: "Payment method",
  },
  ResetPasasword: {
    title: "Reset Pasasword",
    password: "New password",
    passwordRepeat: "Password (Repeat)",
    reset: "Reset",
    paswordHasBeenReset: "Password has been reset",
  },
  EditContactModal: {
    editContact: "Edit Contact",
    newContact: "New Contact",
    communicationLanguages: ["EN", "DE", "FR", "IT", "ES"],
    communicationLanguage: "Communication Language",
    salutation: "Salutation",
    lastName: "Lastname or Company name",
    firstName: "First name",
    email: "Email",
    mobile: "Mobile phone (ex. +41 12 345 67 89)",
    creationSuccess: "Creation successful",
    street: "street",
    zip: "zip",
    city: "city",
    birthday: "birthday",
    phone_p: "phone private",
    phone_b: "phone work",
    alternative_email: "alternative email",
    alternative_mobile: "alternative mobile",
    type: "Type",
    access_badge: "Access badge",
    remarks: "remarks",
    vat: "vat",
    edit: "Edit",
    show: "Show",
    boats: "Boats",
    createContact: "Create Contact",
    save: "save",
    deleteContact: "Delete Contact",
    addNewBoat: "Add new boat",
    areYouSureDeleteContact: "Are you sure you wish to delete this contact?",
    boatparkAccount: "Boatpark Account",
    token: "Token",
    customer_number: "Customer Number",
    id: "ID",
    moorings: "Moorings",
    watiting_contacts: "Waiting for mooring",
  },
  ContactTable: {
    addNewContact: "Add new contact",
    firstname: "Firstname",
    lastname: "Lastname",
    email: "Email",
    mobile: "Mobile",
    street: "Street",
    communication_language: "Language",
    zip: "Zip",
    city: "City",
    birthday: "Birthday",
    type: "Type",
    access_badge: "Access badge",
    remarks: "Remarks",
    token: "Token",
    customer_number: "Customer Number",
    id: "ID",
    editOn: "Edit: on",
    editOff: "Edit: off",
  },
  EditBoatModal: {
    editBoat: "Edit Boat",
    name: "Boat Name",
    registration_number: "Registration Number",
    length: "length",
    beam: "beam",
    draft: "draft",
    type: "type",
    drive: "drive",
    weight: "weight",
    createBoat: "Create Boat",
    save: "save",
    deleteBoat: "Delete Boat",
    boatTypes: ["", "Motor", "Sail",],
    boatDrives: ["None", "Gasoline", "Diesel", "Electric", "Hybrid",],
    documents: "Documents",
    addNewDocument: "Add new document",
    show: "Show",
    areYouSureDeleteBoat: "Are you sure you wish to delete this boat?",
    showPDF: "Show Boat Documents",
    make: "Make",
  },
  EditBoatDocumentModal: {
    editBoatDocument: "Edit document",
    createBoatDocument: "Create document",
    title: "Title",
    image: "Image",
    save: "Save",
    deleteBoatDocument: "Delete document",
    areYouSureDeleteDocument: "Are you sure you wish to delete this document?",
    onlyImages: "Please only upload images (PNG, JPG, JPEG)",
  },
  MaintainAssignedContacts: {
    title: "Maintain Renters",
    editRenter: "Edit Renter",
    addRenter: "Add new Renter",
    correspondence: "Correspondence",
    exemption: "Exemption",
    provisional: "Provisional",
    contact: "Contact",
    boat: "Boat",
    from: "From",
    to: "To",
    notes: "Notes",
  },
  EditWaitingContactModal: {
    editReservation: "Edit entry",
    addReservation: "Add entry",
    contact: "Contact",
    entryDate: "Entry date",
    mooring_zone: "Mooring Zone",
    length: "Length",
    beam: "Beam",
    draft: "Draft",
    mooring_type: "Mooring Tye",
    annual_mooring_interested: "Interested in annual reservation",
    relocation: "Relocation",
    comment: "Comment",
    last_examination: "Last examination",
    archived: "Archived",
    archiving_reason: "Reason for archiving",
    archiving_date: "Archiving date",
    currency: "Currency",
    registration_fee: "Registration Fee",
    periodic_fee: "Periodic Fee",
    payment_period: "Payment Period [Years]",
    areYouSureDeleteEntry: "Are you sure you want to delete this entry? You can also archive it.",
    boat_type: "Boat type",
    WaitingContactShouldNotBeUnarchived: "Waiting list entries should not be un-archived.",
  },
  WaitingContactTable: {
    contact_name: "Name",
    contact_address: "Address",
    contact_type: "Contact Type",
    entry_date: "Entry date",
    mooring_zone: "Mooring zone",
    mooring_type: "Mooring type",
    boat_type: "Boat type",
    min_length: "Min. length",
    min_beam: "Min. beam",
    min_draught: "Min. draft",
    relocation: "Relocation",
    annual_mooring_interested: "annual mooring",
    comment: "Comment",
    archiving_reason: "Archiving reason",
    archiving_date: "Archiving date",
    registration_fee: "Registration Fee",
    periodic_fee: "Periodic Fee",
    currency: "Currency",
    payment_period: "Payment Period [Years]",
    last_examination: "Last examination",
    all: "All",
    active: "Active",
    archived: "Archived",
    status: "Status",
    editOn: "Edit: on",
    editOff: "Edit: off",
    showFilter: "Filter",
    resetFilter: "Reset",
    berth_number: "Berth number",
    dimensions: "Dimensions (L/B/D)",
    addEntry: "Add entry",
    email: "Email",
  },
  Account: {
    title: "Account",
    role: "Role",
    roles: ["Private", "Marina"],
    communicationLanguages: ["EN", "DE", "FR", "IT", "ES"],
    communicationLanguage: "Communication language",
    firstName: "First name",
    lastName: "Lastname or Company name",
    email: "Email",
    mobile: "Mobile phone (ex. +41 12 345 67 89)",
    myBoat: "My Boat",
    boatRegistrationNumber: "Boat registration number",
    boatName: "Boat name and port of registry",
    beam: "Beam [m]",
    length: "length [m]",
    draft: "draft [m]",
    boatDocumentScans: "Boat documents (Scans)",
    additionalInformation: "Additional Information",
    reimbursementBankAccount: "Reimbursement Bank Account",
    paymentmethod: "Payment Method",
    vat: "VAT number (if relevant)",
    changePassword: "Change Password",
    password: "New password",
    passwordRepeat: "Password (Repeat)",
    balance: "Balance",
    success: "Updated Account Data",
    types: "Types",
    mooringTypes: "Mooring Types",
    addMooringType: "Add Mooring Type",
    mooringZones: "Mooring Zones",
    addMooringZone: "Add Mooring Zone",
    contactTypes: "Contact Types",
    addContactType: "Add Contact Types",
    default_currency: "Default Currency",
    default_periodic_fee: "Default periodic fee",
    default_registration_fee: "Default registration fee",
    default_payment_period: "Default payment period",
    manual_booking_confirmation: "Manual booking confirmation",
    automatic_response: "automatic response",
    automatic_response_after: "automatic response after",
    accept: "Accept",
    decline: "Decline",
    salutation: "Salutation",
    street: "street",
    zip: "zip",
    city: "city",
    country: "country",
    bankAccountData: "Reimbursement Bank Account",
    currency: "currency",
    iban: "IBAN",
    owner_name: "owner name",
    confirmation_emails: "Email confirmations",
    confirmation_emails_own: "Email confirmations for own bookings",
    settings: "Settings",
    csv_separator: "CSV Separator",
    email_cc: "Email copy to",
    email_admin_cc: "Settlement email copy to",
    max_nights_per_year: "Max. Number of nights per year and guest",
    decimal_separator: "Decimal Separator",
    detailed_billing_email: "Show details in Billing email",
    bookingRequestsForLongTermBookings: "Booking requests for long bookings",
    booking_settings: "Booking Settings",
    email_settings: "Email Settings",
  },
  ActivateAccount: {
    title: "Activate Account",
    activate: "Activate",
    activationCode: "Mobile Activation Code",
    activationText: "Before activating and returning to login screen, please confirm your e-Mail address by clicking the link sent to your e-mail account",
    resendSMS: "Re-send SMS with Activation Code",
    activationFailed: "Activation Failed",
    tryAgain: "Please try again",
    smsHasBeenSentTitle: "SMS has been sent",
    smsHasBeenSent: "An SMS has been sent. If you do not receive it, please contact the support",
    mobileInfo: "We have sent you an SMS with an activation code. Please enter the code here: ",
    emailInfo: "You also received an email from us. Please click the link in the email to complete the registration. ",
  },
  MaintainMoorings: {
    title: "Maintain Moorings",
    dailyPrice: "daily price",
    delete: "Delete Mooring",
    edit: "Edit",
    show: "Show",
    times: "Times",
    info: "Info",
    book: "Book",
    addNewMooring: "Add new mooring",
    doYouReallyWantToDeleteMooring: "Do you really want to delete this mooring?",
  },
  MooringTable: {
    berth_number: "Berth number",
    harbour_name: "Marina",
    permanent_renter_names: "Permanent Renter",
    exemption_renter_names: "Exempted for",
    renter_from_date: "Rent from",
    renter_to_date: "Rent to",
    max_length: "Max. length",
    max_beam: "Max. beam",
    max_draught: "Max. draft",
    max_weight: "Max. weight",
    pier_height: "Pier height",
    power: "Power ",
    water: "Water",
    buoy: "Buoy",
    mooring_type: "Mooring type",
    mooring_zone: "Mooring zone",
    marina_notes: "Marina notes",
    app_visible: "App visible",
    additional_description: "Additional Description",
    confidential_description: "Confidential Description",
    editOn: "Edit: on",
    editOff: "Edit: off",
    operating_cost: "Operating Cost",
    yearly_rate: "Yearly Rate",
    payment_currency: "Currency",
    daily_rate_default: "Price/Night",
    mass_edit: "Change Code",
    confidential_code_old: "Current Confidential Code",
    confidential_code_new: "New Confidential Code",
  },
  AddMooringMap: {
    title: "Add Mooring",
    whereIs: "Where is your mooring?",
    zoomIn: "Zoom-in to your marina and indicate your mooring berth by " + "touching and holding a place on the map.",
    apply: "Create mooring",
    desiredLocation: "Desired mooring location?",
  },
  EditMooring: {
    details: "Details",
    occupiedBy: "Mooring usually occupied by",
    coordinates: "Coordinates",
    appVisible: "Visible in app",
    maxLength: "Max. length",
    maxBeam: "Max. beam",
    maxDraught: "Max. draft",
    maxWeight: "Max. weight",
    real_length: "Effective length",
    real_beam: "Effective beam",
    berthNo: "Berth number/identification",
    harbourName: "Marina name",
    additionalDescription: "Additional description",
    confidentialDescription: "Confidential description",
    pierHeight: "Pier height (above water)",
    power: "Power available?",
    water: "Water available?",
    buoy: "Buoy place?",
    boatName: "Boat name",
    port: "Port of registry",
    create: "save",
    additionalDescriptionPlaceholder: "Enter here the link to the port homepage and further information about the berth, which is available to all users.",
    confidentialDescriptionPlaceholder: "Enter additional confidential information, which will only be sent to the user in case of a reservation (e.g. secret code for access to the port facility).",
    marinaNotes: "Marina Notes",
    mooringType: "Mooring Type",
    mooringZone: "Mooring Zone",
    availabilities: "availabilities",
    settings: "Settings",
    maxNights: "Max. nights",
    maxNightsPerYear: "Max. nights per year",
    finance: "Finance",
    yearlyRates: "Yearly Rates",
    transferMooring: "Transfer Mooring",
    transfer: "Transfer",
    transferEmailPlaceholder: "mail@example.com",
    email: "Email",
    operatingCosts: "Operating Costs",
    manualConfirmation: "Manual Confirmation",
    areYouSureDeleteMooring: "Are you sure you wish to delete this mooring?",
    AreaBlockedTitle: "Area Blocked",
    AreaBlocked: "This area is blocked. If you have a mooring here, please contact {{contact_email}}",
    country: "Country",
    checkInTime: "Check-In time",
    checkOutTime: "Check-Out time",
  },
  TransferMooring: {
    modalTitle: "Boatpark Account does not exist",
    modalBody: "There is no Boatpark account with this email. Do you want to send an invitation email to register at Boatpark? The mooring will be automatically transferred after the registration.",
    emailLanguage: "Email language",
    sendEmail: "Send invitation",
  },
  MaintainTimesAndPrices: {
    title: "Maintain Times and Prices",
    feeIncluded: "Marina fee included?",
    currency: "Currency",
    from: "From",
    to: "To",
    pricePerNight: "Price/night",
    addNext: "Add next",
    addNewPeriod: "Add new period",
    dayByDayInfoTitle: "Day-by-day availability",
    dayByDayInfo: "If this field is marked, the mooring can only be booked on same day and only for one night (no advance booking). This allows the mooring owner to cancel the bookable period on a daily basis and make the mooring available for himself in case of earlier return.",
    continueTitle: "Are you sure?",
    continueText: "Do you really want to leave this screen without saving?",
    availabilityPeriods: "Availability Periods",
    calendar: "Calendar",
    askMarinaPermission: "Marina fees may only be included if you are entitled to sublet the mooring: are you allowed to do so?",
    berthWillBeAvailable: "Berth will be released in 30 minutes",
    prices: "Prices",
    defaultPrice: "Default Price",
    tourist_tax_adult: "Tax high tariff per night",
    tourist_tax_child: "Tax low tariff per night",
    name: "Name",
    email: "Email",
    mobile: "Mobile",
    boatRegistrationNumber: "Boat registration number",
    boatDimensions: "Boat dimensions",
    updateSuccessful: "Update successful",
    showBoatDocuments: "Show Boat Documents",
    boatDocuments: "Boat Documents",
    boatName: "Boat name",
  },
  Navbar: {
    home: "Home",
    account: "Account",
    maintainMyBookings: "Maintain my bookings",
    findAndBookMoorings: "Find and book moorings",
    shareMyMoorings: "Share my moorings",
    login: "Login",
    logout: "Logout",
    whatWeDo: "What we do",
    register: "Register",
    reservations: "Reservations",
    contacts: "Contacts",
    moorings: "Moorings",
    waitingList: "Waiting List",
    signedInAs: "Signed in as: ",
  },
  FindMoorings: {
    showRentedMoorings: "Show moorings invisible in app",
    showMarinaMap: "Show marina Map",
    showBerthNumbers: "Show berth numbers",
  },
  BookingOverview: {
    title: "Reservation overview",
    replacing: "replacing...",
    Nu: "ID",
    l: "L",
    b: "B",
    d: "D",
    areYouSureYouWantToRebook: "Are you sure you want to reposition this booking?",
    lengthLower: "Boat beam is too big for the mooring",
    beamLower: "Boat beam is too big for the mooring",
    draftLower: "Boat draft is too big for the mooring",
    weightLower: "Boat is too heavy for the mooring",
    doesBoatStillFit: "Boat dimensions check",
    replaceNotPossible: "Replacement not possible. The booking has an ongoing booking extension.",
  },
  AddBookingModal: {
    title: "Add Booking",
    notes: "Notes",
    notesPlaceholder: "...",
    bookMooring: "Book Mooring",
    berth_number: "Berth Number",
    internal_paid: "Paid",
  },
  BookingDetailsModal: {
    title: "Booking Details",
    replace: "Replace reservation",
    internal_paid: "Paid",
  },
  FilterModal: {
    title: "Filter {{attribute}}",
    min: "min",
    max: "max",
    clear: "Clear",
    apply: "Apply",
  },
  MooringStatus: {
    available: "Mooring Available",
    unavailable: "Mooring Occupied",
    infoAvailable: "This berth is available and can be used for one night.<br> To avoid double reservations, please reserve the berth <span style=\"color: red\">before mooring your boat</span> with 'Instant Booking'.",
    infoUnavailable: "This berth is reserved for tonight and may not be occupied.<br> To find an available berth please contact the harbor master or download the Boatpark-App to find suitable moorings within your area: <a href=\"https://boatpark.app\">https://boatpark.app</a>",
    instantBooking: "Instant Booking",
    noInstantBooking: "This mooring is not available for instant booking. Please book with the app or directly at the marina.",
  },
  validators: {
    required: "required",
    invalid: "invalid",
    priceIsRequired: "price is required",
    periodsShouldNotOverlap: "periods should not overlap",
    tooShort: "too short",
    invalidFormat: "invalid format",
    repeatedPasswordWrong: "passwords do not match",
  },
  links: {
    website: "https://boatpark.app/",
    TCsLink: "https://boatpark.app/home/how-it-works/general-terms-and-conditions-tcs/",
  },
  global: {
    save: "Save",
    create: "Create",
    yes: "Yes",
    no: "No",
    ok: "Ok",
    AreYouSure: "Are you sure?",
    NoOrYes: ["no", "yes"],
    currencys: ["CHF", "EUR", "USD", "GBP"],
    communicationLanguages: ["EN", "DE", "FR", "IT", "ES"],
    noInternetTitle: "No Internet",
    noInternetMessage: "Please connect to the internet and try again",
    loading: "loading",
    contunie: "Continue",
    search: "Search",
    edit: "edit",
    delete: "delete",
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    pleaseSelect: "Please select",
    all: "All",
    year: "Year",
    export: "export",
    cancel: "Cancel",
    apply: "Apply",
    from: "From",
    to: "To",
    dateFormat: "DD.MM.YYYY",
    accept: "Accept",
    decline: "Decline",
  },
  errors: {
    somethingWentWrong: "Something went wrong, please try again.",
    someValuesAreNotCorrect: "Some values aren't correct",
    thisEmailIsAlreadyTaken: "This email is already taken",
    thisMobileIsAlreadyTaken: "This mobile number is already taken. Try to login via Boatpark app to  book this mooring.",
    clubDataUpdated: "This email is already taken",
    pleaseAddAValidCreditCard: "Payment not possible: please enter valid credit card",
    invalidCreditCard: "This creditcard is invalid. Please use other card.",
    pleaseAddPaymentMethod: "Payment not possible: please enter valid credit card",
    doubleBookingsNotPossible: "You already have a reservation in this timeframe: Double bookings are not possible.",
    mooringWasBookedMeantime: "Mooring was booked in the meantime: please check again",
    canNotDeleteBookingtime: "Deletion of availability period not possible: your mooring is already booked during this period",
    refertalDoesNotExist: "Entered referral e-Mail is not an authorized ambassador: please remove",
    invalidMobileNumber: "We do not recognize this mobile number. Please use international format, e.g. +41 79 1234567",
    emailAndPasswordDoNotMatch: "Email and password do not match",
    accountDoesNotExist: "There is no account with this email.",
    pleaseLoginAgain: "Please login again.",
    couldNotDeleteMooring: "Could not delete mooring: bookings exist for this mooring.",
    bookingWithoutOwnMooringNotYetPosible: "Booking without sharing own place only from {{time}} onwards",
    loginFailed: "Could not log you in, please review your email and password",
    pleaseActivateMobile: "Please activate your account with the code from your SMS",
    pleaseActivateEmail: "Please verify your email adress using the link we have sent",
    pleaseAcceptTCs: "Please accept the terms and conditions",
    boatparkAccountEmailDoesNotExist: "This email does not belong to a Boatpark Account",
    pleaseUseMobileApp: "Your account is not authorized to use the Webapp. Please use the Boatpark app on your mobile phone.",
    notValid: "Your mobile activation code is not valid. Please try again.",
    BookingTooExpensive: "This booking is too expensive for FastBooking. Please book via Boatpark App.",
    mobileAlreadyOccupied: "This mobile number does already have a negative balance. Check your SMS/Email to complete the registration process or to pay the outstanding bill first.",
    bookingTooLong: "Reservation only up to max. {{max_nights}} consecutive nights",
    bookingTooLongYear: "Reservation only up to max. {{max_nights}} nights per year",
    paymentFailed: "Payment failed",
  },
};

export default en;
