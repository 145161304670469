import axios from "axios";


export const sendForgotPasswordLink = (email, callback, errorcallback) => {
  axios
    .post(`users/forgot_password`, { email: email })
    .then((response) => {
      if (response.status == 200) {
        callback(response.data);
      }
    })
    .catch((err) => {
      errorcallback(err);
    });
};
