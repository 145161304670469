import React, { useEffect, useState } from "react";
import moment from "moment";
import "../i18n"
import {
  Container,
  Row,
  Button,
  Col,
  Modal,
} from "react-bootstrap";
import FormInput from '../components/FormInput'

import { get_permanent_contact_by_mooring_id, createAssignedContact, updateAssignedContact, deleteAssignedContact } from '../actions/assigned_contacts'
import { getBoatsByContactId } from '../actions/boats';
import { getMyContacts } from '../actions/contacts'
import { getMyMooring } from '../actions/moorings'
import { withTranslation } from 'react-i18next';
import { validateRegisterform } from '../utils/validators';


import TableIcon from '../assets/icons/table.png'
import ContactIcon from '../assets/icons/contact.png'
import COLORS from '../constants/colors';
import globalStyles from '../stylesheets/globalStyles';
import AddNewButton from '../components/AddNewButton';
import EditContactModal from "../components/EditContactModal";
import FormCheckbox from "../components/FormCheckbox";


const i18nPrefix = 'MaintainAssignedContacts';

const styles = {
  AssignedContactActive: { marginBottom: 15, borderRadius: 5, padding: 10, borderColor: COLORS.MAIN_GRAY, borderStyle: 'solid', backgroundColor: COLORS.WHITE_ACTIVE },
  AssignedContactPast: { marginBottom: 15, borderRadius: 5, padding: 10, borderColor: COLORS.MAIN_GRAY, borderStyle: 'solid', backgroundColor: COLORS.GRAY_INACTIVE },
  AssignedContactProvisional: { marginBottom: 15, borderRadius: 5, padding: 10, borderColor: COLORS.MAIN_GRAY, borderStyle: 'solid,', backgroundColor: COLORS.ORANGE_PROVISIONAL },
}

class MaintainAssignedContacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      error: false,
      assigned_contacts: [],
      mooring_id: null,
      mooring: {},
      searchText: false,
      show: false,
      contacts: [],

      //Permanent Renter popup
      permanent_renter: {
        mooring_id: null,
        contact_id: null,
        boat_id: null,
      },
      edit: false,
      boats: [],
      contact_name: ""
    };

    this.EditContactModal = React.createRef();

  }


  componentDidMount() {

    // Load params from url
    if (this.props.match.params.id) {
      let mooring_id = this.props.match.params.id;
      this.setState({ mooring_id: mooring_id, permanent_renter: { ...this.state.permanent_renter, mooring_id: mooring_id, } })
      this.refreshAssignedContacts(mooring_id)
      getMyMooring(mooring_id, (mooring) => { this.setState({ mooring: mooring }) }, () => { })


    }

    if (this.props.location.state) {
      this.setState({ mooring: this.props.location.state.mooring });
    }

    getMyContacts((contacts) => { this.setState({ contacts: contacts }) }, () => { })

    // Load params router
    if (this.props.location.state && this.props.location.state.contact) {
      let contact_id = this.props.location.state.contact.id;

      var waiting_contact_id = null
      if (this.props.location.state.waiting_contact) {
        waiting_contact_id = this.props.location.state.waiting_contact.id
      }

      this.setState({
        permanent_renter: { contact_id: contact_id, waiting_contact_id: waiting_contact_id },
        show: true
      }, () => {
        this.loadBoats()
      });
    }

  }

  refreshAssignedContacts = (mooring_id = this.state.mooring_id) => {
    var now = moment()
    get_permanent_contact_by_mooring_id(mooring_id,
      (assigned_contacts) => {

        // Get active state of assigned_contact
        var general_exemption = false
        assigned_contacts.forEach(assigned_contact => {
          if (moment(assigned_contact.from_date) <= now && moment(assigned_contact.to_date) >= now) { //Active now
            if (assigned_contact.exemption == 1) {
              general_exemption = true;
            }
          }
        });

        assigned_contacts.forEach(assigned_contact => {
          if (moment(assigned_contact.from_date) <= now && moment(assigned_contact.to_date) >= now) { //Active now
            if (general_exemption) {  //mooring is exempted
              if (assigned_contact.exemption == 1) {
                assigned_contact.active = true
              }
              else {
                assigned_contact.active = false
              }
            }
            else { //no exemption
              assigned_contact.active = true
            }
          }
          else { // time period not active
            assigned_contact.active = false
          }
        });


        this.setState({ assigned_contacts: assigned_contacts })
      },
      (err) => { })
    this.setState({ permanent_renter: {} })
  }

  loadBoats = () => {

    if (!this.state.permanent_renter.contact_id) {
      this.setState({ boats: [], permanent_renter: { ...this.state.permanent_renter, boat_id: null } });
    }

    getBoatsByContactId(this.state.permanent_renter.contact_id,
      (boats) => {
        if (boats[0] && !this.state.edit) { //in case of new entry, automatically select first boat
          this.setState({ permanent_renter: { ...this.state.permanent_renter, boat_id: boats[0].id } })
        }
        this.setState({ boats: boats });
      },
      () => { })
  }


  editAssignedContactClicked = (renter) => {
    this.setState({ permanent_renter: renter, edit: true }, () => {
      this.handleShow()
    })
  }

  handleShow = () => {
    this.setState({ show: true });
    this.loadBoats()
  }

  handleClose = () => {
    this.setState({ show: false, permanent_renter: {}, edit: false })
  }


  createButtonClicked = () => {

    createAssignedContact({ ...this.state.permanent_renter, mooring_id: this.state.mooring_id },
      () => {
        this.handleClose()
        this.refreshAssignedContacts()

      }, () => { })
  }


  updateButtonClicked = () => {
    updateAssignedContact(this.state.permanent_renter,
      () => {
        this.handleClose()
        this.refreshAssignedContacts()
      }, () => { })
  }

  deleteButtonClicked = () => {
    deleteAssignedContact(this.state.permanent_renter.id,
      () => {
        this.handleClose()
        this.refreshAssignedContacts()
      }, () => { })
  }

  onClickTableView = () => {
    // console.log("location.pathname", this.props.history.location.pathname)
    this.props.history.push("/ContactTable/", { selecting: true, lastScreen: this.props.history.location.pathname })
  }

  onClickWaitingContacts = () => {
    // console.log("this.state", this.state)
    this.props.history.push("/WaitingContactsTable/", { selecting: true, lastScreen: this.props.history.location.pathname, mooring_id: this.state.mooring_id })
  }

  onContactClicked = (contact) => {
    this.EditContactModal.show(contact)
  }


  render() {

    const { t } = this.props
    const { assigned_contacts, permanent_renter } = this.state

    const role = localStorage.getItem("role");
    const management = role == "management";

    return (
      <Container style={{ maxWidth: 1000, marginTop: 50, borderRadius: 0 }}>

        <EditContactModal
          onRef={ref => (this.EditContactModal = ref)}
        />

        <h1>{t(`${i18nPrefix}.title`)}</h1>

        <div style={{ backgroundColor: "white", borderRadius: 5, paddingLeft: 10, marginBottom: 10 }}>

          {this.state.mooring && this.state.mooring.harbour_name && <Row>
            <Col>
              <FormInput
                label="Platznummer"
                value={`${this.state.mooring.harbour_name} ${this.state.mooring.berth_number}`}
                disabled
                last />
            </Col>
            <Col>
              <FormInput
                label="Platzzone"
                value={this.state.mooring.mooring_zone}
                disabled
                last />
            </Col>
            <Col>
              <FormInput
                label="Platztyp"
                value={this.state.mooring.mooring_type}
                disabled
                last />
            </Col>
            <Col>
              <FormInput
                label="Dimensionen (L/B/T)"
                unit="m"
                value={`${this.state.mooring.max_length}/${this.state.mooring.max_beam}/${this.state.mooring.max_draught}`}
                disabled
                last />
            </Col>

          </Row>
          }
        </div>

        <div style={{ paddingLeft: 10 }}>

          <Row >
            <Col>
              <div
                style={{ fontSize: 20, fontWeight: 'bold' }}>Dauermieter</div>
            </Col>
            <Col>
              <div
                style={{ fontSize: 20 }}>Von</div>
            </Col>
            <Col>
              <div
                style={{ fontSize: 20 }}>Bis</div>
            </Col>

            <Col>
              <div
                style={{ fontSize: 20 }}>Freigestellt</div>
            </Col>
            <Col>
              <div
                style={{ fontSize: 20 }}>Korrespondenz</div>
            </Col>

            {management &&
              <Col>
                <div
                  style={{ fontSize: 20 }}>Bearbeiten</div>
              </Col>
            }
          </Row>
        </div>


        {
          assigned_contacts
            .sort((a, b) => a.to_date < b.to_date ? 1 : -1)
            .map((renter) => {

              var renter_name = `${renter.contact.firstname} ${renter.contact.lastname}`
              if (renter.provisional == 1) renter_name = `(${renter_name})`



              return <div
                style={renter.provisional != 1 ? renter.active == 1 ? styles.AssignedContactActive : styles.AssignedContactPast : styles.AssignedContactProvisional}>
                <Row >
                  <Col>
                    <div
                      onClick={() => this.onContactClicked(renter.contact)}
                      style={{ fontSize: 20, fontWeight: 'bold', }}
                      className="pointer_on_hover">{renter_name}</div>
                  </Col>
                  <Col>
                    <div
                      style={{ fontSize: 20 }}>{moment(renter.from_date).format('DD.MM.YYYY')} </div>
                  </Col>
                  <Col>
                    <div
                      style={{ fontSize: 20 }}>{moment(renter.to_date).format('DD.MM.YYYY')} </div>
                  </Col>

                  <Col>
                    <div
                      style={{ fontSize: 20 }}>{renter.exemption == 1 ? "Ja" : ""}</div>
                  </Col>
                  <Col>
                    <div
                      style={{ fontSize: 20 }}>{renter.correspondence == 1 ? "Ja" : ""}</div>
                  </Col>
                  {management &&
                    <Col>
                      <Button style={globalStyles.normalButton} onClick={() => { this.editAssignedContactClicked(renter) }}>{t(`global.edit`)}</Button>
                    </Col>
                  }

                </Row>
                <Row>
                  <Col>
                    <div
                      style={{ fontSize: 20 }}>{renter.notes}</div>
                  </Col>
                </Row>

              </div >

            })
        }

        {management &&
          <AddNewButton
            center
            label={t(`${i18nPrefix}.addRenter`)}
            onClick={this.handleShow}
          />
        }

        <Modal show={this.state.show} onHide={this.handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.edit ? t(`${i18nPrefix}.editRenter`) : t(`${i18nPrefix}.addRenter`)}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>

              <Col md={9}>

                <FormInput as="select"
                  label={t(`${i18nPrefix}.contact`)}
                  type="text"
                  value={this.state.permanent_renter.contact_id}
                  onChange={(e) => {
                    this.setState({ permanent_renter: { ...permanent_renter, contact_id: e.target.value }, }, () => {
                      this.loadBoats();
                    })
                  }}
                >
                  <option key={0}>{t(`global.pleaseSelect`)}</option>
                  {
                    this.state.contacts
                      .sort((a, b) => a.lastname > b.lastname ? 1 : -1)
                      .map((contact) =>
                        <option key={contact.id} value={contact.id}>{`${contact.lastname} ${contact.firstname} (${contact.street} ${contact.zip}, ${contact.city})`}</option>
                      )
                  }

                </FormInput>

              </Col >

              <Col md={3}>

                <img
                  style={{ width: 36, marginTop: 20, float: "right" }}
                  onClick={this.onClickTableView}
                  src={TableIcon}
                  alt="tableicon"
                />
                {this.state.permanent_renter.contact &&
                  <img
                    style={{ width: 36, marginTop: 20, float: "right" }}
                    onClick={() => { this.EditContactModal.show(this.state.permanent_renter.contact) }}
                    src={ContactIcon}
                    alt="tableicon"
                  />
                }
              </Col>

            </Row>
            <Button
              onClick={this.onClickWaitingContacts}>
              Von Warteliste wählen
            </Button>

            <FormInput as="select"
              label={t(`${i18nPrefix}.boat`)}
              type="text"
              value={this.state.permanent_renter.boat_id}
              onChange={(e) => {
                const selected_boat_id = e.target.value == "none" ? null : e.target.value // Select does not take "null" as key
                this.setState({ permanent_renter: { ...permanent_renter, boat_id: selected_boat_id } })
              }}
            >
              <option key={0} value={"none"}>{"-"}</option>

              {
                this.state.boats
                  .map((boat) =>
                    <option key={boat.id} value={boat.id}>{`${boat.registration_number}, ${boat.name} (${boat.length} ${boat.beam}, ${boat.draft})`}</option>
                  )
              }

            </FormInput>


            <div />

            <FormInput
              label=
              {t(`${i18nPrefix}.from`)}
              type="date"
              value={permanent_renter.from_date}
              onChange={(e) => { this.setState({ permanent_renter: { ...this.state.permanent_renter, from_date: e.target.value } }) }}
            />

            <FormInput
              label={t(`${i18nPrefix}.to`)}
              type="date"
              value={permanent_renter.to_date}
              onChange={(e) => { this.setState({ permanent_renter: { ...this.state.permanent_renter, to_date: e.target.value } }) }}
            />

            <FormInput as="textarea"
              label={t(`${i18nPrefix}.notes`)}
              type="textarea"
              rows={2}
              value={permanent_renter.notes}
              onChange={(e) => { this.setState({ permanent_renter: { ...this.state.permanent_renter, notes: e.target.value } }) }}
            />

            <FormCheckbox
              label={t(`${i18nPrefix}.correspondence`)}
              checked={this.state.permanent_renter.correspondence == 1}
              onChange={(e) => { this.setState({ permanent_renter: { ...permanent_renter, correspondence: e.target.checked } }) }}
            />


            <FormCheckbox
              label={t(`${i18nPrefix}.exemption`)}
              checked={this.state.permanent_renter.exemption == 1}
              onChange={(e) => { this.setState({ permanent_renter: { ...permanent_renter, exemption: e.target.checked } }) }}
            />

            <FormCheckbox
              label={t(`${i18nPrefix}.provisional`)}
              checked={this.state.permanent_renter.provisional == 1}
              onChange={(e) => { this.setState({ permanent_renter: { ...permanent_renter, provisional: e.target.checked } }) }}
            />




          </Modal.Body>
          <Modal.Footer>

            {
              this.state.edit == false &&



              <Button
                style={globalStyles.saveButton}
                onClick={this.createButtonClicked}>
                {t(`global.create`)}
              </Button>

            }

            {
              this.state.edit == true &&
              <>
                <Button
                  style={globalStyles.deleteButton}
                  onClick={this.deleteButtonClicked}>
                  {t(`global.delete`)}

                </Button>

                <Button
                  style={globalStyles.saveButton}
                  onClick={this.updateButtonClicked}>
                  {t(`global.save`)}
                </Button>
              </>
            }


          </Modal.Footer>
        </Modal>

      </Container >



    );
  }
}


export default withTranslation()(MaintainAssignedContacts)

