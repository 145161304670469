import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import moment from "moment";
import "../i18n"
import {
    Row,
    Button,
    Col,
    Modal,
} from "react-bootstrap";

import FormInput from './FormInput'
import FormCheckbox from '../components/FormCheckbox'


import { withTranslation } from 'react-i18next';
import { validateCreateWaitingContactForm } from '../utils/validators';

import { createBoat, updateBoat, deleteBoat } from '../actions/boats'
import { createWaitingContact, getWaitingContacts, updateWaitingContact, deleteWaitingContact } from '../actions/waiting_contacts';
import { getMyMooringTypes, getMyMooringZones } from '../actions/moorings';
import { getMyContacts } from '../actions/contacts'

import StatusAlerts from "./StatusAlerts";
import TableIcon from '../assets/icons/table.png'
import globalStyles from '../stylesheets/globalStyles';
import { CURRENCIES } from '../constants/constants';
import { getMyAccountData } from "../actions/account"





const i18nPrefix = 'EditWaitingContactModal';


const emptyWaitingContact = {
    entry_date: moment().format("YYYY-MM-DD"),
    payment_period: 1,
};


class EditWaitingContactModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            create: false, //Are we creating or editing a boat
            loading: false,
            success: false,
            show: false,
            contacts: [],
            mooring_types: [],
            mooring_zones: [],
            original_waiting_contact: emptyWaitingContact,
            waiting_contact: emptyWaitingContact,
            edit: false,
            account: {},
        };
    }



    componentDidMount() {
        this.props.onRef && this.props.onRef(this)
        getMyContacts((contacts) => { this.setState({ contacts: contacts }) }, () => { })
        getMyMooringTypes((mooring_types) => { this.setState({ mooring_types: mooring_types }) }, () => { })
        getMyMooringZones((mooring_zones) => { this.setState({ mooring_zones: mooring_zones }) }, () => { })
        getMyAccountData((account) => { this.setState({ account: account }) }, () => { })

    }

    show = (waiting_contact) => {
        const default_values = {
            ...emptyWaitingContact,
            payment_period: this.state.account.default_payment_period,
            periodic_fee: this.state.account.default_periodic_fee,
            registration_fee: this.state.account.default_registration_fee,
            currency: this.state.account.default_currency,
        }

        if (waiting_contact) { //edit
            if (waiting_contact.id) {

                this.setState({
                    original_waiting_contact: { ...emptyWaitingContact, ...waiting_contact },
                    waiting_contact: { ...emptyWaitingContact, ...waiting_contact },
                    show: true,
                    edit: true
                })
            }
            else { //Select from contact table with saved previous state

                const prevState = this.props.location.state.prevState || {}
                this.setState({ ...prevState, test: 1, waiting_contact: { ...prevState.waiting_contact, contact_id: waiting_contact.contact_id } })
            }
        }
        else {
            this.setState({//create
                waiting_contact: {
                    ...default_values
                }, contact_id: null, show: true, edit: false
            })
        }
    }

    handleShow = () => {
        this.setState({ show: true })
    }

    handleClose = () => {
        this.setState({ show: false, waiting_contact: emptyWaitingContact, edit: false })
        this.props.onClose && this.props.onClose();
    }

    onClickTableView = () => {
        this.props.history.push("/ContactTable/", { selecting: true, lastScreen: this.props.history.location.pathname, prevState: this.state })
    }



    createButtonClicked = () => {
        const noErrors = Object.values(validateCreateWaitingContactForm(this.state.waiting_contact)).every(x => (x == false));
        if (noErrors) {
            createWaitingContact(this.state.waiting_contact,
                () => {
                    this.props.onSave && this.props.onSave();
                    this.handleClose()
                    this.refreshWaitingContacts()
                },
                () => { })
        }
        else { //form errors
            this.setState({ error: true, errorMessage: this.props.t('errors.someValuesAreNotCorrect') });
        }


    }


    updateButtonClicked = () => {
        updateWaitingContact(this.state.waiting_contact,
            () => {
                this.props.onSave && this.props.onSave();
                this.handleClose()
                this.refreshWaitingContacts()
            },
            () => { })
    }

    deleteButtonClicked = () => {
        window.confirm(this.props.t(`${i18nPrefix}.areYouSureDeleteEntry`)) &&
            deleteWaitingContact(this.state.waiting_contact.id,
                () => {
                    this.props.onSave();
                    this.handleClose()
                    this.refreshWaitingContacts()
                }, () => { })
    }


    editBoatClicked = () => {

        const formErrors = validateCreateWaitingContactForm(this.state.waiting_contact);
        const noErrors = Object.values(formErrors).every(x => (x == false));
        if (!noErrors) {
            this.setState({ error: true, errorMessage: this.props.t('errors.someValuesAreNotCorrect') });
            return;
        }


        else { //no form errors
            this.setState({ loading: true, success: false, error: false }, () => {
                updateBoat(this.state.boat,
                    () => {
                        this.setState({ loading: false, success: true, error: false });
                        this.handleClose();
                    },
                    () => {
                        this.setState({ loading: false, success: false, error: true })
                    })
            });
        }
    }


    deleteBoatClicked = () => {
        window.confirm("Are you sure you wish to delete this boat?") &&
            deleteBoat(this.state.boat.id,
                () => {
                    this.setState({ loading: false, success: true, error: false })
                    this.handleClose();
                },
                (err) => { })
    }



    render() {

        const { t } = this.props
        const { waiting_contact } = this.state
        const formErrors = validateCreateWaitingContactForm(this.state.waiting_contact);
        const boat_types = t(`EditBoatModal.boatTypes`)


        return (

            <>
                <Modal show={this.state.show} onHide={this.handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.edit ? t(`${i18nPrefix}.editReservation`) : t(`${i18nPrefix}.addReservation`)}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <Row>

                            <Col md={10}>

                                <FormInput as="select"
                                    error={formErrors.contact_id}
                                    label={t(`${i18nPrefix}.contact`)}
                                    type="text"
                                    value={waiting_contact.contact_id}
                                    onChange={(e) => {
                                        this.setState({ waiting_contact: { ...waiting_contact, contact_id: e.target.value } })
                                    }}
                                >
                                    <option key={0}>{t(`global.pleaseSelect`)}</option>
                                    {
                                        this.state.contacts
                                            .sort((a, b) => a.lastname > b.lastname ? 1 : -1)
                                            .map((contact) =>
                                                <option key={contact.id} value={contact.id}>{`${contact.lastname} ${contact.firstname} (${contact.street} ${contact.zip}, ${contact.city})`}</option>
                                            )
                                    }

                                </FormInput>
                            </Col >
                            <Col md={2}>
                                <img
                                    style={{ width: 36, marginTop: 20, float: "right" }}
                                    onClick={this.onClickTableView}
                                    src={TableIcon}
                                    alt="tableicon"
                                />
                            </Col>
                        </Row>


                        <FormInput
                            error={formErrors.entry_date}
                            label={t(`${i18nPrefix}.entryDate`)}
                            type="date"
                            value={waiting_contact.entry_date}
                            onChange={(e) => { this.setState({ waiting_contact: { ...waiting_contact, entry_date: e.target.value } }) }}
                        />
                        <Row>

                            <Col>
                                <FormInput as="select"
                                    label={t(`${i18nPrefix}.mooring_zone`)}
                                    type="text"
                                    value={waiting_contact.mooring_zone}
                                    onChange={(e) => { this.setState({ waiting_contact: { ...waiting_contact, mooring_zone: e.target.value } }) }}
                                >
                                    <option value='' >{t(`global.all`)}</option>
                                    {this.state.mooring_zones.map((mooring_zone) => {
                                        return <option key={mooring_zone.id} value={mooring_zone.zone}>{mooring_zone.zone}</option>
                                    })}
                                </FormInput>

                            </Col>

                            <Col>
                                <FormInput as="select"
                                    label={t(`${i18nPrefix}.mooring_type`)}
                                    type="text"
                                    value={waiting_contact.mooring_type}
                                    onChange={(e) => { this.setState({ waiting_contact: { ...waiting_contact, mooring_type: e.target.value } }) }}
                                >
                                    <option value='' >{t(`global.all`)}</option>

                                    {this.state.mooring_types.map((mooring_type) => {
                                        return <option key={mooring_type.id} value={mooring_type.mooring_type}>{mooring_type.mooring_type}</option>
                                    })}
                                </FormInput>
                            </Col>

                        </Row>
                        <FormInput as="select"
                            label={t(`${i18nPrefix}.boat_type`)}
                            type="text"
                            value={waiting_contact.boat_type}
                            onChange={(e) => { this.setState({ waiting_contact: { ...waiting_contact, boat_type: e.target.value } }) }}
                        >
                            <option value='' >{t(`global.all`)}</option>
                            {boat_types.map((boat_type) => {
                                return <option key={boat_type} value={boat_type}>{boat_type}</option>
                            })}
                        </FormInput>


                        <Row>
                            <Col md={4}>

                                <FormInput
                                    label={t(`${i18nPrefix}.length`)}
                                    type="number"
                                    value={waiting_contact.min_length}
                                    onChange={(e) => { this.setState({ waiting_contact: { ...waiting_contact, min_length: e.target.value } }) }}
                                />
                            </Col>
                            <Col md={4}>

                                <FormInput
                                    label={t(`${i18nPrefix}.beam`)}
                                    type="number"
                                    value={waiting_contact.min_beam}
                                    onChange={(e) => { this.setState({ waiting_contact: { ...waiting_contact, min_beam: e.target.value } }) }}
                                />

                            </Col>
                            <Col md={4}>

                                <FormInput
                                    label={t(`${i18nPrefix}.draft`)}
                                    type="number"
                                    value={waiting_contact.min_draught}
                                    onChange={(e) => { this.setState({ waiting_contact: { ...waiting_contact, min_draught: e.target.value } }) }}
                                />
                            </Col>



                        </Row>



                        <FormCheckbox
                            label={t(`${i18nPrefix}.relocation`)}
                            checked={this.state.waiting_contact.relocation}
                            onChange={(e) => {
                                this.setState({
                                    waiting_contact: { ...waiting_contact, relocation: e.target.checked },
                                })
                            }} />

                        <FormCheckbox
                            label={t(`${i18nPrefix}.annual_mooring_interested`)}
                            checked={this.state.waiting_contact.annual_mooring_interested}
                            onChange={(e) => {
                                this.setState({
                                    waiting_contact: { ...waiting_contact, annual_mooring_interested: e.target.checked },
                                })
                            }} />

                        <FormInput as="textarea"
                            label={t(`${i18nPrefix}.comment`)}
                            type="text"
                            value={waiting_contact.comment}
                            onChange={(e) => { this.setState({ waiting_contact: { ...waiting_contact, comment: e.target.value } }) }}
                        />

                        <FormInput
                            label={t(`${i18nPrefix}.last_examination`)}
                            type="date"
                            value={waiting_contact.last_examination}
                            onChange={(e) => { this.setState({ waiting_contact: { ...waiting_contact, last_examination: e.target.value } }) }}
                        />

                        <FormCheckbox
                            label={t(`${i18nPrefix}.archived`)}
                            checked={this.state.waiting_contact.archived}
                            onChange={(e) => {
                                if (this.state.original_waiting_contact.archived && this.state.waiting_contact.assigned_contacts.length > 0) {
                                    alert(t(`${i18nPrefix}.WaitingContactShouldNotBeUnarchived`));
                                    return;
                                }
                                this.setState({
                                    waiting_contact: { ...waiting_contact, archived: e.target.checked },
                                })
                            }} />
                        {this.state.waiting_contact.archived &&
                            <>
                                <FormInput
                                    label={t(`${i18nPrefix}.archiving_reason`)}
                                    type="text"
                                    value={waiting_contact.archiving_reason}
                                    onChange={(e) => { this.setState({ waiting_contact: { ...waiting_contact, archiving_reason: e.target.value } }) }}
                                />

                                <FormInput
                                    label={t(`${i18nPrefix}.archiving_date`)}
                                    type="date"
                                    value={waiting_contact.archiving_date}
                                    onChange={(e) => { this.setState({ waiting_contact: { ...waiting_contact, archiving_date: e.target.value } }) }}
                                />
                            </>
                        }

                        <Row>
                            <Col md={8}>

                                <FormInput
                                    label={t(`${i18nPrefix}.registration_fee`)}
                                    type="number"
                                    value={waiting_contact.registration_fee}
                                    onChange={(e) => { this.setState({ waiting_contact: { ...waiting_contact, registration_fee: e.target.value } }) }}
                                />
                            </Col>

                            <Col md={4}>


                            </Col>
                        </Row>

                        <Row>
                            <Col md={8}>
                                <FormInput
                                    label={t(`${i18nPrefix}.periodic_fee`)}
                                    type="number"
                                    value={waiting_contact.periodic_fee}
                                    onChange={(e) => { this.setState({ waiting_contact: { ...waiting_contact, periodic_fee: e.target.value } }) }}
                                />
                            </Col>
                            <Col md={4}>

                                <FormInput as="select"
                                    label={t(`${i18nPrefix}.currency`)}
                                    value={waiting_contact.currency}
                                    onChange={(text) => {
                                        this.setState({
                                            waiting_contact: { ...waiting_contact, currency: text.target.value },
                                        });
                                    }}
                                >
                                    {CURRENCIES.map((currency, index) => {
                                        return <option key={index} value={currency}>{currency}</option>
                                    }
                                    )}

                                </FormInput>
                            </Col>
                        </Row>

                        <FormInput
                            label={t(`${i18nPrefix}.payment_period`)}
                            type="number"
                            value={waiting_contact.payment_period}
                            onChange={(e) => { this.setState({ waiting_contact: { ...waiting_contact, payment_period: e.target.value } }) }}
                        />
                        <StatusAlerts
                            state={this.state}
                            errorMessage={this.state.errorMessage}
                        />
                    </Modal.Body>
                    <Modal.Footer>




                        {
                            this.state.edit == false &&
                            <Button
                                style={globalStyles.saveButton}
                                onClick={this.createButtonClicked}>
                                {t(`global.save`)}
                            </Button>
                        }

                        {
                            this.state.edit == true &&

                            <>
                                <Button
                                    style={globalStyles.deleteButton}
                                    onClick={this.deleteButtonClicked}>
                                    {t(`global.delete`)}

                                </Button>
                                <Button
                                    style={globalStyles.saveButton}
                                    onClick={this.updateButtonClicked}>
                                    {t(`global.save`)}
                                </Button>
                            </>
                        }


                    </Modal.Footer>
                </Modal>
            </>

        );

    }
}



export default withTranslation()(withRouter(EditWaitingContactModal));



