import COLORS from '../constants/colors';



const globalStyles = {
    button: {
        backgroundColor: "white",
        color: COLORS.MAIN_GRAY,
        fontWeight: "bold",
        borderColor: COLORS.MAIN_GRAY,
        marginRight: 10,
        marginTop: 5,
        marginBottom: 5,
    },



}


export default globalStyles;